// Copyright ©️ 2024 eVolve MEP, LLC
import './attachments.css';
import { useCallback, useEffect, useMemo } from 'react';

import { Flex } from '@mantine/core';

import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { isNil, isNotNil } from 'helpers/isNotNil';
import type { TaskId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import type { WorkRequest, WorkRequestId } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import { useWorkRequests } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/useWorkRequests';
import type { WorkCellTask } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';
import type { WorkOrder, WorkOrderId } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';
import { useWorkOrders } from 'modules/Shop/WorkOrders/WorkOrdersPage/useWorkOrders';

import { AttachmentSection } from './AttachmentSection';
import { getDocumentColumnDef } from './columnDefs';
import type { Document, DocumentSkeleton, TaskDocument, WorkOrderDocument, WorkRequestDocument } from './types';
import { useDocuments } from './useDocuments';
import { getSelectedRowNumber } from './utils';

type SubProps =
  | {
      workRequest: WorkRequest;
      workRequestUpdated?: (updatedWR: WorkRequest) => void;
      workOrder?: never;
      workOrderUpdated?: never;
      workCellTask?: never;
      workCellTaskUpdated?: never;
    }
  | {
      workOrder: WorkOrder;
      workOrderUpdated?: (updatedWO: WorkOrder) => void;
      workRequest?: never;
      workRequestUpdated?: never;
      workCellTask?: never;
      workCellTaskUpdated?: never;
    }
  | {
      workCellTask: WorkCellTask;
      workCellTaskUpdated?: (updatedWCT: WorkCellTask) => void;
      workOrder?: never;
      workRequest?: never;
      workRequestUpdated?: never;
      workOrderUpdated?: never;
    };

export type AttachmentSectionName = 'Task' | 'Work Order' | 'Work Request';

type Props = (
  | ({
      readOnly?: false;
    } & SubProps)
  | ({
      readOnly: true;
      workRequestId: WorkRequestId;
      workOrderId?: WorkOrderId;
      taskId?: TaskId;
    } & Partial<SubProps>)
) & {
  onDocumentSelected?: (document: Document, rowIndex: number, section: AttachmentSectionName) => void;
  onDocumentCountLoaded?: (count: number) => void;
} & {
  selectedRow?: number;
  selectedSection?: AttachmentSectionName;
  onRowRealized?: (documentSkeleton: DocumentSkeleton, rowIndex: number, section: AttachmentSectionName) => void;
};

export const AttachmentsList = ({
  onDocumentCountLoaded,
  onDocumentSelected,
  selectedRow,
  selectedSection,
  onRowRealized,
  ...props
}: Props) => {
  const { readOnly = false } = props;
  const { getWorkOrder } = useWorkOrders();
  const { getWorkRequest } = useWorkRequests();
  const workRequestId = useMemo(() => {
    if (props.readOnly) {
      return props.workRequestId;
    }
    if (props.workCellTask) {
      return props.workCellTask.workRequestId;
    }
    if (props.workOrder) {
      return props.workOrder.workRequest.workRequestId;
    }
    return props.workRequest.workRequestId;
  }, [props]);

  const workOrderId = useMemo(() => {
    if (props.readOnly) {
      return props.workOrderId;
    }
    if (props.workCellTask) {
      return props.workCellTask.workOrderId;
    }
    return props.workOrder?.workOrderId;
  }, [props]);

  const taskId = useMemo(() => {
    if (props.readOnly) {
      return props.taskId;
    }
    return props.workCellTask?.taskId;
  }, [props]);

  const { ready, fetchWorkRequestDocs, fetchWorkOrderDocs, fetchTaskDocs, totalDocs } = useDocuments({
    workRequestId,
    workOrderId,
    taskId,
  });
  useEffect(() => {
    if (isNotNil(totalDocs)) {
      onDocumentCountLoaded?.(totalDocs);
    }
  }, [totalDocs, onDocumentCountLoaded]);

  const refreshParent = useCallback(() => {
    if (props.readOnly) return;
    if (isNotNil(workOrderId)) {
      void getWorkOrder(workOrderId).then(props.workOrderUpdated);
    } else if (isNotNil(workRequestId)) {
      void getWorkRequest(workRequestId).then(props.workRequestUpdated);
    }
  }, [
    getWorkOrder,
    getWorkRequest,
    props.readOnly,
    props.workOrderUpdated,
    props.workRequestUpdated,
    workOrderId,
    workRequestId,
  ]);

  const wrColDef = useMemo(
    () =>
      getDocumentColumnDef<WorkRequestDocument>(
        readOnly || isNil(props.workRequest),
        refreshParent,
        onDocumentSelected,
      ),
    [readOnly, props.workRequest, refreshParent, onDocumentSelected],
  );
  const { agGridProps: wrDocsAgGridProps, refreshGrid: refreshWRdocs } = useServerSideGrid({
    tableName: 'work-request-attachments',
    fetchPage: fetchWorkRequestDocs,
    colDef: wrColDef,
    rowId: 'workRequestDocumentId',
    saveColumns: false,
    saveFilters: false,
  });
  const woColDef = useMemo(
    () =>
      getDocumentColumnDef<WorkOrderDocument>(readOnly || isNil(props.workOrder), refreshParent, onDocumentSelected),
    [readOnly, props.workOrder, refreshParent, onDocumentSelected],
  );
  const { agGridProps: woDocsAgGridProps, refreshGrid: refreshWOdocs } = useServerSideGrid({
    tableName: 'work-order-attachments',
    fetchPage: fetchWorkOrderDocs,
    colDef: woColDef,
    rowId: 'workOrderDocumentId',
    saveColumns: false,
    saveFilters: false,
  });
  const taskColDef = useMemo(
    () => getDocumentColumnDef<TaskDocument>(readOnly || isNil(props.workCellTask), refreshParent, onDocumentSelected),
    [readOnly, props.workCellTask, refreshParent, onDocumentSelected],
  );
  const { agGridProps: taskDocsAgGridProps, refreshGrid: refreshTaskDocs } = useServerSideGrid({
    tableName: 'task-attachments',
    fetchPage: fetchTaskDocs,
    colDef: taskColDef,
    rowId: 'taskDocumentId',
    saveColumns: false,
    saveFilters: false,
  });

  useEffect(() => {
    if (isNotNil(selectedRow) && isNotNil(onRowRealized)) {
      const taskRows = taskDocsAgGridProps.ref.current?.api.getDisplayedRowCount() ?? 0;
      const woRows = woDocsAgGridProps.ref.current?.api.getDisplayedRowCount() ?? 0;
      const wrRows = wrDocsAgGridProps.ref.current?.api.getDisplayedRowCount() ?? 0;
      const { sectionName, count: rowNumber } = getSelectedRowNumber(selectedSection ?? 'Task', selectedRow, [
        {
          sectionName: 'Task',
          count: taskRows,
        },
        {
          sectionName: 'Work Order',
          count: woRows,
        },
        {
          sectionName: 'Work Request',
          count: wrRows,
        },
      ]);

      let documentSkeleton: DocumentSkeleton | undefined;
      if (sectionName === 'Task') {
        documentSkeleton = taskDocsAgGridProps.ref.current?.api.getDisplayedRowAtIndex(rowNumber)?.data;
      } else if (sectionName === 'Work Order') {
        documentSkeleton = woDocsAgGridProps.ref.current?.api.getDisplayedRowAtIndex(rowNumber)?.data;
      } else {
        documentSkeleton = wrDocsAgGridProps.ref.current?.api.getDisplayedRowAtIndex(rowNumber)?.data;
      }
      if (isNotNil(documentSkeleton)) {
        onRowRealized(documentSkeleton, rowNumber, sectionName);
      }
    }
  }, [
    onRowRealized,
    selectedRow,
    selectedSection,
    taskDocsAgGridProps.ref,
    woDocsAgGridProps.ref,
    wrDocsAgGridProps.ref,
  ]);

  // AG Grid is now ready so fast,
  // our useEffects often haven't had time to run before onGridReady
  // This is a hacky solution - I'd love to find a better one.
  if (!ready) return null;

  return (
    <Flex direction="column">
      {/* Work Request view doesn't show WO Attachments or Task Attachments */}
      {isNotNil(workOrderId) && (
        <>
          <AttachmentSection
            section="Tasks"
            agGridProps={taskDocsAgGridProps}
            {...(!props.readOnly && !!props.workCellTask
              ? {
                  active: true,
                  refreshTaskDocs: () => {
                    refreshTaskDocs();
                    refreshParent();
                  },
                  workCellTask: props.workCellTask,
                }
              : {
                  active: false,
                })}
          />
          <AttachmentSection
            section="Work Order"
            agGridProps={woDocsAgGridProps}
            {...(!props.readOnly && !!props.workOrder
              ? {
                  active: true,
                  refreshWOdocs: () => {
                    refreshWOdocs();
                    refreshParent();
                  },
                  workOrder: props.workOrder,
                }
              : {
                  active: false,
                })}
          />
        </>
      )}
      <AttachmentSection
        section="Work Request"
        agGridProps={wrDocsAgGridProps}
        {...(!props.readOnly && props.workRequest
          ? {
              active: !readOnly,
              refreshWRdocs: () => {
                refreshWRdocs();
                refreshParent();
              },
              workRequest: props.workRequest,
            }
          : { active: false })}
      />
    </Flex>
  );
};
