// Copyright ©️ 2024 eVolve MEP, LLC
import type React from 'react';

import type { StatusKey, WorkOrderStatusTypeName, WorkRequestStatusName } from 'constants/badgeMappingStatus';
import type { Facility, FacilityId } from 'hooks/projectsAndFacilities/useFacilities';
import type { Project, ProjectId } from 'hooks/projectsAndFacilities/useProjects';
import type {
  WorkRequestId,
  WorkRequestStatusTypeId,
} from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';

import type { WorkOrderId, WorkOrderStatusTypeId } from '../WorkOrders/WorkOrdersPage/types';

export type StatusTotals = {
  onTime: number;
  late: number;
};

const chartStatuses: Exclude<StatusKey, 'Transferred' | 'Started' | 'Paused'>[] = [
  'Draft',
  'Not Started',
  'In Progress',
  'Blocked',
  'Completed',
  'Late',
  'Canceled',
  'Submitted',
  'Pending',
  'Approved',
  'Rejected',
] as const;

type ChartStatus = (typeof chartStatuses)[number];

type LateChartStatus = Exclude<ChartStatus, 'Late' | 'Canceled'>;

const insightsLateStatusKeyNames: `${LateChartStatus} - Late`[] = [
  'Draft - Late',
  'Rejected - Late',
  'Submitted - Late',
  'Pending - Late',
  'Approved - Late',
  'Not Started - Late',
  'In Progress - Late',
  'Completed - Late',
  'Blocked - Late',
] as const;

export const insightsStatusTypeNames = [...chartStatuses, ...insightsLateStatusKeyNames] as const;

export type InsightsStatusTypeName = (typeof insightsStatusTypeNames)[number];

export type ChartDataPoints = {
  Month: string;
} & { [k in ChartStatus]: number } & { [k in `${LateChartStatus} - Late`]: number };

export type InsightsReq = {
  currentDate: string; // timestamp;
  facilities: (FacilityId | 'All Facilities')[];
  projects: (ProjectId | 'All Projects')[];
  timeFrame: TimeframeOption | null;
  specificDate?: Date;
  beginDate?: Date;
  endDate?: Date;
  statuses: (WorkOrderStatusTypeId | WorkRequestStatusTypeId | 'All Statuses')[];
};

export type NonParamFilters = { selectedRecordType: RecordOption | null };

export type WorkRequestsOverTimeRes = {
  completedWorkRequests: number;
  percentageChangeFromPreviousTimeFrameForCompletedWorkRequests: number;
  lateWorkRequests: number;
  percentageChangeFromPreviousTimeFrameForLateWorkRequests: number;
  percentageOfLateVersusTotalWorkRequests: number;
  rejectedWorkRequests: number;
  percentageChangeFromPreviousTimeFrameForRejectedWorkRequests: number;
  averageOrderLeadTime: number;
  percentageChangeFromPreviousTimeFrameForAverageOrderLeadTime: number;
  chartDataPoints: ChartDataPoints[];
};

export type WorkOrdersOverTimeRes = {
  completedWorkOrders: number;
  percentageChangeFromPreviousTimeFrameForCompletedWorkOrders: number;
  lateWorkOrders: number;
  percentageChangeFromPreviousTimeFrameForLateWorkOrders: number;
  percentageOfLateVersusTotalWorkOrders: number;
  averageManufacturingLeadTime: number;
  percentageChangeFromPreviousTimeFrameForAverageManufacturingLeadTime: number;
  averageProductionLeadTime: number;
  percentageChangeFromPreviousTimeFrameForAverageProductionLeadTime: number;
  chartDataPoints: ChartDataPoints[];
};

export type InsightsDetailsWorkOrder = {
  workOrderId: WorkOrderId;
  projectName: string;
  facilityName: string;
  workOrderName: string;
  workOrderDescription: string;
  status: WorkOrderStatusTypeName;
  needBy: string; // date only, MM/DD/YYYY
  daysRemaining: number;
  dateApproved: string; // date only, MM/DD/YYYY
  dateInProgress: string; // date only, MM/DD/YYYY
  dateCompleted: string; // date only, MM/DD/YYYY
  daysEarly: number;
  daysLate: number;
  manufacturingLeadTimeDays: number;
  productionLeadTimeDays: number;
};

export type InsightsDetailsWorkRequest = {
  workRequestId: WorkRequestId;
  projectName: string;
  facilityName: string;
  workRequestName: string;
  workRequestDescription: string;
  status: WorkRequestStatusName;
  needBy: string; // date only, MM/DD/YYYY
  daysRemaining: number;
  dateLastSpoolPublished: string; // date only, MM/DD/YYYY
  submittedBy: string;
  dateSubmitted: string; // date only, MM/DD/YYYY
  dateApproved: string; // date only, MM/DD/YYYY
  dateInProgress: string; // date only, MM/DD/YYYY
  dateCompleted: string; // date only, MM/DD/YYYY
  daysEarly: number;
  daysLate: number;
  orderLeadTimeDays: number;
};

export type InsightsStatusBadgeType = {
  status: StatusKey;
  daysRemaining: number;
} & ({
  status: 'Completed';
} & (
  | {
      daysEarly: number;
      daysLate?: never;
    }
  | {
      daysLate: number;
      daysEarly?: never;
    }
));

export const recordOptions = ['Work Orders', 'Work Requests'] as const;

export type RecordOption = (typeof recordOptions)[number];

export const timeframeOptions = [
  'This Year',
  'This Month',
  'This Week',
  'Last Year',
  'Last Month',
  'Last Week',
  'Specific Date',
  'Date Range',
] as const;

export type TimeframeOption = (typeof timeframeOptions)[number];

export type InsightsContextType = {
  loading: boolean;
  generateReqData: () => false | void;
  lastUpdated: string | null;
  displayedData: (WorkRequestsOverTimeRes | WorkOrdersOverTimeRes) | null;
  woDetailedData: InsightsDetailsWorkOrder[] | null;
  setWoDetailedData: React.Dispatch<React.SetStateAction<InsightsDetailsWorkOrder[] | null>>;
  wrDetailedData: InsightsDetailsWorkRequest[] | null;
  setWrDetailedData: React.Dispatch<React.SetStateAction<InsightsDetailsWorkRequest[] | null>>;
  setSelectedRecordType: React.Dispatch<React.SetStateAction<RecordOption | null>>;
  selectedRecordType: RecordOption | null;
  selectedTimeframe: TimeframeOption | null;
  setSelectedTimeframe: React.Dispatch<React.SetStateAction<TimeframeOption | null>>;
  showDatePicker: boolean;
  setShowDatePicker: React.Dispatch<React.SetStateAction<boolean>>;
  showSelectedDates: boolean;
  setShowSelectedDates: React.Dispatch<React.SetStateAction<boolean>>;
  detailedReport: boolean;
  setDetailedReport: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFacilities: InsightsReq['facilities'];
  setSelectedFacilities: React.Dispatch<React.SetStateAction<InsightsReq['facilities']>>;
  setDate: React.Dispatch<React.SetStateAction<Date | null>>;
  date: Date | null;
  setDateRange: React.Dispatch<React.SetStateAction<[Date | null, Date | null]>>;
  dateRange: [Date | null, Date | null];
  selectedProjects: InsightsReq['projects'];
  setSelectedProjects: React.Dispatch<React.SetStateAction<InsightsReq['projects']>>;
  selectedStatuses: InsightsReq['statuses'];
  setSelectedStatuses: React.Dispatch<React.SetStateAction<InsightsReq['statuses']>>;
  params: Omit<InsightsReq & NonParamFilters, 'currentDate'>;
  recordTypes: RecordOption[] | null;
  facilities: Facility[] | null;
  projects: Project[] | null;
};

export type FacilityProjectOptions = {
  value: FacilityId | ProjectId;
  label: string;
};
