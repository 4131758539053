// Copyright ©️ 2024 eVolve MEP, LLC
import { useEffect, useState } from 'react';

import { SearchableCheckList } from 'components/Mantine/SearchableCheckList';
import { isNotNil } from 'helpers/isNotNil';
import type { SelectedItem } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { TaskType } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import { AddTaskPopover } from './AddTaskPopover';
import { SelectableTask } from './SelectableTask';
import type { BillOfProcessId } from '../../types';
import type { WorkRequestOrderProps } from '../../WorkRequestOrderItemsContext';
import { useDocumentsCache } from '../WorkRequestOrderDetail/Attachments/useDocumentsCache';

type Props = {
  selectedItem: SelectedItem;
  refresh: (billOfProcessId: BillOfProcessId) => void;
} & WorkRequestOrderProps;

export const TasksView = ({ selectedItem, refresh, ...props }: Props) => {
  const [selectedTasks, setSelectedTasks] = useState<TaskType[]>([]);
  const { requestDocumentDetails } = useDocumentsCache();
  const { data, ...paginatedGet } = useWrappedPaginatedGet<TaskType>('shop/taskType', {
    lazy: true,
    defaultConfig: {
      params: {
        orderBy: 'taskTypeName',
        companyId: selectedItem.companyId,
      },
    },
  });
  useEffect(() => {
    requestDocumentDetails(data.map((d) => d.taskTypeImageId).filter(isNotNil));
  }, [data, requestDocumentDetails]);

  return (
    <SearchableCheckList
      paginatedGet={{ data, ...paginatedGet }}
      idKey="taskTypeId"
      onChange={setSelectedTasks}
      value={selectedTasks}
      rightSideComponent={
        <AddTaskPopover
          taskTypes={selectedTasks}
          onUpdateBillOfProcess={(billOfProcessId) => {
            refresh(billOfProcessId);
            setSelectedTasks([]);
          }}
          {...('workOrder' in props
            ? { workOrderId: props.workOrder.workOrderId }
            : { workRequestId: props.workRequest.workRequestId })}
        />
      }
      textInputProps={{ placeholder: 'Search tasks...', style: { width: '90%', maxWidth: 400 } }}
      flexListProps={{ pb: 'sm' }}
      getItemLabel={(task) => <SelectableTask task={task} />}
    />
  );
};
