// Copyright ©️ 2024 eVolve MEP, LLC
import './breadcrumbs.css';
import { useMemo } from 'react';

import { Flex, type FlexProps, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { isNil } from 'helpers/isNotNil';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import { useCurrentModuleFromURL } from 'hooks/useCurrentModuleFromURL';
import { EModuleType, MODULE_URL_MAPPING } from 'types/module';

const modulesHasProjectAndFacilities = [
  EModuleType.DATA_ANALYTICS,
  EModuleType.DESIGN,
  EModuleType.ELECTRICAL,
  EModuleType.MATERIALS,
  EModuleType.MECHANICAL,
  EModuleType.FIELD,
  EModuleType.SHOP,
  EModuleType.SYNC,
].map((module) => MODULE_URL_MAPPING[module]);

export const FacilitiesProjectsBreadcrumb = (props: FlexProps) => {
  const navigate = useNavigate();
  const { selectedItem } = useSelectedProjectFacility();
  const { searchCurrentModule } = useCurrentModuleFromURL(modulesHasProjectAndFacilities, MODULE_URL_MAPPING.ADMIN);

  const { label, url } = useMemo(() => {
    const root = searchCurrentModule();
    if (selectedItem?.type === 'FACILITY') {
      return { label: 'Facilities', url: `/${root}/facilities` };
    }
    return { label: 'Projects', url: `/${root}/projects` };
  }, [searchCurrentModule, selectedItem?.type]);

  if (isNil(selectedItem)) return null;

  return (
    <Flex {...props} gap="xs" align="center">
      <Text className="base-breadcrumb" c="gray.4" onClick={() => navigate(url)}>
        {label}
      </Text>
      <EvolveIcon icon="ArrowRight" size="xs" color="gray.4" />
      <Text>{selectedItem.label}</Text>
    </Flex>
  );
};
