// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import {
  ActionIcon,
  Avatar,
  Flex,
  LoadingOverlay,
  Modal,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { ImageUpload } from 'components/Mantine/ImageUpload';
import { ModalButtons } from 'components/Mantine/ModalButtons';
import type { WithOptional } from 'helpers/helper-types';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPatch, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { TaskType, TaskTypeClassId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import {
  useDocumentImage,
  useSelectImage,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddTasks/useDocumentImage';
import { useUploadDocuments } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/useUploadDocuments';
import type { CompanyId } from 'types/types-api';

type Props = {
  companyId: CompanyId;
  refresh: () => void;
  onClose: () => void;
  taskType?: TaskType;
};

type TaskTypeCreateBody = Pick<TaskType, 'companyId' | 'taskTypeName' | 'taskTypeDescription' | 'taskTypeClassId'>;
type TaskTypeUpdateBody = Partial<Pick<TaskType, 'taskTypeName' | 'taskTypeDescription' | 'taskTypeImageId'>>;

const CreateEditTaskType = ({ companyId, taskType, refresh, onClose }: Props) => {
  const { uploadDocument } = useUploadDocuments();
  const { apiCall: createTaskType } = useWrappedPost<TaskType, TaskTypeCreateBody>('shop/taskType');
  const { apiCall: updateTaskType } = useWrappedPatch<TaskType, TaskTypeUpdateBody>(
    `shop/taskType/${taskType?.taskTypeId}`,
  );
  const [loading, setLoading] = useState(false);

  const form = useForm<Pick<TaskType, 'taskTypeName' | 'taskTypeDescription'>>({
    initialValues: {
      taskTypeName: taskType?.taskTypeName ?? '',
      taskTypeDescription: taskType?.taskTypeDescription ?? '',
    },
    validate: {
      taskTypeName: (t) => !t,
    },
  });
  const existingImageUrl = useDocumentImage(taskType?.taskTypeImageId);
  const { image, onImageSelected, previewUrl } = useSelectImage();

  const onSubmit = async (values: typeof form.values) => {
    setLoading(true);
    try {
      const newTaskType = await (isNotNil(taskType)
        ? updateTaskType(values)
        : createTaskType({
            ...values,
            companyId,
            taskTypeClassId: '9e1a7bc6-5b0a-4aa7-bcb5-b354e3bc2cdb' as TaskTypeClassId,
          }));
      if (image) {
        const taskTypeImageId = await uploadDocument(image, { taskType: newTaskType });
        if (isNotNil(taskTypeImageId)) {
          await updateTaskType(
            { taskTypeImageId },
            {
              url: `shop/taskType/${newTaskType.taskTypeId}`,
            },
          );
        }
      }
      notifications.show({
        title: `Successfully ${taskType ? 'updated' : 'created'}`,
        message: `Task type ${newTaskType.taskTypeName} ${taskType ? 'updated' : 'created'}`,
        color: 'green',
      });
      refresh();
      onClose();
    } finally {
      setLoading(false);
    }
  };
  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Flex direction="column" gap="md">
        <Flex gap="md" mb="sm">
          <ImageUpload onChange={onImageSelected}>
            {(props) => (
              <ActionIcon disabled={loading} {...props} size={84} variant="subtle">
                <Avatar imageProps={{ style: { objectFit: 'contain' } }} src={previewUrl ?? existingImageUrl} size="xl">
                  <EvolveIcon icon="Upload" size="xl" />
                </Avatar>
                <LoadingOverlay visible={!!previewUrl && loading} />
              </ActionIcon>
            )}
          </ImageUpload>
          <Text c="dimmed" fz="sm">
            Select the image you want to upload. Images should not be more than 2MB in size. Only GIF, PNG, or JPEG
            files are accepted.
          </Text>
        </Flex>
        <TextInput
          label="Task Type Name"
          autoFocus
          required
          maxLength={64}
          disabled={loading}
          {...form.getInputProps('taskTypeName')}
        />
        <Textarea
          label="Description"
          disabled={loading}
          maxLength={342}
          {...form.getInputProps('taskTypeDescription')}
        />
        <ModalButtons
          loading={loading}
          confirmationText={taskType ? 'Save' : 'Create'}
          onClose={onClose}
          disabled={!form.isValid() || (!form.isDirty() && !image)}
          type="submit"
        />
      </Flex>
    </form>
  );
};

// Breaking this out into its own modal component
// means the form is remounted every time the modal opens/closes
export const CreateEditTaskTypeModal = ({
  taskType,
  onClose: parentOnClose,
  ...props
}: WithOptional<Props, 'onClose'>) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const onClose = () => {
    setOpened(false);
    parentOnClose?.();
  };
  return (
    <>
      <AgGridStyleTooltip label="Add new task type" withArrow position="right" openDelay={500}>
        <ActionIcon color={theme.primaryColor} size="sm" variant="outline" onClick={() => setOpened(true)}>
          <EvolveIcon icon="Add" size="xs" />
        </ActionIcon>
      </AgGridStyleTooltip>
      <Modal
        title={`${taskType ? 'Edit' : 'New'} task type`}
        opened={opened || isNotNil(taskType)}
        onClose={onClose}
        closeOnClickOutside={false}
        closeOnEscape={false}
        centered
      >
        <CreateEditTaskType taskType={taskType} onClose={onClose} {...props} />
      </Modal>
    </>
  );
};
