// Copyright ©️ 2024 eVolve MEP, LLC
import React, { type ReactNode, useState, useMemo, useEffect } from 'react';

import { isNotNil } from 'helpers/isNotNil';
import { useGeneralContext } from 'helpers/useGeneralContext';
import { getLocalStorage, setLocalStorage } from 'hooks/useLocalStorage';

type ChangeTableViewContextType = {
  selectedView: SelectedView;
  setSelectedView: (value: SelectedView) => void;
};
const ChangeTableViewContext = React.createContext<ChangeTableViewContextType | undefined>(undefined);

export const selectableViews = ['list', 'kanban', 'calendar'] as const;
export type SelectedView = (typeof selectableViews)[number];

export const ChangeTableViewProvider = ({ children, name }: { children: ReactNode; name: string }) => {
  const [selectedView, setSelectedView] = useState<SelectedView>('list');

  const storageKey = useMemo(() => `TABLE_VIEW_${name}` as const, [name]);

  useEffect(() => {
    const storedSelected = getLocalStorage(storageKey);
    if (isNotNil(storedSelected) && selectableViews.includes(storedSelected)) {
      setSelectedView(storedSelected);
    }
  }, [storageKey]);

  useEffect(() => {
    if (isNotNil(selectedView)) {
      setLocalStorage(storageKey, selectedView);
    }
  }, [selectedView, storageKey]);

  const value = useMemo(
    () => ({
      selectedView,
      setSelectedView,
    }),
    [selectedView, setSelectedView],
  );

  return <ChangeTableViewContext.Provider value={value}>{children}</ChangeTableViewContext.Provider>;
};

export const useChangeTableView = () => useGeneralContext(ChangeTableViewContext, 'ChangeTableView');
