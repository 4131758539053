// Copyright ©️ 2025 eVolve MEP, LLC
import { useEffect, useState } from 'react';

import { notifications } from '@mantine/notifications';

import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { UserId } from 'types/types-api';

import type { AssignableRole, RoleEntry, UserRoleId } from './types';

type Props = {
  userIds: UserId[];
  opened: boolean;
  assignableRoles: AssignableRole[];
  onClose: () => void;
  refresh: () => void;
};

export const AssignRolesModal = ({ userIds, opened, onClose, refresh, assignableRoles }: Props) => {
  const { apiCall: assignRoles } = useWrappedPost<unknown, { userRoleAssignments: RoleEntry[] }>('admin/Role/assign');

  const [selectedRoleId, setSelectedRoleId] = useState<UserRoleId | null>(null);

  useEffect(() => {
    if (!opened) setSelectedRoleId(null);
  }, [opened]);

  const onSubmit = () => {
    if (isNotNil(selectedRoleId)) {
      void assignRoles({ userRoleAssignments: userIds.map((u) => ({ userId: u, roleId: selectedRoleId })) }).then(
        () => {
          notifications.show({
            title: 'Successfully assigned',
            message: `Assigned role to ${userIds.length} member${userIds.length === 1 ? '' : 's'}`,
          });
          refresh();
          onClose();
        },
      );
    }
  };
  return (
    <ConfirmationModal title="Assign Roles" opened={opened} onClose={onClose} onConfirm={onSubmit}>
      <WrappedSelect
        label="Roles"
        data={assignableRoles.map((r) => ({
          label: r.scope !== 'Company' ? `${r.displayName} (${r.scope})` : r.displayName,
          value: r.roleId,
        }))}
        value={selectedRoleId}
        onChange={setSelectedRoleId}
      />
    </ConfirmationModal>
  );
};
