// Copyright ©️ 2024 eVolve MEP, LLC
import { useEffect, useState } from 'react';

import { Loader } from '@mantine/core';
import parse from 'html-react-parser';

import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedGet, useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import { useSignOut } from 'modules/Authentication/auth/useSignOut';
import type { UserNameIdSymbol } from 'types/types-api';

type Props = {
  userName: UserNameIdSymbol | undefined;
  onEulaAccepted: () => void;
  onClose: () => void;
};

export const EULAModal = ({ userName, onEulaAccepted, onClose }: Props) => {
  const signOut = useSignOut();
  const [accepted, setAccepted] = useState(false);
  const { apiCall: acceptEula, loading: saving } = useWrappedPatch('admin/user/username/:userName/acceptEula');
  const { data, apiCall, loading } = useWrappedGet<{ content: string }>('admin/eula/latest', { lazy: true });
  useEffect(() => {
    if (isNotNil(userName)) {
      void apiCall();
      setAccepted(false);
    }
  }, [apiCall, userName]);

  return (
    <ConfirmationModal
      opened={isNotNil(userName)}
      onClose={accepted ? onClose : signOut}
      onConfirm={() =>
        acceptEula({}, { url: `admin/user/username/${userName}/acceptEula` })
          .then(() => setAccepted(true))
          .then(onEulaAccepted)
      }
      withCloseButton={false}
      closeOnEscape={false}
      disabled={loading}
      loading={saving}
      confirmationText="Accept"
      cancellationText="Decline"
      title="End-User License Agreement"
      size="xl"
    >
      {isNotNil(data) ? parse(data.content) : <Loader />}
    </ConfirmationModal>
  );
};
