// Copyright ©️ 2024 eVolve MEP, LLC
import { useCallback } from 'react';

import { removeLocalStorage, setLocalStorage } from 'hooks/useLocalStorage';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';

import type { AuthCreds, AuthReturn } from '../types';

export const useSignIn = () => {
  const { apiCall } = useWrappedPost<AuthReturn, AuthCreds>('admin/authentication', { dontAlertOnError: true });

  const signIn = useCallback(
    async ({
      email,
      password,
      keepEnabled,
    }: {
      email: string;
      password: string;
      keepEnabled: boolean;
    }): Promise<AuthReturn> => {
      removeLocalStorage('EVOLVE_ACCESS_TOKEN');
      const authResult = await apiCall({ userName: email, password });

      if (keepEnabled) {
        // Hardcoded as localStorage.setItem so that it can be read in `useLocalStorage`, bypassing the localStorage/sessionStorage check
        localStorage.setItem('STAY_SIGNED_IN', 'true');
      }

      setLocalStorage('EVOLVE_ACCESS_TOKEN', authResult.accessToken);
      setLocalStorage('EVOLVE_REFRESH_TOKEN', authResult.refreshToken);
      setLocalStorage('EVOLVE_TOKEN_USERNAME', authResult.tokenUserName);

      return authResult;
    },
    [apiCall],
  );

  return { signIn };
};
