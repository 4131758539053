// Copyright ©️ 2024 eVolve MEP, LLC
import { ActionIcon, Menu } from '@mantine/core';
import type { ColDef } from 'ag-grid-enterprise';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { lockedColDef } from 'helpers/ag-grid/baseColumnDef';
import { CustomSelectComponent } from 'helpers/ag-grid/CustomSelectComponent';
import { isNotNil } from 'helpers/isNotNil';
import { IncludeDeletedOptions, type User } from 'types/types-api';

const fallback = '--';

export const getEvolveMembersColDef = ({ deleteUser }: { deleteUser: (user: User[]) => void }): ColDef<User>[] => [
  {
    headerName: 'Admin',
    field: 'isAdmin',
    ...lockedColDef(),
    cellRenderer: CellRenderer<User>(({ data }) => (
      <>{data.isAdmin ? <EvolveIcon icon="CheckCircle" color="gray.6" /> : null}</>
    )),
  },
  {
    headerName: 'User',
    valueGetter: ({ data }) => {
      const nameParts = [data?.userFirstName, data?.userLastName].filter(isNotNil);
      if (nameParts.length > 0) {
        return nameParts.join(' ');
      }
      return fallback;
    },
  },
  {
    headerName: 'Email',
    field: 'userEmail',
    minWidth: 200,
    valueFormatter: ({ value }) => value ?? fallback,
    flex: 1,
  },
  {
    headerName: 'Office Phone',
    field: 'workPhoneNumber',
    valueFormatter: ({ value }) => value ?? fallback,
    sortable: false,
  },
  {
    headerName: 'Mobile Phone',
    field: 'personalPhoneNumber',
    valueFormatter: ({ value }) => value ?? fallback,
    sortable: false,
  },
  {
    headerName: 'Status',
    field: 'userStatus.userStatusName',
    cellStyle: ({ data }) => ({
      color: data?.isDeleted ? 'red' : data?.inviteStatus === 'Active' ? 'teal' : 'orange',
    }),
    valueFormatter: ({ data, value }) => (data?.isDeleted ? 'Deleted' : value),
    filter: CustomSelectComponent({
      label: 'User status',
      defaultValue: IncludeDeletedOptions.NO,
      data: [
        {
          label: 'Active users',
          value: IncludeDeletedOptions.NO,
        },
        {
          label: 'Deleted users',
          value: IncludeDeletedOptions.ONLY_DELETED,
        },
        {
          label: 'All users',
          value: IncludeDeletedOptions.YES,
        },
      ],
      filterKey: 'includeDeleted',
    }),
  },
  {
    ...lockedColDef('right'),
    cellRenderer: CellRenderer<User>(
      ({ data }) => (
        <Menu position="bottom-end" withinPortal>
          <Menu.Target>
            <ActionIcon>
              <EvolveIcon icon="MoreOptsVert" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              color="red"
              onClick={() => {
                deleteUser([data]);
              }}
            >
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ),
      {
        show: ({ data }) => !data.isDeleted,
      },
    ),
  },
];
