// Copyright ©️ 2024 eVolve MEP, LLC
type EventsMapping = {
  page_nav: { direction: 'next' | 'prev' };
};

type EventName = keyof EventsMapping;

type EventData<T extends EventName> = {
  detail: EventsMapping[T];
};

export const AutodeskEventBus = {
  on<K extends EventName>(event: K, callback: (detail: EventsMapping[K]) => void) {
    document.addEventListener(event, (e) => callback((e as any as EventData<K>).detail));
  },
  dispatch<K extends EventName>(event: K, data: EventsMapping[K]) {
    const eventData: EventData<K> = { detail: data };
    document.dispatchEvent(new CustomEvent(event, eventData));
  },
  // eslint-disable-next-line no-empty-function
  remove(event: EventName, callback = () => {}) {
    document.removeEventListener(event, callback);
  },
};
