// Copyright ©️ 2024 eVolve MEP, LLC
import { Text, Title } from '@mantine/core';

import { FullPageError } from 'app/FullPageError';

import InvitationExpiredBackground from '../../assets/images/backgrounds/Invitation-Expired-bg.png';

const title = 'Sorry, this invitation has expired :(';

export const InvitationExpired = () => (
  <FullPageError backgroundSrc={InvitationExpiredBackground}>
    <Title>{title}</Title>
    <Text style={{ maxWidth: '634px' }} ta="center">
      Hi there, it seems the invitation link has expired or has been canceled. Please check with your administrator to
      request a new invitation.
    </Text>
  </FullPageError>
);
