// Copyright ©️ 2024 eVolve MEP, LLC
import { type ComponentProps, Fragment, type ReactNode, useEffect, useState } from 'react';

import {
  Accordion,
  Button,
  Divider,
  Flex,
  Loader,
  Space,
  Text,
  Textarea,
  type TextareaProps,
  TextInput,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { format, isValid, parseISO } from 'date-fns';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { EvolveLink } from 'components/Mantine/Navigation/EvolveLink';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import type { StatusKey } from 'constants/badgeMappingStatus';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNil, isNotNil } from 'helpers/isNotNil';
import type { Facility, FacilityId } from 'hooks/projectsAndFacilities/useFacilities';
import type { SelectedItem } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import { useWrappedPaginatedGet, useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import { CellStatusBadge } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListElements/CellComponentStatus/CellStatusBadge';
import { getDaysRemainingWorkRequest } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/common';
import type { WorkRequest } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import { useWorkRequests } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/useWorkRequests';
import { canEditWorkRequest, getDaysRemainingWorkOrder } from 'modules/Shop/WorkOrders/WorkOrdersPage/common';
import type { WorkOrder } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';

import { getPageTitle } from '../../common';
import { AttachmentsList } from './Attachments/AttachmentsList';

const DetailItem = ({
  name,
  renderValue,
  editing = false,
  EditComponent,
}: {
  editing?: boolean;
  name: string;
  renderValue: ReactNode;
  EditComponent?: ReactNode;
}) => (
  <>
    <Flex justify="space-between" fz="sm" align="center" gap="xl" style={{ minHeight: 30, overflowX: 'auto' }}>
      <Text c="dimmed">{name}</Text>
      {editing && isNotNil(EditComponent) ? EditComponent : <Text>{renderValue || '--'}</Text>}
    </Flex>
    <Divider my="sm" />
  </>
);

const DetailItemFromWR = ({
  workRequest,
  value,
  editing = false,
  editableStatuses,
  ...props
}: Omit<ComponentProps<typeof DetailItem>, 'renderValue'> & {
  workRequest: WorkRequest;
  value: keyof WorkRequest | ((workRequest: WorkRequest) => string | number);
  editableStatuses?: StatusKey[];
}) => {
  const canEdit = isNil(editableStatuses) || editableStatuses.includes(workRequest.workRequestStatusName);
  // Prefer '--' over empty string
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const renderValue = typeof value === 'string' ? `${workRequest[value] || '--'}` : value(workRequest);
  return <DetailItem editing={editing && canEdit} renderValue={renderValue} {...props} />;
};

type UpdateWorkRequestBody = Partial<
  Pick<WorkRequest, 'workRequestName' | 'workRequestDescription' | 'facilityId'> & {
    needBy: Date | null;
  }
>;

export type WorkRequestOrderDetailProps = (
  | {
      workOrder: WorkOrder;
      workOrderUpdated: (workOrder: WorkOrder) => void;
      workRequest?: never;
      workRequestUpdated?: never;
    }
  | {
      workOrder?: never;
      workOrderUpdated?: never;
      workRequest: WorkRequest;
      workRequestUpdated: (workRequest: WorkRequest) => void;
    }
) & {
  selectedItem: SelectedItem;
  startEditing?: boolean;
  isDrawer: boolean;
};

const getCurrentPageTitle = (workReqOrd: WorkRequest | WorkOrder, isDrawer: boolean) => {
  if (isDrawer) {
    return 'workOrderId' in workReqOrd ? 'Work Orders' : 'Work Requests';
  }
  return getPageTitle(workReqOrd);
};

const CommentsField = (props: TextareaProps) => (
  <Textarea
    maxLength={1000}
    autosize
    size="xs"
    style={{
      maxWidth: 400,
      width: '100%',
    }}
    minRows={1}
    maxRows={5}
    {...props}
  />
);

export const WorkRequestOrderDetailList = ({
  workOrder,
  workRequest: workRequestFromProps,
  workOrderUpdated,
  workRequestUpdated,
  startEditing = false,
  setSaving: setSavingParent,
  setEditing: setEditingParent,
  selectedItem,
  isDrawer,
}: WorkRequestOrderDetailProps & {
  setSaving?: (saving: boolean) => void;
  setEditing?: (editing: boolean) => void;
}) => {
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const { fetchPage } = useWrappedPaginatedGet<Facility>('admin/facility/all', {
    lazy: true,
  });
  useEffect(() => {
    void getAllDataFromFetcher(fetchPage).then(setFacilities);
  }, [fetchPage]);
  const workRequest = workOrder ? workOrder.workRequest : workRequestFromProps;
  const { workOrdersForWorkRequest } = useWorkRequests();
  const [workOrderName, setWorkOrderName] = useState(workOrder?.workOrderName);
  const [workOrderDescription, setWorkOrderDescription] = useState(workOrder?.workOrderDescription);
  const canEdit = workRequest.workRequestStatusName !== 'Completed';
  const [editing, setEditing] = useState(canEdit ? startEditing : false);
  const [saving, setSaving] = useState(false);
  useEffect(() => setSavingParent?.(saving), [saving, setSavingParent]);
  useEffect(() => setEditingParent?.(editing), [editing, setEditingParent]);

  const { apiCall: updateWorkOrder } = useWrappedPatch<
    WorkOrder,
    { workOrderName: string; workOrderDescription: string }
  >(`shop/workOrder/${workOrder?.workOrderId}`);

  const { apiCall: updateWorkRequestApiCall } = useWrappedPatch<WorkRequest, UpdateWorkRequestBody>(
    `shop/workRequest/${workRequest.workRequestId}`,
  );

  const onSubmit = (values: UpdateWorkRequestBody) => {
    // This can be done type-safely without the casts,
    // but it's compicated and I don't wanna worry about it right now.
    // This is safe.
    const workRequestUpdates = Object.fromEntries(
      Object.entries(values).filter(([key]) => form.isDirty(key)),
    ) as UpdateWorkRequestBody;
    setSaving(true);
    void updateWorkRequestApiCall(workRequestUpdates)
      .then(async (wr) => {
        if (!workOrder) {
          workRequestUpdated(wr);
        } else {
          await updateWorkOrder({
            workOrderName: workOrderName ?? workOrder.workOrderName,
            workOrderDescription: workOrderDescription ?? workOrder.workOrderDescription,
          }).then(workOrderUpdated);
        }
        notifications.show({
          title: 'Successfully updated',
          message: `Updated ${wr.workRequestName}`,
          color: 'green',
        });
        setEditing(false);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const form = useForm<UpdateWorkRequestBody>();
  const resetForm = () => {
    setWorkOrderName(workOrder?.workOrderName);
    setWorkOrderDescription(workOrder?.workOrderDescription);
    const defaultValues: UpdateWorkRequestBody = {
      workRequestName: workRequest.workRequestName,
      workRequestDescription: workRequest.workRequestDescription,
      facilityId: workRequest.facilityId,
      needBy: workRequest.needBy ? parseISO(`${workRequest.needBy}Z`) : new Date(),
    };
    form.setValues(defaultValues);
    form.resetDirty(defaultValues);
  };

  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Flex justify="space-between" mb="lg" align="center">
          {isNotNil(workOrder) &&
            (editing ? (
              <TextInput
                disabled={saving}
                placeholder="Work Order Id"
                maxLength={128}
                defaultValue={workOrderName}
                onChange={(e) => setWorkOrderName(e.target.value)}
              />
            ) : (
              <Flex gap="xs" align="center">
                <EvolveLink
                  to={`/shop/work-orders/${workOrder.workOrderId}`}
                  from={getCurrentPageTitle(workOrder, isDrawer)}
                >
                  {workOrder.workOrderName}
                </EvolveLink>
                <Text fw={500}>|</Text>
                <EvolveLink
                  to={`/shop/work-requests/${workRequest.workRequestId}`}
                  from={getCurrentPageTitle(workOrder, isDrawer)}
                >
                  {workRequest.workRequestIdentifier}
                </EvolveLink>
              </Flex>
            ))}
          {isNil(workOrder) && (
            <EvolveLink
              to={`/shop/work-requests/${workRequest.workRequestId}`}
              from={getCurrentPageTitle(workRequest, isDrawer)}
            >
              {workRequest.workRequestIdentifier}
            </EvolveLink>
          )}
          {canEdit && !editing && (
            <Button
              onClick={() => {
                resetForm();
                setEditing(true);
              }}
              variant="outline"
              leftSection={<EvolveIcon icon="WriteInItem" size="sm" />}
            >
              Edit
            </Button>
          )}
          {editing && (
            <Flex gap="md">
              <Button
                disabled={saving}
                variant="subtle"
                size="sm"
                onClick={() => {
                  setEditing(false);
                  resetForm();
                }}
              >
                Cancel edit
              </Button>
              <Button
                disabled={
                  !form.isDirty() &&
                  workOrderName === workOrder?.workOrderName &&
                  workOrderDescription === workOrder?.workOrderDescription
                }
                loading={saving}
                size="sm"
                type="submit"
              >
                Save
              </Button>
            </Flex>
          )}
        </Flex>

        <Flex direction="column">
          <DetailItemFromWR
            name={workOrder ? 'Work Order Name' : 'Work Request Name'}
            value="workRequestName"
            editing={editing && isNil(workOrder) && selectedItem.type === 'PROJECT'}
            editableStatuses={['Draft', 'Submitted', 'Pending', 'Rejected', 'Canceled']}
            workRequest={workRequest}
            EditComponent={
              <TextInput maxLength={128} disabled={saving} size="xs" {...form.getInputProps('workRequestName')} />
            }
          />
          <DetailItemFromWR
            name={isNil(workOrder) ? 'Comments' : 'Work Request Comments'}
            value="workRequestDescription"
            editing={editing && isNil(workOrder)}
            workRequest={workRequest}
            EditComponent={<CommentsField disabled={saving} {...form.getInputProps('workRequestDescription')} />}
          />
          {isNotNil(workOrder) && (
            <DetailItem
              name="Work Order Comments"
              renderValue={workOrder.workOrderDescription}
              editing={editing}
              EditComponent={
                <CommentsField
                  disabled={saving}
                  defaultValue={workOrder.workOrderDescription}
                  onChange={(e) => setWorkOrderDescription(e.target.value)}
                />
              }
            />
          )}
          <DetailItemFromWR
            name="Facility"
            value="facilityName"
            editableStatuses={['Draft', 'Submitted', 'Rejected', 'Canceled']}
            editing={editing && isNil(workOrder) && selectedItem.type === 'PROJECT'}
            workRequest={workRequest}
            EditComponent={
              <WrappedSelect<FacilityId>
                size="xs"
                disabled={saving}
                data={facilities.map((f) => ({
                  value: f.facilityId,
                  label: f.facilityName,
                }))}
                {...form.getInputProps('facilityId')}
              />
            }
          />
          <DetailItemFromWR name="Project" value="projectName" workRequest={workRequest} />
          <DetailItemFromWR
            name="Need By Date"
            value={(w) => {
              const date = parseISO(`${w.needBy}Z`);
              return isValid(date) ? format(date, 'MM/dd/yyyy') : '';
            }}
            editing={editing}
            EditComponent={
              <DateInput
                disabled={saving}
                minDate={new Date()}
                clearable={canEditWorkRequest(workRequest.workRequestStatusName, 'submit')}
                leftSection={<EvolveIcon icon="CalendarBoard" size="sm" />}
                size="xs"
                {...form.getInputProps('needBy')}
              />
            }
            workRequest={workRequest}
          />
          <DetailItemFromWR name="Days Remaining" value={getDaysRemainingWorkRequest} workRequest={workRequest} />
        </Flex>
      </form>
      <Space my="xs" />
      <Accordion
        radius="md"
        multiple
        styles={{
          item: { border: 'none' },
          label: { padding: 3 },
          content: { padding: 3 },
        }}
      >
        <Accordion.Item value="attachments">
          <Accordion.Control px={3} icon={<EvolveIcon icon="Attachment" />}>
            <Flex gap="sm" align="center">
              <Text>Attachments</Text>
              <Text c="dimmed">{workOrder?.numberOfAttachments ?? workRequest.numberOfAttachments}</Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <AttachmentsList
              readOnly={(workOrder?.workRequest ?? workRequest).workRequestStatusName === 'Completed'}
              workRequestId={workRequest.workRequestId}
              workOrderId={workOrder?.workOrderId}
              {...(workOrder ? { workOrder, workOrderUpdated } : { workRequest, workRequestUpdated })}
            />
          </Accordion.Panel>
        </Accordion.Item>

        {isNil(workOrder) && (
          <Accordion.Item value="work-orders">
            <Accordion.Control
              disabled={isNil(workOrdersForWorkRequest) || workOrdersForWorkRequest.length === 0}
              mt="md"
              px={3}
              icon={<EvolveIcon icon="WorkOrder" />}
            >
              <Flex gap="sm" align="center">
                <Text>Work Orders</Text>
                {isNotNil(workOrdersForWorkRequest) ? (
                  <Text c="dimmed">{workOrdersForWorkRequest.length}</Text>
                ) : (
                  <Loader size="xs" />
                )}
              </Flex>
            </Accordion.Control>
            <Accordion.Panel>
              <Flex direction="column" gap="xs" mt="sm">
                {workOrdersForWorkRequest?.map((wo) => (
                  <Fragment key={wo.workOrderId}>
                    <Flex justify="space-between" align="center">
                      <EvolveLink
                        to={`/shop/work-orders/${wo.workOrderId}`}
                        from={getCurrentPageTitle(workRequest, isDrawer)}
                      >
                        {wo.workOrderName}
                      </EvolveLink>
                      <CellStatusBadge
                        status={wo.workOrderStatusTypeName}
                        daysRemaining={getDaysRemainingWorkOrder(wo)}
                      />
                    </Flex>
                    <Divider />
                  </Fragment>
                ))}
              </Flex>
            </Accordion.Panel>
          </Accordion.Item>
        )}
      </Accordion>
    </>
  );
};
