// Copyright ©️ 2024 eVolve MEP, LLC

import dagre from '@dagrejs/dagre';
import type { Edge, Node } from '@xyflow/react';

import { maxTaskNodeWidth, partNodeHeight, taskNodeHeight } from './nodes/constants';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
export const autoLayoutedNodes = (nodes: Node[], edges: Edge[]) => {
  dagreGraph.setGraph({ rankdir: 'LR' });
  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: maxTaskNodeWidth * (3 / 4),
      height: taskNodeHeight,
    });
  });
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });
  dagre.layout(dagreGraph);
  return nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - maxTaskNodeWidth / 2,
        y: nodeWithPosition.y - (node.type === 'task' ? taskNodeHeight : partNodeHeight) / 2,
      },
    };
  });
};
