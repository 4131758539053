// Copyright ©️ 2024 eVolve MEP, LLC
import React from 'react';

export const useGeneralContext = <T>(Context: React.Context<T>, contextName: string) => {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(`use${contextName} must be used within a ${contextName}.Provider`);
  }
  return context;
};
