// Copyright ©️ 2024 eVolve MEP, LLC
import { useCallback, useMemo, useState } from 'react';

import { Flex, NavLink, useMantineTheme } from '@mantine/core';
import { useLocation } from 'react-router-dom';

import type { NavigationItem, NavigationSet } from 'app/Modules/types';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { useEvolveNavigate } from 'components/Mantine/Navigation/useEvolveNavigate';
import { isNotNil } from 'helpers/isNotNil';
import { navIconMap } from 'helpers/MenuIcons';
import { replaceSpaceToDash } from 'helpers/stringFunctions';
import { uuIDValidator } from 'helpers/uuIDValidator';
import { useSetupModule } from 'hooks/useSetupModule';
import { useUrlParams } from 'hooks/useUrlParams';

type Props = {
  item: NavigationItem | NavigationSet;
};

export const NavItem = ({ item }: Props) => {
  const theme = useMantineTheme();
  const { currentModule, module } = useSetupModule();
  const location = useLocation();
  const navigate = useEvolveNavigate('', true);
  const {
    params: { nav },
  } = useUrlParams();

  const routeIsActive = useCallback(
    (routePath: string) => {
      const pathNames = location.pathname.split('/').filter((x) => x);
      const pathNameWithoutUUID = pathNames.filter((x) => !uuIDValidator(x)).join('/');

      if (nav) {
        return nav === routePath;
      }
      return pathNameWithoutUUID === `${module?.toLowerCase()}/${routePath}`;
    },
    [location.pathname, module, nav],
  );

  const [opened, setOpened] = useState(
    'navigationSetItems' in item &&
      item.navigationSetItems?.some(
        (subItem) => isNotNil(subItem.navigationItem) && routeIsActive(subItem.navigationItem.routePath),
      ),
  );

  const handleClick = () => {
    if ('navigationItemName' in item) {
      navigate(`${currentModule()?.toLowerCase()}/${item.routePath}`);
    }
  };

  const dataTestIdOfItem = useMemo(() => {
    if ('navigationItemName' in item) {
      return `nav-item-${replaceSpaceToDash(item.navigationItemName)}`;
    }
    return `nav-menu-${replaceSpaceToDash(item.navigationSetName)}`;
  }, [item]);

  const navigationSetItems = useMemo(
    () =>
      'isMenu' in item && item.isMenu
        ? item.navigationSetItems?.map(({ navigationItem }) => navigationItem).filter(isNotNil)
        : null,
    [item],
  );

  const active = 'routePath' in item && routeIsActive(item.routePath);

  return (
    <Flex direction="column">
      <NavLink
        // label={`${active}`}
        label={'navigationItemName' in item ? item.navigationItemName : item.navigationSetName}
        active={active}
        onClick={handleClick}
        opened={opened}
        onChange={setOpened}
        py="xs"
        leftSection={<EvolveIcon icon={navIconMap[item.icon]} />}
        variant="filled"
        childrenOffset={0}
        color={theme.primaryColor}
        styles={{
          root: {
            borderRadius: 6,
            color: opened || active ? 'white' : '#9e9e9e',
          },
          children: {
            borderRadius: 6,
            backgroundColor: '#424242',
          },
        }}
        data-testid={dataTestIdOfItem}
      >
        {navigationSetItems?.map((navigationItem) => (
          <NavItem item={navigationItem} key={navigationItem.navigationItemId} />
        ))}
      </NavLink>
    </Flex>
  );
};
