// Copyright ©️ 2025 eVolve MEP, LLC

import { useState } from 'react';

import {
  Button,
  Flex,
  getThemeColor,
  Radio,
  Stack,
  Stepper,
  Tabs,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { useUser } from 'app/UserContext';
import { SearchableMultiSelect } from 'components/Mantine/SearchableMultiSelect';
import { TextInputDebounced } from 'components/Mantine/TextInputDebounced';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { User, UserId } from 'types/types-api';

import { HTMLPreview } from './HTMLPreview';
import type { BulkRecipientCode, MessagePriority, SendMessageReq } from './types';

type MessageType = 'Product notification' | 'Marketing';
type RecipientType = 'All users' | 'Company admins' | 'Custom';
type TabOptions = 'Source' | 'Preview';

export const CreateMessageModal = ({ closeModal }: { closeModal: () => void }) => {
  const [active, setActive] = useState(0);
  const [title, setTitle] = useState<string | null>(null);
  const [priority, setPriority] = useState<MessagePriority | null>(null);
  const [messageType, setMessageType] = useState<MessageType | null>(null);
  const [activeTab, setActiveTab] = useState<TabOptions>('Source');
  const [source, setSource] = useState<string | null>();
  const [htmlError, setHtmlError] = useState(false);
  const [recipientType, setRecipientType] = useState<RecipientType | null>(null);
  const [recipients, setRecipients] = useState<User[]>([]);

  const { user } = useUser();
  const theme = useMantineTheme();
  const activeTabColor = getThemeColor(theme.primaryColor, theme);

  const nextStep = () => setActive((curr) => (curr < 2 ? curr + 1 : curr));
  const prevStep = () => setActive((curr) => (curr > 0 ? curr - 1 : curr));

  const { apiCall, loading } = useWrappedPost<unknown, SendMessageReq>('admin/message');

  const sendingTo = (): { bulkRecipientCode: BulkRecipientCode } | { recipients: UserId[] } => {
    if (recipientType === 'Custom') return { recipients: recipients.map((u) => u.userId) };
    return {
      bulkRecipientCode: recipientType === 'Company admins' ? 'ADMIN' : 'ALL',
    };
  };

  const sendMessage = () => {
    const recipientsObj = sendingTo();
    void apiCall({
      ...recipientsObj,
      companyId: user.companyId,
      fromUserId: user.userId,
      fromName: `${user.userFirstName} ${user.userLastName}`,
      messagePriority: priority ?? 'NORMAL',
      subject: title ?? '',
      body: source ?? '',
    }).then(() => {
      notifications.show({
        title: 'Success',
        message: 'Message successfully sent',
        color: 'green',
      });
      closeModal();
    });
  };

  const isValid = () => {
    if (active === 0 && isNotNil(title) && title !== '' && isNotNil(priority) && isNotNil(messageType)) return true;
    if (active === 1 && isNotNil(source)) return true;
    if (active === 2 && isNotNil(recipientType)) {
      if (recipientType === 'Custom' && recipients.length > 0) return true;
      return true;
    }
    return false;
  };

  const { data, ...paginatedGet } = useWrappedPaginatedGet<User>('admin/user', { lazy: true });

  return (
    <Stack>
      <Stepper active={active}>
        <Stepper.Step label="Message type">
          <Stack>
            <TextInputDebounced
              placeholder="Title"
              description="128 character limit"
              onChange={setTitle}
              value={title ?? ''}
              maxLength={129}
              error={title && title.length > 128}
            />
          </Stack>
          <Text fw={600} mt="xl">
            Select message type
          </Text>
          <Radio.Group onChange={(v) => setMessageType(v as MessageType)} withAsterisk value={messageType}>
            <Radio value="Product notification" label="Product notification" m="xs" />
            <Radio value="Marketing" label="Marketing" m="xs" />
          </Radio.Group>
          <Text fw={600} mt="xl">
            Select priority
          </Text>
          <Text c="gray" size="xs">
            High-priority messages will display in both a pop-up banner and the Message Center. Normal priority messages
            will only display in the Message Center.
          </Text>
          <Radio.Group onChange={(v) => setPriority(v as MessagePriority)} withAsterisk value={priority as string}>
            <Radio value="NORMAL" label="Normal" m="xs" />
            <Radio value="HIGH" label="High" m="xs" />
          </Radio.Group>
        </Stepper.Step>
        <Stepper.Step label="Add source">
          <Stack>
            <Tabs
              value={activeTab}
              onChange={(e) => {
                setActiveTab((t) => (e ?? t) as typeof activeTab);
              }}
              styles={{
                // tabsList: { flexWrap: 'nowrap' },
                tab: {
                  width: 110,
                  height: 50,
                  fontWeight: 600,
                },
                tabLabel: { fontWeight: 600 },
              }}
            >
              <Tabs.List>
                <Tabs.Tab
                  value="Source"
                  style={{ flex: 1, color: activeTab === 'Source' ? activeTabColor : theme.colors.gray[6] }}
                >
                  Source
                </Tabs.Tab>
                <Tabs.Tab
                  value="Preview"
                  style={{ flex: 1, color: activeTab === 'Preview' ? activeTabColor : theme.colors.gray[6] }}
                >
                  Preview
                </Tabs.Tab>
              </Tabs.List>
            </Tabs>
            {activeTab === 'Source' ? (
              <>
                <Text fs="italic" size="sm" c="gray">
                  65,000 character limit
                </Text>
                <Textarea
                  onChange={(e) => {
                    setSource(e.currentTarget.value);
                    if (e.currentTarget.value.length > 65000) setHtmlError(true);
                    else {
                      setHtmlError(false);
                    }
                  }}
                  autosize
                  minRows={8}
                  value={source ?? ''}
                  maxLength={65001}
                  error={htmlError}
                />
              </>
            ) : (
              isNotNil(source) && <HTMLPreview html={source} preview />
            )}
          </Stack>
        </Stepper.Step>
        <Stepper.Step label="Recipients">
          <Text fw={600} mt="xl">
            Select recipient type
          </Text>
          <Radio.Group
            onChange={(v) => setRecipientType(v as RecipientType)}
            withAsterisk
            mb="md"
            value={recipientType as string}
          >
            <Radio value="All users" label="All users" m="xs" />
            <Radio value="Company admins" label="Company admins" m="xs" />
            <Radio value="Custom" label="Custom" m="xs" />
          </Radio.Group>
          {recipientType === 'Custom' && (
            <SearchableMultiSelect
              paginatedGet={{ data, ...paginatedGet }}
              value={recipients}
              onChange={setRecipients}
              getItemLabel={(u) => u.userEmail}
              idKey="userId"
              clearable
              nowrap={false}
            />
          )}
        </Stepper.Step>
      </Stepper>
      <Flex justify="flex-end" gap="md">
        <Button
          variant="outline"
          onClick={() => {
            if (active > 0) prevStep();
            else closeModal();
          }}
        >
          {active > 0 ? 'Back' : 'Cancel'}
        </Button>
        <Button
          onClick={() => {
            if (active < 2 && isValid()) nextStep();
            else sendMessage();
          }}
          disabled={!isValid() || htmlError}
          loading={loading}
        >
          {active < 2 ? 'Next' : 'Send message'}
        </Button>
      </Flex>
    </Stack>
  );
};
