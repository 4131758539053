// Copyright ©️ 2024 eVolve MEP, LLC
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dropzone/styles.css';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom/client';

import { App } from 'app/App';

import { BrowserRouter } from './BrowserRouter';

const history = createBrowserHistory();

const AppWithProvider = () => (
  <BrowserRouter history={history}>
    <App />
  </BrowserRouter>
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<AppWithProvider />);
