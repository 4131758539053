// Copyright ©️ 2024 eVolve MEP, LLC
import { differenceInDays, isValid, parseISO } from 'date-fns';

import type { StatusKey } from 'constants/badgeMappingStatus';
import { isNotNil } from 'helpers/isNotNil';
import type { SelectedItem } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import type { WorkCellTask } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

import type { WorkRequest } from './types';
import type { Task } from '../../WorkRequest/WorkRequestPage/types';

// TODO: Move this somewhere common
export const facilityProjectTypeToQueryParam: Record<SelectedItem['type'], string> = {
  FACILITY: 'facilityId',
  PROJECT: 'projectId',
};

export const getDaysRemaining = (status: StatusKey, needByStr?: string, statusDateStr?: string) => {
  const firstDate = parseISO(`${needByStr}Z`);
  const statusDate = parseISO(`${statusDateStr}Z`);
  const datesAreNotValid = !isValid(firstDate) || (isNotNil(statusDateStr) && !isValid(statusDate));
  if (datesAreNotValid) return 0;
  if (status === 'Completed' && !isValid(statusDate)) {
    return 0;
  }
  const secondDate = status === 'Completed' ? statusDate : new Date();
  const daysRemaining = differenceInDays(firstDate, secondDate);

  return daysRemaining;
};

export const getDaysRemainingWorkRequest = (
  workRequest: Pick<WorkRequest, 'workRequestStatusName' | 'needBy' | 'lastStatusUpdate'>,
) => getDaysRemaining(workRequest.workRequestStatusName, workRequest.needBy, workRequest.lastStatusUpdate);

export const getDaysRemainingWorkCellTask = (task: Task, workCellTask: WorkCellTask) =>
  getDaysRemaining(task.taskStatusTypeName, workCellTask.needBy, workCellTask.taskStatusTypeUpdatedOn);
