// Copyright ©️ 2024 eVolve MEP, LLC
import { ComponentPaneLayout } from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { FacilityOrProjectRequired } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { WorkRequestsPage } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/WorkRequestsPage';
import { CatalogSetupCategorySelector } from 'modules/Materials/CatalogSetup/CatalogSetupPage/CatalogSetupCategorySelector';
import { CatalogSetupDetailsPane } from 'modules/Materials/CatalogSetup/CatalogSetupPage/CatalogSetupDetailsPane';
import { AddEditItemPane } from 'modules/Materials/CatalogSetup/CatalogSetupPage/PartEditor/AddEditItemPane';
import { AssemblyEditorView } from 'modules/Materials/CatalogSetup/CatalogSetupPage/PartEditor/AssemblyEditorView';
import { AssemblyEditorProvider } from 'modules/Materials/CatalogSetup/CatalogSetupPage/PartEditor/useAssemblyEditor';
import { PartEditorProvider } from 'modules/Materials/CatalogSetup/CatalogSetupPage/PartEditor/usePartEditor';
import { CatalogSetupProvider } from 'modules/Materials/CatalogSetup/CatalogSetupPage/useCatalogSetup';

import { ShopDepartmentFallback } from './components/ShopDepartmentFallback/ShopDepartmentFallback';
import { ShopDepartmentRequired } from './components/ShopDepartmentFallback/ShopDepartmentRequired';
import { TaskViewerPage } from './Fabrication/TaskViewer/TaskViewerPage/TaskViewerPage';
import { WorkCellQueuePage } from './Fabrication/WorkCellQueue/WorkCellQueuePage/WorkCellQueuePage';
import { InsightsPage } from './Insights/InsightsPage';
import { InsightsProvider } from './Insights/useInsights';
import { NoShopDptOverlay } from './NoShopDptOverlay';
import { ShopMembersPage } from './ShopMembers/ShopMembersMantine/ShopMembersPage';
import { ShopSetupWrapper } from './ShopSetup/WorkCellSetup/ShopSetupPage/ShopSetupWrapper';
import { WorkCellTaskAdder } from './ShopSetup/WorkCellSetup/ShopSetupPage/TaskTypes/WorkCellTaskAdder';
import { WorkCellSetupPage } from './ShopSetup/WorkCellSetup/ShopSetupPage/WorkCellSetupPage';
import { WorkCellsPage } from './WorkCells/WorkCellsPage/WorkCellsPage';
import { WorkRequestOrderDetailsPane } from './WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetailsPane';
import { WorkOrderItemsTable } from './WorkOrders/WorkOrder/WorkOrderItemsPage/WorkOrderItemsTable';
import { WorkOrderSelectedWrapper } from './WorkOrders/WorkOrder/WorkOrderItemsPage/WorkOrderSelectedWrapper';
import { WorkRequestItemsTable } from './WorkOrders/WorkOrder/WorkOrderItemsPage/WorkRequestItemsTable';
import { WorkRequestSelectedWrapper } from './WorkOrders/WorkOrder/WorkOrderItemsPage/WorkRequestSelectedWrapper';
import { WorkOrdersPage } from './WorkOrders/WorkOrdersPage/WorkOrdersPage';

export const InsightsNavContent = () => (
  <InsightsProvider>
    <ComponentPaneLayout>
      <InsightsPage />
    </ComponentPaneLayout>
  </InsightsProvider>
);

export const ShopMembersInterfaceNavContent = () => (
  <ShopDepartmentRequired>
    {(shopDepartmentId, selectedFacility) => (
      <ComponentPaneLayout>
        <ShopMembersPage shopDepartmentId={shopDepartmentId} selectedFacility={selectedFacility} />
      </ComponentPaneLayout>
    )}
  </ShopDepartmentRequired>
);

export const WorkCellsAndMachinesInterfaceNavContent = () => (
  <ShopDepartmentRequired>
    {(_, selectedFacility) => (
      <ShopSetupWrapper selectedFacility={selectedFacility}>
        <ComponentPaneLayout sizes={[1.4, 1]}>
          <WorkCellSetupPage />
          <WorkCellTaskAdder />
        </ComponentPaneLayout>
      </ShopSetupWrapper>
    )}
  </ShopDepartmentRequired>
);

export const WorkRequestsNavContent = () => (
  <FacilityOrProjectRequired projectRequired facilityRequired>
    {(selectedItem) => (
      <NoShopDptOverlay>
        <ComponentPaneLayout>
          <WorkRequestsPage selectedItem={selectedItem} />
        </ComponentPaneLayout>
      </NoShopDptOverlay>
    )}
  </FacilityOrProjectRequired>
);

export const WorkRequestNavContent = () => (
  <WorkRequestSelectedWrapper>
    <ComponentPaneLayout sizes={[1.4, 1]}>
      <WorkRequestItemsTable />
      <WorkRequestOrderDetailsPane />
    </ComponentPaneLayout>
  </WorkRequestSelectedWrapper>
);

export const WorkCellsInterfaceNavContent = () => (
  <ShopDepartmentRequired>
    {(_, selectedFacility) => (
      <ShopDepartmentFallback>
        <ComponentPaneLayout componentPaneProps={{ pb: 0 }}>
          <WorkCellsPage selectedFacility={selectedFacility} />
        </ComponentPaneLayout>
      </ShopDepartmentFallback>
    )}
  </ShopDepartmentRequired>
);

export const AddEditCatalogItemNavContent = () => (
  <PartEditorProvider>
    {(part) => (
      <AssemblyEditorProvider part={part}>
        <ComponentPaneLayout sizes={[1.5, 1]}>
          <AssemblyEditorView />
          <AddEditItemPane />
        </ComponentPaneLayout>
      </AssemblyEditorProvider>
    )}
  </PartEditorProvider>
);

export const CatalogSetupInterfaceNavContent = () => (
  <CatalogSetupProvider>
    <ComponentPaneLayout>
      <CatalogSetupCategorySelector />
      <CatalogSetupDetailsPane />
    </ComponentPaneLayout>
  </CatalogSetupProvider>
);

export const WorkOrdersNavContent = () => (
  <ShopDepartmentRequired>
    {(_, selectedFacility) => (
      <ComponentPaneLayout componentPaneProps={{ pb: '8px' }}>
        <WorkOrdersPage selectedFacility={selectedFacility} />
      </ComponentPaneLayout>
    )}
  </ShopDepartmentRequired>
);

export const WorkCellQueueNavContent = () => (
  <ShopDepartmentRequired>
    {(_, selectedFacility) => (
      <ComponentPaneLayout>
        <WorkCellQueuePage selectedFacility={selectedFacility} />
      </ComponentPaneLayout>
    )}
  </ShopDepartmentRequired>
);

export const TaskViewerNavContent = () => (
  <ComponentPaneLayout>
    <TaskViewerPage />
  </ComponentPaneLayout>
);

export const WorkOrderItemsNavContent = () => (
  <WorkOrderSelectedWrapper>
    <ComponentPaneLayout sizes={[1.4, 1]}>
      <WorkOrderItemsTable />
      <WorkRequestOrderDetailsPane />
    </ComponentPaneLayout>
  </WorkOrderSelectedWrapper>
);
