// Copyright ©️ 2024 eVolve MEP, LLC
import { useCallback } from 'react';

import { useWrappedPost } from 'hooks-api/useWrappedApiCall';

import { doSignOut } from './signOutHelper';

export const useSignOut = () => {
  const { apiCall } = useWrappedPost('admin/authentication/logout');

  return useCallback(async () => {
    await apiCall({})
      /* eslint-disable no-console */
      .catch(console.error)
      .finally(doSignOut);
  }, [apiCall]);
};
