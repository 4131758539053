// Copyright ©️ 2025 eVolve MEP, LLC
import { type ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  type MantineSize,
  MultiSelect,
  type MultiSelectProps,
  Select,
  type ComboboxItem,
  type SelectProps,
  type ComboboxItemGroup,
  Text,
  useMantineTheme,
} from '@mantine/core';

import type { WithRequiredIsh } from 'helpers/helper-types';

type TypeSafeComboboxItem<T, D> = D &
  Omit<ComboboxItem, 'value' | 'disabled'> & {
    group?: never;
  } & (
    | {
        value: T;
        disabled?: false;
      }
    | {
        value: string;
        // If the option is always disabled, it can't be selected.
        // Therefore, `value` doesn't have to be of type `T`
        disabled: true;
      }
  );

export type TypeSafeGroupComboboxData<T, D> = ComboboxItemGroup<TypeSafeComboboxItem<T, D>>;

type TypeSafeData<T, D> =
  | readonly T[]
  | readonly TypeSafeComboboxItem<T, D>[]
  | readonly TypeSafeGroupComboboxData<T, D>[];

type CreatableProps = {
  creatable?: boolean;
  onCreate?: (v: string) => void;
  createLabel?: string;
  /** If `true`, `onCreate` will be called with any remaining search value when the input is blurred. @default false */
  createOnBlur?: boolean;
  clearSearchOnChange?: boolean;
};

type BaseRenderOptionOptions = Parameters<NonNullable<SelectProps['renderOption']>>[0];
type RenderOptionOptions<T, D> = Omit<BaseRenderOptionOptions, 'option' | 'value'> & {
  option: Omit<BaseRenderOptionOptions['option'], 'value'> & D & { value: T };
};

export type TypeSafeSelectProps<T = string, D = object> = {
  renderOption?: (options: RenderOptionOptions<T, D>) => ReactNode;
  value?: T | null;
  onChange: (value: T | null) => void;
  onOptionSubmit?: (value: T) => void;
  data: TypeSafeData<T, D>;
  hideValueOnOpen?: boolean;
  blurOnChange?: boolean;
} & CreatableProps;

const optionExists = <D,>(data: TypeSafeData<string, D>, value: string): boolean =>
  data.some((opt) => {
    if (typeof opt === 'string') {
      return opt === value;
    }
    if ('label' in opt) {
      return opt.label === value || opt.value === value;
    }
    if ('group' in opt) {
      return optionExists(opt.items, value);
    }
    return false;
  });

const creatorKey = '_$create';

type UseCreatableKeys =
  | 'data'
  | 'searchValue'
  | 'onSearchChange'
  | 'searchable'
  | 'creatable'
  | 'onCreate'
  | 'createOnBlur';

const useCreatable = <T extends string, D>({
  data,
  searchable,
  creatable,
  onCreate,
  createOnBlur,
  searchValue: parentSearchValue,
  onSearchChange: parentOnSearchChange,
}: WithRequiredIsh<
  Pick<
    SelectProps & MultiSelectProps & TypeSafeSelectProps<T, D> & TypeSafeMultiSelectProps<T, D> & CreatableProps,
    UseCreatableKeys
  >
>) => {
  const [searchValue, setSearchValue] = useState(parentSearchValue ?? '');
  useEffect(() => {
    setSearchValue((v) => parentSearchValue ?? v);
  }, [parentSearchValue]);

  const onSearchChange = useCallback(
    (v: string) => {
      parentOnSearchChange?.(v);
      setSearchValue(v);
    },
    [parentOnSearchChange],
  );

  const createValue = useMemo(() => (parentSearchValue ?? searchValue).trim(), [parentSearchValue, searchValue]);

  const createValueIsValid = useMemo(
    () => creatable && !!createValue && !optionExists(data, createValue),
    [data, createValue, creatable],
  );

  const onOptionSubmit = useCallback<NonNullable<SelectProps['onOptionSubmit']>>(
    (v) => {
      if (creatable && v === creatorKey) {
        // Timeout so it occurs after onChange
        setTimeout(() => {
          onCreate?.(createValue);
        });
      }
    },
    [creatable, createValue, onCreate],
  );

  const onKeyUp = useCallback<NonNullable<SelectProps['onKeyUp']>>(
    (e) => {
      if (e.key === 'Enter' && createValueIsValid) {
        onOptionSubmit(creatorKey);
      }
    },
    [createValueIsValid, onOptionSubmit],
  );

  const onBlur = useCallback(() => {
    if (createOnBlur && createValueIsValid) {
      onOptionSubmit(creatorKey);
    }
  }, [createOnBlur, createValueIsValid, onOptionSubmit]);

  return {
    onKeyUp,
    onBlur,
    searchable: searchable || creatable,
    searchValue,
    onSearchChange,
    createValue,
    onOptionSubmit,
    createValueIsValid,
  };
};

export const WrappedSelect = <T extends string, D = object>({
  onChange,
  createLabel,
  data,
  searchValue,
  onSearchChange,
  creatable,
  searchable,
  onCreate,
  createOnBlur,
  hideValueOnOpen = false,
  clearSearchOnChange = false,
  onOptionSubmit,
  blurOnChange,
  ...props
}: Omit<SelectProps, keyof TypeSafeSelectProps<T, D>> & TypeSafeSelectProps<T, D>) => {
  const ref = useRef<HTMLInputElement>(null);
  const theme = useMantineTheme();
  const storedValue = useRef(props.value);
  const [dropdownOpened, setDropdownOpened] = useState(props.dropdownOpened);
  const { createValueIsValid, createValue, ...creatableProps } = useCreatable<T, D>({
    data,
    searchValue,
    onSearchChange,
    searchable,
    creatable,
    onCreate,
    createOnBlur,
  });
  return (
    <Select
      ref={ref}
      limit={1000}
      {...props}
      {...creatableProps}
      onOptionSubmit={(v) => {
        creatableProps.onOptionSubmit(v);
        onOptionSubmit?.(v as T);
      }}
      dropdownOpened={props.dropdownOpened ?? dropdownOpened}
      onDropdownOpen={(...e) => {
        storedValue.current = props.value;
        setDropdownOpened(true);
        props.onDropdownOpen?.(...e);
      }}
      onDropdownClose={(...e) => {
        if (props.value !== storedValue.current) {
          onChange(storedValue.current ?? null);
        }
        setDropdownOpened(false);
        props.onDropdownClose?.(...e);
      }}
      value={hideValueOnOpen && dropdownOpened ? null : props.value}
      onBlur={(...e) => {
        creatableProps.onBlur();
        props.onBlur?.(...e);
      }}
      onChange={(v) => {
        if (!!v || props.clearable) {
          if (v !== creatorKey) {
            storedValue.current = v as T;
          }
          if (clearSearchOnChange) {
            creatableProps.onSearchChange('');
          } else {
            creatableProps.onSearchChange(createValue);
          }
        }
        if (blurOnChange) {
          ref.current?.blur();
        }
      }}
      data={[
        ...data,
        ...(createValueIsValid
          ? [
              {
                label: createValue,
                value: creatorKey,
              },
            ]
          : []),
      ]}
      renderOption={(opts) =>
        opts.option.value === creatorKey ? (
          <Text fw={500} c={theme.primaryColor}>
            + {createLabel ?? 'Create'} {opts.option.label}
          </Text>
        ) : (
          (props.renderOption?.(opts as RenderOptionOptions<T, D>) ?? opts.option.label)
        )
      }
      styles={{
        dropdown: {
          marginTop: props.error ? 20 : undefined,
        },
        ...props.styles,
      }}
    />
  );
};
export type TypeSafeMultiSelectProps<T = string, D = object> = {
  renderOption?: (options: RenderOptionOptions<T, D>) => ReactNode;
  value?: T[];
  onChange: (value: T[]) => void;
  onOptionSubmit?: (value: T) => void;
  data: TypeSafeData<T, D>;
} & CreatableProps;

// TODO: There has to be a way to get this from Mantine theme,
// but I just can't find it
const sizeToRemMap: Record<MantineSize, string> = {
  xs: '1.875rem',
  sm: '2.25rem',
  md: '2.625rem',
  lg: '3.125rem',
  xl: '3.75rem',
} as const;

export const WrappedMultiSelect = <T extends string, D = object>({
  onChange,
  data,
  value,
  createLabel,
  /** Default size of Mantine Select is `sm` */
  size = 'sm',
  /** If `true`, will overflow the Select instead of wrapping @default true */
  nowrap = true,
  searchValue,
  onSearchChange,
  searchable,
  creatable,
  onCreate,
  createOnBlur,
  clearSearchOnChange = false,
  onOptionSubmit,
  ...props
}: Omit<MultiSelectProps, keyof TypeSafeMultiSelectProps<T, D>> &
  TypeSafeMultiSelectProps<T, D> & {
    nowrap?: boolean;
  }) => {
  const theme = useMantineTheme();
  const { createValueIsValid, createValue, ...creatableProps } = useCreatable<T, D>({
    data,
    searchValue,
    onSearchChange,
    searchable,
    creatable,
    onCreate,
    createOnBlur,
  });
  const [opened, setOpened] = useState(false);
  // Filter out any `value`s that don't exist in the data
  // They should never be passed in in the first place,
  // but this sanity check is sometimes needed.
  const existingValues = value?.filter((v) => optionExists(data, v));
  return (
    <MultiSelect
      limit={1000}
      {...creatableProps}
      onOptionSubmit={(v) => {
        creatableProps.onOptionSubmit(v);
        onOptionSubmit?.(v as T);
      }}
      inputContainer={
        nowrap
          ? (children) => (
              <Box
                pos="relative"
                style={{
                  // @ts-expect-error this check is safe
                  maxHeight: size in sizeToRemMap ? sizeToRemMap[size] : `calc(${size})`,
                  // Same zIndex as Dropdown. Would love to pull this programatically
                  zIndex: opened ? 300 : undefined,
                  overflowY: 'visible',
                }}
              >
                {children}
              </Box>
            )
          : undefined
      }
      size={size}
      // valueComponent={({ index, label, onRemove }) => {
      //   const displayLabel = index === 0 || opened;
      //   if (index < 2 || opened) {
      //     return (
      //       <Badge
      //         tt="none"
      //         radius="sm"
      //         size={size}
      //         color="gray"
      //         className="mantine-MultiSelect-defaultValueRemove mantine-CloseButton-root"
      //         c={displayLabel ? 'dark' : undefined}
      //         fw={500}
      //         title={displayLabel ? label : undefined}
      //         rightSection={
      //           displayLabel ? (
      //             <ActionIcon size="xs" variant="transparent" onClick={onRemove}>
      //               <EvolveIcon icon="Close" size="xs" color="gray.8" />
      //             </ActionIcon>
      //           ) : null
      //         }
      //         style={{
      //           userSelect: 'none',
      //           paddingRight: displayLabel ? 0 : undefined,
      //           minWidth: '2.5rem',
      //           maxWidth: 'calc(100% - 0.625rem)',
      //         }}
      //       >
      //         {displayLabel ? label : `+${values.length - 1}`}
      //       </Badge>
      //     );
      //   }
      //   return null;
      // }}
      hidePickedOptions
      {...props}
      placeholder={!existingValues || existingValues.length === 0 ? props.placeholder : ''}
      onBlur={(...e) => {
        setOpened(false);
        creatableProps.onBlur();
        props.onBlur?.(...e);
      }}
      onFocus={(...e) => {
        setOpened(true);
        props.onFocus?.(...e);
      }}
      value={existingValues}
      onChange={(v: string[] | null) => {
        if (!v?.includes(creatorKey)) {
          onChange((v ?? []) as T[]);
        }
        if (clearSearchOnChange) {
          creatableProps.onSearchChange('');
        } else {
          creatableProps.onSearchChange(createValue);
        }
      }}
      data={[
        ...data,
        ...(createValueIsValid
          ? [
              {
                label: createValue,
                value: creatorKey,
              },
            ]
          : []),
      ]}
      renderOption={(opts) =>
        opts.option.value === creatorKey ? (
          <Text fw={500} c={`${theme.primaryColor}.9`}>
            + {createLabel ?? 'Create'} {opts.option.label}
          </Text>
        ) : (
          (props.renderOption?.(opts as RenderOptionOptions<T, D>) ?? opts.option.label)
        )
      }
      styles={{
        dropdown: {
          marginTop: props.error ? 20 : undefined,
        },
        ...(nowrap
          ? {
              inputField: !opened ? { minWidth: 0 } : {},
              pillsList: !opened
                ? {
                    overflowX: 'scroll',
                    scrollbarWidth: 'none',
                    overflowWrap: 'unset',
                    flexWrap: 'nowrap',
                    marginRight: '5px !important',
                    // gap: 8,
                  }
                : {
                    paddingTop: 3,
                    paddingBottom: 3,
                    // gap: '6px 8px',
                  },
              ...props.styles,
            }
          : props.styles),
      }}
    />
  );
};
