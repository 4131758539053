// Copyright ©️ 2025 eVolve MEP, LLC
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useUser } from 'app/UserContext';
import { FILE_FORMATS } from 'constants/globalConstants';
import { isNil } from 'helpers/isNotNil';
import type {
  Document,
  DocumentId,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/types';
import { useDocumentsCache } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/useDocumentsCache';

import type { ForgeViewerProps } from '../ForgeViewer';

const PDF_EXTENSIONS = ['.pdf'];
const FILE_TYPE_FORMATS_DR = ['pdf'];

export const useGetGraphicType = (document?: Document | null) => {
  const { user } = useUser();
  const [typeFileFormat, setTypeFileFormat] = useState<(typeof FILE_FORMATS)[keyof typeof FILE_FORMATS] | null>(null);
  const cachedDocumentId = useRef<DocumentId>();
  const { getPresignedUrlForDocument, presigningInProgress } = useDocumentsCache();
  const [signedURL, setSignedUrl] = useState<string>();

  useEffect(() => {
    if (document?.documentId !== cachedDocumentId.current) {
      cachedDocumentId.current = undefined;
      setSignedUrl(undefined);
      setTypeFileFormat(null);
    }
  }, [document?.documentId]);

  useEffect(() => {
    if (document?.documentId) {
      if (PDF_EXTENSIONS.includes(document.extension.toLowerCase())) {
        cachedDocumentId.current = document.documentId;
        void getPresignedUrlForDocument(document.documentId, {
          expirationHours: 24,
          requestedBy: user.userId,
          verb: 'GET',
        }).then((r) => {
          // Prevent a race condition when clicking around quickly
          if (document.documentId === cachedDocumentId.current) {
            setTimeout(() => {
              setSignedUrl(r?.preSignedURL);
              setTypeFileFormat(FILE_FORMATS.PDF);
            });
          }
        });
      }
    }
  }, [document, getPresignedUrlForDocument, user]);

  const dynamicProps = useMemo<ForgeViewerProps | null>(() => {
    if (typeFileFormat === FILE_FORMATS.PDF)
      return {
        shouldInitializeAuth: false,
        env: 'Local',
        urn: signedURL,
        extensions: ['Autodesk.PDF'],
        accessToken: '',
        getAccessToken: () => null,
        useADP: false,
      };
    return null;
  }, [typeFileFormat, signedURL]);

  const downloadDocument = useCallback(() => {
    if (isNil(document)) return;
    void getPresignedUrlForDocument(document.documentId, {
      expirationHours: 24,
      requestedBy: user.userId,
      verb: 'GET',
    }).then((res) => {
      if (res?.preSignedURL) {
        window.open(res.preSignedURL, '_blank');
      }
    });
  }, [document, getPresignedUrlForDocument, user]);

  const isSupported = useMemo(
    () => FILE_TYPE_FORMATS_DR.some((formatFile) => `.${formatFile}` === document?.extension.toLowerCase()),
    [document?.extension],
  );

  return { dynamicProps, isSupported, downloadDocument, presigningInProgress };
};
