// Copyright ©️ 2024 eVolve MEP, LLC
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Loader } from '@mantine/core';
import type { GridApi } from 'ag-grid-enterprise';

import type { SelectedFacility } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { isNil, isNotNil } from 'helpers/isNotNil';
import type { ShopConfiguration } from 'hooks/projectsAndFacilities/types';
import { useWrappedPaginatedGet, useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import type { WorkCell, WorkCellTaskType } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

import { getWorkCellsColumnDef, getWorkCellTaskTypeColDef } from './columnDefs';

const getRowAtIndex = (api: GridApi<WorkCell>, index: number) => {
  let i = index + 1;
  let row;
  do {
    i -= 1;
    row = api.getDisplayedRowAtIndex(i);
  } while (row?.detail);
  return row;
};

export const useWorkCellsGrid = (
  selectedFacility: SelectedFacility,
  viewing: 'active' | 'draft',
  shopConfiguration: ShopConfiguration | undefined,
  splitViewIsOpen: boolean,
) => {
  const { fetchPage, setDefaultOpts } = useWrappedPaginatedGet<WorkCell>(`shop/workCell`, { lazy: true });
  useEffect(() => {
    if (isNotNil(shopConfiguration?.shopConfigurationId)) {
      setDefaultOpts({
        lazy: true,
        defaultConfig: {
          params: {
            facilityId: selectedFacility.id,
            orderBy: 'sortOrder:asc',
            shopConfigurationId: shopConfiguration.shopConfigurationId,
          },
        },
      });
    }
  }, [selectedFacility.id, setDefaultOpts, shopConfiguration?.shopConfigurationId]);
  const fetchPageWorkCells = useCallback(
    (...params: Parameters<typeof fetchPage>) =>
      fetchPage(...params).then((res) => ({
        ...res,
        // We don't want to show the "Unassigned" work cell
        // on this page, so we filter it out of the respone
        data: res.data.filter((wc) => wc.workCellName !== 'Unassigned'),
        entireCount: res.entireCount - 1,
      })),
    [fetchPage],
  );
  const { fetchPage: fetchPageWorkCellTaskType } = useWrappedPaginatedGet<WorkCellTaskType>('shop/workCellTaskType', {
    lazy: true,
  });
  const colDef = useMemo(
    () =>
      getWorkCellsColumnDef({
        isDraftView: viewing === 'draft',
        splitViewIsOpen,
      }),
    [viewing, splitViewIsOpen],
  );
  const { apiCall: reSortWorkCell, loading: sorting } = useWrappedPatch<WorkCell, unknown>(
    'shop/workCell/:workCellIdA?after=:workCellIdB',
  );
  const workCellTaskTypeColDef = useMemo(() => getWorkCellTaskTypeColDef(viewing === 'draft'), [viewing]);
  const [workCellDraggedToEnd, setWorkCellDraggedToEnd] = useState(false);
  const gridProps = useServerSideGrid({
    colDef,
    fetchPage: fetchPageWorkCells,
    rowId: 'workCellId',
    tableName: 'work-cells',
    saveFilters: false,
    detailTables: [
      {
        title: 'Tasks',
        colDef: workCellTaskTypeColDef,
        configMapper: ({ workCellId }) => ({
          params: {
            workCellId,
          },
        }),
        fetchPage: fetchPageWorkCellTaskType,
        icon: 'WorkCellTask',
        hideHeader: true,
        autoExpand: true,
      },
    ],
  });
  const agGridProps: typeof gridProps.agGridProps = useMemo(
    () => ({
      ...gridProps.agGridProps,
      onRowDragLeave: ({ api }) => {
        setWorkCellDraggedToEnd(false);
        api.deselectAll();
      },
      onRowDragMove: ({ api, overIndex }) => {
        setWorkCellDraggedToEnd(overIndex === -1);
        const row = getRowAtIndex(api, overIndex);
        if (isNil(row)) {
          api.deselectAll();
        } else {
          // Using "selected row" as a way
          // to highlight where the dragged row will drop
          row.setSelected(true, true);
        }
      },
      onRowDragEnd: ({ overIndex, api, node }) => {
        setWorkCellDraggedToEnd(false);
        api.deselectAll();
        const workCellId = node.data?.workCellId;
        if (isNil(workCellId)) return;

        const hoveredRow = getRowAtIndex(api, overIndex);
        const previousRow = isNotNil(hoveredRow)
          ? getRowAtIndex(api, (hoveredRow.rowIndex ?? 0) - 1)
          : api.getDisplayedRowAtIndex(api.getLastDisplayedRowIndex());

        if (hoveredRow?.data?.workCellId === workCellId || previousRow?.data?.workCellId === workCellId) {
          return;
        }
        void reSortWorkCell(
          {},
          {
            url: `shop/workCell/${workCellId}/prioritize`,
            params: {
              after: previousRow?.data?.workCellId,
            },
          },
        ).finally(() => gridProps.refreshGrid());
      },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      rowDragText: ({ rowNode }) => rowNode?.data?.workCellName,
      loadingOverlayComponent: () => <Loader type="bars" />,
      rowSelection: {
        mode: 'singleRow',
        checkboxes: false,
        enableClickSelection: false,
      },
    }),
    [gridProps, reSortWorkCell],
  );
  return {
    ...gridProps,
    agGridProps,
    workCellDraggedToEnd,
    sorting,
  };
};
