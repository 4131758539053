// Copyright ©️ 2025 eVolve MEP, LLC
import { useEffect, useState } from 'react';

import { Button, Flex, Text, Textarea } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';

import { useUser } from 'app/UserContext';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import type { WorkRequestStatusName } from 'constants/badgeMappingStatus';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useSelectedProjectFacility, type SelectedItem } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import { removeLocalStorage } from 'hooks/useLocalStorage';
import { useSetupModule } from 'hooks/useSetupModule';
import {
  getWorkRequestRejectedStorageKey,
  WorkRequestRejectedModal,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/WorkRequestRejectedModal';
import { canEditWorkRequest, type WorkRequestEditable } from 'modules/Shop/WorkOrders/WorkOrdersPage/common';

import { NeedsNeedByModal } from './NeedsNeedByModal';
import { TransferWorkRequestModal } from './TransferWorkRequestModal';
import type { WorkRequest } from './types';
import { useWorkRequests } from './useWorkRequests';

type Props = {
  workRequest: WorkRequest;
  onUpdate: (workRequest: WorkRequest) => void;
  inline?: boolean;
};

type ButtonInfo = {
  editable: WorkRequestEditable;
  projectOrFacility: NonNullable<SelectedItem['type']>[];
  newStatus: WorkRequestStatusName;
};

const secondaryButtons: ButtonInfo[] = [
  {
    editable: 'reject',
    projectOrFacility: ['FACILITY'],
    newStatus: 'Rejected',
  },
  {
    editable: 'cancel',
    projectOrFacility: ['PROJECT'],
    newStatus: 'Canceled',
  },
] as const;

const primaryButtons: ButtonInfo[] = [
  {
    editable: 'submit',
    projectOrFacility: ['PROJECT', 'FACILITY'],
    newStatus: 'Submitted',
  },
  {
    editable: 'approve',
    projectOrFacility: ['FACILITY'],
    newStatus: 'Approved',
  },
] as const;

const ActionButton = ({
  buttonInfo,
  mutationRunning,
  updating,
  inline,
  onSubmit,
  secondary = false,
}: {
  buttonInfo: ButtonInfo | undefined | null;
  mutationRunning: boolean;
  updating: WorkRequestStatusName | undefined;
  inline: boolean;
  onSubmit: (newStatus: WorkRequestStatusName) => void;
  secondary?: boolean;
}) =>
  isNil(buttonInfo) ? null : (
    <Button
      loading={updating === buttonInfo.newStatus}
      variant={secondary ? 'outline' : 'filled'}
      size={inline ? 'compact-sm' : 'sm'}
      onClick={() => onSubmit(buttonInfo.newStatus)}
      disabled={mutationRunning && updating !== buttonInfo.newStatus}
      style={{ textTransform: 'capitalize' }}
    >
      {buttonInfo.editable} {buttonInfo.editable === 'cancel' && 'WR'}
    </Button>
  );

export const WorkRequestActionButtons = ({ workRequest, onUpdate, inline = true }: Props) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { currentModule } = useSetupModule();
  const module = (currentModule() ?? 'shop').toLocaleLowerCase();

  const { selectedItem } = useSelectedProjectFacility();
  const type = selectedItem?.type;
  const { updateWorkRequestStatus, mutationRunning } = useWorkRequests();

  const [needsNeedBy, setNeedsNeedBy] = useState(false);
  const [updating, setUpdating] = useState<WorkRequestStatusName | undefined>();
  const [confirming, setConfirming] = useState<WorkRequestStatusName | undefined>();
  const [confirmReason, setConfirmReason] = useState('');

  useEffect(() => {
    setConfirmReason('');
  }, [confirming]);

  if (isNil(type)) return null;
  const primaryButtonInfo = primaryButtons.find(
    (b) => b.projectOrFacility.includes(type) && canEditWorkRequest(workRequest.workRequestStatusName, b.editable),
  );
  // Only the primary button is shown when the buttons are inline in a table
  const secondaryButtonInfo = inline
    ? null
    : secondaryButtons.find(
        (b) => b.projectOrFacility.includes(type) && canEditWorkRequest(workRequest.workRequestStatusName, b.editable),
      );

  const onSubmit = (newStatus: WorkRequestStatusName, description = '') => {
    setUpdating(newStatus);
    void updateWorkRequestStatus(workRequest.workRequestId, newStatus, description)
      .then(onUpdate)
      .then(() => {
        // If the WR is not being rejected, remove the storage key tracking the last time it was rejected
        // so we have a better chance of showing the most recent rejection if it gets rejected again
        if (newStatus !== 'Rejected') {
          removeLocalStorage(getWorkRequestRejectedStorageKey(user, workRequest));
        }
        notifications.show({
          title: 'Successfully updated',
          message: `${newStatus} work request ${workRequest.workRequestIdentifier}`,
          color: 'green',
        });
        void navigate(`/${module}/work-requests`);
      })
      .finally(() => setUpdating(undefined));
  };

  const baseButtonProps = {
    inline,
    mutationRunning,
    updating,
  };

  return (
    <Flex gap="sm">
      {!inline && <WorkRequestRejectedModal disabled={mutationRunning} workRequest={workRequest} />}
      {!inline && type === 'FACILITY' && workRequest.workRequestStatusName === 'Pending' && (
        <TransferWorkRequestModal disabled={mutationRunning} workRequest={workRequest} updateStatus={onSubmit} />
      )}
      <ActionButton secondary buttonInfo={secondaryButtonInfo} onSubmit={setConfirming} {...baseButtonProps} />
      <ActionButton
        secondary={inline}
        buttonInfo={primaryButtonInfo}
        onSubmit={(newStatus) => {
          if (isNil(workRequest.needBy)) {
            setUpdating(newStatus);
            setNeedsNeedBy(true);
          } else {
            onSubmit(newStatus);
          }
        }}
        {...baseButtonProps}
      />

      <NeedsNeedByModal
        opened={needsNeedBy}
        workRequest={workRequest}
        onUpdate={onUpdate}
        onComplete={() => {
          setTimeout(() => {
            setNeedsNeedBy(false);
            if (isNotNil(updating)) {
              onSubmit(updating);
            }
          });
        }}
        onClose={() => {
          setNeedsNeedBy(false);
          setUpdating(undefined);
        }}
      />

      <ConfirmationModal
        title="Confirmation"
        cancellationText="Go back"
        opened={isNotNil(confirming)}
        onClose={() => setConfirming(undefined)}
        onConfirm={() => {
          if (isNotNil(confirming)) onSubmit(confirming, confirmReason);
          setConfirming(undefined);
        }}
        loading={isNotNil(updating)}
        disabled={confirmReason.length < 1}
      >
        <Text size="sm">
          Please provide a reason for updating <b>{workRequest.workRequestIdentifier}</b> to{' '}
          <b>{confirming ?? updating}</b>.
        </Text>
        <Textarea
          autoFocus
          placeholder="Reason"
          value={confirmReason}
          onChange={(e) => setConfirmReason(e.target.value)}
          mt="xs"
          maxLength={1000}
        />
      </ConfirmationModal>
    </Flex>
  );
};
