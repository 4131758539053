// Copyright ©️ 2024 eVolve MEP, LLC
import { ActionIcon, Indicator } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { useMessages } from 'app/MessageCenter/MessagesContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';

export const MailButton = () => {
  const navigate = useNavigate();
  const goToMessageCenter = () => navigate('/message');
  const { totalUnread } = useMessages();

  return (
    <Indicator
      color="red"
      inline
      label={totalUnread > 999 ? '999+' : totalUnread}
      disabled={totalUnread === 0}
      size={16}
      offset={2}
      styles={{ indicator: { paddingTop: 2 } }}
    >
      <ActionIcon color="gray" variant="filled" size="lg" onClick={goToMessageCenter} radius="xl">
        <EvolveIcon icon="Mail" color="#888" />
      </ActionIcon>
    </Indicator>
  );
};
