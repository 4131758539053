// Copyright ©️ 2024 eVolve MEP, LLC
import { Flex, Title } from '@mantine/core';

import { EvolveIcon, type EvolveIconName } from 'assets/icons/EvolveIcon';

type Props = {
  icon?: EvolveIconName;
  message: string;
};
export const NoContentFallback = ({ icon = 'Info', message }: Props) => (
  <Flex style={{ width: '100%', height: '100%' }} direction="column" align="center" justify="center">
    <EvolveIcon icon={icon} width={42} color="gray.7" />
    <Title order={3} fw={500} mt="xs" c="gray.7">
      {message}
    </Title>
  </Flex>
);
