// Copyright ©️ 2024 eVolve MEP, LLC
import { isNotNil } from 'helpers/isNotNil';
import './MySelectionWindow';

const { Autodesk } = window;

type ICON = 'MULTI' | 'SINGLE' | 'WINDOW';

const ICON_CLASS = {
  POINTER: 'fa-mouse-pointer',
  GROUP: 'fa-object-ungroup',
  WINDOW: 'fa-window-maximize',
} as const;

const FAS = 'fas';

class CustomToolbarExtension extends Autodesk.Viewing.Extension {
  onToolbarCreatedBinded: (() => void) | null | undefined;
  selectedOption: ICON | null;

  constructor(viewer: Autodesk.Viewing.GuiViewer3D, opts: any) {
    super(viewer, opts);
    this.selectedOption = null;
  }

  load() {
    if (isNotNil(this.viewer.toolbar)) {
      // Toolbar is already available, create the UI
      this.createUI();
    } else {
      // Toolbar hasn't been created yet, wait until we get notification of its creation
      this.onToolbarCreatedBinded = this.onToolbarCreated.bind(this);
      this.viewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreatedBinded);
    }
    this.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.handleSelectionChanged);
    return true;
  }

  onToolbarCreated() {
    if (this.onToolbarCreatedBinded) {
      this.viewer.removeEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreatedBinded);
      this.onToolbarCreatedBinded = null;
      this.createUI();
    }
  }

  createUI() {
    const toolbarGroup = this.viewer.toolbar.getControl('navTools') as Autodesk.Viewing.UI.ControlGroup;

    if (isNotNil(toolbarGroup)) {
      const dropdownMenu = new Autodesk.Viewing.UI.ComboButton('SelectCustomAssemblies');
      dropdownMenu.addClass('selectCustomAssemblies');
      dropdownMenu.setToolTip('Select');

      // Add the buttons to the drop-down menu
      dropdownMenu.addControl(this.createSingleSelectButton());
      dropdownMenu.addControl(this.createMultiSelectButton());
      dropdownMenu.addControl(this.createWindowSelectButton());
      dropdownMenu.icon.classList.add(FAS, ICON_CLASS.POINTER);

      toolbarGroup.addControl(dropdownMenu);
    }
  }

  handleSelectionChanged = ({ dbIdArray, model }: { dbIdArray: any[]; model: Autodesk.Viewing.Model }) => {
    if (dbIdArray.length > 0) {
      const selectedDbIds = dbIdArray;
      void model
        .getPropertyDb()
        .executeUserFunction(this.userFunction, selectedDbIds)
        .then((externalIds: string[]) => {
          // eslint-disable-next-line no-console
          console.log(`external ids are: ${externalIds.join(',')}`);
        });
    }
  };

  // ????????
  userFunction(pdb: any, userData: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    const externalIds = userData.map((dbId: any) => pdb.getIdAt(dbId));
    return externalIds;
  }

  createSingleSelectButton() {
    const button = new Autodesk.Viewing.UI.Button('SingleSelectButton');
    button.onClick = () => this.handleSingleSelectButtonClick();
    button.icon.classList.add(FAS, ICON_CLASS.POINTER);
    button.setToolTip('Single Select');
    button.addClass('customAssemblyButton');

    return button;
  }

  createMultiSelectButton() {
    const button = new Autodesk.Viewing.UI.Button('MultiSelectButton');
    button.onClick = () => this.handleMultiSelectButtonClick();
    button.icon.classList.add(FAS, ICON_CLASS.GROUP);
    button.setToolTip('Multi Select');

    return button;
  }

  createWindowSelectButton() {
    const button = new Autodesk.Viewing.UI.Button('WindowSelectButton');
    button.onClick = () => this.handleWindowButtonClick();
    button.icon.classList.add(FAS, ICON_CLASS.WINDOW);
    button.setToolTip('Window Select');

    return button;
  }

  handleSingleSelectButtonClick() {
    this.viewer.setActiveNavigationTool('pan');
    this.selectedOption = 'SINGLE';

    void this.viewer.loadExtension('MySelectionWindow').then((ext) => {
      ext.unload();
    });

    this.updateDropdownMenuIcon();
  }

  handleMultiSelectButtonClick() {
    this.viewer.setActiveNavigationTool('pan');
    this.selectedOption = 'MULTI';

    void this.viewer.loadExtension('MySelectionWindow').then((ext) => {
      ext.unload();
    });

    this.updateDropdownMenuIcon();
  }

  handleWindowButtonClick() {
    this.selectedOption = 'WINDOW';

    this.updateDropdownMenuIcon();

    void this.viewer.loadExtension('MySelectionWindow').then(async (ext) => {
      // @ts-expect-error init exists on the MySelectionWindow (multiSelectExtension.js) class
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      ext.init();
      await ext.load();
    });
  }

  updateDropdownMenuIcon() {
    const dropdownMenu = (this.viewer.toolbar.getControl('navTools') as Autodesk.Viewing.UI.ControlGroup).getControl(
      'SelectCustomAssemblies',
    ) as Autodesk.Viewing.UI.ComboButton;
    if (isNotNil(dropdownMenu)) {
      let iconClass: (typeof ICON_CLASS)[keyof typeof ICON_CLASS] = ICON_CLASS.POINTER;

      if (this.selectedOption === 'SINGLE') {
        iconClass = ICON_CLASS.POINTER;
      }

      if (this.selectedOption === 'MULTI') {
        iconClass = ICON_CLASS.GROUP;
      }

      if (this.selectedOption === 'WINDOW') {
        iconClass = ICON_CLASS.WINDOW;
      }

      dropdownMenu.icon.classList.forEach((c) => dropdownMenu.icon.classList.remove(c));
      dropdownMenu.icon.classList.add(FAS, iconClass);
    }
  }
}

Autodesk.Viewing.theExtensionManager.registerExtension('CustomToolbarExtension', CustomToolbarExtension);
