// Copyright ©️ 2024 eVolve MEP, LLC
export const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegEx = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).*$/;

export const specialCharacterRegEx = /[^A-Za-z0-9]/;

export const numberRegEx = /\d+/;

export const onlyLettersRegEx = /^[a-zA-Z]*$/;

export const uppercaseRegEx = /[A-Z]/;

export const lowercaseRegEx = /[a-z]/;

export const nameRegEx = /^[A-Za-z]+((\s)?([A-Za-z])+)*$/;

export const phoneRegEx = /^[\d-]*$/;

export const addressRegEx = /^[A-Za-z0-9'.,#-/\s\\]*$/;

export const cityRegEx = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;

export const countryRegEx = /^([a-zA-Z\u0080-\u024F]{2,}(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;

export const romanNumeralRegEx = /^M{0,3}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/;

export const scopePackageIdentifierRegEx = /^(SP|sp|Sp|sP)-\d+$/;

export const positiveAndIntegerNumber = /^\d+$/;
