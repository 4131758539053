// Copyright ©️ 2024 eVolve MEP, LLC
import type { Edge, Node } from '@xyflow/react';
import { v4 as uuidv4 } from 'uuid';

import type { Task, TaskId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import {
  ASSEMBLY_EDGE_TYPE_ID as assemblyEdgeTypeId,
  ASSEMBLY_NODE_PART_TYPE_ID,
  ASSEMBLY_NODE_SHOP_TASK_TYPE_ID,
  DEFAULT_ASSEMBLY_NODE_POSITION,
  EDGE_TYPE_ID_DICT,
  ASSEMBLY_EDGE_TYPE_ID,
  type HANDLE_POSITION_ID,
} from 'modules/Materials/AssemblyEditor/Utils/constants';
import type {
  PartNodeType,
  TaskNodeType,
} from 'modules/Materials/CatalogSetup/CatalogSetupPage/PartEditor/nodes/AssemblyNode';
import type { AssemblyNodeId } from 'modules/Materials/CatalogSetup/CatalogSetupPage/PartEditor/types';

import type { Part, PartId } from './SecondaryPane/AddItems/types';
import type { AssemblyEdge, AssemblyNode, BaseAssemblyNode, NewAssemblyNode, NodeReferenceId } from './types';

const referenceId = () => uuidv4() as NodeReferenceId;

export const createAssemblyFromWriteInItem = (
  { partId, partName, description }: Part,
  tasks: Task[],
): {
  partId: PartId;
  edges: AssemblyEdge[];
  nodes: NewAssemblyNode[];
} => {
  // Arrows generated from this func go left to right
  const beginHandlePositionId = EDGE_TYPE_ID_DICT.b;
  const endHandlePositionId = EDGE_TYPE_ID_DICT.a;

  const edges: AssemblyEdge[] = [];
  const nodes: NewAssemblyNode[] = [];

  // Start with the Part
  const partNode: NewAssemblyNode = {
    partId,
    assemblyNodeName: partName,
    assemblyNodeDescription: description ?? null,
    assemblyNodeTypeId: ASSEMBLY_NODE_PART_TYPE_ID,
    referenceId: referenceId(),
    quantity: 0,
    ...DEFAULT_ASSEMBLY_NODE_POSITION,
  };
  nodes.push(partNode);

  // Find every Task that is NOT a predecessor to any other task
  const tasksThatAreNotPredecessors: TaskId[] = tasks
    .filter((task) => !tasks.some((t) => t.taskPredecessorIds.some((pred) => pred.taskId === task.taskId)))
    .map((task) => task.taskId);

  // Add a node for every task
  const taskToNodeMap: Record<TaskId, NewAssemblyNode> = {};
  tasks.forEach((task) => {
    taskToNodeMap[task.taskId] = {
      shopTaskId: task.taskTypeId,
      assemblyNodeName: task.taskTypeName,
      assemblyNodeDescription: null,
      assemblyNodeTypeId: ASSEMBLY_NODE_SHOP_TASK_TYPE_ID,
      referenceId: referenceId(),
    };
    nodes.push(taskToNodeMap[task.taskId]);
  });

  // Then, for every task...
  tasks.forEach((task) => {
    // find its node...
    const taskNode = taskToNodeMap[task.taskId];
    // then, for every predecessor, add an Edge from the predecessor to this Task...
    task.taskPredecessorIds.forEach((t) => {
      const predecessorNode = taskToNodeMap[t.taskId];
      edges.push({
        beginNodeReferenceId: predecessorNode.referenceId,
        endNodeReferenceId: taskNode.referenceId,
        assemblyEdgeTypeId,
        beginHandlePositionId,
        endHandlePositionId,
      });
    });
    // and finally, if this Task is not a predecessor for any other task,
    // and an edge from this Task to the Part.
    if (tasksThatAreNotPredecessors.includes(task.taskId)) {
      edges.push({
        beginNodeReferenceId: taskNode.referenceId,
        endNodeReferenceId: partNode.referenceId,
        assemblyEdgeTypeId,
        beginHandlePositionId,
        endHandlePositionId,
      });
    }
  });

  return { partId, edges, nodes };
};

const getBaseAssemblyNodeFromFlowNode = (node: Node, assemblyPart: Part): BaseAssemblyNode => {
  const common = {
    positionX: Math.floor(node.position.x),
    positionY: Math.floor(node.position.y),
  };
  if (node.type === 'task') {
    const {
      data: { taskType },
    } = node as TaskNodeType;
    return {
      assemblyNodeName: taskType.taskTypeName,
      assemblyNodeDescription: taskType.taskTypeDescription ?? null,
      assemblyNodeTypeId: ASSEMBLY_NODE_SHOP_TASK_TYPE_ID,
      shopTaskId: taskType.taskTypeId,
      ...common,
    };
  }
  const {
    data: { part, quantity },
  } = node as PartNodeType;

  if (node.type === 'assembly') {
    return {
      assemblyNodeName: assemblyPart.partName,
      assemblyNodeDescription: assemblyPart.description ?? null,
      assemblyNodeTypeId: ASSEMBLY_NODE_PART_TYPE_ID,
      partId: assemblyPart.partId,
      quantity,
      ...common,
    };
  }

  return {
    assemblyNodeName: part.partName,
    assemblyNodeDescription: part.description ?? null,
    assemblyNodeTypeId: ASSEMBLY_NODE_PART_TYPE_ID,
    partId: part.partId,
    quantity,
    ...common,
  };
};

export const getAssemblyNodeFromFlowNode = (node: Node, assemblyPart: Part): AssemblyNode => ({
  ...getBaseAssemblyNodeFromFlowNode(node, assemblyPart),
  assemblyNodeId: node.id as AssemblyNodeId,
});

export const getNewAssemblyNodeFromFlowNode = (node: Node, assemblyPart: Part): NewAssemblyNode => ({
  ...getBaseAssemblyNodeFromFlowNode(node, assemblyPart),
  referenceId: node.id as NodeReferenceId,
});

export const getAssemblyEdgeFromFlowEdge = (edge: Edge): AssemblyEdge => ({
  assemblyEdgeTypeId: ASSEMBLY_EDGE_TYPE_ID,
  beginNodeReferenceId: edge.source as NodeReferenceId,
  endNodeReferenceId: edge.target as NodeReferenceId,
  beginHandlePositionId: (edge.sourceHandle as HANDLE_POSITION_ID | null) ?? EDGE_TYPE_ID_DICT.b,
  endHandlePositionId: (edge.targetHandle as HANDLE_POSITION_ID | null) ?? EDGE_TYPE_ID_DICT.a,
});
