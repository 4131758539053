// Copyright ©️ 2024 eVolve MEP, LLC
import { useEffect, useMemo } from 'react';

import { isNil } from 'helpers/isNotNil';
import { useWrappedGet, useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import type { WorkRequestItem } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import type { Part, PartId } from './SecondaryPane/AddItems/types';
import { type PartDecision, PartDecisionMakerModal } from './SecondaryPane/PartDecisionMakerModal';
import type { WorkOrderItem } from './types';

type Props = {
  opened: boolean;
  onClose: () => void;
} & (
  | {
      workOrderItem: WorkOrderItem;
      workOrderItemUpdated: (updatedWorkOrderItem: WorkOrderItem) => void;
    }
  | {
      workRequestItem: WorkRequestItem;
      workRequestItemUpdated: (updatedWorkRequestItem: WorkRequestItem) => void;
    }
);

export const RemakeDecisions = ({ opened, onClose, ...props }: Props) => {
  const { partId, quantity, partVersionNumber, ...item } =
    'workOrderItem' in props ? props.workOrderItem : props.workRequestItem;
  const { data: part, apiCall } = useWrappedGet<Part>(`moab/part/${partId}/version/${partVersionNumber}`, {
    lazy: true,
  });
  useEffect(() => {
    if (opened && isNil(part)) {
      void apiCall();
    }
  }, [apiCall, opened, part]);

  const itemId =
    'workOrderItem' in props ? props.workOrderItem.workOrderItemId : props.workRequestItem.workRequestItemId;
  const { apiCall: updateWorkOrderItemDecisions } = useWrappedPatch<
    Pick<WorkOrderItem, 'workOrderItemName' | 'workOrderItemId'>,
    Pick<PartDecision, 'decisions'>
  >(`shop/workOrderItem/${itemId}/makePartDecisions`);
  const { apiCall: updateWorkRequestItemDecisions } = useWrappedPatch<
    Pick<WorkRequestItem, 'workRequestItemName' | 'workRequestItemId'>,
    Pick<PartDecision, 'decisions'>
  >(`shop/workRequestItem/${itemId}/makePartDecisions`);
  const partToAdd = useMemo(() => (part ? [{ part, quantity }] : []), [part, quantity]);
  // TODO: Pull from API when possible
  const previousDecisions = useMemo(() => [], []);
  if (isNil(partId)) return null;

  const onSubmit = async (decisions: Record<PartId, PartDecision>) => {
    if ('workOrderItem' in props) {
      await updateWorkOrderItemDecisions({ decisions: decisions[partId].decisions }).then((r) =>
        props.workOrderItemUpdated({
          ...props.workOrderItem,
          ...r,
        }),
      );
    } else {
      await updateWorkRequestItemDecisions({ decisions: decisions[partId].decisions }).then((r) =>
        props.workRequestItemUpdated({
          ...props.workRequestItem,
          ...r,
        }),
      );
    }
    onClose();
  };

  return (
    <PartDecisionMakerModal
      title={'workOrderItemName' in item ? item.workOrderItemName : item.workRequestItemName}
      previousDecisions={previousDecisions}
      opened={opened}
      onSubmit={onSubmit}
      onClose={onClose}
      parts={partToAdd}
    />
  );
};
