// Copyright ©️ 2024 eVolve MEP, LLC
import React, { type ReactNode, useMemo } from 'react';

import { useGeneralContext } from 'helpers/useGeneralContext';
import { useWrappedGetWithLocalStorage } from 'hooks-api/useWrappedGetWithLocalStorage';

declare const documentTypeIdSymbol: unique symbol;
type DocumentTypeId = string & { [documentTypeIdSymbol]: never };

export type DocumentType = {
  documentTypeId: DocumentTypeId;
  documentTypeName: string;
};

type DocumentTypesContextType = {
  documentTypes: DocumentType[];
  commonTypeId: DocumentTypeId | null;
  drawingModelCNCIds: `${DocumentTypeId | null},${DocumentTypeId | null},${DocumentTypeId | null}` | '';
};

const getTypeId = (documentTypes: DocumentType[], documentTypeName: string): DocumentTypeId | null =>
  documentTypes.find((d) => d.documentTypeName === documentTypeName)?.documentTypeId ?? null;

const DocumentTypesContext = React.createContext<DocumentTypesContextType | undefined>(undefined);

export const DocumentTypesProvider = ({ children }: { children: ReactNode }) => {
  const { data: documentTypes } = useWrappedGetWithLocalStorage('DOCUMENT_TYPES', undefined, 'docmgt/documentType');

  const value = useMemo<DocumentTypesContextType>(() => {
    if (!documentTypes)
      return {
        documentTypes: [],
        drawingModelCNCIds: '',
        commonTypeId: null,
      };

    const commonTypeId = getTypeId(documentTypes, 'Common');
    const drawingsTypeId = getTypeId(documentTypes, 'Drawings');
    const modelsTypeId = getTypeId(documentTypes, 'Models');
    const CNCTypeId = getTypeId(documentTypes, 'CNC');

    const drawingModelCNCIds = `${drawingsTypeId},${modelsTypeId},${CNCTypeId}` as const;

    return { documentTypes, drawingModelCNCIds, commonTypeId };
  }, [documentTypes]);

  return <DocumentTypesContext.Provider value={value}>{children}</DocumentTypesContext.Provider>;
};

export const useDocumentTypes = () => useGeneralContext(DocumentTypesContext, 'DocumentTypes');
