// Copyright ©️ 2024 eVolve MEP, LLC
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Loader } from '@mantine/core';
import type { IRowNode, RowSelectionOptions } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';

import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import type { ShowOpen } from 'components/Mantine/OpenClosedTab';
import type { WorkRequestStatusName } from 'constants/badgeMappingStatus';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { isNil, isNotNil } from 'helpers/isNotNil';
import type { SelectedItem } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import { WorkRequestOrderDetailDrawer } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/WorkRequestOrderDetailDrawer';

import { bomItemColDef, getWorkRequestColDef, spoolsItemColDef } from './columnDefs';
import { facilityProjectTypeToQueryParam } from './common';
import { NewWorkRequestModal } from './NewWorkRequestModal';
import type { WorkRequest, WorkRequestStatusType } from './types';
import { useWorkRequests } from './useWorkRequests';

const rowSelection: RowSelectionOptions = {
  mode: 'singleRow',
  enableClickSelection: false,
  checkboxes: false,
};

const statusesByGroup: Record<ShowOpen, WorkRequestStatusName[]> = {
  open: ['Draft', 'Submitted', 'Pending', 'Approved', 'Rejected', 'Canceled', 'In Progress'],
  closed: ['Completed'],
};

const statusesToFilterOut: Record<SelectedItem['type'], WorkRequestStatusName[]> = {
  FACILITY: ['Canceled', 'Rejected', 'Draft', 'Transferred'],
  PROJECT: ['Transferred'],
} as const;

type Props = {
  workRequestStatuses: WorkRequestStatusType[];
  facilityProject: SelectedItem;
};

const WorkRequestsTable = ({ workRequestStatuses, facilityProject }: Props) => {
  const {
    setSelectedWorkRequest,
    selectedWorkRequest,
    fetchWorkRequests,
    fetchBomItems,
    fetchSpools,
    setWorkRequestsDefaultOpts,
  } = useWorkRequests();
  const [selectedNode, setSelectedNode] = useState<IRowNode<WorkRequest> | undefined>();
  const [showOpen, setShowOpen] = useState<ShowOpen>('open');
  const possibleStatuses = useMemo(
    () =>
      workRequestStatuses.filter(
        (s) =>
          statusesByGroup[showOpen].includes(s.workRequestStatusTypeName) &&
          !statusesToFilterOut[facilityProject.type].includes(s.workRequestStatusTypeName),
      ),
    [facilityProject.type, showOpen, workRequestStatuses],
  );
  const workRequestColDef = useMemo(
    () => getWorkRequestColDef(possibleStatuses, facilityProject.type, setSelectedWorkRequest, showOpen === 'open'),
    [possibleStatuses, facilityProject.type, setSelectedWorkRequest, showOpen],
  );

  useEffect(() => {
    setSelectedWorkRequest(selectedNode?.data);
  }, [selectedNode?.data, setSelectedWorkRequest]);

  useEffect(() => {
    // The default `workRequestStatusIds` change when a user toggles the Open/Closed tabs,
    // so we use this effect to change the behavior of fetchPage.
    // Once fetchPage updates, the datasource will be recreated as part of a later useEffect
    // which will cause the entire Grid to reload.
    setWorkRequestsDefaultOpts({
      lazy: true,
      defaultConfig: {
        params: {
          [facilityProjectTypeToQueryParam[facilityProject.type]]: facilityProject.id,
          // Order by needBy if not ordering by anything else
          orderBy: 'needBy:asc',
          workRequestStatusIds: possibleStatuses.map((s) => s.workRequestStatusTypeId).join(','),
        },
      },
    });
  }, [facilityProject.id, facilityProject.type, possibleStatuses, setWorkRequestsDefaultOpts]);

  const { agGridProps, selectNextRow, filterIsSet } = useServerSideGrid({
    tableName: 'work-requests',
    fetchPage: fetchWorkRequests,
    colDef: workRequestColDef,
    rowId: 'workRequestId',
    detailTables: [
      {
        icon: 'BillOfMaterials',
        title: 'Bill of Materials',
        colDef: bomItemColDef,
        fetchPage: fetchBomItems,
        exportable: true,
        exportFileName: ({ workRequestIdentifier, projectName }) => `${projectName}_${workRequestIdentifier}_BOM`,
        configMapper: ({ workRequestId }) => ({ params: { workRequestId } }),
      },
      {
        icon: 'Spools',
        title: 'Spools',
        colDef: spoolsItemColDef,
        fetchPage: fetchSpools,
        exportable: true,
        exportFileName: ({ workRequestIdentifier, projectName }) => `${projectName}_${workRequestIdentifier}_Spools`,
        configMapper: ({ workRequestId }) => ({ params: { workRequestId } }),
        hideHeader: true,
      },
    ],
  });

  const workRequestUpdated = useCallback(
    (workRequest: WorkRequest) => {
      setSelectedWorkRequest(workRequest);
      selectedNode?.updateData(workRequest);
    },
    [selectedNode, setSelectedWorkRequest],
  );

  return (
    <>
      <BasePageHeader
        title="Work Requests"
        showOpen={showOpen}
        setShowOpen={setShowOpen}
        gridRef={agGridProps.ref}
        filterIsSet={filterIsSet}
        topRightComponent={
          facilityProject.type === 'PROJECT' ? <NewWorkRequestModal selectedProject={facilityProject} /> : null
        }
      />
      <div style={{ height: '100%' }}>
        <AgGridReact<WorkRequest>
          {...agGridProps}
          rowSelection={rowSelection}
          onSelectionChanged={(e) => setSelectedNode(e.api.getSelectedNodes()[0])}
          noRowsOverlayComponent={() => (
            <NoRowsOverlay
              label={
                <>
                  This {facilityProject.type === 'FACILITY' ? 'facility' : 'project'} has no {showOpen} work requests.
                  {facilityProject.type === 'PROJECT' && showOpen === 'open' && (
                    <>
                      <br />
                      Start by creating a new work request.
                    </>
                  )}
                </>
              }
              icon="WorkRequest"
              gridRef={agGridProps.ref}
            />
          )}
        />
      </div>
      <WorkRequestOrderDetailDrawer
        workRequest={selectedWorkRequest}
        workRequestUpdated={workRequestUpdated}
        selectedItem={facilityProject}
        onClose={() => {
          agGridProps.ref.current?.api.deselectAll();
          setSelectedWorkRequest(undefined);
        }}
        navigate={(next = true) => {
          const index = selectedNode?.rowIndex;
          if (isNotNil(index)) {
            selectNextRow(index, next);
          }
        }}
      />
    </>
  );
};

export const WorkRequestsPage = ({ selectedItem }: { selectedItem: SelectedItem }) => {
  const { workRequestStatuses } = useWorkRequests();
  if (isNil(workRequestStatuses)) {
    return <Loader m="sm" />;
  }

  return <WorkRequestsTable workRequestStatuses={workRequestStatuses} facilityProject={selectedItem} />;
};
