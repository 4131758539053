// Copyright ©️ 2024 eVolve MEP, LLC
import React, { useState } from 'react';

import { Flex, Text, Overlay } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

import type { PartCategory, PendingCatalogNav } from './types';

export type NavPartCategory = Pick<PartCategory, 'partCategoryId' | 'partCategoryName'>;
type Props = {
  blur: boolean;
  categoryIsFromSearch: boolean;
  categoryHistory: NavPartCategory[];
  setPendingNavigation: (pendingNav: PendingCatalogNav) => void;
  clearSearchCategory: () => void;
};

export const CatalogBreadcrumb = ({
  blur,
  categoryIsFromSearch,
  categoryHistory,
  setPendingNavigation,
  clearSearchCategory,
}: Props) => {
  const [hardCloseHover, setHardCloseHover] = useState(false);
  return (
    <Flex gap={8} align="center" fz="sm" pos="relative" style={{ minHeight: 24 }}>
      {blur && <Overlay color="#fff" blur={2} />}
      {categoryHistory.map((c, i) => {
        const notLast = i < categoryHistory.length - 1;
        return (
          <React.Fragment key={`breadcrumb-${c.partCategoryId}`}>
            <Text
              key={`breadcrumb-${c.partCategoryId}-name`}
              c={notLast ? 'dimmed' : undefined}
              style={{
                userSelect: 'none',
                cursor: notLast ? 'pointer' : undefined,
              }}
              onClick={() => {
                if (i === 0) {
                  setHardCloseHover(false);
                  clearSearchCategory();
                } else {
                  setPendingNavigation({
                    categoryHistory: categoryHistory.slice(0, i + 1),
                    category: c,
                  });
                }
              }}
            >
              {c.partCategoryName}
            </Text>

            {notLast && (
              <Text key={`breadcrumb-${c.partCategoryId}-divider`} style={{ userSelect: 'none' }} c="dimmed">
                /
              </Text>
            )}

            {categoryIsFromSearch && i === 0 && (
              <>
                <Text
                  key="breadcrumb-close"
                  fw={600}
                  ta="center"
                  style={{
                    userSelect: 'none',
                    cursor: 'pointer',
                    width: 16,
                  }}
                  onClick={() => {
                    setHardCloseHover(false);
                    clearSearchCategory();
                  }}
                  onMouseEnter={() => setHardCloseHover(true)}
                  onMouseLeave={() => setHardCloseHover(false)}
                >
                  {hardCloseHover ? <EvolveIcon color="black" icon="Close" size="sm" /> : '...'}
                </Text>
                <Text key="breadcrumb-close-divider" style={{ userSelect: 'none' }} c="dimmed">
                  /
                </Text>
              </>
            )}
          </React.Fragment>
        );
      })}
    </Flex>
  );
};
