// Copyright ©️ 2025 eVolve MEP, LLC
import React, { type ReactNode, useMemo, useState } from 'react';

import { Modal, Text, TextInput } from '@mantine/core';

import { useMaybeUser } from 'app/MaybeUserContext';
import type { WithRequired } from 'helpers/helper-types';
import { isNotNil } from 'helpers/isNotNil';

import { type ModalButtonProps, ModalButtons } from './ModalButtons';

type Props = React.ComponentProps<typeof Modal> &
  WithRequired<Omit<ModalButtonProps, 'dividerMargin'>, 'onConfirm'> & {
    requireUserEmail?: boolean;
    title?: string;
  } & (
    | { description: string; children?: never }
    | {
        description?: never;
        children: ReactNode;
      }
  );

export const ConfirmationModal = ({
  title = 'Confirm',
  description,
  children,
  opened,
  cancellationText = 'Cancel',
  confirmationText = 'Confirm',
  buttonColor,
  loading,
  disabled,
  onClose,
  onConfirm,
  requireUserEmail,
  ...props
}: Props) => {
  const { user } = useMaybeUser();
  const [emailInput, setEmailInput] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailValid, setEmailValid] = useState(!requireUserEmail);
  const [cachedChildren, setCachedChildren] = useState(children);
  useMemo(() => {
    if (opened) {
      setCachedChildren(children);
      setEmailInput('');
    }
  }, [children, opened, setCachedChildren]);
  return (
    <Modal opened={opened} centered closeOnClickOutside={false} title={title} onClose={onClose} {...props}>
      <div style={{ overflowX: 'hidden' }}>
        {isNotNil(description) && <Text>{description}</Text>}
        {opened ? children : cachedChildren}
      </div>
      {requireUserEmail && (
        <TextInput
          label="Please type your email to confirm:"
          mt="xs"
          type="email"
          onChange={(e) => {
            const { value } = e.target;
            setEmailError(false);
            setEmailInput(value);
            setEmailValid(value.toLocaleLowerCase() === user?.userEmail.toLocaleLowerCase());
          }}
          autoFocus
          required
          value={emailInput}
          disabled={loading}
          onBlur={() => setEmailError(!emailValid)}
          error={emailError && !!emailInput ? 'Email does not match.' : undefined}
        />
      )}
      <ModalButtons
        dividerMargin="sm"
        confirmationText={confirmationText}
        cancellationText={cancellationText}
        buttonColor={buttonColor}
        loading={loading}
        disabled={disabled || !emailValid}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </Modal>
  );
};
