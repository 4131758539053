// Copyright ©️ 2024 eVolve MEP, LLC
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Divider, Flex, Loader, Modal, Text } from '@mantine/core';
import { useForm } from '@mantine/form';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { ModalButtons } from 'components/Mantine/ModalButtons';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { isNil, isNotNil } from 'helpers/isNotNil';
import type {
  PartAttributeId,
  PartAttributeSelectVariantId,
} from 'modules/Materials/CatalogSetup/CatalogSetupPage/types';

import { CatalogPartCard } from './AddItems/CatalogPartCard';
import type { PartToAdd } from './AddItems/PartCategoryPartAdder';
import type { PartId } from './AddItems/types';

type Props = {
  title?: string;
  parts: PartToAdd[];
  previousDecisions?: PartDecision['decisions'];
  onSubmit: (decisions: Record<PartId, PartDecision>) => Promise<void>;
  onClose: () => void;
  opened?: boolean;
};

export type PartDecision = {
  partId: PartId;
  decisions: {
    partAttributeId: PartAttributeId;
    partAttributeSelectVariantId: PartAttributeSelectVariantId | null;
    writeInValue: string | null;
  }[];
};

export const PartDecisionMakerModal = ({
  title = 'Add Catalog Items',
  parts,
  previousDecisions,
  opened = parts.length > 0,
  onClose,
  onSubmit,
}: Props) => {
  const [saving, setSaving] = useState(false);
  const [decisions, setDecisions] = useState<Record<PartId, PartDecision>>({});
  const [makingDecision, setMakingDecision] = useState(0);

  const form = useForm<PartDecision>({
    initialValues: {
      partId: '' as PartId,
      decisions: [],
    },
  });

  const partToAdd = useMemo<PartToAdd | undefined>(() => parts[makingDecision], [makingDecision, parts]);
  const lastDecision = makingDecision + 1 >= parts.length;

  const moveToDecision = useCallback(
    (newDecision: number, existingDecisions: typeof decisions) => {
      setMakingDecision(newDecision);
      if (!(newDecision in parts)) return;
      const part = parts[newDecision];
      form.setValues(existingDecisions[part.part.partId]);
    },
    [form, parts],
  );

  const saveDecisions = (values: typeof form.values) => {
    const newDecisions = {
      ...decisions,
      [values.partId]: values,
    };
    setDecisions(newDecisions);
    if (lastDecision) {
      setSaving(true);
      void onSubmit(newDecisions).finally(() => setSaving(false));
    } else {
      moveToDecision(makingDecision + 1, newDecisions);
    }
  };

  useEffect(() => {
    if (opened) {
      setDecisions(
        parts.reduce(
          (out, { part }) => {
            const dec: PartDecision = {
              partId: part.partId,
              decisions: part.attriubuteVariantData.map((d) => {
                const previousDecision = previousDecisions?.find((pd) => pd.partAttributeId === d.partAttributeId);
                return {
                  partAttributeId: d.partAttributeId,
                  partAttributeSelectVariantId:
                    d.selectValues.length === 1 ? d.selectValues[0].partAttributeSelectVariantId : null,
                  writeInValue: null,
                  ...previousDecision,
                };
              }),
            };
            return {
              ...out,
              [part.partId]: dec,
            };
          },
          {} as typeof decisions,
        ),
      );
      setMakingDecision(-1);
    }
  }, [opened, parts, previousDecisions]);
  useEffect(() => {
    if (makingDecision === -1 && opened) {
      moveToDecision(0, decisions);
    }
  }, [decisions, makingDecision, moveToDecision, opened]);

  return (
    <Modal title={title} centered closeOnClickOutside={false} closeOnEscape={false} opened={opened} onClose={onClose}>
      {isNil(partToAdd) ? (
        <Flex justify="center">
          <Loader />
        </Flex>
      ) : (
        <form onSubmit={form.onSubmit(saveDecisions)}>
          <Flex direction="column" gap="md">
            {isNotNil(previousDecisions) && <Divider label="Original part" c="dimmed" />}
            <CatalogPartCard
              part={partToAdd.part}
              rightComponent={
                <Text
                  fz="sm"
                  c="dimmed"
                  style={{
                    textWrap: 'nowrap',
                  }}
                >
                  Qty: {partToAdd.quantity}
                </Text>
              }
            />
            <Divider label={isNotNil(previousDecisions) ? 'New decisions' : 'Decisions'} c="dimmed" />
            {partToAdd.part.attriubuteVariantData.map(({ selectValues, allowWriteInVariant, ...attribute }, i) => {
              const existing = form.values.decisions[i];
              if (isNil(existing)) return null;
              const { writeInValue, partAttributeSelectVariantId } = existing;
              const value = writeInValue ?? partAttributeSelectVariantId;

              const data = [
                ...selectValues.map((v) => ({
                  label: `${v.textValue}`,
                  value: v.partAttributeSelectVariantId,
                })),
                ...(writeInValue
                  ? [
                      {
                        label: writeInValue,
                        value: writeInValue,
                      },
                    ]
                  : []),
              ];

              const onCreate = (newWriteInValue: string) => {
                if (allowWriteInVariant && newWriteInValue.trim().length > 0) {
                  form.setFieldValue(`decisions.${i}`, {
                    partAttributeId: attribute.partAttributeId,
                    writeInValue: newWriteInValue.trim(),
                    partAttributeSelectVariantId: null,
                  });
                }
              };

              return (
                <WrappedSelect
                  key={attribute.partAttributeId}
                  label={
                    <Flex gap={4} align="center">
                      {attribute.partAttributeName}
                      {allowWriteInVariant && (
                        <AgGridStyleTooltip label="Write-in values allowed" withArrow openDelay={200}>
                          <EvolveIcon icon="WriteInItem" size="sm" />
                        </AgGridStyleTooltip>
                      )}
                    </Flex>
                  }
                  data={data}
                  searchable
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onCreate(e.currentTarget.value);
                      e.currentTarget.blur();
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }}
                  clearable
                  creatable={allowWriteInVariant}
                  onCreate={(newWriteInValue) => {
                    onCreate(newWriteInValue);
                    return null;
                  }}
                  onChange={(newPartAttributeSelectVariantId) => {
                    form.setFieldValue(`decisions.${i}`, {
                      partAttributeId: attribute.partAttributeId,
                      writeInValue: null,
                      partAttributeSelectVariantId: newPartAttributeSelectVariantId,
                    });
                  }}
                  value={value}
                  disabled={saving}
                />
              );
            })}
            {partToAdd.part.attriubuteVariantData.length === 0 && (
              <Text fz="sm" c="dimmed" ta="center">
                No decisions to make.
              </Text>
            )}
            <ModalButtons
              confirmationText={lastDecision ? 'Submit' : 'Next'}
              cancellationText={makingDecision === 0 ? 'Cancel' : 'Back'}
              type="submit"
              loading={saving}
              disabled={!form.isValid() || parts.length === 0 || partToAdd.part.attriubuteVariantData.length === 0}
              onClose={makingDecision === 0 ? onClose : () => moveToDecision(makingDecision - 1, decisions)}
            />
          </Flex>
        </form>
      )}
    </Modal>
  );
};
