// Copyright ©️ 2025 eVolve MEP, LLC
import { useEffect } from 'react';

import { UserProvider } from 'app/UserContext';
import { isNotNil } from 'helpers/isNotNil';
import { FacilitiesProvider } from 'hooks/projectsAndFacilities/useFacilities';
import { ProjectsProvider } from 'hooks/projectsAndFacilities/useProjects';
import { SelectedFacilityProjectProvider } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import { ShopConfigurationsProvider } from 'hooks/projectsAndFacilities/useShopConfigurations';
import { ManufacturersProvider } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useManufacturers';
import { UnitOfMeasureProvider } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useUnitOfMeasure';
import { WorkRequestsProvider } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/useWorkRequests';
import { PartAttributesProvider } from 'modules/Materials/CatalogSetup/CatalogSetupPage/usePartAttributes';
import { DocumentTypesProvider } from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes';
import { TasksProvider } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/useTasks';
import { WorkOrdersProvider } from 'modules/Shop/WorkOrders/WorkOrdersPage/useWorkOrders';

import { HighPriorityMessageBanner } from './HighPriorityMessageBanner';
import { Layout } from './Layout/Layout';
import { useMaybeUser } from './MaybeUserContext';

export const PrivateWrapper = () => {
  const { user } = useMaybeUser();

  useEffect(() => {
    if (isNotNil(user)) {
      // @ts-expect-error function is defined globally
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.initializePendo(user);
    }
  }, [user]);

  return (
    <UserProvider>
      <DocumentTypesProvider>
        <ManufacturersProvider>
          <WorkRequestsProvider>
            <WorkOrdersProvider>
              <TasksProvider>
                <UnitOfMeasureProvider>
                  <SelectedFacilityProjectProvider>
                    <FacilitiesProvider>
                      <ProjectsProvider>
                        <ShopConfigurationsProvider>
                          <PartAttributesProvider>
                            <HighPriorityMessageBanner />
                            <Layout />
                          </PartAttributesProvider>
                        </ShopConfigurationsProvider>
                      </ProjectsProvider>
                    </FacilitiesProvider>
                  </SelectedFacilityProjectProvider>
                </UnitOfMeasureProvider>
              </TasksProvider>
            </WorkOrdersProvider>
          </WorkRequestsProvider>
        </ManufacturersProvider>
      </DocumentTypesProvider>
    </UserProvider>
  );
};
