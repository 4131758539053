// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { ActionIcon, Drawer, Flex, Tooltip } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { isNotNil } from 'helpers/isNotNil';

import { WorkRequestOrderDetailList, type WorkRequestOrderDetailProps } from './WorkRequestOrderDetailList';

const DrawerTitle = ({ navigate, disabled }: { disabled: boolean; navigate: (next: boolean) => void }) => (
  <Flex gap="xl" justify="space-between" align="center">
    <span>Properties</span>
    <Flex gap="xs" align="center">
      <Tooltip label="View previous" withArrow openDelay={500}>
        <ActionIcon size="xs" variant="transparent" onClick={() => navigate(false)} disabled={disabled}>
          <EvolveIcon icon="ArrowLeft" size="xs" />
        </ActionIcon>
      </Tooltip>
      <Tooltip label="View next" withArrow openDelay={500}>
        <ActionIcon size="xs" variant="transparent" onClick={() => navigate(true)} disabled={disabled}>
          <EvolveIcon icon="ArrowRight" size="xs" />
        </ActionIcon>
      </Tooltip>
    </Flex>
  </Flex>
);

export const WorkRequestOrderDetailDrawer = ({
  onClose,
  workOrder,
  workRequest,
  navigate,
  ...props
}: // TODO: Making this Partial is dangerous with the hard cast below
Partial<WorkRequestOrderDetailProps> & {
  onClose: () => void;
  navigate: (next: boolean) => void;
}) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  return (
    <Drawer
      opened={isNotNil(workOrder) || isNotNil(workRequest)}
      withCloseButton={!saving}
      position="right"
      title={<DrawerTitle disabled={editing} navigate={navigate} />}
      // eslint-disable-next-line no-empty-function
      onClose={saving ? () => {} : onClose}
      overlayProps={{ opacity: 0.25 }}
      closeOnClickOutside={!editing}
      size="lg"
      styles={{
        content: {
          paddingLeft: 4,
          paddingRight: 4,
        },
      }}
    >
      {(isNotNil(workOrder) || isNotNil(workRequest)) && (
        <WorkRequestOrderDetailList
          setSaving={setSaving}
          setEditing={setEditing}
          {...({
            ...props,
            workOrder,
            workRequest,
            // The isNotNil validations above validated these are valid Props,
            // but TypeScript can't tell, so here we are.
            // This can probably be done more safely.
          } as WorkRequestOrderDetailProps)}
          isDrawer
        />
      )}
    </Drawer>
  );
};
