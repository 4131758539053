// Copyright ©️ 2024 eVolve MEP, LLC
import React from 'react';

import { Button } from '@mantine/core';
import type { AgGridReact } from 'ag-grid-react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

export type ClearFiltersButtonProps = {
  gridRef: React.RefObject<AgGridReact>;
  filterIsSet?: boolean;
  onFilterClear?: () => void;
};

export const ClearFiltersButton = ({ gridRef, filterIsSet = true, onFilterClear }: ClearFiltersButtonProps) => (
  <Button
    variant="subtle"
    fz="xs"
    leftSection={<EvolveIcon icon="ClearFilters" size="sm" />}
    onClick={() => {
      if (filterIsSet) {
        gridRef.current?.api.applyColumnState({
          state: [],
          defaultState: {
            sort: null,
          },
        });
        gridRef.current?.api.setFilterModel({});
        onFilterClear?.();
      }
    }}
    size="compact-md"
    style={{
      cursor: filterIsSet ? 'pointer' : 'initial',
      opacity: filterIsSet ? 1 : 0,
      transition: '.1s ease-out',
    }}
  >
    Clear table sort & filters
  </Button>
);
