// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { ActionIcon, FileButton, Flex, Menu, Overlay, Text, useMantineTheme } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { AgGridReact, type AgGridReactProps } from 'ag-grid-react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { compactRowHeight } from 'helpers/ag-grid/baseColumnDef';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import type { WorkRequest } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import type { WorkCellTask } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';
import type { WorkOrder } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';

import { AddLinkModal } from './AddLinkModal';
import { FILE_TYPE_ACCEPT, useUploadDocuments } from './useUploadDocuments';

type Props = {
  section: 'Work Order' | 'Work Request' | 'Tasks';
  agGridProps: AgGridReactProps;
  title?: string;
} & (
  | { active?: false }
  | ({ active: true } & (
      | {
          section: 'Work Order';
          workOrder: WorkOrder;
          refreshWOdocs: () => void;
        }
      | {
          section: 'Work Request';
          workRequest: WorkRequest;
          refreshWRdocs: () => void;
        }
      | {
          section: 'Tasks';
          workCellTask: WorkCellTask;
          refreshTaskDocs: () => void;
        }
    ))
);

const baseGridProps: AgGridReactProps = {
  domLayout: 'autoHeight',
  rowHeight: compactRowHeight,
  headerHeight: 0,
  defaultColDef: {
    resizable: false,
    suppressMovable: true,
    sortable: false,
    contextMenuItems: [],
    suppressHeaderContextMenu: true,
    suppressHeaderMenuButton: true,
  },
};

export const AttachmentSection = ({ ...props }: Props) => {
  const theme = useMantineTheme();
  const [addingLink, setAddingLink] = useState(false);
  const { title, section, agGridProps, active } = props;
  const { uploadDocuments, saving } = useUploadDocuments();
  const onUpload = async (files: File[]) => {
    if (!props.active) return;
    const { projectName, projectId } =
      props.section === 'Work Order'
        ? props.workOrder.workRequest
        : props.section === 'Work Request'
          ? props.workRequest
          : props.workCellTask;
    const project = { projectId, projectName };
    if (props.section === 'Work Order') {
      await uploadDocuments(files, { project, workOrder: props.workOrder }).finally(() => props.refreshWOdocs());
    } else if (props.section === 'Work Request') {
      await uploadDocuments(files, { project, workRequest: props.workRequest }).finally(() => props.refreshWRdocs());
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (props.section === 'Tasks') {
      await uploadDocuments(files, { project, workCellTask: props.workCellTask }).finally(() =>
        props.refreshTaskDocs(),
      );
    }
  };

  return (
    <div className="small-table">
      <Dropzone
        activateOnKeyboard={false}
        activateOnClick={false}
        loading={saving}
        disabled={!active}
        onDrop={onUpload}
        accept={FILE_TYPE_ACCEPT}
        multiple
        styles={{
          root: {
            border: 'none',
            borderRadius: 10,
            cursor: 'initial',
            backgroundColor: 'inherit !important',
            overflow: 'hidden',
          },
          inner: { pointerEvents: 'all' },
        }}
        px={0}
        py="xs"
      >
        <Dropzone.Accept>
          <Overlay bg="rgba(229,219,255,0.75)">
            <Flex gap="sm" align="center" justify="center" style={{ height: '100%' }}>
              <EvolveIcon icon="Upload" />
              <Text fw={500}>Drag to upload</Text>
            </Flex>
          </Overlay>
        </Dropzone.Accept>
        <Dropzone.Reject>
          <Overlay bg="rgba(200,120,120,0.75)">
            <Flex gap="sm" align="center" justify="center" style={{ height: '100%' }}>
              <EvolveIcon icon="Close" />
              <Text fw={500}>Cannot accept files</Text>
            </Flex>
          </Overlay>
        </Dropzone.Reject>

        <Flex direction="column" mb={3}>
          <Flex gap="sm" align="center">
            <Text ta="center" fw={500}>
              {title ?? section}
            </Text>
            {active && (
              <FileButton onChange={onUpload} accept={FILE_TYPE_ACCEPT.join(',')} multiple>
                {(fileButtonProps) => (
                  <Menu position="bottom-start" withinPortal>
                    <Menu.Target>
                      <ActionIcon variant="filled" size="xs" color={theme.primaryColor}>
                        <EvolveIcon icon="Add" size="xs" />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item leftSection={<EvolveIcon icon="Attachment" />} {...fileButtonProps}>
                        Attach files
                      </Menu.Item>

                      <Menu.Item onClick={() => setAddingLink(true)} leftSection={<EvolveIcon icon="ExternalURL" />}>
                        Add web link
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                )}
              </FileButton>
            )}
          </Flex>
          {active && (
            <Text size="xs" c="dimmed" mt={2}>
              Drag and drop or add attachments
            </Text>
          )}
        </Flex>
        <AgGridReact
          {...agGridProps}
          {...baseGridProps}
          noRowsOverlayComponent={() => <NoRowsOverlay label="No attachments found." align="flex-start" />}
        />
        {active && (
          <>
            {'workRequest' in props && (
              <AddLinkModal
                opened={addingLink}
                onClose={() => setAddingLink(false)}
                refresh={() => props.refreshWRdocs()}
                workRequest={props.workRequest}
              />
            )}
            {'workOrder' in props && (
              <AddLinkModal
                opened={addingLink}
                onClose={() => setAddingLink(false)}
                refresh={() => props.refreshWOdocs()}
                workOrder={props.workOrder}
              />
            )}
            {'workCellTask' in props && (
              <AddLinkModal
                opened={addingLink}
                onClose={() => setAddingLink(false)}
                refresh={() => props.refreshTaskDocs()}
                workCellTask={props.workCellTask}
              />
            )}
          </>
        )}
      </Dropzone>
    </div>
  );
};
