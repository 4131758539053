// Copyright ©️ 2024 eVolve MEP, LLC
import type { ReactNode } from 'react';

import { Box, Card, Flex, Overlay, Text } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

import type { Part } from './types';
import { DocumentImage } from '../AddTasks/useDocumentImage';

export const CatalogPartCard = ({
  part,
  disabled,
  rightComponent,
}: {
  part: Part;
  disabled?: boolean;
  rightComponent: ReactNode;
}) => (
  <Flex
    justify="space-between"
    pr="md"
    pos="relative"
    style={{
      width: '100%',
      height: 80,
      maxHeight: 80,
    }}
  >
    {disabled && <Overlay zIndex={2} color="#fff" />}
    <Flex gap="sm">
      <Card p={0} withBorder style={{ width: 80, height: 80, minWidth: 80, minHeight: 80 }}>
        <Flex style={{ height: '100%', width: '100%' }} align="center" justify="center">
          <DocumentImage
            storagePath={part.partImages?.find((img) => img.isDefault)?.document.storagePath}
            alt={part.partName}
            fallback={<EvolveIcon size="xl" icon={part.hasAssembly ? 'Assembly' : 'Part'} light />}
          >
            <Box style={{ position: 'absolute', right: 0, bottom: 0 }}>
              <EvolveIcon className="shadow" size="md" icon={part.hasAssembly ? 'Assembly' : 'Part'} light />
            </Box>
          </DocumentImage>
        </Flex>
      </Card>
      <Flex direction="column" gap={5}>
        <Text title={part.partName} fw={500} lh={1.25} lineClamp={2}>
          {part.partName}
        </Text>
        <Text fz="sm" lh={1}>
          Manufacturer: {part.manufacturer?.manufacturerName}
        </Text>
        {!!part.description && (
          <Text fz="sm" lh={1} lineClamp={1} title={part.description}>
            {part.description}
          </Text>
        )}
      </Flex>
    </Flex>
    <Flex align="flex-end" style={{ height: 80 }}>
      {rightComponent}
    </Flex>
  </Flex>
);
