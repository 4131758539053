// Copyright ©️ 2024 eVolve MEP, LLC
import type React from 'react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import type { TaskStatusTypeName } from 'constants/badgeMappingStatus';

export const TaskPlayPauseIcon = ({
  taskStatusTypeName,
  spanProps,
}: {
  taskStatusTypeName: TaskStatusTypeName;
  spanProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
}) =>
  taskStatusTypeName === 'Started' || taskStatusTypeName === 'Paused' ? (
    <AgGridStyleTooltip
      openDelay={100}
      label={taskStatusTypeName === 'Started' ? 'Task is running' : 'Task is paused'}
      withArrow
    >
      <EvolveIcon spanProps={spanProps} icon={taskStatusTypeName === 'Started' ? 'Play' : 'Pause'} size="sm" inline />
    </AgGridStyleTooltip>
  ) : null;
