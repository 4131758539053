// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { ActionIcon, Loader, Menu } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedDelete, useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import type { Task } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import { SetTaskPredecessorsPopover, type UpdateTaskPredecessorsBody } from './SetTaskPredecessorsPopover';
import { useWorkRequestOrderItems } from './WorkRequestOrderItemsContext';
import { canEditWorkOrder, canEditWorkRequest } from '../../WorkOrdersPage/common';

type Props = {
  task: Task;
  onUpdate: (task: Task) => void;
  refetch: () => void;
};

export const EditTaskMenu = ({ task, onUpdate, refetch }: Props) => {
  const context = useWorkRequestOrderItems();
  const [opened, setOpened] = useState(false);
  const [menuItemSelected, setMenuItemSelected] = useState<'set-predecessors' | 'delete' | undefined>();
  const { apiCall: updateTask, loading: saving } = useWrappedPatch<Task, UpdateTaskPredecessorsBody>(
    `shop/task/${task.taskId}`,
  );
  const { apiCall: deleteTask, loading: deleting } = useWrappedDelete(`shop/task/${task.taskId}`);
  const canEdit =
    context.selectedItem.type === 'FACILITY' &&
    ('workOrder' in context
      ? canEditWorkOrder(context.workOrder.workOrderStatusTypeName, 'editTasks')
      : canEditWorkRequest(context.workRequest.workRequestStatusName, 'editTasks'));
  if (!canEdit) return null;

  const onDelete = () => {
    void deleteTask().then(() => {
      notifications.show({
        title: 'Successfully deleted',
        message: `Deleted task ${task.taskName}`,
        color: 'green',
      });
      refetch();
    });
  };

  return (
    <>
      <SetTaskPredecessorsPopover
        task={task}
        onClose={() => setMenuItemSelected(undefined)}
        onSubmit={(v) => {
          void updateTask(v).then(onUpdate);
          setMenuItemSelected(undefined);
        }}
        opened={menuItemSelected === 'set-predecessors'}
      />
      <Menu position="bottom-start" withArrow withinPortal opened={opened} onChange={setOpened}>
        <Menu.Target>
          <ActionIcon disabled={saving} onClick={() => setOpened((o) => !o)} variant="transparent">
            {saving ? (
              <Loader size="sm" />
            ) : (
              <EvolveIcon
                className={opened || isNotNil(menuItemSelected) ? '' : 'show-on-row-hover'}
                icon="MoreOptsVert"
              />
            )}
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => setMenuItemSelected('set-predecessors')}>Set predecessors</Menu.Item>
          <Menu.Item color="red" onClick={() => setMenuItemSelected('delete')}>
            Delete
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <ConfirmationModal
        description={`Are you sure you want to delete ${task.taskName}?`}
        opened={menuItemSelected === 'delete'}
        onConfirm={onDelete}
        onClose={() => setMenuItemSelected(undefined)}
        loading={deleting}
        buttonColor="red"
      />
    </>
  );
};
