// Copyright ©️ 2025 eVolve MEP, LLC
import { useEffect, useState } from 'react';

import { Box, Card, Flex, Text } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';

import { EvolveIcon, type EvolveIconName } from 'assets/icons/EvolveIcon';
import { type TypeSafeSelectProps, WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';

import type { PartId, PartCategoryId, PartSearchResult, CatalogSearchResult, PartCatalogId } from './types';

const MIN_SEARCH_LENGTH = 2 as const;

type Props = {
  selectedCatalogId: PartCatalogId | null;
  onChange: (item: CatalogSearchResult | PartSearchResult) => void;
  disabled?: boolean;
};

export const CategoryPartSearch = ({ selectedCatalogId, onChange, disabled }: Props) => {
  const [searchPhrase, setSearchPhrase] = useDebouncedState('', 350);
  const [partsSearchResult, setPartsSearchResult] = useState<PartSearchResult[]>([]);
  const [categorySearchResult, setCategorySearchResult] = useState<CatalogSearchResult[]>([]);

  const { fetchPage: searchParts, loading: loadingSearchParts } = useWrappedPaginatedGet<PartSearchResult>(
    'moab/part/search',
    { lazy: true },
  );

  const { fetchPage: searchPartCategories, loading: loadingSearchPartCategories } =
    useWrappedPaginatedGet<CatalogSearchResult>('moab/partCategory/search', { lazy: true });

  useEffect(() => {
    if (searchPhrase.length >= MIN_SEARCH_LENGTH) {
      const opts = { params: { searchTerm: searchPhrase, partCatalogId: selectedCatalogId } };
      void searchParts({ skip: 0, take: 10 }, opts).then((d) => setPartsSearchResult(d.data));
      void searchPartCategories({ skip: 0, take: 10 }, opts).then((d) => setCategorySearchResult(d.data));
    } else {
      setPartsSearchResult([]);
      setCategorySearchResult([]);
    }
  }, [searchPartCategories, searchParts, searchPhrase, selectedCatalogId]);

  return (
    <Box pos="relative">
      <WrappedSelect<PartCategoryId | PartId>
        placeholder="Search..."
        disabled={isNil(selectedCatalogId) || disabled}
        searchable
        rightSection={<EvolveIcon icon={null} />}
        value={null}
        onChange={(id) => {
          setSearchPhrase('');
          const selectedPartCategory = categorySearchResult.find((c) => c.categoryId === id);
          if (isNotNil(selectedPartCategory)) {
            onChange(selectedPartCategory);
            return;
          }
          const selectedPart = partsSearchResult.find((p) => p.partId === id);
          if (isNotNil(selectedPart)) {
            onChange(selectedPart);
          }
        }}
        nothingFoundMessage={
          searchPhrase.length < MIN_SEARCH_LENGTH || loadingSearchPartCategories || loadingSearchParts
            ? 'Start typing...'
            : undefined
        }
        maxDropdownHeight={400}
        comboboxProps={{
          width: 'auto',
          styles: {
            dropdown: {
              minWidth: 208,
            },
          },
        }}
        searchValue={searchPhrase}
        onSearchChange={setSearchPhrase}
        renderOption={ItemRenderer}
        filter={(d) => d.options}
        data={
          searchPhrase.length >= MIN_SEARCH_LENGTH
            ? [
                {
                  group: 'Categories',
                  items: [
                    ...(categorySearchResult.length === 0 || loadingSearchPartCategories
                      ? [
                          {
                            label: loadingSearchPartCategories ? 'Loading...' : 'No categories found.',
                            value: 'loadingCategories',
                            disabled: true,
                          } as const,
                        ]
                      : categorySearchResult
                          .filter((c) => isNotNil(c.parentCategoryPath))
                          .map((c) => ({
                            label: c.categoryName,
                            value: c.categoryId,
                            category: c,
                          }))),
                  ],
                },
                {
                  group: 'Parts',
                  items: [
                    ...(partsSearchResult.length === 0 || loadingSearchParts
                      ? [
                          {
                            label: loadingSearchParts ? 'Loading...' : 'No parts found.',
                            value: 'loadingParts',
                            disabled: true,
                          } as const,
                        ]
                      : partsSearchResult.map((p) => ({
                          label: p.partName,
                          value: p.partId,
                          part: p,
                        }))),
                  ],
                },
              ]
            : []
        }
      />
    </Box>
  );
};

type ItemProps = {
  part?: PartSearchResult;
  category?: CatalogSearchResult;
  label?: string;
};

const ResultRenderer = ({
  label,
  description,
  icon,
}: {
  label: string;
  description?: string | null;
  icon: EvolveIconName;
}) => (
  <Flex gap="xs" maw={500} style={{ overflow: 'hidden' }}>
    <Card p={0} withBorder w={40} h={40} miw={40}>
      <Flex style={{ height: '100%', width: '100%' }} align="center" justify="center">
        <EvolveIcon icon={icon} light />
      </Flex>
    </Card>
    <Flex direction="column" justify="center">
      <Text lineClamp={1}>{label}</Text>
      <Text lineClamp={2} fz="xs" c="dimmed" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
        {description?.replaceAll(
          '/',
          // Invisible character to assist in line-breaks
          '\u200B/',
        )}
      </Text>
    </Flex>
  </Flex>
);

const ItemRenderer: TypeSafeSelectProps<PartCategoryId | PartId, ItemProps>['renderOption'] = (opts) => {
  const { part, category, label } = opts.option;
  return (
    <Box fz="sm">
      {isNotNil(part) && (
        <ResultRenderer
          label={part.partName}
          description={`Mfg: ${part.manufacturerName}`}
          icon={part.hasAssembly ? 'Assembly' : 'Part'}
        />
      )}
      {isNotNil(category) && (
        <ResultRenderer label={category.categoryName} description={category.parentCategoryPath} icon="Folder" />
      )}
      {isNil(part) && isNil(category) && <span>{label}</span>}
    </Box>
  );
};
