// Copyright ©️ 2025 eVolve MEP, LLC
import './mantine-styles.css';
import { AppShell, MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { AgChartsEnterpriseModule } from 'ag-charts-enterprise';
import { AllEnterpriseModule, IntegratedChartsModule, LicenseManager, ModuleRegistry } from 'ag-grid-enterprise';

import { ModuleProvider } from 'app/Modules/ModuleContext';
import { MantineNotificationsProvider } from 'components/Mantine/useMantineNotifications';
import { ExportGridProvider } from 'helpers/ag-grid/useExportGrid';
import { UsersInfoProvider } from 'hooks/useUsersInfo';
import { DocumentsCacheProvider } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/useDocumentsCache';
import { useGenerateMantineTheme } from 'styles/theme/mantineTheme';

import { AppRoutes } from './AppRoutes';
import { ErrorBoundary } from './ErrorBoundary';
import { MaybeUserProvider } from './MaybeUserContext';
import { MessagesProvider } from './MessageCenter/MessagesContext';

ModuleRegistry.registerModules([AllEnterpriseModule, IntegratedChartsModule.with(AgChartsEnterpriseModule)]);
const agGridLicenseKey =
  'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-062140}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Evolve_MEP}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Evolve_Shop}_only_for_{5}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Evolve_Shop}_need_to_be_licensed___{Evolve_Shop}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{21_July_2025}____[v3]_[0102]_MTc1MzA1MjQwMDAwMA==93efe8f24a7804022f221f0da9d8c96f' as const;
LicenseManager.setLicenseKey(agGridLicenseKey);

export const App = () => {
  const theme = useGenerateMantineTheme();
  return (
    <MantineProvider defaultColorScheme="light" withCssVariables theme={theme}>
      <ErrorBoundary>
        <MantineNotificationsProvider>
          <MaybeUserProvider>
            <ModuleProvider>
              <DocumentsCacheProvider>
                <UsersInfoProvider>
                  <ExportGridProvider>
                    <DatesProvider
                      settings={{
                        firstDayOfWeek: 0,
                        weekendDays: [],
                      }}
                    >
                      <MessagesProvider>
                        <AppShell header={{ height: 64 }} navbar={{ width: 64, breakpoint: 'xs' }}>
                          <AppRoutes />
                        </AppShell>
                      </MessagesProvider>
                    </DatesProvider>
                  </ExportGridProvider>
                </UsersInfoProvider>
              </DocumentsCacheProvider>
            </ModuleProvider>
          </MaybeUserProvider>
        </MantineNotificationsProvider>
      </ErrorBoundary>
    </MantineProvider>
  );
};
