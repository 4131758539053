// Copyright ©️ 2024 eVolve MEP, LLC
type Section<T extends string> = {
  sectionName: T;
  count: number;
};

/**
 * Used to get a row number in a list of sections
 * where you can move from one section to the next.
 *
 * For example, if Section 1 has four rows and Section 2 has 3 rows,
 * and `section` is Section 1 and `index` is 5,
 * it will return row index 1 in Section 2.
 */
export const getSelectedRowNumber = <T extends string>(
  section: T,
  index: number,
  sections: [Section<T>, ...Section<T>[]],
): Section<T> => {
  if (sections.reduce((out, { count }) => out + count, 0) === 0) {
    return {
      sectionName: sections[0].sectionName,
      count: 0,
    };
  }
  let countIndex = index;
  let sectionIndex = sections.findIndex((s) => s.sectionName === section);
  if (sectionIndex < 0) sectionIndex = 0;
  while (countIndex >= sections[sectionIndex].count) {
    countIndex -= sections[sectionIndex].count;
    sectionIndex += 1;
    if (sectionIndex >= sections.length) {
      sectionIndex = 0;
    }
  }
  while (countIndex < 0) {
    sectionIndex -= 1;
    if (sectionIndex < 0) {
      sectionIndex = sections.length - 1;
    }
    countIndex += sections[sectionIndex].count;
  }
  return {
    count: countIndex,
    sectionName: sections[sectionIndex].sectionName,
  };
};
