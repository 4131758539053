// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { Flex, Title, Text, Button, Card, Image, Menu, ActionIcon } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { FacilityDefault } from 'assets/icons/images';
import { PageBreadcrumb } from 'components/Mantine/Navigation/PageBreadcrumb';
import { isNotNil } from 'helpers/isNotNil';
import { useFacilities, type Facility, type FacilityId } from 'hooks/projectsAndFacilities/useFacilities';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';

import { CreateEditFacilityModal } from './CreateEditFacilityModal';
import { DeleteFacilityModal } from './DeleteFacilityModal';
import styles from './FacilitiesPage.module.css';

export const FacilitiesPage = () => {
  const { selectedItem, selectFacility } = useSelectedProjectFacility();
  const { facilities } = useFacilities();
  const [creatingFacility, setCreatingFacility] = useState(false);
  const [editingFacility, setEditingFacility] = useState<FacilityId | null>(null);
  const [deletingFacility, setDeletingFacility] = useState<Facility | null>(null);

  return (
    <>
      <CreateEditFacilityModal
        facilityId={editingFacility}
        opened={creatingFacility || isNotNil(editingFacility)}
        onClose={() => {
          setEditingFacility(null);
          setCreatingFacility(false);
        }}
      />
      <DeleteFacilityModal facility={deletingFacility} onClose={() => setDeletingFacility(null)} />
      <Flex justify="space-between" align="center">
        <PageBreadcrumb title="Facilities" />
        <Button
          color="dark"
          onClick={() => setCreatingFacility(true)}
          leftSection={<EvolveIcon icon="Add" size="sm" />}
        >
          Create Facility
        </Button>
      </Flex>
      <Flex mt="xl" wrap="wrap" rowGap="xl" columnGap="md" style={{ overflowY: 'scroll' }}>
        {facilities.map((f) => (
          <Card key={f.facilityId} className={styles.facilityCard} onClick={() => selectFacility(f)} withBorder w={320}>
            <Card.Section>
              <Image src={f.facilityImageId ?? FacilityDefault} height={142} alt="Facility image" />
            </Card.Section>
            <Flex mt="xs" align="center" gap="xs">
              {f.facilityId === selectedItem?.id && <EvolveIcon icon="CheckCircle" color="green.6" />}
              <Title order={4} fw={500}>
                {f.facilityName}
              </Title>
            </Flex>
            <Text c="dimmed" fz="sm">
              {f.address.addressLine1}
              <br />
              {f.address.city}, {f.address.state} {f.address.zip}
            </Text>
            <Flex justify="flex-end">
              <Menu withArrow position="left-end">
                <Menu.Target>
                  <ActionIcon variant="subtle" onClick={(e) => e.stopPropagation()}>
                    <EvolveIcon icon="MoreOptsVert" />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    onClick={(e) => {
                      setEditingFacility(f.facilityId);
                      e.stopPropagation();
                    }}
                  >
                    Edit
                  </Menu.Item>
                  <Menu.Item
                    onClick={(e) => {
                      setDeletingFacility(f);
                      e.stopPropagation();
                    }}
                    color="red"
                  >
                    Delete
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Flex>
          </Card>
        ))}
      </Flex>
    </>
  );
};
