// Copyright ©️ 2025 eVolve MEP, LLC
import './invite-to-shop.css';
import { useState } from 'react';

import { Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { SearchableMultiSelect } from 'components/Mantine/SearchableMultiSelect';
import { emailRegEx } from 'constants/regEx';
import { isNotNil } from 'helpers/isNotNil';
import type { DepartmentId } from 'hooks/projectsAndFacilities/types';
import type { ProjectId } from 'hooks/projectsAndFacilities/useProjects';
import { useWrappedPaginatedGet, useWrappedPatch, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { ProjectUser } from 'modules/Admin/ProjectProperties/types';
import type { CompanyId, User, UserId } from 'types/types-api';

type Props = {
  companyId: CompanyId;
  isAdmin?: boolean;
  refresh: () => void;
} & (
  | {
      pageType: 'company';
    }
  | {
      pageType: 'shop';
      departmentId: DepartmentId;
    }
  | {
      pageType: 'project';
      projectId: ProjectId;
    }
);

type UserIdOrEmail =
  | {
      userId: UserId;
    }
  | {
      userEmail: string;
    };

export const InviteUsersModal = ({ refresh, ...props }: Props) => {
  const [opened, setOpened] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<
    {
      userEmail: string;
      userId?: UserId;
    }[]
  >([]);
  const [customEmails, setCustomEmails] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const dontPullUsers = props.pageType === 'company' && !props.isAdmin;
  const { data, loading, ...paginatedGet } = useWrappedPaginatedGet<User>('admin/user', {
    lazy: true,
    defaultConfig: {
      params: {
        companyId: props.companyId,
        isAdmin: props.isAdmin && props.pageType === 'company' ? false : undefined,
      },
    },
  });
  const { apiCall: addUserToCompany } = useWrappedPost<
    User,
    {
      companyId: CompanyId;
      userEmail: string;
    }
  >('admin/user');
  const { apiCall: makeUserCompanyAdmin } = useWrappedPatch<
    User,
    {
      isAdmin: true;
    }
  >('admin/user/:userId');
  const { apiCall: addUserToProject } = useWrappedPost<
    ProjectUser,
    {
      projectId: ProjectId;
    } & UserIdOrEmail
  >('admin/projectUser');
  const { apiCall: addUserToShop } = useWrappedPost<
    ProjectUser,
    {
      departmentId: DepartmentId;
    } & UserIdOrEmail
  >('admin/departmentUser');

  const onChange = (selected: typeof selectedEmails) => {
    setCustomEmails(customEmails.filter((e) => selected.some((s) => s.userEmail === e)));
    setSelectedEmails(selected);
  };

  const open = () => {
    setSelectedEmails([]);
    setCustomEmails([]);
    setSearchValue('');
    setError('');
    setOpened(true);
  };

  const onSubmit = () => {
    setError('');
    setSaving(true);
    void Promise.all(
      selectedEmails.map(({ userEmail, userId }) => {
        if (props.pageType === 'company') {
          const { pageType: _, ...rest } = props;
          // Can only select existing users in this case
          // if we're adding a new Admin
          if (userId) {
            return makeUserCompanyAdmin({ isAdmin: true }, { url: `admin/user/${userId}` });
          }
          return addUserToCompany({
            ...rest,
            userEmail,
          });
        }
        const userObj = isNotNil(userId) ? { userId } : { userEmail };
        if (props.pageType === 'project') {
          const { pageType: _1, companyId: _2, ...rest } = props;
          return addUserToProject({
            ...rest,
            ...userObj,
          });
        }
        /* props.pageType === 'shop */
        const { pageType: _1, companyId: _2, ...rest } = props;
        return addUserToShop({
          ...rest,
          ...userObj,
        });
      }),
    )
      .then(() => {
        notifications.show({
          title: 'Successfully invited',
          message: `User${selectedEmails.length === 1 ? '' : 's'} ${
            props.isAdmin ? `added as admin${selectedEmails.length === 1 ? '' : 's'} to` : 'invited to'
          } ${props.pageType}`,
          color: 'green',
        });
        refresh();
        setOpened(false);
      })
      .finally(() => setSaving(false));
  };

  const addingAdmin = props.isAdmin ?? false;

  const onAddCustomEmail = (customEmail: string) => {
    if (emailRegEx.test(customEmail)) {
      setCustomEmails([...customEmails, customEmail]);
      setSelectedEmails([...selectedEmails, { userEmail: customEmail }]);
      setSearchValue('');
    } else {
      setError(`Invalid email: ${customEmail}`);
      setSearchValue(customEmail);
    }
  };

  return (
    <>
      <Button onClick={open} leftSection={<EvolveIcon icon="Add" size="sm" />}>
        {addingAdmin ? 'Add admins' : 'Invite users'}
      </Button>
      <ConfirmationModal
        title={`${addingAdmin ? 'Add admins' : 'Invite users'} to ${props.pageType}`}
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
        onConfirm={onSubmit}
        confirmationText={addingAdmin ? 'Add' : 'Invite'}
        loading={saving}
        disabled={selectedEmails.length === 0}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <SearchableMultiSelect<(typeof selectedEmails)[number], 'userEmail'>
          className="wrap-inputs"
          label="Email"
          nowrap={false}
          placeholder={dontPullUsers ? 'Enter emails' : 'Search or enter email'}
          disableSearch={dontPullUsers}
          searchable
          leftSection="@"
          leftSectionProps={{ style: { marginTop: -4 } }}
          searchValue={searchValue}
          onSearchChange={(v) => {
            setSearchValue(v);
            setError('');
          }}
          clearSearchOnChange
          getItemLabel={(v) => v.userEmail}
          idKey="userEmail"
          paginatedGet={{ data, loading, ...paginatedGet }}
          value={selectedEmails}
          onChange={onChange}
          error={error}
          creatable
          disabled={saving}
          createLabel="Invite"
          onCreate={onAddCustomEmail}
          createOnBlur
        />
      </ConfirmationModal>
    </>
  );
};
