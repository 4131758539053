// Copyright ©️ 2025 eVolve MEP, LLC
import { createContext, type ReactNode, useEffect, useState } from 'react';

import { Loader } from '@mantine/core';
import { useForm, type UseFormReturnType } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useParams } from 'react-router-dom';

import { useUser } from 'app/UserContext';
import { useEvolveLocation } from 'components/Mantine/Navigation/useEvolveLocation';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useGeneralContext } from 'helpers/useGeneralContext';
import { useWrappedGet, useWrappedPaginatedGet, useWrappedPatch, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { ManufacturerId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useManufacturers';
import type { UnitOfMeasureId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useUnitOfMeasure';
import type {
  Part,
  PartCategory,
  PartCategoryId,
  PartId,
  PartImage,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';
import type { DocumentId } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/types';
import { useUploadDocuments } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/useUploadDocuments';
import type { CompanyId } from 'types/types-api';

import type { PartAttribute } from '../types';
import type {
  ConvertToAssemblyAdditionalDetails,
  PartCreateBody,
  PartFormBody,
  PartUpdateBody,
} from './AddEditItemForm';

type PartEditorContextType = {
  companyId: CompanyId;
  selectedTab: 'details' | 'parts' | 'tasks';
  setSelectedTab: (tab: PartEditorContextType['selectedTab']) => void;
  partCategory: PartCategory;
  attributes: PartAttribute[];
  part: Part | undefined;
  onPartUpdated: (part: Part) => void;
  form: UseFormReturnType<PartFormBody>;
  publishPart: (values: PartEditorContextType['form']['values']) => Promise<Part>;
  publishing: boolean;
};

const PartEditorContext = createContext<PartEditorContextType | undefined>(undefined);

type ProviderProps = {
  part?: Part;
  partCategoryId?: PartCategoryId;
  children: (part: Part | undefined) => ReactNode;
};

const PartEditorProviderWithForm = ({
  children,
  part,
  ...props
}: Omit<PartEditorContextType, 'form' | 'publishing' | 'publishPart'> & Pick<ProviderProps, 'children'>) => {
  const { state: locationState } = useEvolveLocation();
  const form = useForm<
    PartCreateBody & {
      newImage: File | null;
    }
  >({
    initialValues: (locationState?.additionalData as ConvertToAssemblyAdditionalDetails | undefined)?.formValues ?? {
      newImage: null,
      partName: part?.partName ?? '',
      description: part?.description ?? '',
      unitOfMeasureId: part?.unitOfMeasure.unitOfMeasureId ?? ('' as UnitOfMeasureId),
      manufacturerId: part?.manufacturer?.manufacturerId ?? ('' as ManufacturerId),
      partCategoryId: props.partCategory.partCategoryId,
      partAttributeVariantData: props.attributes.map((a) => ({
        partAttributeId: a.partAttributeId,
        selectValueIds:
          part?.attriubuteVariantData
            .find((av) => av.partAttributeId === a.partAttributeId)
            ?.selectValues.map((v) => v.partAttributeSelectVariantId) ?? [],
      })),
    },
    validate: {
      partName: (v) => !v,
      unitOfMeasureId: (v) => !v,
      manufacturerId: (v) => !v,
    },
  });

  const { uploadDocument, saving: savingDocument } = useUploadDocuments();

  const { apiCall: createPart, loading: creating } = useWrappedPost<Part, PartCreateBody>('moab/part');
  const { apiCall: updatePart, loading: updating } = useWrappedPatch<Part, PartUpdateBody>(`moab/part/${part?.partId}`);
  const { apiCall: attachImage, loading: attachingImage } = useWrappedPost<
    PartImage,
    {
      partId: PartId;
      documentId: DocumentId;
      isDefault?: boolean;
    }
  >(`moab/partImage/withDocumentId`);
  const { apiCall: updateImage, loading: updatingImage } = useWrappedPatch<
    PartImage,
    {
      documentId: DocumentId;
      sortOrder?: number;
      isDefault?: boolean;
    }
  >(`moab/partImage/withDocumentId/:partImageId`);
  const publishing = creating || updating || savingDocument || attachingImage || updatingImage;

  const attachImageToPart = async (image: File, partToAttachTo: Part) => {
    try {
      const documentId = await uploadDocument(image, { part: partToAttachTo });
      if (!documentId) return;
      const existingImage = partToAttachTo.partImages?.[0];
      if (existingImage) {
        await updateImage(
          {
            documentId,
            sortOrder: 0,
            isDefault: true,
          },
          {
            url: `moab/partImage/withDocumentId/${existingImage.partImageId}`,
          },
        );
      } else {
        await attachImage({
          documentId,
          partId: partToAttachTo.partId,
          isDefault: true,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Could not attach image to part.', e);
    }
  };

  const onUpdate = async (
    partToUpdate: Part,
    { newImage, partAttributeVariantData, ...values }: typeof form.values,
  ) => {
    const addPartAttributeVariantData: PartUpdateBody['addPartAttributeVariantData'] = [];
    const updatePartAttributeVariantData: PartUpdateBody['updatePartAttributeVariantData'] = [];
    const deletePartAttributeVariantData: PartUpdateBody['deletePartAttributeVariantData'] = [];

    partAttributeVariantData.forEach((variant, i) => {
      const { partAttributeId, selectValueIds } = variant;
      if (!form.isDirty(`partAttributeVariantData.${i}`)) return;
      const existing = partToUpdate.attriubuteVariantData.find((v) => v.partAttributeId === partAttributeId);
      if (isNil(existing)) {
        addPartAttributeVariantData.push(variant);
      } else if (selectValueIds.length === 0) {
        deletePartAttributeVariantData.push(existing.partAttributeVariantDataId);
      } else if (partToUpdate.attriubuteVariantData.some((v) => v.partAttributeId === partAttributeId)) {
        updatePartAttributeVariantData.push({
          ...existing,
          selectValueIds,
        });
      }
    });

    const updatedPart = await updatePart({
      ...values,
      ...Object.fromEntries(
        Object.entries({
          addPartAttributeVariantData,
          updatePartAttributeVariantData,
          deletePartAttributeVariantData,
        }).filter(([, v]) => v.length > 0),
      ),
    });
    if (newImage) {
      await attachImageToPart(newImage, updatedPart);
    }
    notifications.show({
      title: 'Successfully updated',
      message: `Updated ${updatedPart.hasAssembly ? 'assembly' : 'part'} ${updatedPart.partName}`,
      color: 'green',
    });
    props.onPartUpdated(updatedPart);
    form.resetDirty(form.values);
    return updatedPart;
  };

  const publishPart = async (values: typeof form.values) => {
    if (isNotNil(part)) {
      return onUpdate(part, values);
    }
    const { newImage, partAttributeVariantData, ...remainingValues } = values;
    const newPart = await createPart({
      ...remainingValues,
      partAttributeVariantData: partAttributeVariantData.filter((v) => v.selectValueIds.length > 0),
    });
    if (newImage) {
      await attachImageToPart(newImage, newPart);
    }
    notifications.show({
      title: 'Successfully created',
      message: `Created ${newPart.hasAssembly ? 'assembly' : 'part'} ${newPart.partName}`,
      color: 'green',
    });
    props.onPartUpdated(newPart);
    form.resetDirty(form.values);
    return newPart;
  };

  return (
    <PartEditorContext.Provider
      value={{
        ...props,
        part,
        form,
        publishing,
        publishPart,
      }}
    >
      {children(part)}
    </PartEditorContext.Provider>
  );
};

export const PartEditorProvider = ({
  children,
  part: partFromParent,
  partCategoryId: parentPartCategoryId,
}: ProviderProps) => {
  const { state } = useEvolveLocation();
  const { user } = useUser();
  const [selectedTab, setSelectedTab] = useState<PartEditorContextType['selectedTab']>('details');
  const { partCategoryId: initialPartCategoryId, id: partId } = useParams();
  const [partCategoryId, setPartCategoryId] = useState<PartCategoryId | undefined>(
    parentPartCategoryId ?? (initialPartCategoryId as PartCategoryId),
  );
  const [part, onPartUpdated] = useState<Part | undefined>(
    (state?.additionalData as ConvertToAssemblyAdditionalDetails | undefined)?.part ?? partFromParent,
  );
  const { data: partFromNetwork } = useWrappedGet<
    Part & {
      partCategory: { partCategoryId: PartCategoryId }[];
    }
  >(`moab/part/${partId}`, { lazy: isNil(partId) });
  const { data: partCategory, apiCall: getPartCategory } = useWrappedGet<PartCategory>(
    `moab/partCategory/${partCategoryId}`,
    {
      lazy: isNil(partCategoryId),
    },
  );

  useEffect(() => {
    if (isNotNil(partFromNetwork)) {
      onPartUpdated(partFromNetwork);
      setPartCategoryId(partFromNetwork.partCategory[0].partCategoryId);
    }
  }, [partFromNetwork]);

  useEffect(() => {
    if (isNotNil(partCategoryId) && isNil(partCategory)) {
      void getPartCategory({ url: `moab/partCategory/${partCategoryId}` });
    }
  }, [getPartCategory, partCategoryId, partCategory]);

  const [attributesLoaded, setAttributesLoaded] = useState(false);
  const [attributes, setAttributes] = useState<PartAttribute[]>([]);
  const { fetchPage } = useWrappedPaginatedGet<PartAttribute>('moab/partAttribute', { lazy: true });
  useEffect(() => {
    if (isNotNil(partCategoryId)) {
      void getAllDataFromFetcher(fetchPage, { params: { partCategoryId } })
        .then(setAttributes)
        .then(() => setAttributesLoaded(true));
    }
  }, [fetchPage, partCategoryId]);

  if (isNil(partCategory) || !attributesLoaded) return <Loader m="lg" />;

  return (
    <PartEditorProviderWithForm
      attributes={attributes}
      companyId={user.companyId}
      onPartUpdated={onPartUpdated}
      part={part}
      partCategory={partCategory}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
    >
      {children}
    </PartEditorProviderWithForm>
  );
};

export const usePartEditor = () => useGeneralContext(PartEditorContext, 'PartEditor');
