// Copyright ©️ 2024 eVolve MEP, LLC
import React, { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { useGeneralContext } from 'helpers/useGeneralContext';
import {
  useWrappedDelete,
  useWrappedGet,
  useWrappedPaginatedGet,
  useWrappedPatch,
  type PageFetcher,
} from 'hooks-api/useWrappedApiCall';
import { useWrappedGetWithLocalStorage } from 'hooks-api/useWrappedGetWithLocalStorage';
import type { BOMItem } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import { useWorkRequests } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/useWorkRequests';

import type { WorkOrder, WorkOrderId, WorkOrderStatusType, WorkOrderStatusTypeId } from './types';

type WorkOrdersContextType = {
  selectedWorkOrder: WorkOrder | undefined;
  setSelectedWorkOrder: React.Dispatch<React.SetStateAction<WorkOrder | undefined>>;
  setWorkOrdersDefaultOpts: (defaultOpts: Parameters<typeof useWrappedPaginatedGet>[1]) => void;
  workOrderStatuses: WorkOrderStatusType[] | undefined;
  getWorkOrder: (workOrderId: WorkOrderId) => Promise<WorkOrder>;
  fetchWorkOrders: PageFetcher<WorkOrder>;
  fetchBomItems: PageFetcher<BOMItem>;
  updateWorkOrderStatus: (workOrderId: WorkOrderId, status: WorkOrderStatusTypeId) => Promise<WorkOrder>;
  deleteWorkOrder: (workOrderId: WorkOrderId) => Promise<void>;
  mutationRunning: boolean;
};

const WorkOrdersContext = React.createContext<WorkOrdersContextType | undefined>(undefined);

export const WorkOrdersProvider = ({ children }: { children: ReactNode }) => {
  const { data: workOrderStatuses } = useWrappedGetWithLocalStorage(
    'WORK_ORDER_STATUSES',
    undefined,
    'shop/workOrderStatusType',
  );

  const { setSelectedWorkRequest, selectedWorkRequest } = useWorkRequests();
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<WorkOrder | undefined>();

  useEffect(() => {
    setSelectedWorkRequest(selectedWorkOrder?.workRequest);
  }, [selectedWorkOrder?.workRequest, setSelectedWorkRequest]);

  useEffect(() => {
    setSelectedWorkOrder((wo) => {
      // If the selectedWorkRequest changes, clear out any selectedWorkOrder
      if (wo?.workRequest.workRequestId !== selectedWorkRequest?.workRequestId) {
        return undefined;
      }
      return wo;
    });
  }, [selectedWorkRequest?.workRequestId]);

  // Gets
  const { apiCall: getWorkOrderApiCall } = useWrappedGet<WorkOrder>('shop/workOrder', { lazy: true });
  const { fetchPage: fetchWorkOrders, setDefaultOpts: setWorkOrdersDefaultOpts } = useWrappedPaginatedGet<WorkOrder>(
    'shop/workOrder',
    { lazy: true },
  );
  const { fetchPage: fetchBomItems } = useWrappedPaginatedGet<BOMItem>('shop/workOrder/getBomItemsByWorkOrder', {
    lazy: true,
  });
  const getWorkOrder = useCallback(
    (workOrderId: WorkOrderId) =>
      getWorkOrderApiCall({
        url: `shop/workOrder/${workOrderId}`,
      }),
    [getWorkOrderApiCall],
  );

  // Mutations
  const { apiCall: updateWorkOrderStatusApiCall, loading: saving } = useWrappedPatch<
    WorkOrder,
    { workOrderStatusTypeId: WorkOrderStatusTypeId }
  >('shop/workOrder');
  const { apiCall: deleteWorkOrderApiCll, loading: deleting } = useWrappedDelete('shop/workOrder');

  const updateWorkOrderStatus = useCallback<WorkOrdersContextType['updateWorkOrderStatus']>(
    (workOrderId, workOrderStatusTypeId) =>
      updateWorkOrderStatusApiCall(
        {
          workOrderStatusTypeId,
        },
        { url: `shop/workOrder/${workOrderId}` },
      ),
    [updateWorkOrderStatusApiCall],
  );
  const deleteWorkOrder = useCallback<WorkOrdersContextType['deleteWorkOrder']>(
    async (workOrderId: WorkOrder['workOrderId']) => {
      await deleteWorkOrderApiCll({
        url: `shop/workOrder/${workOrderId}`,
      });
    },
    [deleteWorkOrderApiCll],
  );

  const value = useMemo(
    () => ({
      selectedWorkOrder,
      setSelectedWorkOrder,
      workOrderStatuses,
      setWorkOrdersDefaultOpts,
      getWorkOrder,
      fetchWorkOrders,
      fetchBomItems,
      updateWorkOrderStatus,
      deleteWorkOrder,
      mutationRunning: saving || deleting,
    }),
    [
      selectedWorkOrder,
      workOrderStatuses,
      setWorkOrdersDefaultOpts,
      getWorkOrder,
      fetchWorkOrders,
      fetchBomItems,
      updateWorkOrderStatus,
      deleteWorkOrder,
      saving,
      deleting,
    ],
  );
  return <WorkOrdersContext.Provider value={value}>{children}</WorkOrdersContext.Provider>;
};

export const useWorkOrders = () => useGeneralContext(WorkOrdersContext, 'WorkOrders');
