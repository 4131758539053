// Copyright ©️ 2024 eVolve MEP, LLC
import React, { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import type { WorkRequestStatusName } from 'constants/badgeMappingStatus';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNotNil } from 'helpers/isNotNil';
import { useGeneralContext } from 'helpers/useGeneralContext';
import {
  useWrappedDelete,
  useWrappedGet,
  useWrappedPaginatedGet,
  useWrappedPatch,
  useWrappedPost,
  type PageFetcher,
} from 'hooks-api/useWrappedApiCall';
import { useWrappedGetWithLocalStorage } from 'hooks-api/useWrappedGetWithLocalStorage';
import type { Spool, WorkOrder } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';

import type { BOMItem, WorkRequest, WorkRequestId, WorkRequestStatusType } from './types';

export type CreateWorkRequestBody = {
  projectId: string;
  facilityId: string;
  needBy?: Date;
  workRequestName: string;
  workRequestDescription?: string;
};

type WorkRequestsContextType = {
  selectedWorkRequest: WorkRequest | undefined;
  workOrdersForWorkRequest: WorkOrder[] | undefined;
  setSelectedWorkRequest: React.Dispatch<React.SetStateAction<WorkRequest | undefined>>;
  setWorkRequestsDefaultOpts: (defaultOpts: Parameters<typeof useWrappedPaginatedGet>[1]) => void;
  workRequestStatuses: WorkRequestStatusType[] | undefined;
  getWorkRequest: (workRequestId: WorkRequestId) => Promise<WorkRequest>;
  fetchWorkRequests: PageFetcher<WorkRequest>;
  fetchBomItems: PageFetcher<BOMItem>;
  fetchSpools: PageFetcher<Spool>;
  createWorkRequest: (body: CreateWorkRequestBody) => Promise<WorkRequest>;
  updateWorkRequestStatus: (
    workRequestId: WorkRequest['workRequestId'],
    workRequestStatus: WorkRequestStatusName,
    workRequestStatusDescription: string,
  ) => Promise<WorkRequest>;
  deleteWorkRequest: (workRequestId: WorkRequest['workRequestId']) => Promise<void>;
  mutationRunning: boolean;
};

const WorkRequestsContext = React.createContext<WorkRequestsContextType | undefined>(undefined);

export const WorkRequestsProvider = ({ children }: { children: ReactNode }) => {
  const { data: workRequestStatuses } = useWrappedGetWithLocalStorage(
    'WORK_REQUEST_STATUSES',
    undefined,
    'shop/workRequestStatusType',
  );

  const [selectedWorkRequest, setSelectedWorkRequest] = useState<WorkRequest | undefined>();
  const [workOrdersForWorkRequest, setWorkOrdersForWorkRequest] = useState<WorkOrder[] | undefined>();

  // Gets for selectedWorkRequest
  const { fetchPage: getWorkOrders } = useWrappedPaginatedGet<WorkOrder>('shop/workOrder', { lazy: true });

  useEffect(() => {
    setWorkOrdersForWorkRequest(undefined);
    const workRequestId = selectedWorkRequest?.workRequestId;
    if (isNotNil(workRequestId)) {
      void getAllDataFromFetcher(getWorkOrders, { params: { workRequestId } }).then(setWorkOrdersForWorkRequest);
    }
  }, [selectedWorkRequest?.workRequestId, getWorkOrders]);

  // Gets
  const { apiCall: getWorkRequestApiCall } = useWrappedGet<WorkRequest>('shop/workRequest', { lazy: true });
  const { fetchPage: fetchWorkRequests, setDefaultOpts: setWorkRequestsDefaultOpts } =
    useWrappedPaginatedGet<WorkRequest>('shop/workRequest', {
      lazy: true,
    });
  const { fetchPage: fetchBomItems } = useWrappedPaginatedGet<BOMItem>('shop/workRequest/getBomItemsByWorkRequest', {
    lazy: true,
  });
  const { fetchPage: fetchSpools } = useWrappedPaginatedGet<Spool>('shop/spool/mappedToWorkRequest', {
    lazy: true,
  });
  const getWorkRequest = useCallback(
    (workRequestId: WorkRequestId) =>
      getWorkRequestApiCall({
        url: `shop/workRequest/${workRequestId}`,
      }),
    [getWorkRequestApiCall],
  );

  // Mutations
  const { apiCall: createWorkRequest, loading: creating } = useWrappedPost<WorkRequest, CreateWorkRequestBody>(
    'shop/workRequest',
  );
  const { apiCall: updateWorkRequestStatusApiCall, loading: saving } = useWrappedPatch<
    {
      workRequestStatusId: string;
      workRequestStatusTypeDescription: string;
      workRequestStatusTypeId: string;
      workRequestStatusTypeName: WorkRequestStatusName;
    },
    {
      workRequestStatusDescription: string;
    }
  >('shop/workRequest');
  const { apiCall: deleteWorkRequestApiCall, loading: deleting } = useWrappedDelete('shop/workRequest');

  const updateWorkRequestStatus = useCallback<WorkRequestsContextType['updateWorkRequestStatus']>(
    (workRequestId, workRequestStatus, workRequestStatusDescription) =>
      updateWorkRequestStatusApiCall(
        { workRequestStatusDescription },
        { url: `shop/workRequest/${workRequestId}/${workRequestStatus}` },
      ).then(() => getWorkRequest(workRequestId)),
    [getWorkRequest, updateWorkRequestStatusApiCall],
  );
  const deleteWorkRequest = useCallback<WorkRequestsContextType['deleteWorkRequest']>(
    async (workRequestId: WorkRequest['workRequestId']) => {
      await deleteWorkRequestApiCall({
        url: `shop/workRequest/${workRequestId}`,
      });
    },
    [deleteWorkRequestApiCall],
  );

  const value = useMemo(
    () => ({
      selectedWorkRequest,
      workOrdersForWorkRequest,
      setSelectedWorkRequest,
      workRequestStatuses,
      setWorkRequestsDefaultOpts,
      getWorkRequest,
      fetchWorkRequests,
      fetchBomItems,
      fetchSpools,
      createWorkRequest,
      updateWorkRequestStatus,
      deleteWorkRequest,
      mutationRunning: creating || saving || deleting,
    }),
    [
      selectedWorkRequest,
      workOrdersForWorkRequest,
      workRequestStatuses,
      setWorkRequestsDefaultOpts,
      getWorkRequest,
      fetchWorkRequests,
      fetchBomItems,
      fetchSpools,
      createWorkRequest,
      updateWorkRequestStatus,
      deleteWorkRequest,
      creating,
      saving,
      deleting,
    ],
  );
  return <WorkRequestsContext.Provider value={value}>{children}</WorkRequestsContext.Provider>;
};

export const useWorkRequests = () => useGeneralContext(WorkRequestsContext, 'WorkRequests');
