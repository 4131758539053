// Copyright ©️ 2024 eVolve MEP, LLC
import { Fragment, useRef } from 'react';

import { Divider, Flex } from '@mantine/core';

import { useModule } from 'app/Modules/ModuleContext';
import { isNotNil } from 'helpers/isNotNil';
import { useSetupModule } from 'hooks/useSetupModule';
import { WithColorScheme } from 'styles/theme/ColorScheme';

import { NavItem } from './NavItem';

export const NavBarContent = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { data } = useModule();
  const { currentModule } = useSetupModule();

  const navigationModuleSets = data.find((n) => n.navigationModuleCode === currentModule())?.navigationModuleSets ?? [];
  return (
    <WithColorScheme colorScheme="dark">
      <Flex ref={ref} direction="column" gap="xs" mt="xs">
        {navigationModuleSets.map(({ navigationSet, isLastInGroup, navigationModuleSetId }) => (
          <Fragment key={navigationModuleSetId}>
            {isNotNil(navigationSet) && (
              <NavItem
                item={
                  navigationSet.isMenu
                    ? navigationSet
                    : (navigationSet.navigationSetItems?.[0].navigationItem ?? navigationSet)
                }
              />
            )}
            {isLastInGroup && <Divider color="gray.7" />}
          </Fragment>
        ))}
      </Flex>
    </WithColorScheme>
  );
};
