// Copyright ©️ 2024 eVolve MEP, LLC
import type { ComponentProps } from 'react';

import { AppShell } from '@mantine/core';

import { useBreakpoint } from 'hooks/useBreakpoint';
import { WithColorScheme } from 'styles/theme/ColorScheme';

import { DesktopAndTabletHeader } from './DesktopAndTabletHeader';
import { MobileHeader } from './MobileHeader/MobileHeader';

export const HeaderBar = (props: ComponentProps<typeof DesktopAndTabletHeader>) => {
  const { isMobile } = useBreakpoint();
  return (
    <WithColorScheme colorScheme="dark">
      <AppShell.Header data-cy="app-bar" px="lg" c="white" bg="black" withBorder={false} display="flex">
        {isMobile ? <MobileHeader /> : <DesktopAndTabletHeader {...props} />}
      </AppShell.Header>
    </WithColorScheme>
  );
};
