// Copyright ©️ 2024 eVolve MEP, LLC
import { useMemo } from 'react';

import { Flex, getThemeColor, Loader, useMantineTheme } from '@mantine/core';
import type { AgCartesianSeriesOptions, AgChartOptions } from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';

import { statusToColorMap } from 'constants/badgeMappingStatus';

import { insightsStatusTypeNames } from './types';
import { useInsights } from './useInsights';

export const InsightsChart = () => {
  const { params, displayedData: data, loading, recordTypes } = useInsights();
  const theme = useMantineTheme();
  const series = useMemo<AgCartesianSeriesOptions[]>(
    () =>
      insightsStatusTypeNames.map((key) => ({
        type: 'bar',
        xKey: 'Month',
        yKey: key,
        yName: key,
        stacked: true,
        cornerRadius: 5,
        fill: statusToColorMap[key].chartColor
          ? statusToColorMap[key].chartColor
          : getThemeColor(key in statusToColorMap ? statusToColorMap[key].color : 'gray', theme),
        showInLegend: data?.chartDataPoints.some((entry) => entry[key]),
        tooltip: {
          // TODO: type this better
          renderer: ({ datum, xKey, yKey }) => ({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            title: datum[xKey],
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            content: `${yKey}: ${datum[yKey].toFixed(0)}`,
          }),
        },
      })),
    [theme, data?.chartDataPoints],
  );
  const options = useMemo<AgChartOptions>(
    () => ({
      title: {
        text: params.selectedRecordType === 'Work Requests' ? 'Work Requests Over Time' : 'Work Orders Over Time',
        color: 'black',
        fontSize: 20,
        fontFamily: 'Roboto',
      },
      data: data?.chartDataPoints,
      series,
      axes: [
        {
          type: 'number',
          interval: {
            step: 5,
          },
          position: 'left',
        },
        {
          type: 'category',
          position: 'bottom',
        },
      ],
      legend: {
        position: 'bottom',
        item: {
          marker: {
            shape: 'circle',
          },
        },
      },
    }),
    [params.selectedRecordType, data?.chartDataPoints, series],
  );

  return (
    <>
      {loading || !recordTypes ? (
        <Flex justify="center" align="center" style={{ height: '100%' }}>
          <Loader size="lg" />
        </Flex>
      ) : (
        <AgCharts options={options} style={{ height: '100%' }} />
      )}
    </>
  );
};
