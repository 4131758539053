// Copyright ©️ 2024 eVolve MEP, LLC
import './catalog.css';
import { type ReactNode, useCallback, useEffect, useState } from 'react';

import { Flex, Loader, Overlay, Text } from '@mantine/core';

import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { CompanyId } from 'types/types-api';

import { CatalogBreadcrumb, type NavPartCategory } from './CatalogBreadcrumb';
import { CatalogSelect } from './CatalogSelect';
import { CategoryCard } from './CategoryCard';
import { CategoryPartSearch } from './CategoryPartSearch';
import type { PartCategory, PartListingProps, PartSearchResult, CatalogSearchResult, PendingCatalogNav } from './types';

type Props = {
  companyId: CompanyId;
  /** If `false`, will not update the selected catalog in local storage @default true */
  useLocalStorageCatalog?: boolean;
  refresh?: () => void;
  rightSideComponent?: ReactNode;
  children: (props: PartListingProps) => ReactNode;
  disabled?: boolean;
};

export const Catalog = ({
  rightSideComponent,
  disabled,
  children,
  companyId,
  refresh,
  useLocalStorageCatalog,
}: Props) => {
  const [selectedCatalog, setSelectedCatalog] = useLocalStorage('SELECTED_CATALOG', null, useLocalStorageCatalog);
  const [selectedCategory, setSelectedCategory] = useState<NavPartCategory | null>(null);
  const [categoryHistory, setCategoryHistory] = useState<NavPartCategory[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<PendingCatalogNav>();

  const [selectedSearchItem, setSelectedSearchItem] = useState<CatalogSearchResult | PartSearchResult | null>();
  const partFromSearch = isNotNil(selectedSearchItem) && 'partId' in selectedSearchItem ? selectedSearchItem : null;
  const categoryFromSearch =
    isNotNil(selectedSearchItem) && 'categoryId' in selectedSearchItem ? selectedSearchItem : null;

  const {
    data: categories,
    setDefaultOpts: setDefaultOptsCategory,
    loading: loadingCategories,
  } = useWrappedPaginatedGet<PartCategory>('moab/partCategory', { lazy: true });

  useEffect(() => {
    setSelectedCategory((currentCategory) => {
      const category = isNil(currentCategory) ? categories[0] : currentCategory;
      if (isNotNil(category) && isNil(currentCategory)) {
        setCategoryHistory([category]);
      }
      return category;
    });
  }, [categories]);

  useEffect(() => {
    if (isNotNil(selectedCatalog)) {
      setDefaultOptsCategory({
        lazy: false,
        defaultConfig: {
          params: {
            partCatalogId: selectedCatalog.partCatalogId,
            parentPartCategoryId: selectedCategory?.partCategoryId ?? '',
            orderBy: 'name:asc',
            companyId,
          },
        },
      });
    }
  }, [selectedCatalog, selectedCategory, companyId, setDefaultOptsCategory]);

  const resetToFirstCategory = useCallback(() => {
    setPendingNavigation({
      category: categoryHistory[0],
      categoryHistory: [categoryHistory[0]],
    });
  }, [categoryHistory]);

  useEffect(() => {
    if (isNotNil(pendingNavigation) && !isDirty) {
      setSelectedCatalog((c) => pendingNavigation.catalog ?? c);
      setSelectedCategory((c) => (pendingNavigation.category === undefined ? c : pendingNavigation.category));
      setCategoryHistory((c) => pendingNavigation.categoryHistory ?? c);
      setSelectedSearchItem(pendingNavigation.selectedSearchItem);
      setPendingNavigation(undefined);
    }
  }, [isDirty, pendingNavigation, setSelectedCatalog]);

  const disableCatalogNav = disabled || isNotNil(partFromSearch);

  return (
    <>
      <Flex justify="space-between" gap="sm" wrap="wrap">
        <Flex gap="sm" style={{ flex: '1 0 180px' }}>
          <CatalogSelect
            selectedCatalog={selectedCatalog}
            onCatalogSelected={(catalog) => {
              if (isNotNil(catalog)) {
                setPendingNavigation({
                  category: null,
                  categoryHistory: [],
                  catalog,
                });
              }
            }}
            companyId={companyId}
            disabled={disableCatalogNav}
          />
          <CategoryPartSearch
            selectedCatalogId={selectedCatalog?.partCatalogId ?? null}
            disabled={disableCatalogNav}
            onChange={(item) => {
              if (isNotNil(categoryFromSearch)) {
                resetToFirstCategory();
              }
              if (!('partId' in item)) {
                const newCategory: NavPartCategory = {
                  partCategoryId: item.categoryId,
                  partCategoryName: item.categoryName,
                };
                setPendingNavigation({
                  category: newCategory,
                  categoryHistory: [categoryHistory[0], newCategory],
                  selectedSearchItem: item,
                });
              } else {
                setPendingNavigation({
                  selectedSearchItem: item,
                });
              }
            }}
          />
        </Flex>
        {rightSideComponent}
      </Flex>

      <Flex direction="column" mt="sm" style={{ flex: '1 0 0', overflowY: 'auto', position: 'relative' }}>
        <CatalogBreadcrumb
          categoryHistory={categoryHistory}
          setPendingNavigation={setPendingNavigation}
          blur={disableCatalogNav}
          categoryIsFromSearch={isNotNil(categoryFromSearch)}
          clearSearchCategory={resetToFirstCategory}
        />

        <Flex gap="md" mt="sm" wrap="wrap" pos="relative">
          {isNil(selectedCatalog) && !loadingCategories && (
            <Text c="dimmed" ml="md" fz="sm">
              Select a catalog to get started.
            </Text>
          )}
          {disableCatalogNav && <Overlay color="#fff" blur={2} />}
          {!loadingCategories && categories.length === 0 && isNotNil(selectedCategory) && (
            <Text c="dimmed" m="md" fz="sm">
              No sub categories found.
            </Text>
          )}
          {loadingCategories && <Loader m="xs" />}
          {categories.map((c) => (
            <CategoryCard
              key={c.partCategoryId}
              category={c}
              onClick={() => {
                setPendingNavigation({
                  category: c,
                  categoryHistory: [...categoryHistory, c],
                });
              }}
            />
          ))}
        </Flex>
        {children({
          setIsDirty,
          partCatalogId: selectedCatalog?.partCatalogId,
          partCategoryId: selectedCategory?.partCategoryId,
          overridePartName: partFromSearch?.partName,
          goBack: () =>
            setPendingNavigation({
              selectedSearchItem: undefined,
            }),
          refresh,
        })}
      </Flex>
      <ConfirmationModal
        opened={isDirty && isNotNil(pendingNavigation)}
        onClose={() => setPendingNavigation(undefined)}
        onConfirm={() => setIsDirty(false)}
      >
        Are you sure you want to navigate away from this category? All existing selections will be discarded.
      </ConfirmationModal>
    </>
  );
};
