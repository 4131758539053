// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { type Facility, useFacilities } from 'hooks/projectsAndFacilities/useFacilities';

type Props = {
  facility?: Facility | null;
  onClose: () => void;
};

export const DeleteFacilityModal = ({ facility, onClose }: Props) => {
  const { deleteFacility } = useFacilities();
  const [saving, setSaving] = useState(false);
  const onSubmit = () => {
    if (isNil(facility)) {
      return;
    }
    setSaving(true);
    void deleteFacility(facility.facilityId)
      .then(onClose)
      .finally(() => setSaving(false));
  };
  return (
    <ConfirmationModal
      opened={isNotNil(facility)}
      onConfirm={onSubmit}
      onClose={onClose}
      loading={saving}
      buttonColor="red"
      description="Are you sure you want to delete this facility?"
    />
  );
};
