// Copyright ©️ 2025 eVolve MEP, LLC
import { useState } from 'react';

import { Button, Flex, Modal, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';

import type { AssignableRole, RoleCustomDisplay, UserRoleId } from './types';

type Props = {
  opened: boolean;
  onClose: () => void;
  roles: AssignableRole[];
  fetchRoles: () => void;
};

export const RoleNamesModal = ({ opened, onClose, roles, fetchRoles }: Props) => {
  const [selectedRoleId, setSelectedRoleId] = useState<UserRoleId | null>(null);
  const [enteredText, setEnteredText] = useState<string>();

  const { apiCall: setDisplayName, loading } = useWrappedPost<unknown, RoleCustomDisplay>(
    'admin/Role/setRoleCustomDisplayName',
  );

  return (
    <Modal opened={opened} onClose={onClose} centered>
      <WrappedSelect
        onChange={setSelectedRoleId}
        value={selectedRoleId}
        data={roles.filter((r) => r.isDisplayNameEditable).map((r) => ({ label: r.name, value: r.roleId }))}
        label="Role"
        mb="md"
      />

      {isNotNil(selectedRoleId) && (
        <TextInput
          label="Display Name"
          value={enteredText ?? roles.find((r) => r.roleId === selectedRoleId)?.displayName}
          placeholder={roles.find((r) => r.roleId === selectedRoleId)?.displayName}
          onChange={(event) => setEnteredText(event.currentTarget.value)}
          mb="md"
        />
      )}

      <Flex gap="sm" justify="flex-end">
        <Button
          variant="outline"
          onClick={() => {
            onClose();
            setEnteredText(undefined);
            setSelectedRoleId(null);
          }}
        >
          Cancel
        </Button>

        <Button
          hidden={isNil(selectedRoleId)}
          disabled={isNil(enteredText) || enteredText === '' || isNil(selectedRoleId)}
          onClick={() => {
            if (isNotNil(enteredText) && isNotNil(selectedRoleId)) {
              void setDisplayName({
                roleId: selectedRoleId,
                customDisplayName: enteredText,
              })
                .then(fetchRoles)
                .then(() => {
                  onClose();
                  notifications.show({
                    title: 'Successfully updated',
                    message: `Display name updated for role ${roles.find((r) => r.roleId === selectedRoleId)?.name}`,
                  });
                  setEnteredText(undefined);
                  setSelectedRoleId(null);
                });
            }
          }}
          loading={loading}
        >
          Confirm
        </Button>
      </Flex>
    </Modal>
  );
};
