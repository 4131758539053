// Copyright ©️ 2024 eVolve MEP, LLC
import { type ReactNode, useMemo } from 'react';

import { isNil } from 'helpers/isNotNil';
import { type SelectedItem, useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';

import { NoContentFallback } from '../NoContentFallback/NoContentFallback';

/**
 * Used when either a Facility or a Project *must* be selected
 * in order to render the child page
 */
export const FacilityOrProjectRequired = ({
  children,
  facilityRequired,
  projectRequired,
}: {
  children: (selectedItem: SelectedItem) => ReactNode;
} & (
  | {
      facilityRequired: true;
      projectRequired?: boolean;
    }
  | {
      facilityRequired?: boolean;
      projectRequired: true;
    }
)) => {
  const { selectedItem } = useSelectedProjectFacility();
  const messageSelectedItem = useMemo(() => {
    if (facilityRequired && projectRequired) return 'project or facility';
    return facilityRequired ? 'facility' : 'project';
  }, [facilityRequired, projectRequired]);

  const isRequiredEntitySelected =
    (facilityRequired && selectedItem?.type === 'FACILITY') || (projectRequired && selectedItem?.type === 'PROJECT');

  if (isNil(selectedItem) || !isRequiredEntitySelected) {
    return <NoContentFallback message={`Start by selecting a ${messageSelectedItem}.`} />;
  }
  return children(selectedItem);
};

export type SelectedFacility = SelectedItem & { type: 'FACILITY' };
/**
 * Used when a Facility *must* be selected
 * in order to render the child page
 */
export const FacilityRequired = ({ children }: { children: (selectedItem: SelectedFacility) => ReactNode }) => (
  <FacilityOrProjectRequired facilityRequired>
    {(selectedItem) => children(selectedItem as SelectedFacility)}
  </FacilityOrProjectRequired>
);

export type SelectedProject = SelectedItem & { type: 'PROJECT' };
/**
 * Used when a Project *must* be selected
 * in order to render the child page
 */
export const ProjectRequired = ({ children }: { children: (selectedItem: SelectedProject) => ReactNode }) => (
  <FacilityOrProjectRequired projectRequired>
    {(selectedItem) => children(selectedItem as SelectedProject)}
  </FacilityOrProjectRequired>
);
