// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { ActionIcon, Menu } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { useWrappedDelete } from 'hooks-api/useWrappedApiCall';

import type { WorkCellTask } from './types';

type Props = {
  workCellTask: WorkCellTask;
  refresh: () => void;
};

export const EditWorkCellTaskMenu = ({ workCellTask, refresh }: Props) => {
  const { apiCall: deleteWorkCellTask, loading: deleting } = useWrappedDelete<unknown>(
    `shop/workCellTask/${workCellTask.workCellTaskId}`,
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const onConfirm = () => {
    void deleteWorkCellTask().then(() => {
      notifications.show({
        title: 'Successfully deleted',
        message: `Task ${workCellTask.taskName} deleted.`,
        color: 'green',
      });
      setModalOpen(false);
      refresh();
    });
  };

  return (
    <>
      <Menu position="bottom-end" withinPortal opened={menuOpen} onChange={setMenuOpen}>
        <Menu.Target>
          <ActionIcon>
            <EvolveIcon className={menuOpen ? '' : 'show-on-row-hover'} icon="MoreOptsVert" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item color="red" onClick={() => setModalOpen(true)}>
            Delete
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <ConfirmationModal
        opened={modalOpen}
        onConfirm={onConfirm}
        onClose={() => setModalOpen(false)}
        loading={deleting}
        buttonColor="red"
      >
        Are you sure you want to delete task <b>{workCellTask.taskName}</b> for item{' '}
        <b>{workCellTask.workRequestItemName}</b> on work order <b>{workCellTask.workOrderName}</b>?
      </ConfirmationModal>
    </>
  );
};
