// Copyright ©️ 2024 eVolve MEP, LLC
import { Anchor, Flex, Text, type AnchorProps } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { isNotNil } from 'helpers/isNotNil';
import { useSetupModule } from 'hooks/useSetupModule';

import type { LocationState, Page, PageTitle } from './types';
import { useEvolveLocation } from './useEvolveLocation';

type ForgeViewerProps =
  | {
      /** Set as `true` when page uses Forge Viewer */
      pageHasViewer?: false;
      viewerRenderComplete?: never;
      currentPage?: never;
    }
  | {
      pageHasViewer: true;
      /** Set as `true` when Forge viewer is done rendering */
      viewerRenderComplete: boolean;
      currentPage: number | null;
    };

const Breadcrumb = ({
  title,
  index,
  url,
  ...props
}: {
  title: string;
} & (
  | {
      index: number;
      url?: never;
    }
  | {
      url: `/${string}` | '..';
      index?: never;
    }
) &
  ForgeViewerProps) => {
  const navigate = useNavigate();
  const { currentModule } = useSetupModule();

  const doOnClick = () => {
    if (isNotNil(url)) {
      void navigate(url === '..' ? url : `/${currentModule()?.toLocaleLowerCase()}${url}`);
    } else {
      void navigate(
        -(index ? index + (props.pageHasViewer && props.viewerRenderComplete ? (props.currentPage ?? 1) : 0) : 1),
      );
    }
  };
  return (
    <>
      <Anchor
        c="dimmed"
        onClick={doOnClick}
        underline="never"
        lineClamp={2}
        style={{ wordWrap: 'unset', maxWidth: 300 }}
      >
        {title}
      </Anchor>
      <Text>/</Text>
    </>
  );
};

const headerProps: AnchorProps = {
  size: '26px',
  fw: 600,
  mr: 'md',
} as const;

export type PageBreadcrumbProps = {
  /** If `true`, will ignore history when rendering breadcrumbs */
  noHistory?: boolean;
} & (
  | {
      /** Title of the page we are on */
      title: PageTitle;
      /** The parent page to fallback to if we are unable to pull it from react-router-dom */
      parentPage?: Page;
    }
  | {
      // If parentPage is provided, title can be a raw string.
      title: PageTitle | string;
      parentPage: Page;
    }
) &
  ForgeViewerProps;

export const getHistory = (locationState: LocationState) => {
  if (!locationState?.from) return [];
  if (Array.isArray(locationState.from)) {
    return locationState.from.sort((a, b) => a.index - b.index);
  }
  const locationString: string = locationState.from;
  return [
    {
      title: locationString,
      url: `shop/${locationString.toLowerCase().replace(' ', '-')}`,
      index: 0,
    },
  ];
};

export const PageBreadcrumb = ({ title, parentPage, noHistory = false, ...props }: PageBreadcrumbProps) => {
  const { state: locationState } = useEvolveLocation();
  const history = getHistory(locationState);
  const hasLocationHistory = !noHistory && history.length > 0;
  const hasParentPage = hasLocationHistory || isNotNil(parentPage);
  return (
    <Flex align="center" gap="xs">
      {hasLocationHistory &&
        history.map(({ title: breadcrumbTitle }, i) => (
          <Breadcrumb key={breadcrumbTitle} title={breadcrumbTitle} index={history.length - i} {...props} />
        ))}
      {!hasLocationHistory && isNotNil(parentPage) && <Breadcrumb {...parentPage} />}
      <Anchor
        lineClamp={1}
        c="dark"
        style={{ cursor: 'initial', maxWidth: 450 }}
        underline="never"
        {...(!hasParentPage ? headerProps : {})}
      >
        {title}
      </Anchor>
    </Flex>
  );
};
