// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { ActionIcon, Flex, Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import type { ColDef } from 'ag-grid-enterprise';
import { format, parseISO } from 'date-fns';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { isNotNil } from 'helpers/isNotNil';
import type { ProductPool } from 'types/types-api';

const CopyLicense = ({ desktopLicenseId }: { desktopLicenseId: string }) => {
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const onCopy = async () => {
    setCopied(false);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!navigator.clipboard) return;
    setLoading(true);
    navigator.clipboard
      .writeText(desktopLicenseId)
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 2500);
        }, 250);
      })
      .catch((err) => {
        setLoading(false);
        notifications.show({
          title: 'Something went wrong',
          message: 'Could not copy to clipboard',
        });
        throw err;
      });
  };
  return (
    <Flex gap="xs" align="center">
      {desktopLicenseId}
      <AgGridStyleTooltip
        label={copied ? 'Copied to clipboard!' : 'Copy to clipboard'}
        openDelay={copied ? 0 : 1000}
        withArrow
        withinPortal
      >
        <ActionIcon variant="transparent" onClick={onCopy}>
          {loading ? (
            <Loader size="xs" />
          ) : (
            <EvolveIcon icon={copied ? 'CheckCircle' : 'Copy'} size="sm" color={copied ? 'green' : 'inherit'} />
          )}
        </ActionIcon>
      </AgGridStyleTooltip>
    </Flex>
  );
};

export const getLicensesColDef = (): ColDef<ProductPool>[] => [
  {
    headerName: 'Product',
    minWidth: 200,
    flex: 1,
    field: 'product.productName',
  },
  {
    headerName: 'Total',
    width: 150,
    field: 'poolQuantity',
  },
  {
    headerName: 'Assigned',
    width: 150,
    valueGetter: ({ data }) => (isNotNil(data) ? data.poolQuantity - data.poolAvailableQuantity : '--'),
  },
  {
    headerName: 'Available',
    width: 150,
    field: 'poolAvailableQuantity',
  },
  {
    field: 'renewalDate',
    width: 150,
    valueFormatter: ({ value }) => (isNotNil(value) ? format(parseISO(value), 'MM/dd/yyyy') : '--'),
  },
  {
    field: 'status',
    width: 150,
    cellStyle: ({ value }: { value: ProductPool['status'] | null }) => ({
      color: {
        Active: 'green',
        Expired: 'red',
        Pending: 'orange',
      }[value ?? 'Active'],
    }),
  },
  {
    headerName: 'Desktop License',
    minWidth: 350,
    field: 'desktopLicenseId',
    cellRenderer: CellRenderer<ProductPool, ProductPool['desktopLicenseId']>(({ value }) =>
      isNotNil(value) ? <CopyLicense desktopLicenseId={value} /> : '--',
    ),
  },
];
