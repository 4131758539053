// Copyright ©️ 2025 eVolve MEP, LLC

import type { UserRole } from 'modules/Admin/CompanyMembers/types';
import type { DocumentId } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/types';

export type GetCompanyProductPoolParams = {
  IncludeExpired?: boolean | null;
  LicenseTypeId?: string | null;
  LicenseClassId?: string | null;
};

export type GetCompanyProductPoolResponse = {
  companyId: string;
  productPool: ProductPool[];
};

export type EntitlementCreateParams = {
  UserIds: string[];
  ProductdPoolIds: string[];
};

export type EntitlementCreateResponse = {
  entitlementId: EntitlementId;
  userId: string;
  productPoolId: ProductPoolId;
  productPool: ProductPool;
  secureData: any;
  secureSignature: any;
};

export type EntitlementGetListParams = {
  userId?: string;
  productName?: string;
  ClientId?: string;
};

declare const entitlementIdSymbol: unique symbol;
export type EntitlementId = string & { [entitlementIdSymbol]: never };

export type UpdateDeleteEntitlementParams = {
  entitlementId: EntitlementId;
};

export type Entitlement = {
  entitlementId: EntitlementId;
  userId: string;
  productPoolId: ProductPoolId;
  isCheckedOut: boolean | BooleanStringOptions;
  checkedOutEndDate: string;
  productPool: ProductPool;
  secureData: any;
  secureSignature: any;
};

export interface AddProductPoolParams {
  companyId?: string | null;
  productId?: string | null;
  poolQuantity?: number | null;
  customData?: string | null;
  term?: number | null;
  startDate?: string | null;
  termDate?: string | null;
  trialTermDate?: string | null;
  trialDuration?: string | null;
}

declare const productPoolIdSymbol: unique symbol;
export type ProductPoolId = string & { [productPoolIdSymbol]: never };

declare const productIdSymbol: unique symbol;
export type ProductId = string & { [productIdSymbol]: never };

export interface ProductPool {
  productPoolId: ProductPoolId;
  desktopLicenseId?: string;
  companyId: string;
  company: Company;
  productId: ProductId;
  product: Product;
  bundle: any;
  poolQuantity: number;
  poolAvailableQuantity: number;
  customData: any;
  renewalDate: string;
  startDate: string;
  status: 'Expired' | 'Active' | 'Pending';
  label: string;
  type: string;
  licenseClassification?: string | null;
}

export type Product = {
  productId: string;
  productName: string;
};

export type GetCompanyParams = {
  companyId: string;
};

declare const addressIdSymbol: unique symbol;
export type AddressId = string & { [addressIdSymbol]: never };

export type Address = {
  addressId: AddressId;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};

declare const companyIdSymbol: unique symbol;
export type CompanyId = string & { [companyIdSymbol]: never };

export interface Company {
  companyId: CompanyId;
  companyName: string;
  companyDownloadPaths: DownloadPath[];
  status: any;
  size: any;
  type: any;
  creationDate: string;
  address: Address;
  hq: string;
  mfA_Required: boolean | BooleanStringOptions;
  data_Analytics: boolean | BooleanStringOptions;
  companyImageId: string;
  hubSpotId: string;
  netsuiteId?: string;
  optInToRevitConfigurations: boolean;
}

export type DownloadPath = {
  document: string;
  updatedBy: string;
  updatedOn: string;
};

export type LicensesGetParams = {
  CompanyId?: string;
  ProductOrBundleId?: string;
  ProductOrBundleName?: string;
  ExcludeExpired?: boolean;
  Skip?: number;
  Take?: number;
  OrderBy?: string;
};

export type UserGetMultisearchParams = {
  CompanyId: string;
  IncludeDeleted?: boolean | IncludeDeletedOptions;
};

export enum IncludeDeletedOptions {
  YES = 'yes',
  NO = 'no',
  ONLY_DELETED = 'only deleted',
}

export enum BooleanStringOptions {
  TRUE = 'true',
  FALSE = 'false',
}

export type UserGetMultisearchResponse = {
  data: User[];
};

export type UserInviteStatus = 'Active' | 'Pending' | 'Deleted';

declare const userIdSymbol: unique symbol;
export type UserId = string & { [userIdSymbol]: never };

declare const projectUserIdSymbol: unique symbol;
export type ProjectUserId = string & { [projectUserIdSymbol]: never };

declare const userNameIdSymbol: unique symbol;
export type UserNameIdSymbol = string & { [userNameIdSymbol]: never };

export type User = {
  userId: UserId;
  userIdentifier: any;
  userName: UserNameIdSymbol;
  userEmail: string;
  userFirstName: string | null;
  userLastName: string | null;
  workPhoneNumber: string | null;
  personalPhoneNumber: string | null;
  companyId: CompanyId;
  company: Company;
  userStatusId: string | null;
  userStatus: UserStatus;
  userPhotoId: DocumentId | null;
  userTypes: UserType[];
  expiresAt: string;
  confirmedAt: string | null;
  inviteStatus: UserInviteStatus | null;
  acceptedLatestEula: boolean;
  isAdmin: boolean;
  isMarketingNotificationAllowed: boolean | null;
  isProductNotificationAllowed: boolean | null;
  isDeleted: boolean;
  hubSpotId: string | null;
  userRoles: UserRole[] | null;
};

export type UserStatus = {
  userStatusId: string | null;
  userStatusName: string | null;
  userStatusCode: string | null;
  userStatusDescription: string | null;
  sortOrder?: number;
};

declare const userTypeIdSymbol: unique symbol;
type UserTypeId = string & { [userTypeIdSymbol]: never };

// TO DO: work with BE to unify the keys being used for user types
// across endpoints.
//
// On returned user object, UserType is the data format, while when
// using admin/type endpoint...
export type UserType = {
  userTypeId: UserTypeId;
  typeName: string;
  typeId: string;
};
// ...data is returned with the TypeOfUser format.
export type TypeOfUser = {
  id: UserTypeId;
  name: string;
  description: string;
};
