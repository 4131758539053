// Copyright ©️ 2024 eVolve MEP, LLC
import './attribute-modal.css';
import { useCallback, useEffect, useState } from 'react';

import { ActionIcon, Checkbox, Chip, Flex, Modal, Stepper, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { ModalButtons } from 'components/Mantine/ModalButtons';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import type { WithOptional } from 'helpers/helper-types';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type {
  PartCatalogId,
  PartCategoryId,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';

import type { PartAttribute, PartAttributeTypeId } from '../types';
import { usePartAttributes } from '../usePartAttributes';
import { AttributeNameField } from './AttributeNameField';

type Props = {
  partCatalogId: PartCatalogId;
  partCategoryId: PartCategoryId;
  refresh: () => void;
};

type AddAttributeBody = {
  partAttributeName: string;
  partAttributeTypeId: PartAttributeTypeId;
  allowWriteInVariant: boolean;
  partCatalogId: PartCatalogId;
  partCategoryId: PartCategoryId;
  addPartAttributeSelectVariantRequests: {
    textValue: string;
    codeValue?: string;
  }[];
};

const AddAttributeForm = ({ partCatalogId, partCategoryId, refresh, onClose }: { onClose: () => void } & Props) => {
  const theme = useMantineTheme();
  const { partAttributeTypes } = usePartAttributes();
  const [variantPlaceholder, setVariantPlaceholder] = useState('');
  const [variantError, setVariantError] = useState<string>();
  const [step, setStep] = useState<number>(1);

  const { apiCall } = useWrappedPost<PartAttribute, AddAttributeBody>('moab/partAttribute');
  const form = useForm<AddAttributeBody>({
    initialValues: {
      partAttributeName: '',
      partAttributeTypeId: '' as PartAttributeTypeId,
      addPartAttributeSelectVariantRequests: [],
      allowWriteInVariant: false,
      partCatalogId,
      partCategoryId,
    },
    validate: (values) => {
      if (step === 1) {
        return {
          partAttributeName: !values.partAttributeName,
          partAttributeTypeId: !values.partAttributeTypeId,
        };
      }
      if (step === 2) {
        return {
          variants: values.addPartAttributeSelectVariantRequests.length === 0,
        };
      }
      return {};
    },
  });

  type FormValues = typeof form.values;
  const onSubmit = useCallback(
    (values: FormValues) => {
      setStep(4);
      apiCall(values)
        .then(() => {
          notifications.show({
            title: 'Successfully added',
            message: `Added new attribute ${values.partAttributeName}`,
            color: 'green',
          });
          onClose();
        })
        .catch(() => {
          setStep(values.addPartAttributeSelectVariantRequests.length > 0 ? 3 : 2);
        })
        .finally(() => refresh());
    },
    [apiCall, onClose, refresh],
  );

  useEffect(() => {
    if (step === 3 && form.values.addPartAttributeSelectVariantRequests.length === 0) {
      onSubmit(form.values);
    }
  }, [form.values, onSubmit, step]);

  const addVariant = (textValue: string) => {
    if (!textValue.trim()) return;
    const existing = form.values.addPartAttributeSelectVariantRequests.find(
      (v) => v.textValue.toLocaleLowerCase() === textValue.trim().toLocaleLowerCase(),
    );
    if (isNotNil(existing)) {
      setVariantError(`Variant ${existing.textValue} already exists`);
    } else {
      form.insertListItem('addPartAttributeSelectVariantRequests', {
        textValue,
        codeValue: '',
      });
      setVariantPlaceholder('');
    }
  };

  const nextStep = () => {
    if (!form.validate().hasErrors) {
      setStep((s) => s + 1);
    }
  };

  return (
    <form
      onSubmit={
        step === 3
          ? form.onSubmit(onSubmit)
          : (e) => {
              nextStep();
              e.preventDefault();
              e.stopPropagation();
            }
      }
    >
      <Stepper className="attribute-modal" active={step - 1} size="sm" styles={{ steps: { marginRight: 10 } }}>
        <Stepper.Step label="Attribute" description={step > 1 ? form.values.partAttributeName : undefined}>
          <Flex direction="column" gap="md">
            <AttributeNameField
              onChange={form.getInputProps('partAttributeName').onChange}
              partCatalogId={partCatalogId}
              defaultValue={form.values.partAttributeName}
            />
            <WrappedSelect
              required
              label="Attribute Type"
              data={
                partAttributeTypes
                  // Range not yet supported
                  ?.filter((t) => t.partAttributeTypeName !== 'Range')
                  .map((t) => ({
                    label: t.partAttributeTypeName,
                    value: t.partAttributeTypeId,
                  })) ?? []
              }
              {...form.getInputProps('partAttributeTypeId')}
            />
          </Flex>
        </Stepper.Step>
        <Stepper.Step
          label="Attribute Variants"
          description={step > 2 ? `${form.values.addPartAttributeSelectVariantRequests.length} variants` : undefined}
        >
          <Flex direction="column" gap="md">
            <Flex gap="sm">
              <TextInput
                placeholder="Variant"
                autoFocus
                value={variantPlaceholder}
                onChange={(e) => {
                  setVariantError('');
                  setVariantPlaceholder(e.target.value);
                }}
                maxLength={64}
                onBlur={() => addVariant(variantPlaceholder)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    addVariant(variantPlaceholder);
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }}
                style={{ width: '100%' }}
                error={variantError}
              />
              <AgGridStyleTooltip label="You can also add attributes by hitting enter." openDelay={0} withArrow>
                <ActionIcon
                  size="lg"
                  variant="outline"
                  disabled={!variantPlaceholder}
                  onClick={() => addVariant(variantPlaceholder)}
                  color={theme.primaryColor}
                >
                  <EvolveIcon icon="Add" size="sm" />
                </ActionIcon>
              </AgGridStyleTooltip>
            </Flex>
            <Flex gap="xs" wrap="wrap">
              {form.values.addPartAttributeSelectVariantRequests.map((v, i) => (
                <Chip
                  key={v.textValue}
                  checked
                  onChange={() => form.removeListItem('addPartAttributeSelectVariantRequests', i)}
                  styles={{ label: { paddingLeft: 6, paddingRight: 10 } }}
                  icon={<EvolveIcon icon="Close" size="sm" />}
                >
                  {v.textValue}
                </Chip>
              ))}
            </Flex>
            <Checkbox
              label="Allow write-in attribute variants"
              {...form.getInputProps('allowWriteInVariant', { type: 'checkbox' })}
            />
          </Flex>
        </Stepper.Step>
        <Stepper.Step
          label="Variant Codes"
          description={
            step > 3
              ? `${
                  form.values.addPartAttributeSelectVariantRequests.some((v) => !!v.codeValue) ? 'Codes' : 'No codes'
                } set`
              : undefined
          }
        >
          <Text c="dimmed" fz="sm" my="xs">
            Variant codes are optional.
          </Text>
          <Flex
            gap="sm"
            direction="column"
            style={{
              maxHeight: 500,
              overflowY: 'auto',
            }}
            px="xs"
          >
            {form.values.addPartAttributeSelectVariantRequests.map((v, i) => (
              <TextInput
                key={v.textValue}
                label={v.textValue}
                placeholder="Code"
                maxLength={6}
                style={{ width: 150 }}
                {...form.getInputProps(`addPartAttributeSelectVariantRequests.${i}.codeValue`)}
              />
            ))}
          </Flex>
        </Stepper.Step>
      </Stepper>
      <ModalButtons
        type="submit"
        loading={step > 3}
        onClose={step === 1 ? onClose : () => setStep((s) => s - 1)}
        confirmationText={
          step === 3 ||
          (step === 2 &&
            form.values.addPartAttributeSelectVariantRequests.length === 0 &&
            form.values.allowWriteInVariant)
            ? 'Submit'
            : 'Next'
        }
        cancellationText={step === 1 ? 'Cancel' : 'Back'}
        dividerMargin="md"
        disabled={!form.isValid()}
      />
    </form>
  );
};

export const AddAttributeModal = (props: WithOptional<Props, 'partCategoryId'>) => {
  const { partCategoryId } = props;
  const [opened, setOpened] = useState(false);
  return (
    <>
      <ActionIcon
        color="dark"
        variant="filled"
        size="xs"
        onClick={() => setOpened(true)}
        disabled={isNil(partCategoryId)}
      >
        <EvolveIcon icon="Add" size="xs" />
      </ActionIcon>
      {isNotNil(partCategoryId) && (
        <Modal
          title="Add Attribute"
          opened={opened}
          onClose={() => setOpened(false)}
          closeOnClickOutside={false}
          closeOnEscape={false}
          size="lg"
          centered
          withinPortal
        >
          <AddAttributeForm onClose={() => setOpened(false)} {...props} partCategoryId={partCategoryId} />
        </Modal>
      )}
    </>
  );
};
