// Copyright ©️ 2024 eVolve MEP, LLC
import { useEffect, useState } from 'react';

import { Loader } from '@mantine/core';
import { parseISO, isAfter } from 'date-fns';
import { Navigate, useNavigate } from 'react-router-dom';

import { useQuery } from 'hooks/useQuery';

import { useConfirmUser, isInvitationType } from './auth/restCalls';
import type { LoginErrorCode } from './components/LoginErrorAlert';
import { Join } from './Join/Join';

export const AcceptInvitation = () => {
  const navigate = useNavigate();
  const { confirm, confirming } = useConfirmUser();
  const [loading, setLoading] = useState(true);
  const [errorCode, setErrorCode] = useState<LoginErrorCode>();

  // All these come from a link in the invite email
  const queryParams = useQuery();
  const userId = queryParams.get('userId');
  const inviteSourceName = queryParams.get('name');
  const type = queryParams.get('type');
  const id = queryParams.get('id');
  const code = queryParams.get('code');
  const expirationDateStr = queryParams.get('expiration');

  useEffect(() => {
    if (expirationDateStr) {
      const expirationDate = parseISO(expirationDateStr);
      const hasExpired = isAfter(new Date(), expirationDate);
      if (hasExpired) {
        setLoading(false);
        setErrorCode('InvitationExpired');
        return;
      }
    }

    (async () => {
      try {
        if (isInvitationType(type) && !!id && !!code) {
          await confirm(id, type, {
            confirmationCode: code,
          });
          void navigate('/auth/login', {
            state: {
              joinConfirm: inviteSourceName,
            },
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Could not accept invite directly, user is likely not confirmed.', e);
      } finally {
        setLoading(false);
      }
    })();
  }, [code, confirm, expirationDateStr, id, inviteSourceName, navigate, type, userId]);

  if (errorCode === 'InvitationExpired') {
    return <Navigate to="/invitation-expired" />;
  }

  if (errorCode || !code || !userId || !id || !isInvitationType(type)) {
    return <Navigate to={`/auth/login?errorCode=${errorCode ?? 'UnknownException'}`} />;
  }

  if (loading || confirming) return <Loader />;

  return <Join inviteSourceName={inviteSourceName} type={type} id={id} code={code} />;
};
