// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { AppShell, type DefaultMantineColor, useMatches } from '@mantine/core';

import { NavBarContent } from './NavBarContent/NavBarContent';

const color: DefaultMantineColor = 'rgb(48,48,48)';

export const DesktopNavBar = () => {
  const [open, setOpen] = useState(false);
  const openWidth = useMatches({
    base: undefined,
    xs: 260,
  });
  return (
    <AppShell.Navbar
      p={8}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      bg={color}
      withBorder
      w={open ? openWidth : undefined}
      style={{
        scrollbarWidth: open ? undefined : 'none',
        borderColor: color,
        position: 'fixed',
        whiteSpace: 'nowrap',
        transition: '.2s',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <NavBarContent />
    </AppShell.Navbar>
  );
};
