// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';
import { WRITE_IN_ASSEMBLY } from 'modules/Field/WorkRequests/WorkRequest/constants';
import type { WorkRequestItemTypeId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import { EditItemPopover, type ItemFields } from '../../EditItemPopover';
import type {
  CreateOrUpdateWorkOrderItemBody,
  CreateOrUpdateWorkRequestItemBody,
  WorkOrderItemTypeId,
} from '../../types';
import type { WorkRequestOrderProps } from '../../WorkRequestOrderItemsContext';

type Props = {
  refresh: () => void;
} & WorkRequestOrderProps;

export const AddWriteInItem = ({ refresh, ...props }: Props) => {
  const selectedWorkOrder = 'workOrder' in props ? props.workOrder : undefined;
  const selectedWorkRequest = 'workRequest' in props ? props.workRequest : undefined;
  const [opened, setOpened] = useState(false);
  const { apiCall: addWorkOrderItem, loading: loading1 } = useWrappedPost<unknown, CreateOrUpdateWorkOrderItemBody>(
    'shop/workOrderItem',
  );
  const { apiCall: addWorkRequestItem, loading: loading2 } = useWrappedPost<unknown, CreateOrUpdateWorkRequestItemBody>(
    'shop/workRequestItem',
  );
  const loading = loading1 || loading2;

  const onSubmitAddWorkOrderItem = async ({ itemName: workOrderItemName, quantity, unitOfMeasureId }: ItemFields) => {
    if (isNil(selectedWorkOrder)) return;
    await addWorkOrderItem({
      quantity,
      unitOfMeasureId,
      workOrderItemName,
      workOrderId: selectedWorkOrder.workOrderId,
      workOrderItemIdentification: '',
      workOrderItemTypeId: WRITE_IN_ASSEMBLY as WorkOrderItemTypeId,
    });
  };

  const onSubmitAddWorkRequestItem = async ({
    itemName: workRequestItemName,
    quantity,
    unitOfMeasureId,
  }: ItemFields) => {
    if (isNil(selectedWorkRequest)) return;
    await addWorkRequestItem({
      quantity,
      unitOfMeasureId,
      workRequestItemName,
      workRequestId: selectedWorkRequest.workRequestId,
      workRequestItemIdentification: '',
      workRequestItemTypeId: WRITE_IN_ASSEMBLY as WorkRequestItemTypeId,
    });
  };

  const onSubmit = async (values: ItemFields) => {
    await (isNotNil(selectedWorkOrder) ? onSubmitAddWorkOrderItem(values) : onSubmitAddWorkRequestItem(values));
    notifications.show({
      title: 'Successfully added',
      message: `Added ${values.itemName} to ${isNotNil(selectedWorkOrder) ? 'work order' : 'work request'}`,
      color: 'green',
    });
    refresh();
    setOpened(false);
  };

  return (
    <EditItemPopover loading={loading} onSubmit={onSubmit} opened={opened} onClose={() => setOpened(false)}>
      <Button onClick={() => setOpened((o) => !o)} leftSection={<EvolveIcon size="sm" icon="Add" />} variant="outline">
        Add write-in item
      </Button>
    </EditItemPopover>
  );
};
