// Copyright ©️ 2024 eVolve MEP, LLC
import type { EvolveIconName } from 'assets/icons/EvolveIcon';

export const navIconMap = {
  COMPANY: 'Company',
  SHOPS: 'Shops',
  SHOP_MEMBERS: 'ShopMembers',
  SHOP_PROPERTIES: 'ShopProperties',
  PROJECTS: 'Projects',
  PROJECT_MEMBERS: 'ShopMembers',
  PROJECT_PROPERTIES: 'Settings',
  PROPERTIES: 'ShopSetup',
  WORK_CELLS_MACHINES: 'WorkCellsAndMachines',
  SHOP_TASKS_NAV: 'WorkCellTask',
  WORK_PLANNING: 'WorkPlanning',
  WORK_REQUESTS: 'WorkRequest',
  DRAWING_REGISTER: 'DrawingDocument',
  WORK_ORDERS: 'WorkOrder',
  WORK_CELLS: 'WorkCell',
  FABRICATION: 'Fabrication',
  WORK_CELL_QUEUE: 'WorkCellQueue',
  PARTS_CATALOG: 'Part',
  CATALOG_SETUP: 'Catalog',
  DATA_IMPORT: 'DataImport',
  PROJECT_MATERIALS: 'ProjectMaterials',
  BILL_OF_MATERIALS: 'BillOfMaterials',
  HOME: 'Home',
  INSIGHTS: 'Insights',
  LICENSES: 'Licenses',
  COMPANY_MEMBERS: 'ShopMembers',
  COMPANY_PROPERTIES: 'Settings',
  CUSTOMERS: 'Customers',
  NAV_ITEM_1: 'EvolveMembers',

  // 360 Sync
  SYNC: 'Sync',
  CONNECTIONS: 'Connections',
  WORKFLOWS: 'Workflows',
  DASHBOARD: 'Dashboard',
  LOGS: 'Logs',
} as const satisfies Record<string, EvolveIconName>;

export type MenuIcon = keyof typeof navIconMap;
