// Copyright ©️ 2024 eVolve MEP, LLC
import React from 'react';

import { Tooltip, type TooltipProps } from '@mantine/core';

const shadow: Partial<React.CSSProperties> = {
  borderWidth: 1,
  borderColor: 'lightgray',
  borderStyle: 'solid',
  boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.2)',
};

/** A tooltip intended to mimic the styling of built-in AG Grid tooltips */
export const AgGridStyleTooltip = ({ style, styles, transitionProps, ...props }: TooltipProps) => (
  <Tooltip
    color="white"
    c="gray.9"
    multiline
    arrowSize={8}
    withinPortal
    openDelay={700}
    {...props}
    transitionProps={{ transition: 'fade', duration: 200, ...transitionProps }}
    style={{
      maxWidth: '50rem',
      ...shadow,
      ...style,
    }}
    styles={{
      tooltip: { overflowWrap: 'break-word' },
      arrow: {
        ...shadow,
        boxShadow: '0 0 8px -8px rgba(0, 0, 0, 0.2)',
      },
      ...styles,
    }}
  />
);
