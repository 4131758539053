// Copyright ©️ 2024 eVolve MEP, LLC
import isEqual from 'lodash.isequal';

import { isNotNil } from './isNotNil';

export const strongKeys = <K extends string | number | symbol, V>(obj: Record<K, V>) => Object.keys(obj) as K[];

export const strongValues = <K extends string | number | symbol, V>(obj: Record<K, V>) => Object.values(obj) as V[];

// This is not the most type-safe thing in the world.
// It's current structure really only works if V is the same type for EVERY key-value pair.
export const strongEntries = <K extends string | number | symbol, V>(obj: Partial<Record<K, V>>) =>
  Object.entries(obj) as [K, V][];

export const strongFromEntries = <K extends string | number | symbol, V>(entries: readonly [K, V][]) =>
  Object.fromEntries(entries) as Record<K, V>;

export const groupBy = <T, K extends string | number | symbol>(collection: readonly T[], keyGetter: (obj: T) => K) =>
  collection.reduce((out, curr) => {
    const key = keyGetter(curr);
    if (isNotNil(out[key])) {
      out[key].push(curr);
    } else {
      // This is more effecient than spreading a new `out` obj,
      // which is really important for large collections.
      // eslint-disable-next-line no-param-reassign
      out[key] = [curr];
    }
    return out;
  }, {} as Record<K, T[]>);

/** Standard `lodash.isEqual` with an extra type check to make sure dispread types aren't passed in for `a` and `b` */
export const strongIsEqual: <T>(a: T, B: T) => boolean = isEqual;
