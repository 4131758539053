// Copyright ©️ 2024 eVolve MEP, LLC
import { useMemo } from 'react';

import type { WorkOrderStatusTypeName, WorkRequestStatusName } from 'constants/badgeMappingStatus';
import { strongFromEntries } from 'helpers/strongEntries';
import { getDaysRemaining } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/common';
import type { WorkRequest } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';

import type { WorkOrder } from './types';

export const getDaysRemainingWorkOrder = (workOrder: WorkOrder) =>
  getDaysRemaining(
    workOrder.workOrderStatusTypeName,
    workOrder.workRequest.needBy,
    workOrder.lastStatusUpdate ?? workOrder.createdOn,
  );

const workRequestEditables = ['reject', 'cancel', 'submit', 'approve', 'editItems', 'editTasks'] as const;
export type WorkRequestEditable = typeof workRequestEditables[number];
const workRequestTruthTable: Record<WorkRequestEditable, WorkRequestStatusName[]> = {
  reject: ['Pending'],
  cancel: ['Submitted', 'Pending', 'Approved', 'Rejected', 'In Progress'],
  submit: ['Draft', 'Rejected', 'Canceled'],
  approve: ['Pending'],
  editItems: ['Draft', 'Submitted', 'Pending', 'Rejected', 'Canceled'],
  editTasks: ['Pending'],
} as const;

export const canEditWorkRequest = (workRequestStatus: WorkRequestStatusName, editing: WorkRequestEditable) =>
  workRequestTruthTable[editing].includes(workRequestStatus);

export const useCanEditWorkRequest = (workRequest: WorkRequest) =>
  useMemo(
    () =>
      strongFromEntries(
        workRequestEditables.map(
          (editable) => [editable, canEditWorkRequest(workRequest.workRequestStatusName, editable)] as const,
        ),
      ),
    [workRequest.workRequestStatusName],
  );

const workOrderEditables = ['release', 'editItems', 'editTasks'] as const;
type WorkOrderEditable = typeof workOrderEditables[number];
const workOrderTruthTable: Record<WorkOrderEditable, WorkOrderStatusTypeName[]> = {
  release: ['Draft', 'Blocked'],
  editItems: ['Draft', 'Blocked'],
  editTasks: ['Draft', 'Blocked'],
} as const;

export const canEditWorkOrder = (workOrderStatus: WorkOrderStatusTypeName, editing: WorkOrderEditable) =>
  workOrderTruthTable[editing].includes(workOrderStatus);

export const useCanEditWorkOrder = (workOrder: WorkOrder) =>
  useMemo(
    () =>
      strongFromEntries(
        workOrderEditables.map(
          (editable) => [editable, canEditWorkOrder(workOrder.workOrderStatusTypeName, editable)] as const,
        ),
      ),
    [workOrder.workOrderStatusTypeName],
  );
