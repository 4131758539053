// Copyright ©️ 2024 eVolve MEP, LLC
import { useEffect, useState } from 'react';

import { useDebouncedState } from '@mantine/hooks';

export const useDebouncedStateV2 = <T>(initialValue: T, delay = 400) => {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useDebouncedState(value, delay);
  useEffect(() => {
    setValue(debouncedValue);
  }, [debouncedValue]);
  return [value, setDebouncedValue, setValue] as const;
};
