// Copyright ©️ 2025 eVolve MEP, LLC
import './draft-table.css';
import { useState } from 'react';

import { Box, Button, Flex } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { AgGridReact } from 'ag-grid-react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { isNotNil } from 'helpers/isNotNil';
import { ConfirmNavigationModal } from 'modules/Field/WorkRequests/WorRequestsProperties/WorkRequestPropertiesAttachments/hooks/ConfirmNavigationModal';
import type { WorkCell } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

import { CreateEditWorkCellModal } from './CreateEditWorkCellModal';
import { useWorkCellsGrid } from './useWorkCellsGrid';
import { useWorkCellSetup } from './WorkCellSetupContext';

export const WorkCellSetupPage = () => {
  const {
    selectedFacility,
    activeShopConfiguration,
    draftShopConfiguration,
    createDraftShopConfiguration,
    deleteDraftShopConfiguration,
    applyDraftShopConfiguration,
    // Grid props for "draft" config
    serverSideGridProps: {
      agGridProps: draftAgGridProps,
      refreshGrid,
      workCellDraggedToEnd: draftWorkCellDraggedToEnd,
    },
    createWorkCell,
    mutationRunning,
  } = useWorkCellSetup();
  const [showDiscard, setShowDiscard] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [creatingWorkCell, setCreatingWorkCell] = useState(false);

  const draftExists = isNotNil(draftShopConfiguration);
  // Grid props for "active" (not draft) config
  const { agGridProps, sorting, workCellDraggedToEnd } = useWorkCellsGrid(
    selectedFacility,
    'active',
    activeShopConfiguration,
    draftExists,
  );

  return (
    <>
      <BasePageHeader
        title="Work Cell Setup"
        spacing="xl"
        topRightComponent={
          draftExists ? (
            <>
              <Button variant="subtle" disabled={mutationRunning} onClick={() => setShowDiscard(true)}>
                Discard
              </Button>
              <Button disabled={mutationRunning} onClick={() => setShowSave(true)}>
                Save changes
              </Button>
            </>
          ) : (
            <AgGridStyleTooltip
              withArrow
              openDelay={350}
              label="Add new work cells, remove existing work cells, and map tasks to work cells"
            >
              <Button
                variant="outline"
                onClick={createDraftShopConfiguration}
                leftSection={<EvolveIcon icon="WriteInItem" />}
                disabled={mutationRunning}
              >
                Edit setup
              </Button>
            </AgGridStyleTooltip>
          )
        }
        bottomRightComponent={
          draftExists ? (
            <Button
              onClick={() => setCreatingWorkCell(true)}
              leftSection={<EvolveIcon icon="Add" size="sm" />}
              variant="outline"
              disabled={mutationRunning}
            >
              Add work cell
            </Button>
          ) : null
        }
      />
      <Flex style={{ height: '100%' }} gap="sm">
        <Box style={{ height: '100%', width: draftExists ? '50%' : '100%' }}>
          <div
            className={`work-cell-setup ${workCellDraggedToEnd ? 'dragged-to-end' : ''} ${
              draftExists ? 'active-table' : ''
            }`}
            style={{ height: '100%' }}
          >
            <AgGridReact<WorkCell>
              {...agGridProps}
              noRowsOverlayComponent={() => (
                <NoRowsOverlay
                  icon="WorkCellsAndMachines"
                  label="Edit setup to get started."
                  gridRef={agGridProps.ref}
                />
              )}
            />
          </div>
        </Box>
        {draftExists && (
          <Box style={{ height: '100%', width: '50%' }}>
            <div
              className={`work-cell-setup draft-table ${draftWorkCellDraggedToEnd ? 'dragged-to-end' : ''}`}
              style={{ height: '100%' }}
            >
              <AgGridReact<WorkCell> loading={sorting} {...draftAgGridProps} />
            </div>
          </Box>
        )}
      </Flex>

      {isNotNil(draftShopConfiguration) && (
        <>
          <ConfirmNavigationModal
            isDirty
            subtitle="Your changes have not been saved. Navigating away will remember changes in edit mode, but will not reflect in the existing work cell setup."
          />

          <ConfirmationModal
            opened={showDiscard}
            onConfirm={() => {
              setShowDiscard(false);
              void deleteDraftShopConfiguration();
            }}
            onClose={() => setShowDiscard(false)}
            buttonColor="red"
          >
            Are you sure you want to discard your changes?
          </ConfirmationModal>

          <ConfirmationModal
            opened={showSave}
            onConfirm={() => {
              setShowSave(false);
              void applyDraftShopConfiguration().then(() => {
                notifications.show({
                  title: 'Successfully updated',
                  message: 'New work cell setup changes saved.',
                  color: 'green',
                });
              });
            }}
            confirmationText="Save"
            onClose={() => setShowSave(false)}
          >
            Are you ready to save your changes? Doing so will rebalance your work cells. This may take some time.
          </ConfirmationModal>

          <CreateEditWorkCellModal
            opened={creatingWorkCell}
            onClose={() => setCreatingWorkCell(false)}
            onSubmit={async (values) => {
              const res = await createWorkCell(values, draftShopConfiguration.shopConfigurationId);
              refreshGrid();
              setCreatingWorkCell(false);
              notifications.show({
                title: 'Successfully created',
                message: `Created work cell ${res.workCellName}`,
                color: 'green',
              });
            }}
          />
        </>
      )}
    </>
  );
};
