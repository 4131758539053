// Copyright ©️ 2024 eVolve MEP, LLC
import { useMemo } from 'react';

import { Card, Space, Text } from '@mantine/core';
import { AgGridReact } from 'ag-grid-react';

import { compactRowHeight } from 'helpers/ag-grid/baseColumnDef';
import type { TaskId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import type { WorkCellTask } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';
import type { WorkOrder } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';

import { getCancelTasksColumnDef } from './columnDefs';

type Props = {
  workOrder: WorkOrder;
  tasks: WorkCellTask[];
  tasksToUpdate: Record<TaskId, boolean>;
  disabled: boolean;
  setTaskDecision: (taskId: TaskId, isSaveData: boolean) => void;
};

export const CancelWorkOrderTasks = ({ workOrder, tasks, tasksToUpdate, disabled, setTaskDecision }: Props) => {
  const colDef = useMemo(
    () => getCancelTasksColumnDef(tasksToUpdate, setTaskDecision, disabled),
    [tasksToUpdate, setTaskDecision, disabled],
  );
  return (
    <>
      <div
        style={{
          height: Math.min(250, (tasks.length + 1) * (compactRowHeight + 1) + 1),
        }}
      >
        <AgGridReact<WorkCellTask>
          columnDefs={colDef}
          suppressCellFocus
          rowData={tasks}
          rowHeight={compactRowHeight}
          headerHeight={compactRowHeight}
          suppressContextMenu
          tooltipShowDelay={1000}
          defaultColDef={{
            resizable: false,
            suppressMovable: true,
            sortable: false,
            suppressHeaderMenuButton: true,
            suppressHeaderContextMenu: true,
            suppressColumnsToolPanel: true,
          }}
        />
      </div>
      <Card withBorder>
        <Card.Section p="sm">
          <Text fz="sm" fw={600} mb={3}>
            Save Data
          </Text>
          <Text fz="xs">
            Will record and preserve production time data and allow you to continue where you left off
          </Text>
          <Space my="xs" />
          <Text fz="sm" fw={600} mb={3}>
            Discard Data
          </Text>
          <Text fz="xs">Will delete all production time data for the task and allow you to start over</Text>
        </Card.Section>
      </Card>
      <Card style={{ backgroundColor: '#eee' }}>
        <Card.Section p="sm">
          <Text ta="center">
            Are you sure you want to cancel <b>{workOrder.workOrderName}</b>?
          </Text>
        </Card.Section>
      </Card>
    </>
  );
};
