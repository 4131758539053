// Copyright ©️ 2024 eVolve MEP, LLC
import { useCallback, useMemo } from 'react';

import { useWrappedPatch } from 'hooks-api/useWrappedApiCall';

const invitationTypes = ['project', 'department', 'company'] as const;
export type InvitationType = (typeof invitationTypes)[number];
export const isInvitationType = (type: string | undefined | null): type is InvitationType =>
  invitationTypes.includes(type as InvitationType);

const resourceUrlMap: Record<InvitationType, string> = {
  company: 'user',
  department: 'departmentUser',
  project: 'projectUser',
};

export const useConfirmUser = (showError = false) => {
  const { apiCall, loading } = useWrappedPatch<
    unknown,
    {
      confirmationCode: string;
      password?: string;
    }
  >('admin/:resource/:id/confirmation', { dontAlertOnError: !showError });
  const confirm = useCallback(
    (
      id: string,
      type: InvitationType,
      body: {
        confirmationCode: string;
        password?: string;
      },
    ) => apiCall(body, { url: `admin/${resourceUrlMap[type]}/${id}/confirmation` }),
    [apiCall],
  );
  return useMemo(() => ({ confirm, confirming: loading }), [confirm, loading]);
};
