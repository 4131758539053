// Copyright ©️ 2024 eVolve MEP, LLC

import { AutodeskEventBus } from '../AutodeskEventBus';

const { Autodesk } = window;

class PageNavExtension extends Autodesk.Viewing.Extension {
  subToolbar: Autodesk.Viewing.UI.ControlGroup | null;

  constructor(viewer: Autodesk.Viewing.GuiViewer3D, opts: any) {
    super(viewer, opts);
    this.subToolbar = null;
  }

  load() {
    return true;
  }

  onToolbarCreated(toolbar: Autodesk.Viewing.UI.ToolBar) {
    const prevButton = new window.Autodesk.Viewing.UI.Button('[PageNavExtension]_prev_btn');
    prevButton.onClick = function () {
      AutodeskEventBus.dispatch('page_nav', { direction: 'prev' });
    };
    prevButton.addClass('autodesk-prev-page-btn');
    prevButton.setIcon('adsk-icon-arrow');
    prevButton.setToolTip('Previous');

    const pageNumButton = new window.Autodesk.Viewing.UI.Button('[PageNavExtension]_page_num_btn');
    pageNumButton.addClass('autodesk-page_num_btn');

    const nextButton = new window.Autodesk.Viewing.UI.Button('[PageNavExtension]_next_btn');
    nextButton.onClick = function () {
      AutodeskEventBus.dispatch('page_nav', { direction: 'next' });
    };
    nextButton.addClass('autodesk-next-page-btn');
    nextButton.setIcon('adsk-icon-arrow');
    nextButton.setToolTip('Next');

    this.subToolbar = new window.Autodesk.Viewing.UI.ControlGroup('page-navigation-toolbar');
    this.subToolbar.addControl(prevButton);
    this.subToolbar.addControl(pageNumButton);
    this.subToolbar.addControl(nextButton);

    toolbar.addControl(this.subToolbar, { index: 0 });
  }

  unload() {
    if (this.subToolbar) {
      this.viewer.toolbar.removeControl(this.subToolbar);
      this.subToolbar = null;
    }
    return super.unload();
  }
}
Autodesk.Viewing.theExtensionManager.registerExtension('PageNavExtension', PageNavExtension);
