// Copyright ©️ 2024 eVolve MEP, LLC
import { useEffect, useState } from 'react';

import { Loader } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetLinkFromQrId } from 'hooks-api/useGetLinkFromQRId';

export const QRRedirect = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [url, setUrl] = useState<string | undefined>(undefined);

  const { getLinkFromQrCode } = useGetLinkFromQrId();

  useEffect(() => {
    if (!params.id) return;
    void getLinkFromQrCode(params.id).then((response) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!response || response.status !== 200 || !response.url) {
        void navigate('/auth');
        return;
      }

      setUrl(response.url);
    });
  }, [navigate, params.id, getLinkFromQrCode]);

  if (url) {
    window.location.href = url;
    return null;
  }

  return <Loader />;
};
