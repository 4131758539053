// Copyright ©️ 2024 eVolve MEP, LLC
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDebouncedState } from '@mantine/hooks';

import type { SearchableSelectorProps } from './searchableTypes';

export const useSearchableData = <T, TKey extends keyof T, TLabel>({
  paginatedGet,
  fetchPageOpts,
  searchKey = 'searchPhrase',
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  useLike = searchKey !== 'searchPhrase',
  lazy = false,
}: { lazy?: boolean } & Pick<
  SearchableSelectorProps<T, TKey, TLabel>,
  'paginatedGet' | 'fetchPageOpts' | 'searchKey' | 'useLike'
>) => {
  const [isLazy, setIsLazy] = useState(lazy);
  const [loadingFresh, setLoadingFresh] = useState(false);
  const [searchPhrase, setSearchPhrase] = useDebouncedState('', 400);

  const { data: options, loading, reset, fetchNextPage: fetchNextPageNoParams } = paginatedGet;

  const fetchNextPage = useCallback(async () => {
    await fetchNextPageNoParams({
      ...fetchPageOpts,
      params: {
        ...fetchPageOpts?.params,
        ...(searchPhrase.length > 0
          ? {
              [searchKey]: `${useLike ? 'like:' : ''}${searchPhrase}`,
            }
          : {}),
      },
    });
  }, [fetchNextPageNoParams, fetchPageOpts, searchKey, searchPhrase, useLike]);

  useEffect(() => {
    if (!isLazy) {
      setLoadingFresh(true);
      reset();
      void fetchNextPage().finally(() => setLoadingFresh(false));
    }
  }, [fetchNextPage, reset, isLazy]);

  return useMemo(
    () => ({
      options,
      fetchNextPage,
      loading,
      loadingFresh,
      searchPhrase,
      setSearchPhrase,
      setIsLazy,
    }),
    [fetchNextPage, loading, loadingFresh, options, searchPhrase, setSearchPhrase],
  );
};
