// Copyright ©️ 2024 eVolve MEP, LLC
import React, { type ReactNode } from 'react';

import { Badge, Box, Card, type CardProps, Divider, Flex, Text } from '@mantine/core';
import { format, parseISO } from 'date-fns';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { EvolveLink } from 'components/Mantine/Navigation/EvolveLink';
import type { PageTitle } from 'components/Mantine/Navigation/types';
import { useEvolveNavigate } from 'components/Mantine/Navigation/useEvolveNavigate';
import { type StatusKey, statusToColorMap } from 'constants/badgeMappingStatus';
import { CellStatusBadge } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListElements/CellComponentStatus/CellStatusBadge';
import { getDaysRemaining } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/common';

import type { WorkOrderId } from './types';

type Props = {
  currentPage: PageTitle;
  cardDestination: string;
  projectName: string;
  workOrderId: WorkOrderId;
  workOrderName: string;
  status: StatusKey;
  needBy: string;
  includeStatusBadge?: boolean;
  footer?: ReactNode;
  cardProps?: Omit<CardProps, 'children'> & { ref?: React.Ref<HTMLDivElement> };
  children: ReactNode;
};

export const ShopKanbanCard = ({
  currentPage,
  cardDestination,
  projectName,
  workOrderId,
  workOrderName,
  children,
  status,
  needBy,
  footer,
  includeStatusBadge,
  cardProps,
}: Props) => {
  const navigate = useEvolveNavigate(currentPage);
  const daysRemaining = getDaysRemaining(status, needBy);
  const needByDate = format(parseISO(`${needBy}Z`), 'MM/dd/yyyy');
  const isLate = daysRemaining < 0;
  return (
    <Card
      py="sm"
      mb="xs"
      withBorder
      radius="md"
      {...cardProps}
      style={{
        ...cardProps?.style,
        flex: '0 0 auto',
        lineHeight: 1,
        userSelect: 'none',
      }}
      onClick={() => navigate(cardDestination)}
    >
      <Card.Section bg="gray.1">
        <Flex p="xs" pb={6} gap={4} fz="sm" align="center">
          <Text
            title={projectName}
            c="dimmed"
            style={{ minWidth: 40, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            {projectName}
          </Text>
          <EvolveIcon icon="ArrowRight" width={8} color="gray.5" />
          <EvolveLink
            className="kanban-work-order-link kanban-work-order-small-link"
            to={currentPage === 'Work Orders' ? `${workOrderId}` : `/shop/work-orders/${workOrderId}`}
            from={currentPage}
          >
            <Text style={{ whiteSpace: 'nowrap' }} title={workOrderName}>
              {workOrderName}
            </Text>
          </EvolveLink>
        </Flex>
      </Card.Section>

      <Box my="sm">{children}</Box>

      <Flex align="center" gap="xs">
        <AgGridStyleTooltip
          c={isLate ? 'red' : 'gray.9'}
          label={
            <Flex align="center" gap="xs">
              Need by
              <Divider orientation="vertical" color={isLate ? 'red' : 'gray.9'} />
              Days remaining
            </Flex>
          }
          withArrow
          openDelay={150}
        >
          <Badge
            size="lg"
            variant="outline"
            color={isLate ? statusToColorMap.Late.color : 'gray.4'}
            c={isLate ? undefined : 'gray'}
            bg={isLate ? 'red.0' : undefined}
            radius="sm"
            p={0}
            fw={500}
            style={{ cursor: 'initial' }}
            onClick={(e) => e.stopPropagation()}
            styles={{
              label: {
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
              },
            }}
          >
            <Flex px={6}>{needByDate}</Flex>
            <Divider orientation="vertical" opacity={0.4} />
            <Flex bg={isLate ? statusToColorMap.Late.color : undefined} px={6} c={isLate ? 'gray.0' : undefined}>
              {daysRemaining}
            </Flex>
          </Badge>
        </AgGridStyleTooltip>
        {includeStatusBadge && <CellStatusBadge size="lg" status={status} />}
        {footer}
      </Flex>
    </Card>
  );
};
