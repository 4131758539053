// Copyright ©️ 2025 eVolve MEP, LLC
import React, { useState, useMemo, type ReactNode, useCallback, useEffect } from 'react';

import { useGeneralContext } from 'helpers/useGeneralContext';
import { getLocalStorage, removeLocalStorage } from 'hooks/useLocalStorage';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';
import type { User } from 'types/types-api';

type MaybeUserContextType = {
  user: User | null;
  setUser: (user: User | null) => void;
  loading: boolean;
  refreshUser: () => Promise<User | null>;
};

export const MaybeUserContext = React.createContext<MaybeUserContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const MaybeUserProvider = ({ children }: Props) => {
  const { apiCall: getUserByName, loading: apiLoading } = useWrappedGet<User>('admin/user/username/', { lazy: true });
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  const pullUser = useCallback(async () => {
    try {
      setLoading(true);
      const username = getLocalStorage('EVOLVE_TOKEN_USERNAME');
      if (username) {
        const res = await getUserByName({
          url: `admin/user/username/${username}`,
        });
        setUser(res);
        return res;
      }
    } catch {
      removeLocalStorage('EVOLVE_TOKEN_USERNAME');
      setUser(null);
    } finally {
      setLoading(false);
    }
    return null;
  }, [getUserByName]);

  useEffect(() => {
    void pullUser();
  }, [pullUser]);

  const userObj = useMemo(
    () => ({
      user,
      setUser,
      loading: loading || apiLoading,
      refreshUser: pullUser,
    }),
    [user, loading, apiLoading, pullUser],
  );

  return <MaybeUserContext.Provider value={userObj}>{children}</MaybeUserContext.Provider>;
};

export const useMaybeUser = () => useGeneralContext(MaybeUserContext, 'User');
