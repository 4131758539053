// Copyright ©️ 2024 eVolve MEP, LLC
import type { ColDef } from 'ag-grid-enterprise';
import { format, parseISO } from 'date-fns';

import { CellRenderer } from 'components/Mantine/CellRenderer';
import type { Task } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import { UserAvatar } from './UserAvatar';
import type { TaskStatusType } from '../../WorkCellQueue/WorkCellQueuePage/types';

type TaskEvent = Task['time']['events'][number];

export const getEventsColDef = (taskStatuses: TaskStatusType[]): ColDef<TaskEvent>[] => [
  {
    width: 56,
    cellRenderer: CellRenderer<TaskEvent>(({ data }) => <UserAvatar userId={data.userId} />, {
      flexProps: { px: 'xs' },
    }),
  },
  {
    headerName: 'Action',
    flex: 1,
    valueGetter: ({ data }) =>
      taskStatuses.find((t) => t.taskStatusTypeId === data?.taskStatusTypeId)?.taskStatusTypeName,
  },
  {
    headerName: 'Date',
    flex: 1,
    field: 'timesStamp',
    valueFormatter: ({ value }) => format(parseISO(`${value}Z`), 'MM/dd/yy'),
  },
  {
    headerName: 'Time',
    flex: 1,
    field: 'timesStamp',
    valueFormatter: ({ value }) => format(parseISO(`${value}Z`), 'p'),
  },
];
