// Copyright ©️ 2024 eVolve MEP, LLC
import { Button, Flex, NumberInput } from '@mantine/core';

import type { WithOptional } from 'helpers/helper-types';
import type { WorkRequestId } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import type { WorkOrderId } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';

import type { PartDecision } from '../PartDecisionMakerModal';
import { CatalogPartCard } from './CatalogPartCard';
import type { Part, PartId } from './types';

export type AddCatalogItemToWorkOrderBody = WithOptional<PartDecision, 'decisions'> & {
  quantity: number;
} & {
  workOrderId: WorkOrderId;
};

export type AddCatalogItemToWorkRequestBody = WithOptional<PartDecision, 'decisions'> & {
  quantity: number;
} & {
  workRequestId: WorkRequestId;
};

type Props = {
  part: Part;
  saving?: boolean;
  savingSingle?: PartId;
  multiplePartsMap: Record<PartId, number>;
  updateMultiPartsMap: (partId: PartId, qty: number | undefined) => void;
  addSingleCatalogItem: (part: Part) => void;
  placeholder?: string;
  disabled?: boolean;
};

export const AddCatalogPartCard = ({
  placeholder = 'Qty',
  part,
  saving,
  savingSingle,
  multiplePartsMap,
  updateMultiPartsMap,
  addSingleCatalogItem,
  disabled,
}: Props) => (
  <CatalogPartCard
    part={part}
    disabled={disabled}
    rightComponent={
      <Flex gap="md" align="flex-end">
        <NumberInput
          style={{ width: 100 }}
          max={9999}
          min={0}
          startValue={1}
          placeholder={placeholder}
          disabled={saving}
          value={multiplePartsMap[part.partId] || ''}
          onChange={(qty) => {
            updateMultiPartsMap(part.partId, typeof qty === 'number' ? qty : undefined);
          }}
        />
        <Button
          loading={(saving && !savingSingle && !!multiplePartsMap[part.partId]) || savingSingle === part.partId}
          onClick={() => addSingleCatalogItem(part)}
          disabled={saving || !multiplePartsMap[part.partId] || disabled}
          variant="outline"
        >
          Add
        </Button>
      </Flex>
    }
  />
);
