// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { Button, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Link, useNavigate } from 'react-router-dom';

import { NewPasswordInput } from 'app/Layout/HeaderBar/ProfileDropdown/ResetPasswordModal/NewPasswordInput';

import { useConfirmUser, type InvitationType } from '../auth/restCalls';
import { AuthFormWrapper } from '../components/AuthFormWrapper';
import type { LoginErrorCode } from '../components/LoginErrorAlert';

type Props = {
  inviteSourceName?: string | null;
  type: InvitationType;
  id: string;
  code: string;
};

export const Join = ({ inviteSourceName, type, id, code }: Props) => {
  const { confirm, confirming } = useConfirmUser(true);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [errorCode, setErrorCode] = useState<LoginErrorCode>();
  const navigate = useNavigate();

  const form = useForm<{
    password: string;
    confirmationCode: string;
  }>({
    initialValues: {
      password: '',
      confirmationCode: code,
    },
  });

  const handleSubmit = (body: typeof form.values) => {
    confirm(id, type, body)
      .then(() => navigate('/auth/login', { state: { inviteConfirm: true } }))
      .catch(({ message, code: errCode }: any) => setErrorCode(message ?? errCode));
  };

  return (
    <AuthFormWrapper
      title={`You have been invited to join ${inviteSourceName}`}
      errorCode={errorCode}
      onCloseError={() => setErrorCode(undefined)}
      form={form}
      onSubmit={handleSubmit}
    >
      <NewPasswordInput
        loading={confirming}
        setPasswordIsValid={setPasswordIsValid}
        {...form.getInputProps('password')}
      />
      <Button type="submit" fullWidth loading={confirming} disabled={!passwordIsValid} color="violet.9">
        Join {type}
      </Button>
      <Text c="dimmed" ta="center" fz="sm">
        Already have an account?{' '}
        <Link to="/auth/login" className="link">
          <Text c="violet.9" span>
            Login here.
          </Text>
        </Link>
      </Text>
    </AuthFormWrapper>
  );
};
