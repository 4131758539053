// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { Button, Text, useMantineTheme } from '@mantine/core';
import { Link } from 'react-router-dom';

import { extractErrorMessage } from 'helpers/extractError';
import { useQuery } from 'hooks/useQuery';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';

import { AuthFormWrapper } from './components/AuthFormWrapper';
import type { LoginErrorCode } from './components/LoginErrorAlert';

export const VerifyEmailPrompt = () => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const [verificationResent, setVerificationResent] = useState(false);
  const { apiCall: resendInvite } = useWrappedGet('admin/authentication/sendInvitation​', { dontAlertOnError: true });
  const query = useQuery();
  const isUnverified = query.get('isUnverified');
  const email = query.get('email');
  const [errorCode, setErrorCode] = useState<LoginErrorCode | string | undefined>(
    isUnverified ? 'EmailNotVerified' : undefined,
  );

  const handleResendEmail = async () => {
    if (email) {
      try {
        setLoading(true);
        await resendInvite({ params: { email } });
        setVerificationResent(true);
      } catch (error) {
        setErrorCode(extractErrorMessage(error));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <AuthFormWrapper
      title="Verify Email Address"
      onSubmit={handleResendEmail}
      errorCode={errorCode}
      onCloseError={() => setErrorCode(undefined)}
      successMessage={verificationResent ? `An email verification link has been sent to ${email}!` : undefined}
      onCloseSuccess={() => setVerificationResent(false)}
    >
      <Text>You must verify your email address to login to your account.</Text>
      <Button type="submit" fullWidth color={`${theme.primaryColor}.9`} loading={loading}>
        Resend email
      </Button>
      <Text ta="center">
        Entered the wrong email address?
        <Link className="link" to="/auth/register">
          Register with a different email.
        </Link>
      </Text>
    </AuthFormWrapper>
  );
};
