// Copyright ©️ 2024 eVolve MEP, LLC
import type { User } from 'types/types-api';

import { isNil } from './isNotNil';

export const validateUserNameOrEmail = (
  user: Pick<User, 'userFirstName' | 'userLastName' | 'userEmail'> | undefined | null,
): string => {
  if (isNil(user)) {
    return '';
  }
  if (user.userFirstName && user.userLastName) return `${user.userFirstName} ${user.userLastName}`;
  return (user.userFirstName ?? user.userEmail) || '';
};
