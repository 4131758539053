// Copyright ©️ 2024 eVolve MEP, LLC
import { Badge, Card, Flex, Text, Title, Tooltip } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';

import { useInsights } from './useInsights';

type TrendOptions = 'up' | 'down';

export const InsightsStats = () => {
  const { params, displayedData: data } = useInsights();
  const timeframe =
    params.timeFrame !== 'Date Range' && params.timeFrame !== 'Specific Date'
      ? params.timeFrame?.replace('Last', '').replace('This', '').replace(' ', '').toLowerCase()
      : params.timeFrame.toLowerCase();

  const getTrendIcon = (percentageChange: number, isGood: TrendOptions) => {
    if (percentageChange === 0) {
      return (
        <Text c="gray.4" mr={4}>
          No change
        </Text>
      );
    }
    return percentageChange <= 0 ? (
      <>
        <EvolveIcon icon="TrendingDown" color={isGood === 'down' ? 'green.5' : 'red.5'} />
        <Text fw={600} c={isGood === 'down' ? 'green.5' : 'red.5'} mr={4}>
          {percentageChange.toString().replace('-', '')}%
        </Text>
      </>
    ) : (
      <>
        <EvolveIcon icon="TrendingUp" color={isGood === 'up' ? 'green.5' : 'red.5'} />
        <Text fw={600} c={isGood === 'up' ? 'green.5' : 'red.5'} mr={4}>
          {percentageChange.toString().replace('-', '')}%
        </Text>
      </>
    );
  };

  const getTrendInfoText = () => {
    if (params.timeFrame === 'Specific Date') {
      return 'vs same date previous year';
    }
    if (params.timeFrame === 'Date Range') {
      return 'vs same date range previous year';
    }
    return `vs previous ${timeframe}`;
  };

  return (
    <Flex>
      <Card withBorder m="md" style={{ flex: 1 }}>
        <Text size="sm" c="gray.6">
          {params.selectedRecordType === 'Work Orders' ? 'Completed Work Orders' : 'Completed Work Requests'}
        </Text>
        {data && (
          <Flex direction="column" justify="space-between">
            <Title order={1}>
              {'completedWorkOrders' in data ? data.completedWorkOrders : data.completedWorkRequests}
            </Title>
            <Flex>
              {'percentageChangeFromPreviousTimeFrameForCompletedWorkOrders' in data ? (
                <>{getTrendIcon(data.percentageChangeFromPreviousTimeFrameForCompletedWorkOrders, 'up')}</>
              ) : (
                <>{getTrendIcon(data.percentageChangeFromPreviousTimeFrameForCompletedWorkRequests, 'up')}</>
              )}
              <Text c="gray.6">{getTrendInfoText()}</Text>
            </Flex>
          </Flex>
        )}
      </Card>

      <Card withBorder m="md" style={{ flex: 1 }}>
        <Text size="sm" c="gray.6">
          {params.selectedRecordType === 'Work Orders' ? 'Late Work Orders' : 'Late Work Requests'}
        </Text>
        {data && (
          <Flex direction="column" justify="space-between">
            <Flex align="center">
              <Title order={1}>{'lateWorkOrders' in data ? data.lateWorkOrders : data.lateWorkRequests}</Title>
              <Badge radius={2} m="xs" color="gray" size="lg">
                {'percentageOfLateVersusTotalWorkOrders' in data
                  ? `${data.percentageOfLateVersusTotalWorkOrders}%`
                  : `${data.percentageOfLateVersusTotalWorkRequests}%`}
              </Badge>
            </Flex>
            <Flex>
              {'percentageChangeFromPreviousTimeFrameForLateWorkOrders' in data ? (
                <>{getTrendIcon(data.percentageChangeFromPreviousTimeFrameForLateWorkOrders, 'down')}</>
              ) : (
                <>{getTrendIcon(data.percentageChangeFromPreviousTimeFrameForLateWorkRequests, 'down')}</>
              )}
              <Text c="gray.6">{getTrendInfoText()}</Text>
            </Flex>
          </Flex>
        )}
      </Card>

      <Card withBorder m="md" style={{ flex: 1 }}>
        <Flex align="center">
          <Text size="sm" c="gray.6" mr={4}>
            {params.selectedRecordType === 'Work Orders' ? 'Avg. Manufacturing Lead Time' : 'Work Request Rejections'}
          </Text>
          {params.selectedRecordType === 'Work Orders' && (
            <Tooltip
              label="Time from creating the work order to production complete"
              color="gray"
              style={{ fontSize: '12px' }}
            >
              <EvolveIcon icon="Info" size="sm" color="gray.6" />
            </Tooltip>
          )}
        </Flex>
        {data && (
          <Flex direction="column" justify="space-between">
            <Title order={1}>
              {'averageManufacturingLeadTime' in data
                ? `${data.averageManufacturingLeadTime} days`
                : data.rejectedWorkRequests}
            </Title>
            <Flex>
              {'percentageChangeFromPreviousTimeFrameForAverageManufacturingLeadTime' in data ? (
                <>{getTrendIcon(data.percentageChangeFromPreviousTimeFrameForAverageManufacturingLeadTime, 'down')}</>
              ) : (
                <>{getTrendIcon(data.percentageChangeFromPreviousTimeFrameForRejectedWorkRequests, 'down')}</>
              )}
              <Text c="gray.6">{getTrendInfoText()}</Text>
            </Flex>
          </Flex>
        )}
      </Card>

      <Card withBorder m="md" style={{ flex: 1 }}>
        <Flex align="center">
          <Text size="sm" c="gray.6" mr={4}>
            {params.selectedRecordType === 'Work Orders' ? 'Avg. Production Lead Time' : 'Avg. Order Lead Time'}
          </Text>
          <AgGridStyleTooltip
            openDelay={0}
            withArrow
            label={
              params.selectedRecordType === 'Work Orders'
                ? 'Time from production start to production complete'
                : 'Time from work request submission to delivery'
            }
          >
            <EvolveIcon icon="Info" size="sm" color="gray.6" />
          </AgGridStyleTooltip>
        </Flex>
        {data && (
          <Flex direction="column" justify="space-between">
            <Title order={1}>
              {'averageProductionLeadTime' in data
                ? `${data.averageProductionLeadTime} days`
                : `${data.averageOrderLeadTime} days`}
            </Title>
            <Flex>
              {'percentageChangeFromPreviousTimeFrameForAverageProductionLeadTime' in data ? (
                <>{getTrendIcon(data.percentageChangeFromPreviousTimeFrameForAverageProductionLeadTime, 'down')}</>
              ) : (
                <>{getTrendIcon(data.percentageChangeFromPreviousTimeFrameForAverageOrderLeadTime, 'down')}</>
              )}
              <Text c="gray.6">{getTrendInfoText()}</Text>
            </Flex>
          </Flex>
        )}
      </Card>
    </Flex>
  );
};
