// Copyright ©️ 2025 eVolve MEP, LLC
import { useMemo, useState } from 'react';

import { ActionIcon, Box, Flex, Text, useMantineTheme } from '@mantine/core';
import { format, parseISO } from 'date-fns';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { useDocumentsCache } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/useDocumentsCache';
import type { DownloadPath, User } from 'types/types-api';

export const CompanyDocument = ({ downloadPath, user }: { downloadPath: DownloadPath; user: User }) => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const { getPresignedUrl, presigningInProgress } = useDocumentsCache();
  const name = useMemo(
    () =>
      downloadPath.document
        .split('/')
        .find((el) => el.includes('.'))
        ?.replace(/\.zip/g, '')
        .split('-')
        .join(' '),
    [downloadPath.document],
  );
  const download = () => {
    if (presigningInProgress) return;
    setLoading(true);
    void getPresignedUrl({
      objectKey: downloadPath.document,
      expirationHours: 24,
      requestedBy: user.userId,
      verb: 'GET',
    })
      .then(({ preSignedURL }) => {
        window.open(preSignedURL, '_blank');
      })
      .finally(() => setLoading(false));
  };
  const disabled = presigningInProgress && !loading;
  return (
    <Box style={{ cursor: 'pointer' }} onClick={download}>
      <Flex gap="xs" align="center">
        <ActionIcon variant="transparent" loading={loading} disabled={disabled} color="primary">
          <EvolveIcon icon="Download" />
        </ActionIcon>
        <Text fz="sm" c={disabled ? 'dimmed' : theme.primaryColor} lh={1.25}>
          {name}
        </Text>
        <Text c="dimmed" fz="xs" lh={1.25}>
          Uploaded {format(parseISO(`${downloadPath.updatedOn}Z`), 'MM/dd/yyyy')}
        </Text>
      </Flex>
    </Box>
  );
};
