// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { ActionIcon } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedDelete } from 'hooks-api/useWrappedApiCall';

import type { RenderableDocument } from './types';

type Props = {
  document: RenderableDocument;
  refresh: () => void;
};

export const DeleteAttachment = ({ document, refresh }: Props) => {
  const [opened, setOpened] = useState(false);
  const { apiCall: deleteDocument, loading } =
    // URL will be overridden on the apiCall
    useWrappedDelete<unknown>('shop/...Document');

  const attachmentType = isNotNil(document.externalUrl) ? 'URL attachment' : 'file attachment';

  const onConfirm = async () => {
    setOpened(false);
    if ('workRequestId' in document) {
      await deleteDocument({ url: `shop/workRequestDocument/${document.workRequestDocumentId}` });
    } else if ('workOrderId' in document) {
      await deleteDocument({ url: `shop/workOrderDocument/${document.workOrderDocumentId}` });
    } else if ('taskId' in document) {
      await deleteDocument({ url: `shop/taskDocument/${document.taskDocumentId}` });
    }
    notifications.show({
      title: 'Successfully deleted',
      message: `Deleted ${attachmentType}.`,
      color: 'green',
    });
    refresh();
  };

  return (
    <>
      <ActionIcon onClick={() => setOpened(true)} variant="transparent" loading={loading}>
        <EvolveIcon className={loading ? undefined : 'show-on-row-hover'} icon="Delete" />
      </ActionIcon>
      <ConfirmationModal
        opened={opened}
        onClose={() => setOpened(false)}
        loading={loading}
        buttonColor="red"
        onConfirm={onConfirm}
      >
        Are you sure you want to delete this {attachmentType}?
      </ConfirmationModal>
    </>
  );
};
