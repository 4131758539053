// Copyright ©️ 2024 eVolve MEP, LLC
import { Flex, Text } from '@mantine/core';
import type { ColDef } from 'ag-grid-enterprise';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { lockedColDef } from 'helpers/ag-grid/baseColumnDef';
import type { WorkCell, WorkCellTaskType } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

import { EditWorkCellMenu } from './EditWorkCellMenu';
import { UnmapTaskType } from './UnmapTaskType';

export const getWorkCellsColumnDef = ({
  isDraftView,
  splitViewIsOpen,
}: {
  isDraftView: boolean;
  splitViewIsOpen: boolean;
}): ColDef<WorkCell>[] => [
  {
    ...lockedColDef(),
    width: 32,
    cellRenderer: 'agGroupCellRenderer',
    cellStyle: { paddingRight: 0 },
  },
  {
    headerName: splitViewIsOpen ? (isDraftView ? 'New Work Cell Setup' : 'Existing Work Cell Setup') : 'Work Cells',
    valueGetter: ({ data }) => `${data?.workCellName}${data?.workCellDescription}`,
    tooltipValueGetter: ({ data }) =>
      `${data?.workCellName}${data?.workCellDescription.trim() ? `: ${data.workCellDescription}` : ''}`,
    flex: 1,
    resizable: false,
    sortable: false,
    cellRenderer: CellRenderer<WorkCell>(({ data }) => (
      <Flex align="center" gap="xs">
        <EvolveIcon icon="WorkCell" light />
        <Text>{data.workCellName}</Text>
        <Text c="dimmed">{data.workCellDescription}</Text>
      </Flex>
    )),
    rowDrag: true,
  },
  {
    ...lockedColDef('right'),
    width: 50,
    cellRenderer: CellRenderer<WorkCell>(({ node }) => (
      <EditWorkCellMenu workCell={node.data} workCellUpdated={(d) => node.updateData(d)} canDelete={isDraftView} />
    )),
  },
];

export const getWorkCellTaskTypeColDef = (canEdit: boolean): ColDef<WorkCellTaskType>[] => [
  {
    field: 'taskTypeName',
    tooltipField: 'taskTypeName',
    flex: 1,
    resizable: false,
    cellRenderer: CellRenderer<WorkCellTaskType>(({ value }) => (
      <Flex align="center" gap="xs">
        <EvolveIcon icon="WorkCellTask" light />
        {value}
      </Flex>
    )),
    sortable: false,
  },
  {
    ...lockedColDef('right'),
    width: 50,
    cellRenderer: CellRenderer<WorkCellTaskType>(({ data }) => <UnmapTaskType workCellTaskType={data} />),
    hide: !canEdit,
  },
];
