// Copyright ©️ 2024 eVolve MEP, LLC
import { Flex, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { isNil } from 'helpers/isNotNil';
import type { WorkRequest } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import type { WorkCellTask } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';
import type { WorkOrder } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';

import { useUploadDocuments } from './useUploadDocuments';

type Props = {
  opened: boolean;
  onClose: () => void;
  refresh: () => void;
} & (
  | {
      workRequest: WorkRequest;
      workOrder?: never;
      workCellTask?: never;
    }
  | {
      workOrder: WorkOrder;
      workRequest?: never;
      workCellTask?: never;
    }
  | {
      workCellTask: WorkCellTask;
      workOrder?: never;
      workRequest?: never;
    }
);

export const AddLinkModal = ({ opened, refresh, onClose, ...props }: Props) => {
  const { addLink, saving } = useUploadDocuments();
  const form = useForm<{ externalUrl: string }>({
    initialValues: {
      externalUrl: '',
    },
    validate: {
      externalUrl: (u) => isNil(u) || u.length === 0,
    },
  });

  const onSubmit = ({ externalUrl }: typeof form.values) => {
    void addLink(externalUrl, props)
      .then(refresh)
      .then(() => form.reset())
      .then(onClose);
  };

  return (
    <ConfirmationModal
      title={`Add web link to ${
        props.workOrder?.workOrderName ?? props.workRequest?.workRequestIdentifier ?? props.workCellTask?.taskName
      }`}
      opened={opened}
      onClose={onClose}
      loading={saving}
      disabled={!form.isValid()}
      onConfirm={() => onSubmit(form.values)}
      confirmationText="Add"
      closeButtonProps={{ tabIndex: -1 }}
    >
      <form onSubmit={form.onSubmit(onSubmit)} style={{ width: '100%' }}>
        <Flex gap="xs" style={{ width: '100%' }}>
          <TextInput
            placeholder="URL"
            leftSection={<EvolveIcon size="sm" icon="ExternalURL" />}
            disabled={saving}
            style={{ width: '100%' }}
            {...form.getInputProps('externalUrl')}
            autoFocus
          />
        </Flex>
      </form>
    </ConfirmationModal>
  );
};
