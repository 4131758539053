// Copyright ©️ 2025 eVolve MEP, LLC
import type { CustomFilterProps } from 'ag-grid-react';

import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { isNotNil } from 'helpers/isNotNil';

import type { HandledFilterModel } from './convertAgGridRequest';

type Props<T extends string> = {
  label: string;
  data: {
    value: T;
    label: string;
  }[];
  defaultValue?: T;
  filterKey?: string;
};

type FilterProps<T extends string> = Omit<CustomFilterProps<any, T>, 'model' | 'onModelChange'> & {
  model: (HandledFilterModel<T> & { filterType: 'custom' }) | undefined;
  onModelChange: (newModel: FilterProps<T>['model']) => void;
};

export const CustomSelectComponent = <T extends string>({ label, defaultValue, data, filterKey }: Props<T>) =>
  function ({ model, onModelChange }: FilterProps<T>) {
    return (
      <WrappedSelect<T>
        p="sm"
        value={model?.value ?? defaultValue}
        defaultValue={defaultValue}
        label={label}
        data={data}
        classNames={{
          // Necessary for AG Grid to not close the filter
          // when we click on the popover
          dropdown: 'ag-custom-component-popup',
        }}
        onChange={(value) => {
          if (isNotNil(value)) {
            onModelChange({
              filterType: 'custom',
              filterKey,
              value,
            });
          }
        }}
      />
    );
  };
