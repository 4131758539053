// Copyright ©️ 2025 eVolve MEP, LLC
import { Avatar, type AvatarProps } from '@mantine/core';

import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { useUsersInfo } from 'hooks/useUsersInfo';
import type { UserId } from 'types/types-api';

type Props = { userId: UserId };

export const UserAvatar = ({ userId, ...props }: Props & AvatarProps) => {
  const { cachedUserData } = useUsersInfo();

  return (
    <AgGridStyleTooltip label={cachedUserData.get(userId)?.completeName} withArrow openDelay={0}>
      <Avatar radius="100%" size="sm" {...props} />
    </AgGridStyleTooltip>
  );
};
