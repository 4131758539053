// Copyright ©️ 2025 eVolve MEP, LLC
import React, { type ReactNode, useEffect, useMemo, useState } from 'react';

import { Loader } from '@mantine/core';

import { useUser } from 'app/UserContext';
import type { WithRequired } from 'helpers/helper-types';
import { isNil, isNotNil } from 'helpers/isNotNil';

import type { DocumentId } from '../WorkRequestOrderDetail/Attachments/types';
import {
  getObjectKeyForDocument,
  useDocumentsCache,
  type GetPresignedUrlResponse,
} from '../WorkRequestOrderDetail/Attachments/useDocumentsCache';

/** Returns a presigned URL for a given `DocumentID` or `StoragePath` */
export const useDocumentImage = (documentId?: DocumentId, storagePath?: string) => {
  const { user } = useUser();
  const { attachmentToDocumentMap, getPresignedUrl } = useDocumentsCache();
  const [presignedRes, setPresignedRes] = useState<GetPresignedUrlResponse>();
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const objectKey = useMemo(() => {
    if (isNotNil(storagePath)) return storagePath;
    if (isNotNil(documentId)) {
      const document = attachmentToDocumentMap[documentId];
      if (isNil(document)) return null;
      return getObjectKeyForDocument(document);
    }
    return null;
  }, [attachmentToDocumentMap, documentId, storagePath]);

  useEffect(() => {
    if (isNotNil(imageUrl)) return;
    if (isNotNil(objectKey)) {
      void getPresignedUrl({
        objectKey,
        expirationHours: 1,
        requestedBy: user.userId,
        verb: 'GET',
      }).then(setPresignedRes);
    }
  }, [getPresignedUrl, imageUrl, objectKey, user.userId]);

  useEffect(() => {
    if (presignedRes?.objectKey === objectKey) {
      if (isNil(imageUrl)) {
        setImageUrl(presignedRes.preSignedURL);
      }
    } else if (isNotNil(imageUrl)) {
      setImageUrl(null);
    }
  }, [imageUrl, objectKey, presignedRes]);

  return imageUrl;
};

export const useSelectImage = () => {
  const [image, onImageSelected] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string>();
  useEffect(() => {
    if (isNotNil(image)) {
      const objectUrl = URL.createObjectURL(image);
      setPreviewUrl(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
    return undefined;
  }, [image]);
  return useMemo(
    () => ({
      image,
      onImageSelected,
      previewUrl,
    }),
    [image, previewUrl],
  );
};

type ImageProps = WithRequired<
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  'alt'
>;

export const DocumentImage = ({
  documentId,
  storagePath,
  fallback,
  alt,
  children,
  ...props
}: { documentId?: DocumentId; storagePath?: string; fallback?: ReactNode; children?: ReactNode } & Omit<
  ImageProps,
  'src'
>) => {
  const imageUrl = useDocumentImage(documentId, storagePath);
  const [loading, setLoading] = useState(isNotNil(documentId) || isNotNil(storagePath));
  if (!loading && isNil(imageUrl)) return fallback;
  return (
    <>
      {loading && <Loader opacity={0.5} />}
      {isNotNil(imageUrl) && (
        <>
          <img
            {...props}
            src={imageUrl}
            alt={alt}
            style={{
              objectFit: 'contain',
              ...props.style,
            }}
            onLoad={(e) => {
              setLoading(false);
              props.onLoad?.(e);
            }}
            width={loading ? 0 : (props.width ?? '100%')}
            height={loading ? 0 : (props.height ?? '100%')}
          />
          {children}
        </>
      )}
    </>
  );
};
