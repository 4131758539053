// Copyright ©️ 2025 eVolve MEP, LLC
import { useCallback, useMemo } from 'react';

import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';
import rateLimit from 'axios-rate-limit';
import { jwtDecode } from 'jwt-decode';

import { getEnvVars } from 'envVars';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { getLocalStorage, setLocalStorage } from 'hooks/useLocalStorage';
import { doSignOut } from 'modules/Authentication/auth/signOutHelper';
import type { RefreshReturn } from 'modules/Authentication/types';

const axiosClient = rateLimit(axios.create(), { maxRPS: 9 });

export type EvolveURL =
  | `admin/${string}`
  | `design/${string}`
  | `shop/${string}`
  | `moab/${string}`
  | `docmgt/${string}`
  | `field/${string}`;

type ApiVersion = 'v1';
export const getBaseApiUrl = async (version: ApiVersion = 'v1') =>
  getEnvVars().then((vars) => {
    const { REACT_APP_API_BASE_URL } = vars;
    if (!REACT_APP_API_BASE_URL) {
      throw new Error('Missing API URL');
    }
    return `${vars.REACT_APP_API_BASE_URL}/${version}`;
  });

export type AdditionalRequestConfig<D = any> = Omit<AxiosRequestConfig<D>, 'url'> & {
  url?: EvolveURL;
};

type CallType = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
type NoBodyCalls = 'GET' | 'DELETE';

const methodMap = {
  GET: axiosClient.get,
  POST: axiosClient.post,
  PATCH: axiosClient.patch,
  PUT: axiosClient.put,
  DELETE: axiosClient.delete,
} as const;

let accessTokenPromise: Promise<string> | null = null;

const refreshAccessToken = async () => {
  const refreshToken = getLocalStorage('EVOLVE_REFRESH_TOKEN');
  if (isNil(refreshToken)) {
    doSignOut();
    return null;
  }
  const baseUrl = await getBaseApiUrl();
  if (!accessTokenPromise) {
    accessTokenPromise = axios
      .post<{ refreshToken: string }, AxiosResponse<RefreshReturn>>(`${baseUrl}/admin/authentication/refreshToken`, {
        refreshToken,
      })
      .then(async ({ data: { accessToken } }) => {
        setLocalStorage('EVOLVE_ACCESS_TOKEN', accessToken);
        return accessToken;
      })
      .catch((err) => {
        doSignOut();
        throw err;
      })
      .finally(() => (accessTokenPromise = null));
  }
  return accessTokenPromise;
};

export const getToken = async () => {
  const accessToken = getLocalStorage('EVOLVE_ACCESS_TOKEN');
  if (isNil(accessToken)) return null;
  const decoded = jwtDecode(accessToken);
  const expired = isNotNil(decoded.exp) && decoded.exp * 1000 < Date.now();
  if (!expired) return accessToken;
  return refreshAccessToken();
};

/**
 * Global function to get the JWT token for the logged in user
 * This is intended to be used by 360 Sync, which does not have import access to our code,
 * but whose code runs inside of ours all the same.
 */
// @ts-expect-error global function
window.getJwtToken = getToken;

const responseMapper = <ReturnType>(res: AxiosResponse<ReturnType>): ReturnType =>
  res.status === 204 ? (undefined as ReturnType) : res.data ?? (res as ReturnType);

const callBuilder = async <ReturnType, DataType = any>(
  params: {
    path: EvolveURL;
    config?: AdditionalRequestConfig<DataType>;
  } & (
    | {
        method: Extract<CallType, NoBodyCalls>;
        body?: never;
      }
    | {
        method: Exclude<CallType, NoBodyCalls>;
        body: DataType;
      }
  ),
  retry = false,
): Promise<ReturnType> => {
  const { path, config: customConfig, method, body } = params;
  const endpoint = await getBaseApiUrl();

  // Not all endpoints require auth. If we don't have a JWT, perform the call anyway.
  const token = await getToken();

  const headers = {
    Authorization: token ? `Bearer ${token}` : '',
    Accept: '*/*',
  };
  const config = {
    ...customConfig,
    headers,
  };
  const url = `${endpoint}/${path}`;
  try {
    if (method === 'GET' || method === 'DELETE') {
      return await methodMap[method](url, config).then(responseMapper);
    }
    return await methodMap[method](url, body, config).then(responseMapper);
  } catch (error: any) {
    const { status } = error;
    if (!retry && (status === 401 || status === 403)) {
      // Catch potential race conditions on Access Token expiration,
      // or handle a now-missing access/refresh token.
      return refreshAccessToken().then(() => callBuilder(params, true));
    }
    throw error;
  }
};

export const useEvolveApi = () => {
  const get = useCallback(
    <T, D = any>(path: EvolveURL, config?: AdditionalRequestConfig<D>) =>
      callBuilder<T, D>({ path, config, method: 'GET' }),
    [],
  );

  const post = useCallback(
    <T, D = any>(path: EvolveURL, body: D, config?: AdditionalRequestConfig<D>) =>
      callBuilder<T, D>({ path, body, config, method: 'POST' }),
    [],
  );

  const patch = useCallback(
    <T, D = any>(path: EvolveURL, body: D, config?: AdditionalRequestConfig<D>) =>
      callBuilder<T, D>({ path, body, config, method: 'PATCH' }),
    [],
  );

  const put = useCallback(
    <T, D = any>(path: EvolveURL, body: D, config?: AdditionalRequestConfig<D>) =>
      callBuilder<T, D>({ path, body, config, method: 'PUT' }),
    [],
  );

  const apiDelete = useCallback(
    <T, D = any>(path: EvolveURL, config?: AdditionalRequestConfig<D>) =>
      callBuilder<T, D>({ path, config, method: 'DELETE' }),
    [],
  );

  return useMemo(() => ({ get, post, patch, put, apiDelete }), [apiDelete, get, patch, post, put]);
};
