// Copyright ©️ 2024 eVolve MEP, LLC
import React, { type ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useGeneralContext } from 'helpers/useGeneralContext';
import { validateUserNameOrEmail } from 'helpers/userHelpers';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';
import type { DocumentId } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/types';
import type { User, UserId } from 'types/types-api';

type CachedUser = { completeName: string; photo: DocumentId | null };

type UsersInfoContextType = {
  loading: boolean;
  getUserData: (userId: UserId) => Promise<CachedUser>;
  getUsersData: (userIds: UserId[]) => Promise<CachedUser[]>;
  cachedUserData: Map<UserId, CachedUser>;
};

const UsersInfoContext = React.createContext<UsersInfoContextType | undefined>(undefined);

const promises: Map<UserId, Promise<User>> = new Map();

export const UsersInfoProvider = ({ children }: { children: ReactNode }) => {
  const cacheRef = useRef<UsersInfoContextType['cachedUserData']>(new Map());
  const [cachedUserData, setCachedUserData] = useState<UsersInfoContextType['cachedUserData']>(cacheRef.current);
  const { apiCall: getUserApiCall, loading } = useWrappedGet<User>('admin/user', { lazy: true });

  useEffect(() => {
    cacheRef.current = cachedUserData;
  }, [cachedUserData]);

  const getUserData = useCallback<UsersInfoContextType['getUserData']>(
    async (userId) => {
      const cachedUser = cacheRef.current.get(userId);
      if (cachedUser) return cachedUser;
      const promise =
        promises.get(userId) ??
        getUserApiCall({
          url: `admin/user/${userId}`,
        });
      promises.set(userId, promise);
      const user = await promise;
      const newRecord: CachedUser = { completeName: validateUserNameOrEmail(user), photo: user.userPhotoId };
      setCachedUserData((cache) => cache.set(userId, newRecord));
      return newRecord;
    },
    [getUserApiCall],
  );

  const getUsersData = useCallback<UsersInfoContextType['getUsersData']>(
    (userIds) => Promise.all([...new Set(userIds)].map(getUserData)),
    [getUserData],
  );

  const value = useMemo(
    () => ({
      loading,
      getUserData,
      getUsersData,
      cachedUserData,
    }),
    [cachedUserData, getUserData, getUsersData, loading],
  );

  return <UsersInfoContext.Provider value={value}>{children}</UsersInfoContext.Provider>;
};

export const useUsersInfo = () => useGeneralContext(UsersInfoContext, 'UsersInfo');
