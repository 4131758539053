// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { ActionIcon, Flex, Menu, Text, useMantineTheme } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

import { AddEditVariantPopover } from './AddEditVariantPopover';
import type { PartAttribute, SelectVariant } from './types';

type Props = {
  partAttribute: PartAttribute;
  variant: SelectVariant;
  setDeletingVariant: (variant: SelectVariant) => void;
  refresh: () => void;
};

export const VariantListItem = ({ partAttribute, variant, setDeletingVariant, refresh }: Props) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [editing, setEditing] = useState(false);
  return (
    <>
      <Flex
        className="hover-title"
        align="center"
        justify="space-between"
        style={{ height: 34 }}
        bg={editing ? `${theme.primaryColor}.0` : undefined}
      >
        <Flex px="md" py={1} align="center" gap="sm">
          <Text>{variant.textValue}</Text>
          {!!variant.codeValue && <Text c="dimmed">({variant.codeValue})</Text>}
        </Flex>
        <Menu opened={opened} onChange={setOpened}>
          <Menu.Target>
            <ActionIcon mx={4}>
              <EvolveIcon className={opened || editing ? undefined : 'hover-title--on'} icon="MoreOptsVert" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={() => setEditing(true)}>Edit</Menu.Item>
            <Menu.Item onClick={() => setDeletingVariant(variant)} c="red">
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
      <AddEditVariantPopover
        opened={editing}
        selectVariant={variant}
        refresh={refresh}
        partAttribute={partAttribute}
        onClose={() => setEditing(false)}
      />
    </>
  );
};
