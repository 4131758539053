// Copyright ©️ 2025 eVolve MEP, LLC
import { ActionIcon } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedDelete, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { Entitlement } from 'types/types-api';

import type { RoleEntry, UserRole } from './types';

type Props = {
  refresh: () => void;
} & (
  | {
      entitlement: Entitlement;
      role?: never;
    }
  | {
      entitlement?: never;
      role: UserRole;
    }
);

export const RemoveEntitlementOrRole = ({ entitlement, role, refresh }: Props) => {
  const { apiCall: unassignEntitlement, loading: unassigningEnt } = useWrappedDelete(
    `admin/entitlement/${entitlement?.entitlementId}`,
  );
  const { apiCall: unassignRole, loading: unassigningRole } = useWrappedPost<
    unknown,
    { userRoleAssignments: RoleEntry[] }
  >('admin/Role/remove');
  const onDelete = () => {
    if (isNotNil(entitlement)) {
      void unassignEntitlement().then(() => {
        notifications.show({
          title: 'Successfully removed',
          message: `${entitlement.productPool.label} license removed`,
          color: 'green',
        });
        refresh();
      });
    } else if (isNotNil(role)) {
      void unassignRole({
        userRoleAssignments: [
          {
            userId: role.userId,
            roleId: role.roleId,
          },
        ],
      }).then(() => {
        notifications.show({
          title: 'Successfully unassigned',
          message: `${role.displayName} role unassigned`,
          color: 'green',
        });
        refresh();
      });
    }
  };
  return (
    <ActionIcon loading={unassigningEnt || unassigningRole} size="sm" onClick={onDelete}>
      <EvolveIcon className="show-on-row-hover" icon="Delete" />
    </ActionIcon>
  );
};
