// Copyright ©️ 2024 eVolve MEP, LLC
import Connections from '../images/nav-icons/sync-icons/connections.svg?react';
import Logs from '../images/nav-icons/sync-icons/logs.svg?react';
import Sync from '../images/nav-icons/sync-icons/sync.svg?react';
import Workflows from '../images/nav-icons/sync-icons/workflow.svg?react';
import Dashboard from '../images/nav-icons/sync-icons/workflows.svg?react';

export const syncIcons = {
  Sync,
  Connections,
  Dashboard,
  Logs,
  Workflows,
};
