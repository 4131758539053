// Copyright ©️ 2025 eVolve MEP, LLC
import './headerSearch.css';
import { useState } from 'react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { isNotNil } from 'helpers/isNotNil';
import { type FacilityId, useFacilities } from 'hooks/projectsAndFacilities/useFacilities';
import { type ProjectId, useProjects } from 'hooks/projectsAndFacilities/useProjects';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';

type Props = {
  onBlurAfterSelection?: () => void;
};

export const HeaderSearch = ({ onBlurAfterSelection }: Props) => {
  const { selectProject, selectFacility } = useSelectedProjectFacility();
  const { projects } = useProjects();
  const { facilities } = useFacilities();
  const [selected, setSelected] = useState<ProjectId | FacilityId | null>(null);

  const onChange = (id: ProjectId | FacilityId | null) => {
    if (!id) return;
    setSelected(id);
    const project = projects.find((p) => p.projectId === id);
    if (isNotNil(project)) {
      selectProject(project);
      return;
    }
    const facility = facilities.find((f) => f.facilityId === id);
    if (isNotNil(facility)) {
      selectFacility(facility);
    }
  };

  return (
    <WrappedSelect<ProjectId | FacilityId>
      className="header-search"
      searchable
      value={selected}
      placeholder="Open Facilities or Projects..."
      onChange={onChange}
      onBlur={() => {
        if (isNotNil(selected)) {
          onBlurAfterSelection?.();
        }
        setSelected(null);
      }}
      leftSection={<EvolveIcon icon="Search" />}
      maxDropdownHeight={600}
      autoComplete="off"
      comboboxProps={{
        withinPortal: false,
      }}
      data={[
        {
          group: 'Facilities',
          items: [
            ...facilities
              .sort((a, b) => a.facilityName.localeCompare(b.facilityName))
              .map((f) => ({
                value: f.facilityId,
                label: f.facilityName,
              })),
            ...(facilities.length === 0
              ? [
                  {
                    value: 'no-facility',
                    label: 'No facilities found',
                    disabled: true,
                  } as const,
                ]
              : []),
          ],
        },
        {
          group: 'Projects',
          items: [
            ...projects
              .sort((a, b) => a.projectName.localeCompare(b.projectName))
              .map((p) => ({
                value: p.projectId,
                label: p.projectName,
              })),
            ...(projects.length === 0
              ? [
                  {
                    value: 'no-project',
                    label: 'No projects found',
                    disabled: true,
                  } as const,
                ]
              : []),
          ],
        },
      ]}
    />
  );
};
