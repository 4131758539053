// Copyright ©️ 2025 eVolve MEP, LLC
import { useState } from 'react';

import { ActionIcon, Menu } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { useUser } from 'app/UserContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import type { User } from 'types/types-api';

export const companyMembersBulkActions = [
  'Add licenses',
  'Reset password',
  'Add admin',
  'Remove admin',
  'Assign roles',
  'Delete',
] as const;
export type CompanyMembersBulkAction = (typeof companyMembersBulkActions)[number];

type Props = {
  user: User;
  actionSingleUser: (user: User, selectedAction: CompanyMembersBulkAction) => void;
};

export const CompanyMemberMenu = ({ user, actionSingleUser }: Props) => {
  const [opened, setOpened] = useState(false);
  const { user: myUser } = useUser();
  const { apiCall: resendInvitation, loading } = useWrappedPatch(`admin/user/${user.userId}/resendInvitation`);
  return (
    <Menu position="bottom-end" withinPortal opened={opened} onChange={setOpened}>
      <Menu.Target>
        <ActionIcon variant="transparent">
          <EvolveIcon icon="MoreOptsVert" className={opened ? undefined : 'show-on-row-hover'} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {user.inviteStatus === 'Pending' && (
          <Menu.Item
            disabled={loading}
            onClick={() => {
              void resendInvitation({}).then(() => {
                notifications.show({
                  title: 'Successfully sent',
                  message: `Invitation resent to ${user.userEmail}`,
                  color: 'green',
                });
              });
            }}
          >
            Resend Invitation
          </Menu.Item>
        )}
        {companyMembersBulkActions.map((action) => (
          <Menu.Item
            key={action}
            color={action === 'Delete' ? 'red' : undefined}
            disabled={
              ((action === 'Delete' || action === 'Remove admin') && user.userId === myUser.userId) ||
              (action === 'Reset password' && user.inviteStatus === 'Pending') ||
              (action === 'Remove admin' && !user.isAdmin) ||
              (action === 'Add admin' && user.isAdmin)
            }
            onClick={() => actionSingleUser(user, action)}
          >
            {action}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
