// Copyright ©️ 2025 eVolve MEP, LLC
import { useEffect, useState } from 'react';

import { notifications } from '@mantine/notifications';

import { useUser } from 'app/UserContext';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { WrappedMultiSelect } from 'components/Mantine/TypeSafeSelect';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { useWrappedPaginatedGet, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { ProductPool, ProductPoolId, UserId } from 'types/types-api';

type Props = {
  userIds: UserId[];
  opened: boolean;
  onClose: () => void;
  refresh: () => void;
};

export const AddLicensesModal = ({ userIds, opened, onClose, refresh }: Props) => {
  const { user } = useUser();
  const [productPools, setProductPools] = useState<ProductPool[]>([]);
  const { fetchPage, loading } = useWrappedPaginatedGet<ProductPool>('admin/productPool', {
    lazy: true,
  });
  const { apiCall: addLicensesApiCall, loading: saving } = useWrappedPost<
    unknown,
    {
      productPoolIds: ProductPoolId[];
      userIds: UserId[];
    }
  >('admin/entitlement');
  const [productPoolIds, setProductPoolIds] = useState<ProductPoolId[]>([]);

  useEffect(() => {
    if (!opened) setProductPoolIds([]);
  }, [opened]);

  useEffect(() => {
    void getAllDataFromFetcher(fetchPage, {
      params: {
        companyId: user.companyId,
      },
    }).then(setProductPools);
  }, [fetchPage, user.companyId]);

  const onSubmit = () => {
    void addLicensesApiCall({ productPoolIds, userIds }).then(() => {
      notifications.show({
        title: 'Successfully added',
        message: `Added license${productPoolIds.length === 1 ? '' : 's'} to ${userIds.length} member${
          userIds.length === 1 ? '' : 's'
        }`,
        color: 'green',
      });
      refresh();
      onClose();
    });
  };

  return (
    <ConfirmationModal
      title="Add licenses"
      opened={opened}
      onClose={onClose}
      onConfirm={onSubmit}
      disabled={productPoolIds.length === 0}
      loading={saving}
      confirmationText="Assign"
    >
      <WrappedMultiSelect
        label="Products"
        nowrap={false}
        placeholder={loading ? 'Loading...' : productPools.length === 0 ? 'No licenses found' : undefined}
        value={productPoolIds}
        onChange={setProductPoolIds}
        data={productPools.map((p) => ({
          label: `${p.label} (${p.poolAvailableQuantity} available)`,
          value: p.productPoolId,
        }))}
        disabled={saving || productPools.length === 0}
      />
    </ConfirmationModal>
  );
};
