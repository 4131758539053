// Copyright ©️ 2024 eVolve MEP, LLC
import { Fragment, useCallback, useEffect, useState } from 'react';

import { ActionIcon, Card, Divider, Flex, Loader, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { TriggerOnVisible } from 'hooks/useOnScreen';
import { useWrappedDelete, useWrappedGet, useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type {
  PartCatalogId,
  PartCategoryId,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';

import { AddAttributeModal } from './AddAttribute/AddAttributeModal';
import { AddEditVariantPopover } from './AddEditVariantPopover';
import { AttributeListItem } from './AttributeListItem';
import type { PartAttribute, SelectVariant } from './types';
import { VariantListItem } from './VariantMenu';

type Props = {
  partCatalogId: PartCatalogId;
  partCategoryId: PartCategoryId | null;
};

export const CategorySetup = ({ partCatalogId, partCategoryId }: Props) => {
  const {
    data,
    fetchNextPage,
    loading,
    setDefaultOpts,
    refetch: refreshAllAttributes,
    reset,
    entireCount,
  } = useWrappedPaginatedGet<PartAttribute>('moab/partAttribute', {
    lazy: true,
  });
  const {
    data: attributeDetails,
    loading: loadingAttribute,
    apiCall: getPartAttributeDetails,
  } = useWrappedGet<PartAttribute>('moab/partAttribute/:id', {
    lazy: true,
  });
  const { apiCall: deleteAttributeOrVariant, loading: deleting } = useWrappedDelete(
    'moab/partAttribute[SelectVariant]/:id',
  );

  const [selectedPartAttribute, setSelectedPartAttribute] = useState<PartAttribute | null>(null);

  const [addingVariant, setAddingVariant] = useState(false);
  const [deletingAttribute, setDeletingAttribute] = useState<PartAttribute>();
  const [deletingVariant, setDeletingVariant] = useState<SelectVariant>();

  useEffect(() => {
    if (isNotNil(partCategoryId)) {
      setDefaultOpts({
        lazy: false,
        defaultConfig: {
          params: { partCategoryId, orderBy: 'name:asc' },
        },
      });
    } else {
      reset();
      setSelectedPartAttribute(null);
    }
  }, [partCategoryId, reset, setDefaultOpts]);

  useEffect(() => {
    if (isNil(selectedPartAttribute) && isNotNil(partCategoryId) && data.length > 0) {
      setSelectedPartAttribute(data[0] ?? null);
    } else if (data.length === 0 && isNotNil(selectedPartAttribute)) {
      setSelectedPartAttribute(null);
    }
  }, [data, partCategoryId, selectedPartAttribute]);

  const refreshAttributeDetails = useCallback(
    () => getPartAttributeDetails({ url: `moab/partAttribute/${selectedPartAttribute?.partAttributeId}` }),
    [getPartAttributeDetails, selectedPartAttribute?.partAttributeId],
  );

  useEffect(() => {
    if (
      isNotNil(selectedPartAttribute?.partAttributeId) &&
      selectedPartAttribute.partAttributeId !== attributeDetails?.partAttributeId
    ) {
      void refreshAttributeDetails();
    }
  }, [
    attributeDetails?.partAttributeId,
    getPartAttributeDetails,
    selectedPartAttribute?.partAttributeId,
    refreshAttributeDetails,
  ]);

  return (
    <Flex gap="lg" style={{ flex: '1 0 0', overflowX: 'auto' }}>
      {/* Attributes */}
      <Card withBorder display="flex" style={{ flexDirection: 'column', flex: '3 0 0', minWidth: 300 }}>
        <Flex p={2} fw={600} justify="space-between" align="center" style={{ height: 34 }}>
          Attributes
          <AddAttributeModal
            partCatalogId={partCatalogId}
            partCategoryId={partCategoryId ?? undefined}
            refresh={refreshAllAttributes}
          />
        </Flex>
        <Divider />

        {!loading && entireCount === 0 && isNotNil(partCategoryId) && (
          <Text fz="sm" c="dimmed" m="xs">
            No attributes in category.
          </Text>
        )}

        {isNil(partCategoryId) && (
          <Text m="xs" c="dimmed">
            Select a category.
          </Text>
        )}

        <Flex direction="column" style={{ flex: '1 0 0', overflowY: 'auto' }}>
          {isNotNil(partCategoryId) &&
            data.map((d) => (
              <Fragment key={d.partAttributeId}>
                <AttributeListItem
                  attribute={d}
                  onAttributeSelected={setSelectedPartAttribute}
                  onDeleteClicked={setDeletingAttribute}
                  partCatalogId={partCatalogId}
                  selected={d.partAttributeId === selectedPartAttribute?.partAttributeId}
                />
                <Divider />
              </Fragment>
            ))}
          {isNotNil(partCategoryId) && (
            <TriggerOnVisible loading={loading} onVisible={fetchNextPage} loaderProps={{ m: 'sm' }} />
          )}
        </Flex>
      </Card>

      {/* Attribute Variants */}
      <Card withBorder display="flex" style={{ flexDirection: 'column', flex: '3 0 0', minWidth: 300 }}>
        <Flex p={2} fw={600} justify="space-between" align="center" style={{ height: 34 }}>
          <Flex align="center" gap="sm">
            Attribute variants
            <Text c="dimmed" fw={500}>
              (Code)
            </Text>
          </Flex>
          {isNotNil(attributeDetails) && (
            <AddEditVariantPopover
              opened={addingVariant}
              onClose={() => setAddingVariant(false)}
              refresh={refreshAttributeDetails}
              partAttribute={attributeDetails}
            >
              <ActionIcon
                onClick={() => setAddingVariant((v) => !v)}
                color="dark"
                variant="filled"
                size="xs"
                disabled={isNil(selectedPartAttribute)}
              >
                <EvolveIcon icon="Add" size="xs" />
              </ActionIcon>
            </AddEditVariantPopover>
          )}
        </Flex>
        <Divider />

        {selectedPartAttribute?.partAttributeId === attributeDetails?.partAttributeId &&
          attributeDetails?.selectVariants.length === 0 && (
            <Text c="dimmed" fz="sm" m="xs">
              No attribute variants.
            </Text>
          )}

        <Flex direction="column" style={{ flex: '1 0 auto', overflowY: 'auto' }}>
          {selectedPartAttribute?.partAttributeId === attributeDetails?.partAttributeId &&
            attributeDetails?.selectVariants.map((d) => (
              <Fragment key={d.partAttributeSelectVariantId}>
                <VariantListItem
                  variant={d}
                  partAttribute={attributeDetails}
                  refresh={refreshAttributeDetails}
                  setDeletingVariant={setDeletingVariant}
                />
                <Divider />
              </Fragment>
            ))}

          {loadingAttribute && <Loader size="md" type="dots" m="sm" />}
        </Flex>
      </Card>

      <ConfirmationModal
        opened={isNotNil(deletingAttribute)}
        onClose={() => setDeletingAttribute(undefined)}
        onConfirm={() =>
          deleteAttributeOrVariant({
            url: `moab/partAttribute/${deletingAttribute?.partAttributeId}`,
          })
            .then(() => {
              notifications.show({
                title: 'Successfully deleted',
                message: `Deleted attribute ${deletingAttribute?.partAttributeName}.`,
                color: 'green',
              });
            })
            .then(() => setDeletingAttribute(undefined))
            .finally(refreshAllAttributes)
        }
        loading={deleting}
        confirmationText="Delete"
        buttonColor="red"
      >
        Are you sure you want to delete attribute <b>{deletingAttribute?.partAttributeName}</b>?
      </ConfirmationModal>

      <ConfirmationModal
        opened={isNotNil(deletingVariant)}
        onClose={() => setDeletingVariant(undefined)}
        onConfirm={() =>
          deleteAttributeOrVariant({
            url: `moab/partAttributeSelectVariant/${deletingVariant?.partAttributeSelectVariantId}`,
          })
            .then(() => {
              notifications.show({
                title: 'Successfully deleted',
                message: `Deleted variant ${deletingVariant?.textValue}.`,
                color: 'green',
              });
            })
            .then(() => setDeletingVariant(undefined))
            .finally(refreshAttributeDetails)
        }
        loading={deleting}
        confirmationText="Delete"
        buttonColor="red"
      >
        Are you sure you want to delete variant <b>{deletingVariant?.textValue}</b>?
      </ConfirmationModal>
    </Flex>
  );
};
