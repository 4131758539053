// Copyright ©️ 2024 eVolve MEP, LLC
import { useMemo, useState } from 'react';

import { Text } from '@mantine/core';
import type { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { format, isAfter, parseISO } from 'date-fns';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { TextInputDebounced } from 'components/Mantine/TextInputDebounced';
import { lockedColDef } from 'helpers/ag-grid/baseColumnDef';
import { ExportGridButton } from 'helpers/ag-grid/ExportGridButton';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import { getCompanyMembersColDef } from 'modules/Admin/CompanyMembers/columnDefs';
import type { Company, ProductPool, User } from 'types/types-api';

const colDef: ColDef<Company>[] = [
  {
    ...lockedColDef(),
    cellRenderer: 'agGroupCellRenderer',
    width: 48,
  },
  {
    field: 'companyName',
    minWidth: 200,
    flex: 2,
  },
  {
    field: 'netsuiteId',
    minWidth: 200,
    flex: 1,
    sortable: false,
  },
];

const productPoolColDef: ColDef<ProductPool>[] = [
  {
    headerName: 'Product',
    minWidth: 200,
    flex: 1,
    field: 'product.productName',
    cellRenderer: CellRenderer<ProductPool>(({ data, value }) => (
      <>
        {isAfter(parseISO(`${data.startDate}Z`), new Date()) && (
          <Text c="goldenrod" mr="xs">
            PENDING
          </Text>
        )}
        {value}
      </>
    )),
  },
  {
    field: 'licenseClassification',
    width: 200,
  },
  {
    headerName: 'Quantity',
    width: 150,
    field: 'poolQuantity',
  },
  {
    headerName: 'Assigned',
    width: 150,
    valueGetter: ({ data }) => (isNotNil(data) ? data.poolQuantity - data.poolAvailableQuantity : '--'),
  },
  {
    headerName: 'Available',
    width: 150,
    field: 'poolAvailableQuantity',
  },
  {
    field: 'startDate',
    width: 150,
    valueGetter: ({ data }) => (isNotNil(data?.startDate) ? parseISO(data.startDate) : null),
    valueFormatter: ({ value }) => (isNotNil(value) ? format(value, 'MM/dd/yyyy') : '--'),
    cellStyle: ({ value }) => ({
      ...(isAfter(value, new Date())
        ? {
            color: '#0FA93A',
          }
        : {}),
    }),
  },
  {
    field: 'renewalDate',
    width: 150,
    valueFormatter: ({ value }) => (isNotNil(value) ? format(parseISO(value), 'MM/dd/yyyy') : '--'),
  },
];

export const Customers = () => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const { fetchPage, searchHandler } = useWrappedPaginatedGet<Company>('admin/company');

  const { fetchPage: fetchPageProductPool } = useWrappedPaginatedGet<ProductPool>('admin/productPool', {
    lazy: true,
  });

  const { fetchPage: fetchPageAdmins } = useWrappedPaginatedGet<User>('admin/user', { lazy: true });

  const adminsColDef = useMemo(
    () =>
      getCompanyMembersColDef({
        hasDetailTable: false,
        includeSearch: true,
      }),
    [],
  );

  const { agGridProps, filterIsSet } = useServerSideGrid({
    colDef,
    fetchPage,
    rowId: 'companyId',
    tableName: 'customers',
    detailTables: [
      {
        title: 'License Pool',
        fetchPage: fetchPageProductPool,
        icon: null,
        colDef: productPoolColDef,
        configMapper: ({ companyId }) => ({ params: { companyId } }),
      },
      {
        title: 'Company Admins',
        fetchPage: fetchPageAdmins,
        icon: null,
        colDef: adminsColDef,
        configMapper: ({ companyId }) => ({ params: { companyId, isAdmin: true } }),
      },
    ],
  });

  return (
    <>
      <BasePageHeader
        title="Customers"
        filterIsSet={filterIsSet || !!searchPhrase}
        onFilterClear={() => {
          setSearchPhrase('');
          searchHandler('');
        }}
        gridRef={agGridProps.ref}
        topRightComponent={
          <TextInputDebounced
            leftSection={<EvolveIcon icon="Search" />}
            placeholder="Search..."
            onChange={searchHandler}
            value={searchPhrase}
            onImmediateChange={setSearchPhrase}
          />
        }
        bottomRightComponent={
          <ExportGridButton
            columnDefs={colDef}
            fetchPage={fetchPage}
            fileName="customers"
            size="xs"
            compact={false}
            getDetailGridState={() => {
              const api = agGridProps.ref.current?.api;
              return {
                columnState: api?.getColumnState(),
                filterModel: api?.getFilterModel() ?? null,
              };
            }}
          />
        }
      />
      <div style={{ height: '100%' }}>
        <AgGridReact<Company> {...agGridProps} />
      </div>
    </>
  );
};
