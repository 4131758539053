// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { Button, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';

import { NewPasswordInput } from 'app/Layout/HeaderBar/ProfileDropdown/ResetPasswordModal/NewPasswordInput';
import { extractErrorMessage } from 'helpers/extractError';
import { useQuery } from 'hooks/useQuery';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';

import { AuthFormWrapper } from './components/AuthFormWrapper';
import type { LoginErrorCode } from './components/LoginErrorAlert';

export const ResetPassword = () => {
  const theme = useMantineTheme();
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const { apiCall } = useWrappedPost<unknown, { confirmationCode: string; username: string; newPassword: string }>(
    'admin/authentication/confirmForgotPassword',
    { dontAlertOnError: true },
  );
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState<LoginErrorCode | string>();
  const navigate = useNavigate();
  const queryParams = useQuery();

  const form = useForm<{ newPassword: string }>({
    initialValues: { newPassword: '' },
  });

  const resetPasswordHandler = async ({ newPassword }: typeof form.values) => {
    setLoading(true);
    const username = queryParams.get('username');
    const confirmationCode = queryParams.get('code');

    if (username && confirmationCode) {
      try {
        await apiCall({ confirmationCode, username, newPassword });
        void navigate('/auth/login', { state: { resetPassword: true } });
      } catch (error) {
        setErrorCode(extractErrorMessage(error));
        setLoading(false);
      }
    } else {
      setErrorCode('UnknownException');
    }
  };

  return (
    <AuthFormWrapper
      title="Create new password"
      onSubmit={resetPasswordHandler}
      form={form}
      errorCode={errorCode}
      onCloseError={() => setErrorCode(undefined)}
    >
      <NewPasswordInput
        {...form.getInputProps('newPassword')}
        loading={loading}
        setPasswordIsValid={setPasswordIsValid}
      />
      <Button type="submit" fullWidth color={`${theme.primaryColor}.9`} loading={loading} disabled={!passwordIsValid}>
        Submit
      </Button>
    </AuthFormWrapper>
  );
};
