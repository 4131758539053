// Copyright ©️ 2024 eVolve MEP, LLC
import { useMemo } from 'react';

import type { DeviceSize } from 'constants/globalConstants';

import { useBreakpoint } from './useBreakpoint';

export const useDeviceSize = () => {
  const { isMobile, isAboveTablet, isDesktop } = useBreakpoint();

  const deviceSize = useMemo<DeviceSize>(() => {
    if (isAboveTablet && !isDesktop) return 'TABLET';
    if (isMobile) return 'MOBILE';
    return 'DESKTOP';
  }, [isMobile, isAboveTablet, isDesktop]);

  return { deviceSize };
};
