// Copyright ©️ 2024 eVolve MEP, LLC
import { type MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Loader, type LoaderProps } from '@mantine/core';

export const useOnScreen = (ref: MutableRefObject<any>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), []);

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return isIntersecting;
};

export const TriggerOnVisible = ({
  loading = false,
  loaderProps,
  onVisible,
  height,
}: {
  loading?: boolean;
  loaderProps?: LoaderProps;
  onVisible: () => void;
  height?: number;
}) => {
  const ref = useRef(null);
  const onScreen = useOnScreen(ref);
  useEffect(() => {
    if (onScreen && !loading) onVisible();
  }, [loading, onScreen, onVisible]);
  return (
    <Box
      ref={ref}
      display="flex"
      style={
        loading
          ? { height, maxHeight: height, alignItems: 'center' }
          : {
              height: 1,
              width: 1,
              marginTop: -1,
              marginLeft: -1,
            }
      }
    >
      {loading && <Loader type="dots" m="xs" {...loaderProps} />}
    </Box>
  );
};
