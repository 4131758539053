// Copyright ©️ 2024 eVolve MEP, LLC
import { useEffect, useState } from 'react';

import { ActionIcon, Flex, Menu, useMantineTheme } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { TextWithRef } from 'components/Mantine/TextWithRef';
import type { PartCatalogId } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';

import { EditAttributePopover } from './EditAttributePopover';
import type { PartAttribute } from './types';

type Props = {
  partCatalogId: PartCatalogId;
  attribute: PartAttribute;
  selected: boolean;
  onAttributeSelected: (attribute: PartAttribute) => void;
  onDeleteClicked: (attribute: PartAttribute) => void;
};

export const AttributeListItem = ({
  partCatalogId,
  attribute: initialAttribute,
  selected,
  onAttributeSelected,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDeleteClicked,
}: Props) => {
  const theme = useMantineTheme();
  const [menuOpened, setMenuOpened] = useState(false);
  const [editing, setEditing] = useState(false);
  const [attribute, setAttribute] = useState(initialAttribute);
  useEffect(() => {
    setAttribute(initialAttribute);
  }, [initialAttribute]);

  useEffect(() => {
    if (selected) {
      onAttributeSelected(attribute);
    }
  }, [attribute, onAttributeSelected, selected]);

  return (
    <>
      <Flex
        className="hover-title"
        onClick={() => onAttributeSelected(attribute)}
        bg={editing ? `${theme.primaryColor}.0` : selected ? 'gray.2' : undefined}
        py={1}
        align="center"
        justify="space-between"
        style={{ userSelect: 'none', height: 34 }}
      >
        <AgGridStyleTooltip
          openDelay={400}
          withArrow
          position="left"
          label={
            <>
              <b>Attribute type:</b> {attribute.partAttributeType.partAttributeTypeName}
              <br />
              <b>Write in attributes allowed:</b> {attribute.allowWriteInVariant ? 'Yes' : 'No'}
            </>
          }
        >
          <TextWithRef px="md" fw={selected ? 500 : undefined}>
            {attribute.partAttributeName}
          </TextWithRef>
        </AgGridStyleTooltip>

        <Menu opened={menuOpened} onChange={setMenuOpened}>
          <Menu.Target>
            <ActionIcon
              mx={4}
              onClick={(e) => {
                setMenuOpened((o) => !o);
                e.stopPropagation();
              }}
            >
              <EvolveIcon className={menuOpened || editing ? undefined : 'hover-title--on'} icon="MoreOptsVert" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={(e) => {
                setEditing(true);
                e.stopPropagation();
              }}
            >
              Edit
            </Menu.Item>
            {/* TODO: Reenable once DELETE moab/partAttribute/:id is available */}
            {/* <Menu.Item
              onClick={(e) => {
                onDeleteClicked(attribute);
                e.stopPropagation();
              }}
              c="red"
            >
              Delete
            </Menu.Item> */}
          </Menu.Dropdown>
        </Menu>
      </Flex>
      <EditAttributePopover
        attribute={attribute}
        onAttributeUpdated={setAttribute}
        onClose={() => setEditing(false)}
        opened={editing}
        partCatalogId={partCatalogId}
      />
    </>
  );
};
