// Copyright ©️ 2024 eVolve MEP, LLC
import { NoContentFallback } from 'components/NoContentFallback/NoContentFallback';

import { useCheckIfShopDepartmentExists } from './useCheckIfShopDeparmentExists';

export const NoShopDptOverlay = ({ children }) => {
  if (!useCheckIfShopDepartmentExists()) {
    return <NoContentFallback message="This facility does not have a Shop department." />;
  }

  return children;
};
