// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { ActionIcon, Box, NumberInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { type NodeProps, useReactFlow } from '@xyflow/react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { AlertingPopover } from 'components/Mantine/AlertingPopover';
import { isNotNil } from 'helpers/isNotNil';

import { useAssemblyEditor } from '../useAssemblyEditor';
import type { PartNodeType } from './AssemblyNode';

export const EditNode = ({ node: { data, id } }: { node: NodeProps<PartNodeType> }) => {
  const { getNode } = useReactFlow();
  const { onNodesChange } = useAssemblyEditor();
  const [editing, setEditing] = useState(false);
  const form = useForm<Pick<typeof data, 'quantity'>>({
    initialValues: data,
    validate: {
      quantity: (v) => !Number.isInteger(v),
    },
  });

  const onSubmit = (values: typeof form.values) => {
    const node = getNode(id);
    if (isNotNil(node)) {
      onNodesChange([
        {
          id,
          type: 'replace',
          item: {
            ...node,
            data: {
              ...node.data,
              ...values,
            },
          },
        },
      ]);
      setEditing(false);
    }
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <AlertingPopover
          isDirty={form.isDirty()}
          onOpen={() => {
            form.setValues(data);
            form.resetDirty(data);
          }}
          offset={40}
          opened={editing}
          confirmButtonText="Save"
          onSubmit={form.onSubmit(onSubmit)}
          onClose={() => setEditing(false)}
          target={
            <ActionIcon
              className={editing ? undefined : 'hover-node--on'}
              variant="outline"
              style={{
                position: 'absolute',
                right: 6,
                top: 4,
              }}
              onClick={() => setEditing(true)}
            >
              <AgGridStyleTooltip label="Edit part" withArrow>
                <EvolveIcon icon="WriteInItem" />
              </AgGridStyleTooltip>
            </ActionIcon>
          }
        >
          <NumberInput
            style={{ width: 100 }}
            max={9999}
            min={1}
            startValue={1}
            label={`${data.part.partName} Unit Qty`}
            autoFocus
            {...form.getInputProps('quantity')}
          />
        </AlertingPopover>
      </form>
    </Box>
  );
};
