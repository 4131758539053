// Copyright ©️ 2024 eVolve MEP, LLC
import { DEPARTMENT_TYPE_IDS } from 'constants/globalConstants';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import { useSetupModule } from 'hooks/useSetupModule';

export const useCheckIfShopDepartmentExists = () => {
  const { SHOP_TYPE_DEPARTMENT_ID } = DEPARTMENT_TYPE_IDS;
  const { selectedItem } = useSelectedProjectFacility();
  const { currentModule } = useSetupModule();
  const shopDepartment = selectedItem?.departments?.find(
    (department) => department.departmentType.departmentTypeId === SHOP_TYPE_DEPARTMENT_ID,
  );
  if (selectedItem?.type === 'PROJECT') return true;
  return !!shopDepartment && currentModule() === 'SHOP';
};
