// Copyright ©️ 2024 eVolve MEP, LLC
import type { MantineColor, MantineThemeOverride } from '@mantine/core';

import { isNotNil } from 'helpers/isNotNil';
import { useSetupModule } from 'hooks/useSetupModule';
import type { EModuleType } from 'types/module';

export const themePrimaryColorMap: Partial<Record<EModuleType, MantineColor>> = {
  ADMIN: 'teal',
  SHOP: 'violet',
  MATERIALS: 'orange',
  FIELD: 'green',
  MANAGEMENT: 'cyan',
  DESIGN: 'blue',
  SYNC: 'orange',
};

export const useGenerateMantineTheme = (): MantineThemeOverride => {
  const { currentModule } = useSetupModule();
  const module = currentModule();
  const primaryColor = isNotNil(module) && module in themePrimaryColorMap ? themePrimaryColorMap[module] : 'indigo';
  const primaryShade = 6;
  document.documentElement.style.setProperty(
    '--mantine-primary-color',
    `var(--mantine-color-${primaryColor}-${primaryShade})`,
  );

  return {
    fontFamily: 'Roboto',
    headings: {
      fontFamily: 'Roboto',
      fontWeight: '600',
    },
    primaryColor,
    primaryShade: {
      light: primaryShade,
      dark: 8,
    },
    components: {
      ActionIcon: {
        defaultProps: {
          variant: 'subtle',
          color: 'gray',
        },
      },
      Card: {
        defaultProps: {
          shadow: 'none',
        },
      },
      Divider: {
        defaultProps: {
          labelPosition: 'left',
        },
      },
      Loader: {
        defaultProps: {
          size: 'md',
        },
      },
    },
  };
};
