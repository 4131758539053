// Copyright ©️ 2025 eVolve MEP, LLC

import { Flex, Tooltip } from '@mantine/core';
import type { ColDef } from 'ag-grid-enterprise';
import type { CustomHeaderProps } from 'ag-grid-react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { lockedColDef } from 'helpers/ag-grid/baseColumnDef';
import { CustomSelectComponent } from 'helpers/ag-grid/CustomSelectComponent';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { IncludeDeletedOptions, type Entitlement, type User } from 'types/types-api';

import { CompanyMemberMenu, type CompanyMembersBulkAction } from './CompanyMemberMenu';
import { RemoveEntitlementOrRole } from './RemoveEntitlementOrRole';
import type { UserRole } from './types';

const fallback = '--';

export const getCompanyMembersColDef = ({
  hasDetailTable,
  actionSingleUser,
  filterOnStatus = true,
  includeSearch = false,
  isOptedInRevitRoles,
}: {
  hasDetailTable: boolean;
  filterOnStatus?: boolean;
  includeSearch?: boolean;
  isOptedInRevitRoles?: boolean;
  actionSingleUser?: (user: User, selectedAction: CompanyMembersBulkAction) => void;
}): ColDef<User>[] =>
  [
    {
      ...lockedColDef(),
      width: 48,
      cellRenderer: 'agGroupCellRenderer',
      hide: !hasDetailTable,
    },
    {
      ...lockedColDef(),
      headerName: 'Admin',
      headerComponentParams: {
        innerHeaderComponent: ({ displayName }: CustomHeaderProps) => (
          <Flex gap={3} align="center">
            {displayName}
            {isOptedInRevitRoles && (
              <Tooltip label="Admins have Power User privileges by proxy" disabled={!isOptedInRevitRoles}>
                <EvolveIcon size="sm" icon="Info" color="gray" />
              </Tooltip>
            )}
          </Flex>
        ),
      },
      field: 'isAdmin',
      width: 100,
      cellRenderer: CellRenderer<User>(({ data }) =>
        data.isAdmin ? <EvolveIcon icon="CheckCircle" size="md" color="gray.7" /> : null,
      ),
    },
    {
      headerName: 'Email',
      field: 'userEmail',
      valueFormatter: ({ value }) => value ?? fallback,
      flex: 1,
      minWidth: 250,
      filter: includeSearch ? 'agTextColumnFilter' : undefined,
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
        buttons: ['reset'],
      },
    },
    {
      headerName: 'Office Phone',
      width: 200,
      field: 'workPhoneNumber',
      valueFormatter: ({ value }) => value ?? fallback,
      sortable: false,
    },
    {
      headerName: 'Mobile Phone',
      width: 200,
      field: 'personalPhoneNumber',
      valueFormatter: ({ value }) => value ?? fallback,
      sortable: false,
    },
    {
      headerName: 'Status',
      field: 'inviteStatus',
      minWidth: 150,
      cellStyle: ({ data }) => ({
        color: data?.isDeleted ? 'red' : data?.inviteStatus === 'Active' ? 'teal' : 'orange',
      }),
      valueFormatter: ({ data, value }) => (data?.isDeleted ? 'Deleted' : value),
      filter: filterOnStatus
        ? CustomSelectComponent({
            label: 'User status',
            defaultValue: IncludeDeletedOptions.NO,
            data: [
              {
                label: 'Active users',
                value: IncludeDeletedOptions.NO,
              },
              {
                label: 'Deleted users',
                value: IncludeDeletedOptions.ONLY_DELETED,
              },
              {
                label: 'All users',
                value: IncludeDeletedOptions.YES,
              },
            ],
            filterKey: 'includeDeleted',
          })
        : undefined,
    },
    {
      ...lockedColDef('right'),
      width: 56,
      cellRenderer: CellRenderer<User>(
        ({ data }) =>
          isNotNil(actionSingleUser) ? <CompanyMemberMenu actionSingleUser={actionSingleUser} user={data} /> : null,
        { show: ({ data }) => !data.isDeleted },
      ),
      hide: isNil(actionSingleUser),
    },
  ] as const;

export const getEntitlementsColDef = (): ColDef<Entitlement>[] =>
  [
    {
      ...lockedColDef(),
      width: 60,
      cellRenderer: CellRenderer<Entitlement>(({ data, api }) => (
        <RemoveEntitlementOrRole entitlement={data} refresh={() => api.refreshServerSide({ purge: true })} />
      )),
    },
    {
      field: 'productPool.label',
      flex: 1,
    },
  ] as const;

export const getRolesColDef = (): ColDef<UserRole>[] =>
  [
    {
      ...lockedColDef(),
      width: 60,
      cellRenderer: CellRenderer<UserRole>(({ data, api }) => (
        <RemoveEntitlementOrRole role={data} refresh={() => api.refreshServerSide({ purge: true })} />
      )),
    },
    {
      field: 'displayName',
      flex: 1,
      valueFormatter: ({ data, value }) =>
        data?.scope === 'EE' || data?.scope === 'EM' ? `${value} (${data.scope})` : value,
    },
  ] as const;
