// Copyright ©️ 2024 eVolve MEP, LLC
import { useEffect, useMemo, useState } from 'react';

import { ActionIcon, Flex, Modal, type SelectProps, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { ModalButtons } from 'components/Mantine/ModalButtons';
import { type TypeSafeSelectProps, WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { useDebouncedStateV2 } from 'components/Mantine/useDebouncedStateV2';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { CompanyId } from 'types/types-api';

import type { PartCatalog } from './types';

type Props = {
  selectedCatalog: PartCatalog | null;
  onCatalogSelected: (partCatalogId: PartCatalog | null) => void;
  companyId: CompanyId;
  disabled?: boolean;
  showLabel?: boolean;
  selectProps?: Partial<Omit<SelectProps, keyof TypeSafeSelectProps>>;
  allowCreation?: boolean;
};

type CreatePartCatalogBody = {
  partCatalogName: string;
  companyId: CompanyId;
};

export const CatalogSelect = ({
  selectedCatalog,
  companyId,
  disabled,
  showLabel,
  selectProps,
  onCatalogSelected,
  allowCreation = false,
}: Props) => {
  const [creatingCatalog, setCreatingCatalog] = useState(false);
  const [catalogSelectOpened, setCatalogSelectOpened] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [catalogSearchPhrase, setCatalogSearchPhrase, setCatalogSearchPhraseImmediate] = useDebouncedStateV2('', 350);
  const {
    data: catalogs,
    setDefaultOpts: setDefaultOptsCatalogs,
    loading,
    refetch,
  } = useWrappedPaginatedGet<PartCatalog>('moab/partCatalog', {
    lazy: true,
  });

  useEffect(() => {
    if (catalogSelectOpened) {
      setDefaultOptsCatalogs({
        lazy: false,
        defaultConfig: {
          params: {
            companyId,
            ...(catalogSearchPhrase.length > 0
              ? {
                  name: `like:${catalogSearchPhrase}`,
                }
              : {}),
          },
        },
      });
    }
  }, [catalogSearchPhrase, catalogSelectOpened, companyId, setDefaultOptsCatalogs]);

  useEffect(() => {
    if (catalogSelectOpened && loading) {
      setDropdownOpened(true);
    }
  }, [catalogSelectOpened, loading]);

  const [creating, setCreating] = useState(false);
  const { apiCall: createCatalogApiCall } = useWrappedPost<PartCatalog, CreatePartCatalogBody>('moab/partCatalog');
  const form = useForm<CreatePartCatalogBody>({
    initialValues: {
      companyId,
      partCatalogName: '',
    },
    validate: { partCatalogName: (v) => !v },
  });
  const createCatalog = (values: typeof form.values) => {
    setCreating(true);
    void createCatalogApiCall(values)
      .then(async (c) => {
        await refetch();
        onCatalogSelected(c);
        setCreatingCatalog(false);
        notifications.show({
          title: 'Successfully created',
          message: `Catalog ${c.partCatalogName} created`,
          color: 'green',
        });
      })
      .finally(() => setCreating(false));
  };

  const data = useMemo(
    () =>
      catalogSelectOpened
        ? catalogs.map((c) => ({
            label: c.partCatalogName,
            value: c.partCatalogId,
          }))
        : isNotNil(selectedCatalog)
          ? [
              {
                value: selectedCatalog.partCatalogId,
                label: selectedCatalog.partCatalogName,
              },
            ]
          : [],
    [catalogSelectOpened, catalogs, selectedCatalog],
  );

  return (
    <>
      {allowCreation && (
        <>
          <AgGridStyleTooltip label="Create new catalog" position="left" withArrow openDelay={400}>
            <ActionIcon color="dark" onClick={() => setCreatingCatalog(true)} variant="outline" size="sm">
              <EvolveIcon icon="Add" size="xs" />
            </ActionIcon>
          </AgGridStyleTooltip>
          <Modal
            title="Create catalog"
            opened={creatingCatalog}
            onClose={() => setCreatingCatalog(false)}
            closeOnClickOutside={false}
            closeOnEscape={false}
            centered
          >
            <form onSubmit={form.onSubmit(createCatalog)}>
              <Flex gap="md" direction="column">
                <TextInput
                  label="Catalog Name"
                  disabled={creating}
                  maxLength={64}
                  {...form.getInputProps('partCatalogName')}
                />
                <ModalButtons
                  type="submit"
                  confirmationText="Create"
                  loading={creating}
                  onClose={() => setCreatingCatalog(false)}
                  disabled={!form.isValid()}
                />
              </Flex>
            </form>
          </Modal>
        </>
      )}
      <WrappedSelect
        label={showLabel ? 'Catalog' : undefined}
        placeholder="Select a catalog..."
        value={selectedCatalog?.partCatalogId}
        blurOnChange
        onChange={(v) => {
          const newCatalog = catalogs.find((c) => c.partCatalogId === v);
          if (isNotNil(newCatalog)) {
            onCatalogSelected(newCatalog);
          }
        }}
        onFocus={() => {
          setCatalogSearchPhraseImmediate('');
          setCatalogSelectOpened(true);
        }}
        onBlur={() => {
          setCatalogSelectOpened(false);
          setDropdownOpened(false);
        }}
        onDropdownClose={() => {
          setCatalogSelectOpened(false);
          setDropdownOpened(false);
        }}
        dropdownOpened={dropdownOpened}
        searchable
        searchValue={catalogSearchPhrase}
        onSearchChange={(v) => {
          setCatalogSearchPhrase(v);
          if (v !== catalogSearchPhrase && v !== selectedCatalog?.partCatalogName && !catalogSelectOpened) {
            setCatalogSearchPhraseImmediate(v);
            setCatalogSelectOpened(true);
          }
        }}
        disabled={disabled}
        hideValueOnOpen
        style={{ minWidth: 150 }}
        filter={({ options }) => options}
        nothingFoundMessage={loading ? 'Loading...' : 'No results.'}
        {...selectProps}
        data={
          loading
            ? []
            : [
                {
                  group: 'Catalogs',
                  items: data,
                },
              ]
        }
      />
    </>
  );
};
