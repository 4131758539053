// Copyright ©️ 2024 eVolve MEP, LLC
import React, { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { isNil, isNotNil } from 'helpers/isNotNil';
import { useGeneralContext } from 'helpers/useGeneralContext';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'hooks/useLocalStorage';
import { DEPARTMENT_TYPE } from 'types/DepartmentTypes';
import type { CompanyId } from 'types/types-api';

import type { Department } from './types';
import type { Facility, FacilityId } from './useFacilities';
import type { Project, ProjectId } from './useProjects';

export type SelectedItem = {
  label: string;
  companyId: CompanyId;
  departments: Department[] | null;
} & (
  | {
      id: FacilityId;
      type: 'FACILITY';
    }
  | {
      id: ProjectId;
      type: 'PROJECT';
    }
);

type SelectedFacilityProjectContextType = {
  selectedItem?: SelectedItem;
  selectFacility: (facility: Facility) => void;
  selectProject: (project: Project) => void;
  clearSelectedItem: () => void;
};

const SelectedFacilityProjectContext = React.createContext<SelectedFacilityProjectContextType | undefined>(undefined);

/**
 * Runs some basic validations to make sure the selectedItem
 * (pulled from local storage usually)
 * has all the expected keys.
 * This helps prevent from corrupted or outdated local storage values.
 */
const selectedItemIsValid = (selectedItem: any): selectedItem is SelectedItem => {
  try {
    if (
      isNil(selectedItem) ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      isNil(selectedItem.id) ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      isNil(selectedItem.label) ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      isNil(selectedItem.companyId) ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      !['FACILITY', 'PROJECT'].includes(selectedItem.type) ||
      // To catch a departmentType bug fixed in FWP-994
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      selectedItem.departments?.some((d: Department) => isNil(d.departmentType))
    ) {
      return false;
    }
    return true;
  } catch {
    return false;
  }
};

export const SelectedFacilityProjectProvider = ({ children }: { children: ReactNode }) => {
  const [selectedItem, setSelectedItem] = useState<SelectedItem | undefined>();

  const selectFacility = useCallback((facility: Facility) => {
    setSelectedItem({
      type: 'FACILITY',
      label: facility.facilityName,
      id: facility.facilityId,
      companyId: facility.companyId,
      departments: facility.departments,
    });
  }, []);

  const selectProject = useCallback((project: Project) => {
    setSelectedItem({
      type: 'PROJECT',
      label: project.projectName,
      id: project.projectId,
      companyId: project.companyId,
      departments: project.departments,
    });
  }, []);

  const clearSelectedItem = useCallback(() => {
    removeLocalStorage('SELECTED_ITEM');
    setSelectedItem(undefined);
  }, []);

  useEffect(() => {
    if (isNotNil(selectedItem)) {
      setLocalStorage('SELECTED_ITEM', selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    const selectedItemStored = getLocalStorage('SELECTED_ITEM');
    if (selectedItemIsValid(selectedItemStored) && isNil(selectedItem)) {
      setSelectedItem(selectedItemStored);
    }
  }, [selectedItem]);

  const value = useMemo(
    () => ({
      selectedItem,
      selectFacility,
      selectProject,
      clearSelectedItem,
    }),
    [clearSelectedItem, selectFacility, selectProject, selectedItem],
  );
  return <SelectedFacilityProjectContext.Provider value={value}>{children}</SelectedFacilityProjectContext.Provider>;
};

export const useSelectedProjectFacility = () =>
  useGeneralContext(SelectedFacilityProjectContext, 'ActiveProjectFacility');

export const useSelectedFacilityShop = () => {
  const { selectedItem } = useSelectedProjectFacility();
  if (isNil(selectedItem) || selectedItem.type === 'PROJECT') {
    return null;
  }
  return selectedItem.departments?.find((d) => d.departmentType.departmentTypeId === DEPARTMENT_TYPE.SHOP)
    ?.departmentId;
};
