// Copyright ©️ 2024 eVolve MEP, LLC
import { createContext, type ReactNode, useCallback, useEffect, useState } from 'react';

import { LoadingOverlay } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  ReactFlowProvider,
  type OnConnect,
  type ReactFlowProps,
  type Edge,
  type Node,
  type OnEdgesChange,
  type OnNodesChange,
  getOutgoers,
} from '@xyflow/react';
import { v4 as uuidv4 } from 'uuid';

import type { WithRequired } from 'helpers/helper-types';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useGeneralContext } from 'helpers/useGeneralContext';
import { useWrappedPaginatedGet, useWrappedPatch, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import {
  getAssemblyEdgeFromFlowEdge,
  getAssemblyNodeFromFlowNode,
  getNewAssemblyNodeFromFlowNode,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/assemblyEditorHelper';
import type { Part } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';
import { useDocumentsCache } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/useDocumentsCache';
import type {
  AssemblyNode,
  NewAssemblyBody,
  NewAssemblyNode,
  UpdateAssemblyBody,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/types';

import { autoLayoutedNodes } from './autoLayoutedNodes';
import type { NodeType, PartNodeType } from './nodes/AssemblyNode';
import type { Assembly } from './types';
import { getNodeInfoFromAssemblyNode } from './utils';

type AssemblyEditorContextType = WithRequired<
  ReactFlowProps,
  'nodes' | 'edges' | 'onNodesChange' | 'onEdgesChange' | 'onConnect'
> & {
  isDirty: boolean;
  addNode: (node: Omit<NodeType, 'id'>) => NodeType;
  loading: boolean;
  saveAssembly: (part: Part) => Promise<void>;
  saving: boolean;
};

const AssemblyEditorContext = createContext<AssemblyEditorContextType | undefined>(undefined);

export const AssemblyEditorProvider = ({ part, children }: { part?: Part; children: ReactNode }) => {
  const { requestDocumentDetails } = useDocumentsCache();
  const [isDirty, setIsDirty] = useState(false);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      setIsDirty((d) => d || changes.some((c) => c.type !== 'dimensions' && c.type !== 'select'));
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes],
  );
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => {
      setIsDirty((d) => d || changes.some((c) => c.type !== 'select'));
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges],
  );
  const addNode = useCallback<AssemblyEditorContextType['addNode']>((node) => {
    setIsDirty(true);
    const newNode = {
      ...node,
      id: uuidv4(),
    } as NodeType;
    setNodes((nds) => nds.concat(newNode));
    return newNode;
  }, []);
  const onConnect: OnConnect = useCallback(
    (connection) => {
      const hasCycle = (node: Node, visited = new Set<Node['id']>()): boolean => {
        if (visited.has(node.id)) return false;
        visited.add(node.id);

        return getOutgoers(node, nodes, edges).some(
          (outgoer) => outgoer.id === connection.source || hasCycle(outgoer, visited),
        );
      };
      const target = nodes.find((node) => node.id === connection.target);
      if (isNil(target)) return;
      if (hasCycle(target)) {
        notifications.show({
          title: 'Circular reference prevented',
          message: 'Cannot create infinite loops.',
          color: 'red',
        });
        return;
      }
      setIsDirty(true);
      setEdges((eds) => addEdge(connection, eds));
    },
    [edges, nodes],
  );

  const [assembly, setAssembly] = useState<Assembly>();

  const { apiCall: createAssembly, loading: creating } = useWrappedPost<Assembly, NewAssemblyBody>('moab/assembly');
  const { apiCall: patchAssembly, loading: updating } = useWrappedPatch<Assembly, UpdateAssemblyBody>(
    'moab/assembly/:assemblyId',
  );
  const saving = creating || updating;

  const { data: assemblies, loading } = useWrappedPaginatedGet<Assembly>('moab/assembly', {
    lazy: isNil(part) || !part.hasAssembly,
    perPage: 1,
    defaultConfig: {
      params: { partId: part?.partId },
    },
  });
  useEffect(() => {
    if (assemblies.length > 0) setAssembly(assemblies[0]);
  }, [assemblies]);

  useEffect(() => {
    if (isNotNil(assembly)) {
      setIsDirty(false);
      const { assemblyNodes, assemblyEdges } = assembly;
      requestDocumentDetails(assemblyNodes.flatMap((n) => n.part?.documentIds).filter(isNotNil));
      const newNodes = assemblyNodes
        .map((node) => {
          const ext = getNodeInfoFromAssemblyNode(node);
          if (isNil(ext)) return null;
          return {
            id: node.assemblyNodeId,
            position: {
              x: node.positionX ?? 0,
              y: node.positionY ?? 0,
            },
            ...ext,
          };
        })
        .filter(isNotNil);
      const newEdges = assemblyEdges
        .filter(
          (e): e is WithRequired<Assembly['assemblyEdges'][number], 'beginNode' | 'endNode'> =>
            isNotNil(e.beginNode) && isNotNil(e.endNode),
        )
        .map((edge) => ({
          id: edge.assemblyEdgeId,
          source: edge.beginNode.assemblyNodeId,
          target: edge.endNode.assemblyNodeId,
          sourceHandle: edge.beginHandlePosition.assemblyHandlePositionId,
          targetHandle: edge.endHandlePosition.assemblyHandlePositionId,
        }));

      setNodes(
        assemblyNodes.some((n) => !n.positionX && !n.positionY) ? autoLayoutedNodes(newNodes, newEdges) : newNodes,
      );
      setEdges(newEdges);
    } else if (!loading) {
      setIsDirty(true);
    }
  }, [assembly, loading, requestDocumentDetails]);

  const updateAssembly = useCallback(
    async ({ assemblyId, assemblyNodes, assemblyEdges }: Assembly, latestPart: Part) => {
      const addNodes: NewAssemblyNode[] = nodes
        .filter((n) => !assemblyNodes.some((an) => an.assemblyNodeId === n.id))
        .map((n) => getNewAssemblyNodeFromFlowNode(n, latestPart));
      const addEdges = edges
        .filter((e) => !assemblyEdges.some((ae) => ae.assemblyEdgeId === e.id))
        .map(getAssemblyEdgeFromFlowEdge);

      const updateNodes: AssemblyNode[] = nodes
        .filter((n) => {
          const existingNode = assemblyNodes.find((an) => an.assemblyNodeId === n.id);
          if (isNil(existingNode)) return false;
          // Filter to nodes that have changed, or is our assembly node
          return (
            existingNode.part?.partId === latestPart.partId ||
            n.position.x !== existingNode.positionX ||
            n.position.y !== existingNode.positionY ||
            (n.type === 'part' && (n as PartNodeType).data.quantity !== existingNode.quantity)
          );
        })
        .map((n) => {
          const node = getAssemblyNodeFromFlowNode(n, latestPart);
          if (!('partId' in node) || node.partId !== latestPart.partId) return node;
          // If it's our assembly node, update it with the latest name and desc
          return {
            ...node,
            assemblyNodeName: latestPart.partName,
            assemblyNodeDescription: latestPart.description ?? node.assemblyNodeDescription,
          };
        });

      const deleteNodes = assemblyNodes
        .filter((n) => !nodes.some((an) => an.id === n.assemblyNodeId))
        .map((n) => n.assemblyNodeId);
      const deleteEdges = assemblyEdges
        .filter((e) => !edges.some((ae) => ae.id === e.assemblyEdgeId))
        .map((e) => e.assemblyEdgeId);

      await patchAssembly(
        {
          addNodes,
          addEdges,
          updateNodes,
          deleteEdges,
          deleteNodes,
        },
        {
          url: `moab/assembly/${assemblyId}`,
        },
      ).then(setAssembly);
    },
    [edges, nodes, patchAssembly],
  );

  const saveAssembly = useCallback(
    async (latestPart: Part) => {
      if (isNotNil(assembly)) {
        await updateAssembly(assembly, latestPart);
      } else {
        await createAssembly({
          partId: latestPart.partId,
          nodes: nodes.map((n) => getNewAssemblyNodeFromFlowNode(n, latestPart)),
          edges: edges.map(getAssemblyEdgeFromFlowEdge),
        }).then(setAssembly);
      }
      setIsDirty(false);
    },
    [assembly, createAssembly, edges, nodes, updateAssembly],
  );

  return (
    <ReactFlowProvider>
      <AssemblyEditorContext.Provider
        value={{
          nodes,
          edges,
          onNodesChange,
          onEdgesChange,
          onConnect,
          addNode,
          saveAssembly,
          isDirty,
          saving,
          loading,
        }}
      >
        {children}
        <LoadingOverlay visible={saving} />
      </AssemblyEditorContext.Provider>
    </ReactFlowProvider>
  );
};

export const useAssemblyEditor = () => useGeneralContext(AssemblyEditorContext, 'AssemblyEditor');
