// Copyright ©️ 2024 eVolve MEP, LLC
import { useState, useCallback, useMemo, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { type EModuleType, MODULE_URL_MAPPING, MODULE_LABEL_MAPPING } from 'types/module';

import { getLocalStorage, removeLocalStorage, setLocalStorage } from './useLocalStorage';

export const useSetupModule = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [module, setModule] = useState(getLocalStorage('CURRENT_MODULE') ?? null);
  const moduleNavigationName = useMemo(
    () =>
      pathname
        .substring(1, pathname.indexOf('/', 1) !== -1 ? pathname.indexOf('/', 1) : pathname.length)
        .toLocaleUpperCase() as EModuleType | '',
    [pathname],
  );
  const pageNavigationName = useMemo(
    () => pathname.split('/')[2] ?? moduleNavigationName,
    [pathname, moduleNavigationName],
  );

  useEffect(() => {
    if (moduleNavigationName && MODULE_LABEL_MAPPING[moduleNavigationName]) {
      setModule(moduleNavigationName);
      setLocalStorage('CURRENT_MODULE', moduleNavigationName);
    }
  }, [moduleNavigationName]);

  useEffect(() => {
    setLocalStorage('CURRENT_PAGE', pageNavigationName);
  }, [pageNavigationName]);

  const setSelectedModule = useCallback(
    (selectedModule: EModuleType) => {
      void navigate(`/${MODULE_URL_MAPPING[selectedModule]}`);
    },
    [navigate],
  );

  const resetModule = () => {
    setModule(null);
    removeLocalStorage('CURRENT_MODULE');
  };

  const currentModule = useCallback(() => getLocalStorage('CURRENT_MODULE'), []);
  const currentPage = useCallback(() => getLocalStorage('CURRENT_PAGE'), []);

  return {
    module,
    setSelectedModule,
    resetModule,
    currentModule,
    currentPage,
  };
};
