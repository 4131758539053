// Copyright ©️ 2024 eVolve MEP, LLC
import { useCallback, useEffect } from 'react';

import type { FacilityId } from 'hooks/projectsAndFacilities/useFacilities';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';
import type { WorkCellId } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

export const useWorkCellsList = (facilityId: FacilityId) => {
  const {
    data: workCells,
    apiCall,
    loading,
  } = useWrappedGet<
    {
      workCellId: WorkCellId;
      workCellName: string;
    }[]
  >('shop/workCell/all', { lazy: true });

  const refetch = useCallback(() => apiCall({ params: { facilityId } }), [apiCall, facilityId]);

  useEffect(() => {
    void refetch();
  }, [refetch]);

  return {
    workCells,
    refetch,
    loading,
  };
};
