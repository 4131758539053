// Copyright ©️ 2024 eVolve MEP, LLC
import { Flex, Tabs } from '@mantine/core';

import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { isNotNil } from 'helpers/isNotNil';

import { CategorySetup } from './CategorySetup';
import { ManageItems } from './ManageItems';
import { useCatalogSetup } from './useCatalogSetup';

export const CatalogSetupDetailsPane = () => {
  const { selectedCatalog, selectedCategoryId, selectedTab, setSelectedTab } = useCatalogSetup();
  return (
    <Tabs
      value={selectedTab}
      onChange={(t) => setSelectedTab(t as typeof selectedTab)}
      display="flex"
      h="100%"
      style={{ flexDirection: 'column' }}
      variant="outline"
      orientation="horizontal"
      keepMounted={false}
    >
      <Tabs.List mb="lg">
        <Tabs.Tab value="manage-items">Manage Items</Tabs.Tab>
        <Tabs.Tab value="category-setup">Manage Attributes</Tabs.Tab>
      </Tabs.List>

      {isNotNil(selectedCatalog) ? (
        <>
          <Tabs.Panel
            value="category-setup"
            styles={{
              panel: {
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto',
              },
            }}
          >
            <CategorySetup partCatalogId={selectedCatalog.partCatalogId} partCategoryId={selectedCategoryId} />
          </Tabs.Panel>
          <Tabs.Panel
            value="manage-items"
            styles={{
              panel: {
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto',
              },
            }}
          >
            <ManageItems partCatalogId={selectedCatalog.partCatalogId} partCategoryId={selectedCategoryId} />
          </Tabs.Panel>
        </>
      ) : (
        <Flex align="center" style={{ height: '100%' }}>
          <NoRowsOverlay label="Select a catalog to begin." icon="Assembly" />
        </Flex>
      )}
    </Tabs>
  );
};
