// Copyright ©️ 2024 eVolve MEP, LLC
import { Routes, Route, Navigate } from 'react-router-dom';

import { PageNotFound } from 'app/PageNotFound';
import {
  FacilitiesInterfaceNavContent,
  ProjectInterfaceNavContent,
  ProjectMembersInterfaceNavContent,
  ProjectPropertiesInterfaceNavContent,
} from 'modules/Admin/AdminNavInterfaces';
import { InsightsNavContent, WorkRequestNavContent, WorkRequestsNavContent } from 'modules/Shop/ShopNavInterfaces';

export const FieldRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="work-requests" />} />
    <Route path="insights" element={<InsightsNavContent />} />
    <Route path="facilities" element={<FacilitiesInterfaceNavContent />} />
    <Route path="projects" element={<ProjectInterfaceNavContent />} />
    <Route path="members/project-members" element={<ProjectMembersInterfaceNavContent />} />
    <Route path="properties/project-properties" element={<ProjectPropertiesInterfaceNavContent />} />
    <Route path="work-requests" element={<WorkRequestsNavContent />} />
    <Route path="work-requests/:id" element={<WorkRequestNavContent />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);
