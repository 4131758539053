// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { ActionIcon, Menu } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { isNil, isNotNil } from 'helpers/isNotNil';
import type { WorkCell } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

import { CreateEditWorkCellModal } from './CreateEditWorkCellModal';
import { useWorkCellSetup } from './WorkCellSetupContext';

type Props = {
  canDelete: boolean;
  workCell: WorkCell;
  workCellUpdated: (workCell: WorkCell) => void;
};

export const EditWorkCellMenu = ({ workCell, workCellUpdated, canDelete }: Props) => {
  const {
    updateWorkCell,
    deleteWorkCell,
    mutationRunning,
    serverSideGridProps: { refreshGrid },
  } = useWorkCellSetup();
  const [menuOpen, setMenuOpen] = useState(false);
  const [selected, setSelected] = useState<'edit' | 'delete'>();
  const [saving, setSaving] = useState<'editing' | 'deleting'>();
  return (
    <>
      <Menu withinPortal opened={menuOpen} onChange={setMenuOpen} position="bottom-end">
        <Menu.Target>
          <ActionIcon
            color={saving === 'deleting' ? 'red' : saving === 'editing' ? 'green' : undefined}
            loading={isNotNil(saving)}
            disabled={mutationRunning && isNil(saving)}
            variant="transparent"
          >
            <EvolveIcon className={menuOpen ? '' : 'show-on-row-hover'} icon="MoreOptsVert" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => setSelected('edit')}>Edit</Menu.Item>
          {canDelete && (
            <Menu.Item color="red" onClick={() => setSelected('delete')}>
              Delete
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      <CreateEditWorkCellModal
        opened={selected === 'edit'}
        onClose={() => setSelected(undefined)}
        workCell={workCell}
        onSubmit={async (values) => {
          setSaving('editing');
          setSelected(undefined);
          await updateWorkCell({ ...values, workCellId: workCell.workCellId })
            .then(workCellUpdated)
            .finally(() => setSaving(undefined));
        }}
      />
      {canDelete && (
        <ConfirmationModal
          title="Confirm Delete Work Cell"
          opened={selected === 'delete'}
          onClose={() => setSelected(undefined)}
          loading={mutationRunning}
          buttonColor="red"
          onConfirm={() => {
            setSaving('deleting');
            setSelected(undefined);
            void deleteWorkCell(workCell.workCellId)
              .then(() => {
                refreshGrid();
                notifications.show({
                  title: 'Successfully deleted',
                  message: `Deleted work cell ${workCell.workCellName}`,
                  color: 'green',
                });
              })
              .finally(() => setSaving(undefined));
          }}
        >
          Are you sure you want to delete <b>{workCell.workCellName}</b>?
        </ConfirmationModal>
      )}
    </>
  );
};
