// Copyright ©️ 2024 eVolve MEP, LLC
import { type ReactNode, useMemo } from 'react';

import { MantineProvider } from '@mantine/core';
import { v4 as uuid } from 'uuid';

export const WithColorScheme = ({ children, colorScheme }: { children: ReactNode; colorScheme: 'light' | 'dark' }) => {
  const id = useMemo(() => `a${uuid().replaceAll('-', '')}`, []);
  const selector = `#${id}`;
  return (
    <div id={id}>
      <MantineProvider
        getRootElement={() => document.querySelector<HTMLElement>(selector)!}
        cssVariablesSelector={selector}
        forceColorScheme={colorScheme}
      >
        {children}
      </MantineProvider>
    </div>
  );
};
