// Copyright ©️ 2025 eVolve MEP, LLC
import { useMemo } from 'react';

import { Flex, Title, ActionIcon, Tooltip, Card, Button, Popover } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { format } from 'date-fns';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { BoxWithRef } from 'components/Mantine/BoxWithRef';
import { WrappedMultiSelect, WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { workOrderStatusTypeNames } from 'constants/badgeMappingStatus';
import { useWorkRequests } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/useWorkRequests';

import { InsightsChart } from './InsightsChart';
import { InsightsDetailedReport } from './InsightsDetailedReport';
import { InsightsStats } from './InsightsStats';
import { timeframeOptions } from './types';
import { useInsights } from './useInsights';
import { useWorkOrders } from '../WorkOrders/WorkOrdersPage/useWorkOrders';

export const InsightsPage = () => {
  const { workOrderStatuses } = useWorkOrders();
  const { workRequestStatuses } = useWorkRequests();

  const {
    generateReqData,
    lastUpdated,
    setSelectedRecordType,
    selectedRecordType,
    setSelectedTimeframe,
    showDatePicker,
    setShowDatePicker,
    showSelectedDates,
    setShowSelectedDates,
    detailedReport,
    setDetailedReport,
    facilities,
    selectedFacilities,
    setSelectedFacilities,
    selectedTimeframe,
    setDate,
    date,
    setDateRange,
    dateRange,
    projects,
    selectedProjects,
    setSelectedProjects,
    selectedStatuses,
    setSelectedStatuses,
    recordTypes,
    loading,
  } = useInsights();

  const statusesForMultiSelect =
    (selectedRecordType === 'Work Orders'
      ? workOrderStatuses
          ?.filter((status) => workOrderStatusTypeNames.includes(status.workOrderStatusTypeName))
          .map((status) => ({
            value: status.workOrderStatusTypeId,
            label: status.workOrderStatusTypeName,
          }))
      : workRequestStatuses
          ?.filter(
            (status) =>
              status.workRequestStatusTypeName !== 'Transferred' && status.workRequestStatusTypeName !== 'Canceled',
          )
          .map((status) => ({
            value: status.workRequestStatusTypeId,
            label: status.workRequestStatusTypeName,
          }))
    )?.sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const mappedFacilities = useMemo(
    () =>
      facilities?.map((facility) => ({
        value: facility.facilityId,
        label: facility.facilityName,
      })) ?? [],
    [facilities],
  );

  const mappedProjects = useMemo(
    () =>
      projects?.map((project) => ({
        value: project.projectId,
        label: project.projectName,
      })) ?? [],
    [projects],
  );

  const formatTimestamp = (timestamp: Date | null) => {
    if (timestamp === null) return '';
    return format(new Date(timestamp), 'MM/dd/yy');
  };

  const getTimeframeLabel = (rangeDate?: boolean) => {
    if (rangeDate) {
      return `${formatTimestamp(dateRange[0])} - ${formatTimestamp(dateRange[1])}`;
    }
    return `${formatTimestamp(date)}`;
  };

  const getTimeframeOptions = () =>
    timeframeOptions.map((option) => {
      if (option === 'Date Range') {
        return {
          value: option,
          label: showSelectedDates && (dateRange[0] || dateRange[1]) ? getTimeframeLabel(true) : option,
        };
      }
      if (option === 'Specific Date') {
        return {
          value: option,
          label: showSelectedDates && date ? getTimeframeLabel() : option,
        };
      }
      return { value: option, label: option };
    });

  return (
    <>
      <Flex justify="space-between" gap="lg" m="lg" mb="xs">
        <Title order={2}>Insights</Title>
        <Flex justify="flex-end" gap="sm" wrap="wrap">
          <WrappedSelect
            size="md"
            value={selectedRecordType}
            data={recordTypes ?? []}
            onChange={setSelectedRecordType}
            disabled={loading || showDatePicker}
            style={{ width: 220 }}
          />
          <WrappedMultiSelect
            searchable
            clearable
            size="md"
            value={selectedFacilities}
            data={mappedFacilities}
            onChange={setSelectedFacilities}
            placeholder="All Facilities"
            disabled={loading || showDatePicker}
            style={{ width: 250 }}
          />
          <WrappedMultiSelect
            searchable
            clearable
            size="md"
            value={selectedProjects}
            data={mappedProjects}
            onChange={setSelectedProjects}
            placeholder="All Projects"
            disabled={loading || showDatePicker}
            style={{ width: 250 }}
          />
          <WrappedMultiSelect<(typeof selectedStatuses)[number]>
            searchable
            clearable
            size="md"
            value={selectedStatuses}
            data={statusesForMultiSelect}
            onChange={setSelectedStatuses}
            placeholder="All Statuses"
            disabled={loading || showDatePicker}
            style={{ width: 250 }}
          />
          <Popover
            shadow="md"
            opened={showDatePicker}
            onClose={() => setShowDatePicker(false)}
            middlewares={{ flip: true, shift: true, inline: true }}
            closeOnClickOutside={false}
          >
            <Tooltip
              position="top"
              label={getTimeframeLabel(selectedTimeframe === 'Date Range')}
              disabled={showDatePicker || selectedTimeframe !== 'Date Range'}
            >
              <Popover.Target>
                <BoxWithRef>
                  <WrappedSelect
                    size="md"
                    value={selectedTimeframe}
                    data={getTimeframeOptions()}
                    maxDropdownHeight={400}
                    onDropdownOpen={() => setShowSelectedDates(false)}
                    onDropdownClose={() => setShowSelectedDates(true)}
                    onChange={(value) => {
                      if (value === 'Specific Date' || value === 'Date Range') {
                        setShowDatePicker(true);
                      } else {
                        setShowDatePicker(false);
                      }
                      setSelectedTimeframe(value);
                    }}
                    disabled={loading || showDatePicker}
                    style={{ minWidth: '170px' }}
                  />
                </BoxWithRef>
              </Popover.Target>
            </Tooltip>
            <Popover.Dropdown>
              {selectedTimeframe === 'Date Range' ? (
                <DatePicker
                  type="range"
                  value={dateRange}
                  onChange={(value: [Date | null, Date | null]) => {
                    setDateRange(value);
                  }}
                  defaultDate={dateRange[0] ?? new Date()}
                />
              ) : (
                <DatePicker
                  type="default"
                  value={date}
                  onChange={(value: Date | null) => {
                    setDate(value);
                  }}
                  defaultDate={date ?? new Date()}
                />
              )}

              <Button
                onClick={() => {
                  setShowDatePicker(false);
                }}
                disabled={selectedTimeframe === 'Date Range' ? dateRange.includes(null) : date === null}
                size="sm"
                style={{ marginTop: '10px' }}
              >
                Confirm
              </Button>
            </Popover.Dropdown>
          </Popover>
          <AgGridStyleTooltip openDelay={0} withArrow label={`Last updated on ${lastUpdated}`} disabled={!lastUpdated}>
            <ActionIcon
              color="gray.4"
              variant="outline"
              size={42}
              onClick={generateReqData}
              disabled={loading || showDatePicker}
            >
              <EvolveIcon icon="Refresh" color="gray.6" />
            </ActionIcon>
          </AgGridStyleTooltip>
          <AgGridStyleTooltip
            openDelay={0}
            withArrow
            label={detailedReport ? 'Chart view' : 'Generate detailed report'}
          >
            <ActionIcon
              size={42}
              variant="outline"
              color="gray.4"
              onClick={() => {
                setDetailedReport((prev) => !prev);
                generateReqData();
              }}
              disabled={loading || showDatePicker}
            >
              {detailedReport ? (
                <EvolveIcon icon="Insights" color="gray.6" />
              ) : (
                <EvolveIcon icon="TableChart" color="gray.6" />
              )}
            </ActionIcon>
          </AgGridStyleTooltip>
        </Flex>
      </Flex>

      {detailedReport ? (
        <InsightsDetailedReport />
      ) : (
        <>
          <InsightsStats />
          <Card withBorder style={{ height: '100%', backgroundColor: 'white' }} m="md">
            <InsightsChart />
          </Card>
        </>
      )}
    </>
  );
};
