// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { ActionIcon, Flex, Input } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { AlertingPopover } from 'components/Mantine/AlertingPopover';
import { SearchableCheckList } from 'components/Mantine/SearchableCheckList';
import { TextWithRef } from 'components/Mantine/TextWithRef';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { Task, TaskId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

export type UpdateTaskPredecessorsBody = {
  taskPredecessorIds: TaskId[];
};

type Props = {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: UpdateTaskPredecessorsBody) => void;
  loading?: boolean;
  task: Task;
};

export const SetTaskPredecessorsPopover = ({ opened, onClose, onSubmit, loading, task }: Props) => {
  const paginatedGet = useWrappedPaginatedGet<Task['taskPredecessorIds'][number]>(
    `shop/task/${task.taskId}/viable-predecessors`,
    { lazy: true },
  );
  const [taskPredecessors, setSelectedTasks] = useState<Task['taskPredecessorIds'][number][]>([]);
  const [isDirty, setIsDirty] = useState(false);

  return (
    <AlertingPopover
      opened={opened}
      onClose={onClose}
      isDirty={isDirty}
      onSubmit={() =>
        onSubmit({
          taskPredecessorIds: taskPredecessors.map((t) => t.taskId),
        })
      }
      loading={loading}
      disabled={!isDirty}
    >
      <ActionIcon onClick={onClose} style={{ position: 'absolute', right: 5, top: 5 }}>
        <EvolveIcon icon="Close" size="sm" color="dark" />
      </ActionIcon>
      <Flex direction="column" gap={3}>
        <Input.Label>Predecessors for {task.taskName}</Input.Label>
        <SearchableCheckList
          idKey="taskId"
          nothingFoundMessage="No tasks found."
          getItemLabel={(t) => (
            <AgGridStyleTooltip label={t.taskName}>
              <TextWithRef>{t.taskName}</TextWithRef>
            </AgGridStyleTooltip>
          )}
          paginatedGet={paginatedGet}
          preselectedOptions={task.taskPredecessorIds}
          onChange={setSelectedTasks}
          setIsDirty={setIsDirty}
          excludeIds={[task.taskId]}
          disabled={loading}
          // eslint-disable-next-line @typescript-eslint/no-deprecated
          useLike={false}
          searchKey="taskName"
          textInputProps={{ size: 'xs', placeholder: 'Search Bill of Process...', style: { width: '100%' } }}
          flexListProps={{
            gap: 'xs',
            style: {
              height: 200,
            },
          }}
        />
      </Flex>
    </AlertingPopover>
  );
};
