// Copyright ©️ 2024 eVolve MEP, LLC
import React from 'react';

import type { SelectedFacility } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { useGeneralContext } from 'helpers/useGeneralContext';
import type { ShopConfiguration, ShopConfigurationId } from 'hooks/projectsAndFacilities/types';
import type { TaskTypeId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import type {
  WorkCell,
  WorkCellId,
  WorkCellTaskType,
  WorkCellTaskTypeId,
} from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

import type { useWorkCellsGrid } from './useWorkCellsGrid';

export type WorkCellSetupContextType = {
  selectedFacility: SelectedFacility;
  /** `serverSideGridProps` specifically for the "Draft" table */
  serverSideGridProps: ReturnType<typeof useWorkCellsGrid>;
  /** The active, in use `shopConfiguration` */
  activeShopConfiguration: ShopConfiguration;
  draftShopConfiguration: ShopConfiguration | undefined;
  loadingDraftShopConfiguration: boolean;

  createDraftShopConfiguration: () => Promise<void>;
  deleteDraftShopConfiguration: () => Promise<void>;
  applyDraftShopConfiguration: () => Promise<void>;

  createWorkCell: (
    workCell: Pick<WorkCell, 'workCellName' | 'workCellDescription'>,
    shopConfigurationId: ShopConfigurationId,
  ) => Promise<WorkCell>;
  updateWorkCell: (params: Pick<WorkCell, 'workCellId' | 'workCellName' | 'workCellDescription'>) => Promise<WorkCell>;
  deleteWorkCell: (workCellId: WorkCellId) => Promise<void>;

  mapWorkCellTaskType: (workCellId: WorkCellId, taskTypeId: TaskTypeId) => Promise<WorkCellTaskType>;
  unmapWorkCellTaskType: (workCellTaskTypeId: WorkCellTaskTypeId) => Promise<void>;

  tasksNeedRefreshing: boolean;
  setTasksNeedRefreshing: (tasksNeedRefreshing: boolean) => void;

  mutationRunning: boolean;
};
export const WorkCellSetupContext = React.createContext<WorkCellSetupContextType | undefined>(undefined);
export const useWorkCellSetup = () => useGeneralContext(WorkCellSetupContext, 'WorkCellSetup');
