// Copyright ©️ 2024 eVolve MEP, LLC
import { type ReactNode, forwardRef, useState } from 'react';

import { Flex, NumberInput, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { AlertingPopover } from 'components/Mantine/AlertingPopover';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { isNil, isNotNil } from 'helpers/isNotNil';
import {
  useUnitOfMeasure,
  type UnitOfMeasureId,
} from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useUnitOfMeasure';

import { NewUnitOfMeasureModal } from './NewUnitOfMeasureModal';

export type ItemFields = {
  itemName: string;
  quantity: number;
  unitOfMeasureId: UnitOfMeasureId;
};

type Props = {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: ItemFields) => Promise<void>;
  children?: ReactNode;
  loading?: boolean;
  defaultValues?: ItemFields;
};

export const HiddenTarget = forwardRef<HTMLDivElement>((props, ref) => <div ref={ref} {...props} />);

export const EditItemPopover = ({ opened, onClose, onSubmit, loading, children, defaultValues }: Props) => {
  const [customUOM, setCustomUOM] = useState('');
  const { uoms } = useUnitOfMeasure();
  const form = useForm<ItemFields>({
    initialValues: {
      itemName: '',
      unitOfMeasureId: '',
    } as ItemFields,
    validate: {
      itemName: (n) => isNil(n) || n.length === 0,
      quantity: (q) => isNil(q) || q <= 0 || q > 9999,
      unitOfMeasureId: (id) => isNil(id) || id.length === 0,
    },
  });

  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <AlertingPopover
          width={350}
          opened={opened}
          suppressJiggle={!!customUOM}
          onClose={onClose}
          isDirty={form.isDirty() || !!customUOM}
          disabled={!form.isValid()}
          loading={loading}
          target={children}
          onOpen={() => {
            if (isNotNil(defaultValues)) {
              form.setValues(defaultValues);
              form.resetDirty(defaultValues);
            } else {
              form.reset();
              form.setDirty({});
            }
          }}
          onSubmit={form.onSubmit(onSubmit)}
        >
          <Flex direction="column" gap="sm">
            <TextInput label="Item Name" autoFocus disabled={loading} {...form.getInputProps('itemName')} />
            <Flex gap="sm">
              <NumberInput
                style={{ width: 100 }}
                max={9999}
                min={1}
                label="Qty"
                disabled={loading}
                {...form.getInputProps('quantity')}
              />
              <WrappedSelect
                style={{ width: '100%' }}
                searchable
                rightSection={
                  <AgGridStyleTooltip label="Start typing to create a new UOM" withArrow openDelay={0}>
                    <EvolveIcon icon="Info" size="sm" color="cyan.5" />
                  </AgGridStyleTooltip>
                }
                maxLength={32}
                creatable
                onCreate={(query) => {
                  setCustomUOM(query);
                  return null;
                }}
                data={[
                  ...(uoms?.map((u) => ({
                    label: `${u.unitOfMeasureName} (${u.unitOfMeasureCode})`,
                    value: u.unitOfMeasureId,
                  })) ?? [
                    {
                      label: 'Loading',
                      value: '',
                      disabled: true,
                    },
                  ]),
                ]}
                label="UOM"
                disabled={loading}
                {...form.getInputProps('unitOfMeasureId')}
              />
            </Flex>
          </Flex>
        </AlertingPopover>
      </form>
      <NewUnitOfMeasureModal
        opened={!!customUOM}
        onAdded={(unitOfMeasure) => {
          form.setDirty({
            unitOfMeasureId: true,
          });
          form.setFieldValue('unitOfMeasureId', unitOfMeasure.unitOfMeasureId);
        }}
        defaultValue={customUOM}
        onClose={() => setCustomUOM('')}
      />
    </>
  );
};
