// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { ActionIcon } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import type { WorkCellTaskType } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

import { useWorkCellSetup } from './WorkCellSetupContext';

type Props = {
  workCellTaskType: WorkCellTaskType;
};

export const UnmapTaskType = ({ workCellTaskType }: Props) => {
  const {
    mutationRunning,
    unmapWorkCellTaskType,
    serverSideGridProps: { refreshDetailGrid },
  } = useWorkCellSetup();
  const [opened, setOpened] = useState(false);
  const [deleting, setDeleting] = useState(false);
  return (
    <>
      <ActionIcon
        color={deleting ? 'red' : undefined}
        disabled={mutationRunning && !deleting}
        loading={deleting}
        onClick={() => setOpened(true)}
        variant="transparent"
      >
        <EvolveIcon icon="Unpin" color="inheirt" size="sm" className="show-on-row-hover" />
      </ActionIcon>
      <ConfirmationModal
        title="Confirm unmap task type"
        buttonColor="red"
        onConfirm={() => {
          setOpened(false);
          setDeleting(true);
          void unmapWorkCellTaskType(workCellTaskType.workCellTaskTypeId)
            .then(() => {
              notifications.show({
                title: 'Successfully unmapped',
                message: `Unmapped task type ${workCellTaskType.taskTypeName}`,
                color: 'green',
              });
              refreshDetailGrid(workCellTaskType.workCellId, 'Tasks');
            })
            .finally(() => setDeleting(false));
        }}
        onClose={() => setOpened(false)}
        opened={opened}
        loading={deleting}
      >
        Are you sure you want to unmap <b>{workCellTaskType.taskTypeName}</b>?
      </ConfirmationModal>
    </>
  );
};
