// Copyright ©️ 2024 eVolve MEP, LLC
import { useCallback } from 'react';

import type { EvolveApiReturn } from 'hooks-api/useWrappedApiCall';
import { getDaysRemaining } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/common';

import type { WorkCellTask } from './types';

export const getDaysRemainingWorkCellTask = (workCellTask: WorkCellTask) =>
  getDaysRemaining(workCellTask.taskStatusTypeName, workCellTask.needBy, workCellTask.taskStatusTypeUpdatedOn);

export const useEmptyApiResponder = <T>(...changeOn: any[]) =>
  useCallback(
    async (): Promise<EvolveApiReturn<T>> => ({
      data: [],
      entireCount: 0,
      requestedSkip: 0,
      requestedTake: 0,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    changeOn,
  );
