// Copyright ©️ 2024 eVolve MEP, LLC
import type { FormEvent, ReactNode } from 'react';

import { Alert, Box, Flex, Title } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';

import { LoginErrorAlert, type LoginErrorCode } from './LoginErrorAlert';

type Props<T> = {
  title: string;
  children: ReactNode;
  successMessage?: string;
  errorCode?: LoginErrorCode | string;
  onCloseSuccess?: () => void;
  onCloseError?: () => void;
} & (
  | {
      form: UseFormReturnType<T>;
      onSubmit: (values: T, event?: FormEvent<HTMLFormElement>) => void;
    }
  | {
      form?: never;
      onSubmit: () => void;
    }
);

export const AuthFormWrapper = <T,>({
  title,
  form,
  onSubmit,
  children,
  successMessage,
  errorCode,
  onCloseSuccess,
  onCloseError,
}: Props<T>) => (
  <Box style={{ flex: '1 0 auto', maxWidth: 400, animation: 'fadeIn .5s' }}>
    <Box style={{ minHeight: 50 }}>
      {!!successMessage && (
        <Alert color="green" variant="light" withCloseButton onClose={onCloseSuccess}>
          {successMessage}
        </Alert>
      )}
      <LoginErrorAlert errorCode={errorCode} onClose={onCloseError} />
    </Box>
    <form
      onSubmit={
        form
          ? form.onSubmit(onSubmit)
          : (e) => {
              onSubmit();
              e.preventDefault();
              e.stopPropagation();
            }
      }
    >
      <Flex direction="column" gap="md" mt="xl" mb={140}>
        <Title fw={400}>{title}</Title>
        {children}
      </Flex>
    </form>
  </Box>
);
