// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { ActionIcon, Flex, List, Menu, Modal, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { AlertingPopover } from 'components/Mantine/AlertingPopover';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { ModalButtons } from 'components/Mantine/ModalButtons';
import { useWrappedDelete, useWrappedPatch, useWrappedPost } from 'hooks-api/useWrappedApiCall';

import type { PartCatalogId, PartCategory, PartCategoryId } from './types';

type Props = {
  allowUpdateCategory: boolean;
  partCatalogId: PartCatalogId;
  partCategory: PartCategory;
  onCategoryCreated: (newPartCategoryId: PartCategoryId) => void;
  onCategoryUpdated: (updatedPartCategory: PartCategory) => void;
  onCategoryDeleted: () => void;
};

type CreateCategoryBody = {
  partCategoryName: string;
  parentPartCategoryId: PartCategoryId;
  partCatalogId: PartCatalogId;
};

export const CategoryMenu = ({
  allowUpdateCategory,
  partCategory,
  partCatalogId,
  onCategoryCreated,
  onCategoryUpdated,
  onCategoryDeleted,
}: Props) => {
  const [opened, setOpened] = useState(false);
  const [addingCategory, setAddingCategory] = useState(false);
  const [editingCategory, setEditingCategory] = useState(false);
  const [deletingCategory, setDeletingCategory] = useState(false);

  const newCategoryForm = useForm<CreateCategoryBody>({
    initialValues: {
      partCategoryName: '',
      partCatalogId,
      parentPartCategoryId: partCategory.partCategoryId,
    },
    validate: {
      partCategoryName: (v) => !v,
    },
  });
  const editCategoryForm = useForm<Pick<CreateCategoryBody, 'partCategoryName'>>({
    initialValues: {
      partCategoryName: '',
    },
    validate: {
      partCategoryName: (v) => !v,
    },
  });

  const { apiCall: createCategoryApiCall, loading: creating } = useWrappedPost<PartCategory, CreateCategoryBody>(
    'moab/partCategory',
  );
  const { apiCall: updateCategoryApiCall, loading: updating } = useWrappedPatch<
    PartCategory,
    Pick<CreateCategoryBody, 'partCategoryName'>
  >(`moab/partCategory/${partCategory.partCategoryId}`);
  const { apiCall: deleteCategoryApiCall, loading: deleting } = useWrappedDelete(
    `moab/partCategory/${partCategory.partCategoryId}`,
  );
  const saving = creating || updating || deleting;

  const createCategory = (values: typeof newCategoryForm.values) => {
    void createCategoryApiCall(values).then((c) => {
      notifications.show({
        title: 'Successfully created',
        message: `Category ${c.partCategoryName} created`,
        color: 'green',
      });
      setAddingCategory(false);
      onCategoryCreated(c.partCategoryId);
      newCategoryForm.setFieldValue('partCategoryName', '');
    });
  };
  const updateCategory = (values: typeof editCategoryForm.values) => {
    void updateCategoryApiCall(values).then((c) => {
      notifications.show({
        title: 'Successfully updated',
        message: `Category ${c.partCategoryName} updated`,
        color: 'green',
      });
      setEditingCategory(false);
      onCategoryUpdated(c);
    });
  };
  const deleteCategory = () => {
    void deleteCategoryApiCall().then(() => {
      notifications.show({
        title: 'Successfully created',
        message: `Category ${partCategory.partCategoryName} deleted`,
        color: 'green',
      });
      setDeletingCategory(false);
      onCategoryDeleted();
    });
  };

  return (
    <Flex onClick={(e) => e.stopPropagation()} gap="xs" align="center">
      <AgGridStyleTooltip label="Add subcategory" withArrow openDelay={100}>
        <ActionIcon
          className={addingCategory ? undefined : 'hover-title--on'}
          color="dark"
          variant="filled"
          size="xs"
          onClick={() => setAddingCategory(true)}
        >
          <EvolveIcon icon="Add" size="xs" />
        </ActionIcon>
      </AgGridStyleTooltip>

      {allowUpdateCategory && (
        <Menu opened={opened} onChange={setOpened}>
          <Menu.Target>
            <ActionIcon mx={4} onClick={() => setOpened((o) => !o)}>
              <EvolveIcon
                className={opened || addingCategory || deletingCategory ? undefined : 'hover-title--on'}
                icon="MoreOptsVert"
              />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={() => setEditingCategory(true)}>Rename</Menu.Item>
            <Menu.Item onClick={() => setDeletingCategory(true)} c="red">
              Delete
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      )}

      <AlertingPopover
        onOpen={() => {
          editCategoryForm.setFieldValue('partCategoryName', partCategory.partCategoryName);
          editCategoryForm.resetDirty({
            ...editCategoryForm.values,
            partCategoryName: partCategory.partCategoryName,
          });
        }}
        disabled={!editCategoryForm.isValid()}
        loading={saving}
        confirmButtonText="Save"
        onSubmit={editCategoryForm.onSubmit(updateCategory)}
        opened={editingCategory}
        onClose={() => setEditingCategory(false)}
        isDirty={editCategoryForm.isDirty()}
      >
        <form onSubmit={editCategoryForm.onSubmit(updateCategory)}>
          <TextInput
            label="New Category Name"
            autoFocus
            disabled={saving}
            {...editCategoryForm.getInputProps('partCategoryName')}
          />
        </form>
      </AlertingPopover>

      <Modal
        title="Create Subcategory"
        opened={addingCategory}
        onClose={() => setAddingCategory(false)}
        centered
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <form onSubmit={newCategoryForm.onSubmit(createCategory)}>
          <Flex direction="column" gap="md">
            <TextInput
              label="New Category Name"
              autoFocus
              disabled={saving}
              {...newCategoryForm.getInputProps('partCategoryName')}
            />
            <Text fz="sm">
              This category will be a subcategory of <b>{partCategory.partCategoryName}</b>. Any attributes available on{' '}
              <b>{partCategory.partCategoryName}</b> will also be available to this subcategory.
            </Text>
            <ModalButtons
              type="submit"
              onClose={() => setAddingCategory(false)}
              confirmationText="Submit"
              loading={saving}
              disabled={!newCategoryForm.isValid()}
            />
          </Flex>
        </form>
      </Modal>

      <ConfirmationModal
        title="Confirm deletion"
        onConfirm={deleteCategory}
        onClose={() => setDeletingCategory(false)}
        opened={deletingCategory}
        loading={saving}
        requireUserEmail
      >
        Deleting this category will delete:
        <List>
          <List.Item>All subcategories underneath it</List.Item>
          <List.Item>All attributes, attribute variants, and codes</List.Item>
          <List.Item>All items</List.Item>
        </List>
        Are you sure you want to delete category <b>{partCategory.partCategoryName}</b>?
      </ConfirmationModal>
    </Flex>
  );
};
