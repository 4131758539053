// Copyright ©️ 2024 eVolve MEP, LLC

import type { ColDef } from 'ag-grid-enterprise';

import { isNotNil } from 'helpers/isNotNil';

import type { ProjectUser } from './types';

const fallback = '--';

export const getProjectAdminsColDefs = (): ColDef<ProjectUser>[] => [
  {
    field: 'user',
    headerName: 'User',
    flex: 1,
    minWidth: 100,
    valueGetter: ({ data }) => {
      const nameParts = [data?.user.userFirstName, data?.user.userLastName].filter(isNotNil);
      if (nameParts.length > 0) {
        return nameParts.join(' ');
      }
      return fallback;
    },
  },
  {
    headerName: 'Email',
    field: 'user.userEmail',
    valueFormatter: ({ value }) => value ?? fallback,
    flex: 2,
    minWidth: 250,
  },
  {
    headerName: 'Office Phone',
    width: 150,
    field: 'user.workPhoneNumber',
    valueFormatter: ({ value }) => value ?? fallback,
    sortable: false,
  },
  {
    headerName: 'Mobile Phone',
    width: 150,
    field: 'user.personalPhoneNumber',
    valueFormatter: ({ value }) => value ?? fallback,
    sortable: false,
  },
  {
    headerName: 'Status',
    field: 'inviteStatus',
    minWidth: 100,
    width: 150,
    cellStyle: ({ data }) => ({
      color: data?.inviteStatus === 'Active' ? 'teal' : 'orange',
    }),
  },
];
