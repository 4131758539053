// Copyright ©️ 2024 eVolve MEP, LLC
/* eslint-disable no-console */
import { useCallback, useState } from 'react';

import type { LOCAL_STORAGE_KEY, LOCAL_STORAGE_TYPE } from 'constants/globalConstants';

const getStorageArea = () => (localStorage.getItem('STAY_SIGNED_IN') === 'true' ? localStorage : sessionStorage);

export const getLocalStorage = <T extends LOCAL_STORAGE_KEY>(
  key: T,
  initialValue?: LOCAL_STORAGE_TYPE[T],
): LOCAL_STORAGE_TYPE[T] | undefined => {
  try {
    const item = getStorageArea().getItem(key);
    if (item === 'undefined') return initialValue;
    return item ? (JSON.parse(item) as LOCAL_STORAGE_TYPE[T]) : initialValue;
  } catch (error) {
    console.error(`Error [getLocalStorage], ${error}`);
    return initialValue;
  }
};

export const setLocalStorage = <T extends LOCAL_STORAGE_KEY>(
  key: T,
  value: LOCAL_STORAGE_TYPE[T],
  throwOnError = false,
) => {
  try {
    // null is valid, undefined is not

    if (value !== undefined) {
      getStorageArea().setItem(key, JSON.stringify(value));
    } else {
      removeLocalStorage(key);
    }
  } catch (error) {
    console.error(`Error [setLocalStorage], ${error}`);
    if (throwOnError) throw error;
  }
};

export const removeLocalStorage = (key: LOCAL_STORAGE_KEY, throwOnError = false) => {
  try {
    getStorageArea().removeItem(key);
  } catch (error) {
    console.error(`Error [removeLocalStorage], ${error}`);
    if (throwOnError) throw error;
  }
};

/**
 * Type-safe local storage handler.
 * @param key The local storage key name
 * @param initialValue The value to return if no value found in storage
 */
export const useLocalStorage = <K extends LOCAL_STORAGE_KEY>(
  key: K,
  initialValue: LOCAL_STORAGE_TYPE[K],
  writeToLocalStorage = true,
) => {
  const [storedValue, setStoredValue] = useState(getLocalStorage(key, initialValue) ?? initialValue);

  const setValue = useCallback(
    (value: LOCAL_STORAGE_TYPE[K] | ((value: typeof storedValue) => LOCAL_STORAGE_TYPE[K])) => {
      setStoredValue((stored) => {
        const valueToStore = value instanceof Function ? value(stored) : value;
        if (writeToLocalStorage) {
          setLocalStorage(key, valueToStore, true);
        }
        return valueToStore;
      });
    },
    [key, writeToLocalStorage],
  );

  return [storedValue, setValue] as const;
};
