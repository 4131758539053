// Copyright ©️ 2024 eVolve MEP, LLC
import { useRef, useCallback, useEffect, useMemo, useState, type ReactNode } from 'react';

import { Flex } from '@mantine/core';
import type {
  ColDef,
  GridApi,
  GridReadyEvent,
  GetRowIdParams,
  ICellRendererParams,
  IDetailCellRendererParams,
} from 'ag-grid-enterprise';
import type { AgGridReact, AgGridReactProps } from 'ag-grid-react';

import { EvolveIcon, type EvolveIconName } from 'assets/icons/EvolveIcon';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { useMantineNotifications } from 'components/Mantine/useMantineNotifications';
import { useSaveColumnState } from 'helpers/ag-grid/useSaveColumnState';
import { isNil, isNotNil } from 'helpers/isNotNil';
import type { AdditionalRequestConfig } from 'hooks-api/useEvolveApi';
import type { PageFetcher } from 'hooks-api/useWrappedApiCall';

import { baseColumnDef, baseGridDef, compactRowHeight } from './baseColumnDef';
import { convertAgGridRequestToParams } from './convertAgGridRequest';
import { ExportGridButton } from './ExportGridButton';
import { NoRowsOverlay } from './NoRowsOverlay';

/** Ensures TData[K] is a string. */
export type KeyHasStringValue<TData, K extends keyof TData> = TData[K] extends string ? K : never;

type Props<TData, TKey extends keyof TData, DetailTitle> = {
  tableName: string;
  fetchPage: PageFetcher<TData>;
  /**
   * The columnDefs for this table.
   * Simply a pass-through to make spreading required props onto the table easier.
   */
  colDef: ColDef<TData>[];
  /** Unique ID key of each row. Value must be of type string, otherwise will throw `never`. */
  rowId: TKey & KeyHasStringValue<TData, TKey>;
  detailTables?: readonly DetailTable<TData, DetailTitle>[];
  /** Whether or not to persist sorts and filters applied to columns. @default true */
  saveFilters?: boolean;
  /** Whether or not to persist the column state (column order, width, pins, etc) @default saveFilters */
  saveColumns?: boolean;
};

export type DetailTable<ParentRowType, DetailTitle, DetailRowType = any> = {
  title: DetailTitle;
  icon: EvolveIconName | null;
  exportable?: boolean;
  /**
   * If `true`, will hide the header on this detail table.
   * @default false
   * */
  hideHeader?: boolean;
  autoExpand?: boolean;
  colDef: ColDef<DetailRowType>[] | ((parentData: ParentRowType) => ColDef<DetailRowType>[]);
  fetchPage: PageFetcher<DetailRowType>;
  rightSide?: ReactNode | ((parentData: ParentRowType, refreshDetailGrid: () => void) => ReactNode);
  /**
   * This function is used to map additional URL params (or anything else)
   * onto the detail table Paginated GET request.
   *
   * For example, if the parent is a WorkRequest,
   * and the detail table requires a URL parameter `workRequestId`,
   * this function would be
   *
   *     configMapper: (row: WorkRequest) => ({
   *       params: {
   *         workRequestId: row.workRequestId,
   *       },
   *     })
   * */
  configMapper: (parentData: ParentRowType) => AdditionalRequestConfig<DetailRowType>;
} & {
  exportable?: true;
  exportFileName?: (parentData: ParentRowType) => string;
};

type MidLevelRow<TData, DetailTitle> = {
  parentRowData: TData | undefined;
} & Pick<DetailTable<TData, DetailTitle>, 'title' | 'icon'>;

/**
 * Used to generate all necessary props to pass into an `<AgGridReact />`
 */
export const useServerSideGrid = <TData, TKey extends keyof TData, DetailTitle extends string = string>({
  tableName,
  fetchPage,
  colDef,
  rowId,
  saveFilters = true,
  saveColumns = saveFilters,
  detailTables: originalDetailTables,
}: Props<TData, TKey, DetailTitle>) => {
  const gridRef = useRef<AgGridReact<TData>>(null);
  const { showError } = useMantineNotifications();
  // `detailTables` is usually defined in-line, meaning every re-render
  // causes `detailTables` to be recreated, causing the AG Grid props to be recreated.
  // We use `useState` here to prevent this domino effect from happening,
  // though it has the side effect of making editing the detail tables much harder.
  // Luckily that use case doesn't exist yet.
  const [detailTables] = useState(originalDetailTables);
  const masterDetail = useMemo(() => isNotNil(detailTables), [detailTables]);
  // TODO: This probably should be set via CSS instead
  const rowHeight = useMemo(() => (masterDetail ? compactRowHeight : undefined), [masterDetail]);
  const [totalRowCount, setTotalRowCount] = useState<number>();
  const { filterIsSet, loadColumnState, saveColumnState } = useSaveColumnState(
    tableName,
    gridRef,
    saveFilters,
    saveColumns,
  );

  const setDataSource = useCallback(
    (api: GridApi<TData>) => {
      setTimeout(() => {
        // If the datasource itself changes,
        // the existing row count is no longer valid
        setTotalRowCount(undefined);
        api.setGridOption('serverSideDatasource', {
          getRows: async ({ request, success }) => {
            api.hideOverlay();
            // If we're requesting fresh data, any previous selection is no longer valid
            if (request.startRow === 0) {
              api.deselectAll();
            }
            const { data, entireCount } = await fetchPage(...convertAgGridRequestToParams(request)).catch((err) => {
              showError(err);
              throw err;
            });
            success({
              rowData: data,
              rowCount: entireCount,
            });
            setTotalRowCount(entireCount);
            if (entireCount === 0) api.showNoRowsOverlay();
          },
        });
      });
    },
    // When fetchPage changes, so will setDataSource...
    [fetchPage, showError],
  );
  // ...and when setDataSource changes, we'll then re-set the datasource on the grid,
  // which will automatically cause it to re-fetch using the new fetchPage
  useEffect(() => {
    if (isNotNil(gridRef.current?.api)) {
      setDataSource(gridRef.current.api);
    }
  }, [gridRef, setDataSource]);

  // Calculates the extra spacing needed to indent detail tables to look good
  const extraSpace = useMemo(() => {
    const dropdown = colDef.findIndex((c) => c.cellRenderer === 'agGroupCellRenderer');
    return colDef
      .slice(0, dropdown + 1)
      .filter((c) => !c.hide)
      .reduce((o, c) => Math.max(c.width ?? 0, 48) + o, 0);
  }, [colDef]);

  const onGridReady = useCallback(
    (gre: GridReadyEvent<TData>) => {
      if (isNotNil(detailTables)) {
        // Detail table renderer params (renders different data depending on which intermediate row is opened)
        const itemDetailRendererParams = (midLevelRow: ICellRendererParams<MidLevelRow<TData, DetailTitle>>) => {
          const parentData = midLevelRow.node.data?.parentRowData;
          const detailConfig = detailTables.find(({ title }) => title === midLevelRow.data?.title);
          if (isNil(detailConfig) || isNil(parentData)) {
            return null;
          }
          const detailColDef =
            typeof detailConfig.colDef === 'function' ? detailConfig.colDef(parentData) : detailConfig.colDef;
          return {
            refreshStrategy: 'everything',
            detailGridOptions: {
              ...baseGridDef,
              rowHeight,
              headerHeight: detailConfig.hideHeader ? 0 : rowHeight,
              domLayout: 'autoHeight',
              rowModelType: 'serverSide',
              defaultColDef: {
                ...baseColumnDef,
                suppressHeaderContextMenu: true,
                contextMenuItems: [],
              },
              columnDefs: [
                // Spacer, probably not the right way to do this
                {
                  width: extraSpace,
                  resizable: false,
                  sortable: false,
                  lockPosition: 'left',
                  enableCellChangeFlash: false,
                },
                ...detailColDef,
              ],
              noRowsOverlayComponent: () => (
                <NoRowsOverlay px={extraSpace + 14} pr={0} align="flex-start" label="No results found." />
              ),
              onGridReady: ({ api: detailApi }) => {
                detailApi.setGridOption('serverSideDatasource', {
                  getRows: async ({ request, success }) => {
                    detailApi.hideOverlay();
                    const { data: rowData, entireCount: rowCount } = await detailConfig
                      .fetchPage(...convertAgGridRequestToParams(request, detailConfig.configMapper(parentData)))
                      .catch((err) => {
                        showError(err);
                        throw err;
                      });
                    success({
                      rowData,
                      rowCount,
                    });
                    if (rowCount === 0) detailApi.showNoRowsOverlay();
                  },
                });
              },
            },
          } as Partial<
            IDetailCellRendererParams<
              MidLevelRow<TData, DetailTitle>,
              /**
               * This type is the `DetailRowType` from `detailConfig`,
               * but I'm not sure how to extract that (and it provides little/no value anyway)
               */
              any
            >
          >;
        };

        // Intermediate table renderer params
        const detailCellRendererParams = {
          detailGridOptions: {
            ...baseGridDef,
            rowHeight,
            headerHeight: 0,
            domLayout: 'autoHeight',
            masterDetail: true,
            defaultColDef: {
              ...baseColumnDef,
              suppressHeaderContextMenu: true,
              contextMenuItems: [],
              enableCellChangeFlash: false,
              sortable: false,
              resizable: false,
            },
            getRowId: (d) => d.data.title,
            columnDefs: [
              ...(extraSpace - 48 > 0
                ? [
                    {
                      width: extraSpace - 48,
                      resizable: false,
                      sortable: false,
                      lockPosition: 'left',
                    },
                  ]
                : []),
              {
                cellRenderer: 'agGroupCellRenderer',
                width: 58,
                lockPosition: 'left',
              },
              {
                field: 'title',
                width: 200,
                cellRenderer: ({ value, data }: ICellRendererParams<MidLevelRow<TData, DetailTitle>>) => (
                  <Flex align="center" gap="xs">
                    <EvolveIcon icon={data?.icon ?? null} light />
                    {value}
                  </Flex>
                ),
              },
              {
                width: 120,
                cellRenderer: CellRenderer<MidLevelRow<TData, DetailTitle>>(
                  ({ data, api }) => {
                    const detailConfig = detailTables.find((t) => t.title === data.title);
                    if (isNil(data.parentRowData) || isNil(detailConfig) || !detailConfig.exportable) return null;
                    return (
                      <ExportGridButton
                        fileName={detailConfig.exportFileName?.(data.parentRowData) ?? detailConfig.title}
                        columnDefs={
                          typeof detailConfig.colDef === 'function'
                            ? detailConfig.colDef(data.parentRowData)
                            : detailConfig.colDef
                        }
                        fetchPage={detailConfig.fetchPage}
                        config={detailConfig.configMapper(data.parentRowData)}
                        getDetailGridState={() => {
                          const detailApi = api.getDetailGridInfo(`detail_${detailConfig.title}`)?.api;
                          return {
                            columnState: detailApi?.getColumnState(),
                            filterModel: detailApi?.getFilterModel() ?? null,
                          };
                        }}
                      />
                    );
                  },
                  { flexProps: { justify: 'flex-end' } },
                ),
              },
              {
                minWidth: 64,
                flex: 1,
                cellRenderer: CellRenderer<MidLevelRow<TData, DetailTitle>>(
                  ({ data, api }) => {
                    const rightSide = detailTables.find((t) => t.title === data.title)?.rightSide;
                    if (isNil(rightSide) || isNil(data.parentRowData)) return null;
                    const refreshDetailGrid = () =>
                      api.getDetailGridInfo(`detail_${data.title}`)?.api?.refreshServerSide({ purge: true });
                    return typeof rightSide === 'function'
                      ? rightSide(data.parentRowData, refreshDetailGrid)
                      : rightSide;
                  },
                  { flexProps: { justify: 'flex-end' } },
                ),
              },
            ],
            onGridReady: (p) => {
              p.api.setGridOption('detailCellRendererParams', itemDetailRendererParams);
            },
            onFirstDataRendered: (p) => {
              detailTables
                .filter((d) => d.autoExpand)
                .forEach(({ title }) => p.api.getRowNode(title)?.setExpanded(true));
            },
          },
          getDetailRowData: (p) => {
            p.successCallback(
              detailTables.map(({ title, icon }) => ({
                title,
                icon,
                parentRowData: p.data,
              })),
            );
          },
        } as Partial<IDetailCellRendererParams<TData, MidLevelRow<TData, DetailTitle>>>;
        gre.api.setGridOption('detailCellRendererParams', detailCellRendererParams);
      }
      loadColumnState();
      setDataSource(gre.api);
    },
    [detailTables, extraSpace, loadColumnState, rowHeight, setDataSource, showError],
  );

  const refreshGrid = useCallback(() => {
    setTimeout(() => {
      gridRef.current?.api.refreshServerSide({ purge: true });
    });
  }, []);

  const expandDetailGrid = useCallback((parentRowId: TData[TKey], detailTableTitle: DetailTitle) => {
    gridRef.current?.api.getRowNode(parentRowId as string)?.setExpanded(true);
    setTimeout(() => {
      const detailGridId = `detail_${parentRowId}`;
      const detailGrid = gridRef.current?.api.getDetailGridInfo(detailGridId);
      detailGrid?.api?.getRowNode(detailTableTitle)?.setExpanded(true);
    });
  }, []);

  const refreshDetailGrid = useCallback((parentRowId: TData[TKey], detailTableTitle?: DetailTitle) => {
    const detailGridId = `detail_${parentRowId}`;
    const detailGrid = gridRef.current?.api.getDetailGridInfo(detailGridId);
    if (isNotNil(detailTableTitle)) {
      const childDetailGrid = detailGrid?.api?.getDetailGridInfo(`detail_${detailTableTitle}`);
      childDetailGrid?.api?.refreshServerSide({ purge: true });
    } else {
      detailGrid?.api?.forEachDetailGridInfo((grid) => grid.api?.refreshServerSide({ purge: true }));
    }
  }, []);

  const selectNextRow = useCallback(
    (
      index: number,
      /** If false, will select previous row */
      next: boolean,
    ) => {
      const numRows = gridRef.current?.api.getDisplayedRowCount() ?? 0;
      if (numRows > 0) {
        let newIndex = index + (next ? 1 : -1);
        if (newIndex < 0) newIndex += numRows;
        else if (newIndex >= numRows) newIndex -= numRows;
        const newRow = gridRef.current?.api.getDisplayedRowAtIndex(newIndex);
        if (newRow?.detail) {
          selectNextRow(newIndex, next);
        } else {
          newRow?.setSelected(true, true);
          gridRef.current?.api.ensureIndexVisible(newIndex);
        }
      }
    },
    [],
  );

  const spreadableAgGridProps = useMemo<
    AgGridReactProps<TData> & {
      ref: typeof gridRef;
    }
  >(
    () => ({
      ...baseGridDef,
      ref: gridRef,
      onGridReady,
      columnDefs: colDef,
      defaultColDef: baseColumnDef,
      getRowId: (row: GetRowIdParams<TData>) => row.data[rowId] as string,
      masterDetail,
      rowModelType: 'serverSide',
      rowHeight,
      headerHeight: rowHeight,
      onFilterChanged: saveColumnState,
      onColumnMoved: saveColumnState,
      onColumnResized: saveColumnState,
      onColumnPinned: saveColumnState,
      onColumnVisible: saveColumnState,
      onSortChanged: saveColumnState,
    }),
    [onGridReady, colDef, masterDetail, rowHeight, saveColumnState, rowId],
  );

  return useMemo(
    () =>
      ({
        /**
         * Spread these props onto your `AgGridReact` component. Example:
         *
         *     const { agGridProps } = useServerSideGrid({...});
         *     return <AgGridReact {...agGridProps} />;
         * */
        agGridProps: spreadableAgGridProps,
        refreshGrid,
        refreshDetailGrid,
        expandDetailGrid,
        selectNextRow,
        filterIsSet,
        totalRowCount,
      }) as const,
    [
      spreadableAgGridProps,
      refreshGrid,
      refreshDetailGrid,
      expandDetailGrid,
      selectNextRow,
      filterIsSet,
      totalRowCount,
    ],
  );
};
