// Copyright ©️ 2025 eVolve MEP, LLC
import { useEffect, useState } from 'react';

import { Card } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';

import { useUser } from 'app/UserContext';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { isNotNil } from 'helpers/isNotNil';
import { useQuery } from 'hooks/useQuery';
import { CatalogCategoryNav } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/CatalogCategoryNav';
import { CatalogSelect } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/CatalogSelect';
import type { PartCategoryId } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';

import { useCatalogSetup } from './useCatalogSetup';

export const CatalogSetupCategorySelector = () => {
  const { selectedCatalog, setSelectedCatalog, selectedCategoryId, setSelectedCategoryId, selectedTab } =
    useCatalogSetup();
  const { user } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useQuery();
  const [preSelectedCategoryId, setPreSelectedCategoryId] = useState(
    queryParams.get('categoryId') as PartCategoryId | null,
  );
  useEffect(() => {
    if (isNotNil(preSelectedCategoryId)) {
      setSelectedCategoryId(preSelectedCategoryId);
    }
  }, [preSelectedCategoryId, setSelectedCategoryId]);

  useEffect(() => {
    if (
      isNotNil(selectedCategoryId) &&
      isNotNil(preSelectedCategoryId) &&
      preSelectedCategoryId !== selectedCategoryId
    ) {
      setPreSelectedCategoryId(null);
    }
  }, [preSelectedCategoryId, selectedCategoryId]);

  useEffect(() => {
    if (isNotNil(selectedCategoryId)) {
      setSearchParams({
        categoryId: selectedCategoryId,
      });
    } else {
      searchParams.delete('categoryId');
      setSearchParams(searchParams);
    }
  }, [searchParams, selectedCategoryId, setSearchParams]);

  const { companyId } = user;
  return (
    <>
      <BasePageHeader
        title="Catalog Setup"
        topRightComponent={
          <CatalogSelect
            onCatalogSelected={(c) => {
              setSelectedCatalog(c);
              setSelectedCategoryId(null);
              setPreSelectedCategoryId(null);
            }}
            selectedCatalog={selectedCatalog}
            companyId={companyId}
            allowCreation
          />
        }
      />
      {isNotNil(selectedCatalog) && (
        <Card
          withBorder
          display="flex"
          style={{ flexDirection: 'column', flex: '4 0 0', minWidth: 400, overflowY: 'auto' }}
        >
          <CatalogCategoryNav
            companyId={companyId}
            partCatalogId={selectedCatalog.partCatalogId}
            openToCategoryId={preSelectedCategoryId ?? undefined}
            selectedCategoryId={selectedCategoryId}
            setSelectedCategoryId={setSelectedCategoryId}
            autoSelectFirst={selectedTab === 'category-setup'}
            allowRootSelection={selectedTab === 'category-setup'}
            allowAddingCategories
            withIcon={false}
            size="md"
          />
        </Card>
      )}
    </>
  );
};
