// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { Flex, Modal, PasswordInput, Space } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { ModalButtons } from 'components/Mantine/ModalButtons';

import { NewPasswordInput } from './NewPasswordInput';
import { type ChangePasswordBody, useResetPassword } from './useResetPassword';

type Props = {
  opened: boolean;
  onClose: () => void;
  onComplete?: () => void;
};

export const ResetPasswordModal = ({ opened, onClose: closeModal, onComplete }: Props) => {
  const { resetPassword, loading } = useResetPassword();
  const [passwordIsValid, setPasswordIsValid] = useState(false);

  const form = useForm<ChangePasswordBody>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
    },
    validate: {
      currentPassword: (p) => !p,
      newPassword: (p) => !p,
    },
  });

  const onClose = () => {
    form.reset();
    closeModal();
  };

  const onSubmit = (values: typeof form.values) => {
    void resetPassword(values).then(() => {
      notifications.show({
        title: 'Successfully changed',
        message: 'Password successfully updated.',
        color: 'green',
      });
      onComplete?.();
      onClose();
    });
  };

  const submitDisabled = !form.isDirty() || !form.isValid() || !passwordIsValid;

  return (
    <Modal
      opened={opened}
      closeOnClickOutside={!form.isDirty()}
      closeOnEscape={false}
      onClose={onClose}
      title="Reset password"
      centered
    >
      <form onSubmit={submitDisabled ? () => form.validate() : form.onSubmit(onSubmit)}>
        <Flex direction="column" gap="xs">
          <PasswordInput
            label="Current password"
            {...form.getInputProps('currentPassword')}
            autoFocus
            disabled={loading}
          />
          <Space />
          <NewPasswordInput
            {...form.getInputProps('newPassword')}
            loading={loading}
            setPasswordIsValid={setPasswordIsValid}
          />
          <ModalButtons
            onClose={onClose}
            confirmationText="Save changes"
            loading={loading}
            disabled={submitDisabled}
            type="submit"
          />
        </Flex>
      </form>
    </Modal>
  );
};
