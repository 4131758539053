// Copyright ©️ 2024 eVolve MEP, LLC
import { useMemo, useRef } from 'react';

import { Button, Flex, Loader } from '@mantine/core';
import { AgGridReact } from 'ag-grid-react';

import { baseColumnDef, baseGridDef } from 'helpers/ag-grid/baseColumnDef';
import { useSaveColumnState } from 'helpers/ag-grid/useSaveColumnState';

import { getWorkOrderColumnDefs, getWorkRequestColumnDefs } from './columnDefs';
import { useInsights } from './useInsights';

export const InsightsDetailedReport = () => {
  const gridRef = useRef<AgGridReact>(null);
  const { saveColumnState, loadColumnState } = useSaveColumnState('Insights Chart', gridRef);

  const { wrDetailedData, woDetailedData, params, loading } = useInsights();
  const workOrderColumnDefs = useMemo(() => getWorkOrderColumnDefs(), []);
  const workRequestColumnDefs = useMemo(() => getWorkRequestColumnDefs(), []);

  const getColumnDef = useMemo(
    () =>
      params.selectedRecordType === 'Work Orders'
        ? { rowData: woDetailedData, columnDefs: workOrderColumnDefs }
        : { rowData: wrDetailedData, columnDefs: workRequestColumnDefs },
    [params.selectedRecordType, woDetailedData, workOrderColumnDefs, workRequestColumnDefs, wrDetailedData],
  );

  return (
    <Flex direction="column" h="100%" m="sm" mt={0}>
      <Flex justify="flex-end">
        <Button
          onClick={() => {
            gridRef.current?.api.exportDataAsCsv({ fileName: params.selectedRecordType ?? '' });
          }}
          size="xs"
          my="xs"
        >
          Export CSV
        </Button>
      </Flex>
      <AgGridReact
        ref={gridRef}
        {...baseGridDef}
        defaultColDef={baseColumnDef}
        loadingOverlayComponent={() => <Loader size="lg" />}
        loading={loading}
        onGridReady={() => loadColumnState()}
        {...getColumnDef}
        onFilterChanged={saveColumnState}
        onColumnMoved={saveColumnState}
        onColumnResized={saveColumnState}
        onColumnPinned={saveColumnState}
        onColumnVisible={saveColumnState}
        onSortChanged={saveColumnState}
      />
    </Flex>
  );
};
