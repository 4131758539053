// Copyright ©️ 2025 eVolve MEP, LLC
import React, { type ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useMaybeUser } from 'app/MaybeUserContext';
import { useGeneralContext } from 'helpers/useGeneralContext';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';
import { EModuleType } from 'types/module';

import type { NavigationModule } from './types';

type ModuleContextType = {
  data: NavigationModule[];
  loading: boolean;
  isModuleSelectOpen: boolean;
  setIsModuleSelectOpen: (open: boolean) => void;
};

const ModuleContext = React.createContext<ModuleContextType | undefined>(undefined);

export const ModuleProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useMaybeUser();
  const [isModuleSelectOpen, setIsModuleSelectOpen] = useState(false);
  const popperRef = useRef(null);
  const rightSideFlyoutRef = useRef(null);
  const {
    apiCall: getModules,
    data,
    loading,
  } = useWrappedGet<{ navigationModules: NavigationModule[] }>('admin/navigationmodule', {
    lazy: true,
  });

  const refetchModules = useCallback(() => getModules(), [getModules]);

  useEffect(() => {
    if (user) void refetchModules();
  }, [refetchModules, user]);

  const modulesObj = useMemo(
    () => ({
      module: EModuleType.ADMIN,
      data: data?.navigationModules ?? [],
      loading,
      isModuleSelectOpen,
      setIsModuleSelectOpen,
      popperRef,
      rightSideFlyoutRef,
    }),
    [loading, data, isModuleSelectOpen],
  );

  return <ModuleContext.Provider value={modulesObj}>{children}</ModuleContext.Provider>;
};

export const useModule = () => useGeneralContext<ModuleContextType | undefined>(ModuleContext, 'ModuleContext');
