// Copyright ©️ 2024 eVolve MEP, LLC
import type { ReactNode } from 'react';

import { Flex, type FlexProps } from '@mantine/core';
import type { ICellRendererParams } from 'ag-grid-enterprise';

import { isNotNil } from 'helpers/isNotNil';

type NonNilParams<TData, TKey extends TData[keyof TData] = any> = ICellRendererParams<TData, TKey> & {
  data: NonNullable<ICellRendererParams<TData, TKey>['data']>;
  value: TKey;
  node: ICellRendererParams<TData, TKey>['node'] & {
    data: NonNullable<ICellRendererParams<TData, TKey>['node']['data']>;
  };
};

export const CellRenderer = <TData, TKey extends TData[keyof TData] = any>(
  renderer: (nonNilParams: NonNilParams<TData, TKey>) => ReactNode,
  {
    show = true,
    flexProps,
  }: {
    show?: boolean | ((nonNilParams: NonNilParams<TData, TKey>) => boolean);
    flexProps?: FlexProps;
  } = {},
) =>
  function (rendererParams: ICellRendererParams<TData, TKey>) {
    if (!show) return null;
    const { data, node } = rendererParams;
    if (isNotNil(data) && isNotNil(node.data)) {
      // We know we can do this cast safely since we did the isNotNil checks
      const nonNilData = rendererParams as NonNilParams<TData, TKey>;
      if (typeof show !== 'function' || show(nonNilData)) {
        return (
          <Flex align="center" fz="sm" gap="xs" {...flexProps} style={{ height: '100%', ...flexProps?.style }}>
            {renderer(nonNilData)}
          </Flex>
        );
      }
    }
    return null;
  };
