// Copyright ©️ 2024 eVolve MEP, LLC
import { Routes, Route, Navigate } from 'react-router-dom';

import { PageNotFound } from 'app/PageNotFound';

import { EvolveMembersInterfaceNavContent, CustomersInterfaceNavContent } from './ManagementNavInterfaces';

export const ManagementRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="evolve-customers" />} />
    <Route path="/evolve-members" element={<EvolveMembersInterfaceNavContent />} />
    <Route path="/evolve-customers" element={<CustomersInterfaceNavContent />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);
