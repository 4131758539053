// Copyright ©️ 2024 eVolve MEP, LLC
import { Routes, Route, Navigate } from 'react-router-dom';

import { PageNotFound } from 'app/PageNotFound';
import {
  ProjectInterfaceNavContent,
  FacilitiesInterfaceNavContent,
  ProjectPropertiesInterfaceNavContent,
  ProjectMembersInterfaceNavContent,
} from 'modules/Admin/AdminNavInterfaces';

import {
  ShopMembersInterfaceNavContent,
  WorkRequestsNavContent,
  WorkCellsAndMachinesInterfaceNavContent,
  WorkRequestNavContent,
  WorkCellsInterfaceNavContent,
  CatalogSetupInterfaceNavContent,
  WorkOrdersNavContent,
  WorkCellQueueNavContent,
  TaskViewerNavContent,
  WorkOrderItemsNavContent,
  InsightsNavContent,
  AddEditCatalogItemNavContent,
} from './ShopNavInterfaces';

export const ShopRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="work-requests" />} />
    <Route path="insights" element={<InsightsNavContent />} />
    <Route path="facilities" element={<FacilitiesInterfaceNavContent />} />
    <Route path="shop-members" element={<ShopMembersInterfaceNavContent />} />
    <Route path="projects" element={<ProjectInterfaceNavContent />} />
    <Route path="members/project-members" element={<ProjectMembersInterfaceNavContent />} />
    <Route path="properties/project-properties" element={<ProjectPropertiesInterfaceNavContent />} />
    <Route path="shop-setup/work-cell-setup" element={<WorkCellsAndMachinesInterfaceNavContent />} />
    <Route path="work-requests" element={<WorkRequestsNavContent />} />
    <Route path="work-requests/:id" element={<WorkRequestNavContent />} />
    <Route path="work-orders" element={<WorkOrdersNavContent />} />
    <Route path="work-orders/:id" element={<WorkOrderItemsNavContent />} />
    <Route path="catalog-setup" element={<CatalogSetupInterfaceNavContent />} />
    <Route path="add-catalog-item/:partCategoryId" element={<AddEditCatalogItemNavContent />} />
    <Route path="edit-catalog-item/:id" element={<AddEditCatalogItemNavContent />} />
    <Route path="work-cell-queue" element={<WorkCellQueueNavContent />} />
    <Route path="task-viewer/:id" element={<TaskViewerNavContent />} />
    <Route path="work-cells" element={<WorkCellsInterfaceNavContent />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);
