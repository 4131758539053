// Copyright ©️ 2024 eVolve MEP, LLC
import type { MantineColor } from '@mantine/core';

import type { InsightsStatusTypeName } from 'modules/Shop/Insights/types';

export const workRequestStatusNames = [
  'Draft',
  'Submitted',
  'Pending',
  'Approved',
  'Transferred',
  'In Progress',
  'Rejected',
  'Canceled',
  'Completed',
] as const;
export type WorkRequestStatusName = (typeof workRequestStatusNames)[number];

export const workOrderStatusTypeNames = [
  'In Progress',
  'Blocked',
  'Not Started',
  'Draft',
  'Completed',
  // These are returned from shop/workOrderStatusType
  // but do not appear to actually be used yet
  // 'Hold',
  // 'Delivered',
  // 'Ready to Ship',
  // 'In Transit',
  // 'Canceled',
] as const;
export type WorkOrderStatusTypeName = (typeof workOrderStatusTypeNames)[number];

export const taskStatusTypeNames = ['Not Started', 'Paused', 'Started', 'Completed'] as const;
export type TaskStatusTypeName = (typeof taskStatusTypeNames)[number];

export const sortByStatusKey =
  <T, TKey extends keyof T>(order: readonly StatusKey[], key: TKey) =>
  (a: T[TKey] extends StatusKey ? T : never, b: T[TKey] extends StatusKey ? T : never) =>
    // The typecheck on the above line makes this a safe cast
    order.indexOf(a[key] as StatusKey) - order.indexOf(b[key] as StatusKey);

export type StatusKey =
  | WorkRequestStatusName
  | WorkOrderStatusTypeName
  | TaskStatusTypeName
  // Late is not a real status,
  // but represents a WR/WO past its Need By date
  | 'Late';

export const statusToColorMap: Record<
  StatusKey | InsightsStatusTypeName,
  {
    label: string;
    color: MantineColor;
    chartColor?: string;
  }
> = {
  Draft: {
    color: 'cyan.7',
    label: 'Draft',
    chartColor: '#00CCCC',
  },
  Rejected: {
    color: 'red.9',
    label: 'Rejected',
    chartColor: '#FD7272',
  },
  Submitted: {
    color: 'violet.7',
    label: 'Submitted',
    chartColor: '#4400CC',
  },
  Pending: {
    color: 'yellow.7',
    label: 'Pending',
    chartColor: '#FFAA00',
  },
  Approved: {
    color: 'green.8',
    label: 'Approved',
    chartColor: '#28775D',
  },
  Canceled: {
    color: 'gray.5',
    label: 'Canceled',
    chartColor: '#ADB5BD',
  },
  'Not Started': {
    color: 'yellow.7',
    label: 'Not started',
  },
  'In Progress': {
    color: 'blue.6',
    label: 'In progress',
    chartColor: '#008ACC',
  },
  Completed: {
    color: 'green.9',
    label: 'Completed',
    chartColor: '#00CC88',
  },
  Started: {
    color: 'blue.6',
    label: 'In progress',
  },
  Paused: {
    color: 'blue.6',
    label: 'In progress',
  },
  Late: {
    color: 'red.8',
    label: 'Late',
    chartColor: '#CC0500',
  },
  Blocked: {
    color: 'orange.7',
    label: 'Blocked',
    chartColor: '#CC4C00',
  },
  Transferred: {
    color: 'grape.6',
    label: 'Transferred',
  },
  'Draft - Late': {
    color: 'red.9',
    label: 'Draft - Late',
  },
  'Rejected - Late': {
    color: 'red.9',
    label: 'Rejected - Late',
  },
  'Approved - Late': {
    color: 'red.9',
    label: 'Approved - Late',
  },
  'Submitted - Late': {
    color: 'red.9',
    label: 'Submitted - Late',
  },
  'Pending - Late': {
    color: 'red.9',
    label: 'Pending - Late',
  },
  'Not Started - Late': {
    color: 'red.9',
    label: 'Not started - Late',
  },
  'In Progress - Late': {
    color: 'red.9',
    label: 'In progress - Late',
  },
  'Completed - Late': {
    color: 'red.9',
    label: 'Completed - Late',
  },
  'Blocked - Late': {
    color: 'red.9',
    label: 'Blocked - Late',
  },
} as const;
