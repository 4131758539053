// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { notifications } from '@mantine/notifications';

import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { useWrappedDelete } from 'hooks-api/useWrappedApiCall';
import type { WorkRequestItem } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import type { WorkOrderItem } from './types';

type Props = { refresh: () => void; onClose: () => void; opened: boolean } & (
  | {
      workRequestItems: WorkRequestItem[];
    }
  | {
      workOrderItems: WorkOrderItem[];
    }
);

export const DeleteItemsModal = ({ refresh, opened, onClose, ...props }: Props) => {
  const [deleting, setDeleting] = useState(false);
  const { apiCall: deleteItem } = useWrappedDelete(
    // URL will be overridden on the API call
    'shop/work...Item',
  );

  const itemName =
    'workOrderItems' in props
      ? props.workOrderItems.length === 1
        ? props.workOrderItems[0].workOrderItemName
        : `${props.workOrderItems.length} items`
      : props.workRequestItems.length === 1
        ? props.workRequestItems[0].workRequestItemName
        : `${props.workRequestItems.length} items`;

  const onDelete = () => {
    setDeleting(true);
    if ('workOrderItems' in props) {
      const { workOrderItems } = props;
      void Promise.all(
        workOrderItems.map((workOrderItem) =>
          deleteItem({
            url: `shop/workOrderItem/${workOrderItem.workOrderItemId}`,
          }),
        ),
      )
        .then(() => {
          onClose();
          notifications.show({
            title: 'Successfully deleted',
            message: `Deleted ${itemName}`,
            color: 'green',
          });
          refresh();
        })
        .finally(() => setDeleting(false));
    } else if ('workRequestItems' in props) {
      const { workRequestItems } = props;
      void Promise.all(
        workRequestItems.map((workRequestItem) =>
          deleteItem({
            url: `shop/workRequestItem/${workRequestItem.workRequestItemId}`,
          }),
        ),
      )
        .then(() => {
          onClose();
          notifications.show({
            title: 'Successfully deleted',
            message: `Deleted ${itemName}`,
            color: 'green',
          });
          refresh();
        })
        .finally(() => setDeleting(false));
    }
  };

  return (
    <ConfirmationModal
      size="sm"
      opened={opened}
      onConfirm={onDelete}
      onClose={onClose}
      loading={deleting}
      buttonColor="red"
    >
      Are you sure you want to delete <b>{itemName}</b> from this{' '}
      {'workOrderItems' in props ? 'work order' : 'work request'}?
    </ConfirmationModal>
  );
};
