// Copyright ©️ 2024 eVolve MEP, LLC
import { useState } from 'react';

import { Button, Flex, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Link, useLocation } from 'react-router-dom';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { extractErrorMessage } from 'helpers/extractError';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';

import { CheckEmailForPasswordReset } from './CheckEmailForPasswordReset';
import { AuthFormWrapper } from '../components/AuthFormWrapper';
import type { LoginErrorCode } from '../components/LoginErrorAlert';

export const ForgotPassword = () => {
  const theme = useMantineTheme();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorCode, setErrorCode] = useState<LoginErrorCode | string>();
  const { apiCall, loading } = useWrappedPost('admin/authentication/forgotPassword', { dontAlertOnError: true });
  const {
    // From src/modules/Authentication/Login/Login.tsx
    email: emailFromLocation,
  } = (useLocation().state ?? {}) as {
    email?: string;
  };

  const form = useForm<{ email: string }>({
    initialValues: { email: emailFromLocation ?? '' },
  });

  if (formSubmitted) return <CheckEmailForPasswordReset email={form.values.email} />;

  const handleForgotPassword = async ({ email }: { email: string }) => {
    setErrorCode(undefined);
    try {
      await apiCall({}, { params: { email } });
      setFormSubmitted(true);
    } catch (error) {
      setErrorCode(extractErrorMessage(error));
    }
  };

  return (
    <AuthFormWrapper
      title="Forgot Password"
      form={form}
      onSubmit={handleForgotPassword}
      errorCode={errorCode}
      onCloseError={() => setErrorCode(undefined)}
    >
      <Text fz="sm">Enter your email to receive a link to reset your password.</Text>
      <TextInput size="md" disabled={loading} label="Email" type="email" autoFocus {...form.getInputProps('email')} />
      <Button loading={loading} color={`${theme.primaryColor}.9`} type="submit" fullWidth>
        Submit
      </Button>
      <Link className="link" data-cy="forgot-password" to="/auth/login">
        <Flex justify="center" align="center" gap={4}>
          <EvolveIcon icon="ArrowLeft" width={10} />
          <Text c="dimmed" fz="sm">
            Return to login
          </Text>
        </Flex>
      </Link>
    </AuthFormWrapper>
  );
};
