// Copyright ©️ 2024 eVolve MEP, LLC
import { Card, Flex, Text } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

import type { PartCategory } from './types';

type Props = { category: PartCategory; onClick: () => void };

export const CategoryCard = ({ category, onClick }: Props) => (
  <Card withBorder style={{ height: 50, width: 200, cursor: 'pointer' }} onClick={onClick}>
    <Flex
      align="center"
      gap="md"
      style={{
        height: '100%',
      }}
    >
      <EvolveIcon icon="Folder" light />
      <Text fz="sm" title={category.partCategoryName} lineClamp={2}>
        {category.partCategoryName}
      </Text>
    </Flex>
  </Card>
);
