// Copyright ©️ 2025 eVolve MEP, LLC

/// <reference types="forge-viewer" />
import './forge-viewer.css';
import './extensions/CustomToolbarExtension';
import './extensions/PageNavExtension';

import { useCallback, useEffect, useRef } from 'react';

import { isNil, isNotNil } from 'helpers/isNotNil';

import { AutodeskEventBus } from './AutodeskEventBus';
import { onLoadPageNavExtension } from './extensions/onLoadPageNavExtension';

const { Autodesk } = window;

let viewerRuntimePromise: Promise<void> | null;

const initializeViewerRuntime = ({
  accessToken,
  getAccessToken,
  env = 'AutodeskProduction2',
  api = 'streamingV2',
  shouldInitializeAuth = true,
}: Autodesk.Viewing.InitializerOptions) => {
  if (!viewerRuntimePromise) {
    viewerRuntimePromise = new Promise<void>((resolve) =>
      Autodesk.Viewing.Initializer({ accessToken, getAccessToken, env, api, shouldInitializeAuth }, resolve),
    );
  }
  return viewerRuntimePromise;
};

export type ForgeViewerProps = Autodesk.Viewing.InitializerOptions & {
  setCurrentPage?: (currentPage: number) => void;
  setViewerRenderComplete?: (complete: boolean) => void;
  urn?: string;
};

export const ForgeViewer = ({ urn, setCurrentPage, setViewerRenderComplete, ...props }: ForgeViewerProps) => {
  const containerRef = useRef<HTMLDivElement>();
  const viewer = useRef<Autodesk.Viewing.GuiViewer3D>();
  const viewerPageNumberRef = useRef<number>(1);
  const viewerTotalPageNumbersRef = useRef<number>(0);

  useEffect(
    () =>
      // Cleanup on unmount
      () => {
        viewer.current?.tearDown();
        viewer.current?.finish();
        viewer.current = undefined;
        AutodeskEventBus.remove('page_nav');
      },
    [],
  );

  const syncState = useCallback(
    async (prevProps: ForgeViewerProps, page = 1) => {
      if (!urn || urn === prevProps.urn || isNil(viewer.current)) return;

      // load pdf files
      if (urn.startsWith('http')) {
        // impl and model are both nullable.
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const currentModel = viewer.current.impl?.model;
        if (isNotNil(currentModel)) {
          viewer.current.unloadModel(currentModel);
        }

        viewer.current.loadModel(urn, { page }, async (model) => {
          viewerTotalPageNumbersRef.current =
            // @ts-expect-error PDFLoader is not typed properly
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            model.loader.pdf?.numPages ?? 0;
          await viewer.current?.loadExtension('PageNavExtension').then(
            onLoadPageNavExtension({
              currentPageNumber: page,
              totalPageNumber: viewerTotalPageNumbersRef.current,
              gotoPageCb: (newPage = 1) => {
                void syncState({}, newPage);
                viewerPageNumberRef.current = newPage;
                setCurrentPage?.(viewerPageNumberRef.current);
              },
            }),
          );
          setViewerRenderComplete?.(true);
        });
        return;
      }

      // load translated files
      Autodesk.Viewing.Document.load(
        `urn:${urn}`,
        (doc) => viewer.current?.loadDocumentNode(doc, doc.getRoot().getDefaultGeometry()),
        // eslint-disable-next-line no-console
        console.error,
      );
    },
    [setCurrentPage, setViewerRenderComplete, urn],
  );

  const navigatePage = useCallback(
    ({ direction }: { direction: 'prev' | 'next' }) => {
      let nextPageNumber = viewerPageNumberRef.current;
      if (direction === 'prev') {
        nextPageNumber = nextPageNumber === 1 ? 1 : viewerPageNumberRef.current - 1;
      }

      if (direction === 'next') {
        nextPageNumber =
          nextPageNumber === viewerTotalPageNumbersRef.current
            ? viewerTotalPageNumbersRef.current
            : viewerPageNumberRef.current + 1;
      }

      void syncState({}, nextPageNumber);
      viewerPageNumberRef.current = nextPageNumber;
      setCurrentPage?.(nextPageNumber);
    },
    [setCurrentPage, syncState],
  );

  return (
    <div
      ref={(ref: HTMLDivElement | null) => {
        if (!ref || containerRef.current) return;
        initializeViewerRuntime(props)
          .then(async () => {
            containerRef.current = ref;
            if (viewer.current) return;
            viewer.current = new Autodesk.Viewing.GuiViewer3D(containerRef.current, {
              extensions: props.extensions || [],
            });
            viewer.current.start();
            viewerPageNumberRef.current = 1;
            await syncState({});
            // Single select, Multi select, Window select
            // Reenable when ready
            await viewer.current.loadExtension('CustomToolbarExtension');
          })
          // eslint-disable-next-line no-console
          .catch(console.error);

        AutodeskEventBus.on('page_nav', navigatePage);
      }}
    />
  );
};
