// Copyright ©️ 2024 eVolve MEP, LLC
import { Stack, type StackProps, Title } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

export const MobileRestricted = () => (
  <StyledRestrictedBackground>
    <EvolveIcon icon="Info" size="xl" />
    <Title order={3}>Switch to a larger display to view content.</Title>
  </StyledRestrictedBackground>
);

const StyledRestrictedBackground = (props: StackProps) => (
  <Stack
    style={{
      width: '100%',
      height: '91vh',
      justifyContent: 'center',
      color: 'rgba(0,0,0,0.5)',
      alignItems: 'center',
      marginLeft: '-64px',
    }}
    {...props}
  />
);
