// Copyright ©️ 2024 eVolve MEP, LLC

import head from 'lodash.head';

import { isNil, isNotNil } from 'helpers/isNotNil';

const PREV_PAGE_BUTTON_ID = '[PageNavExtension]_prev_btn';
const NEXT_PAGE_BUTTON_ID = '[PageNavExtension]_next_btn';
const PAGE_NUM_BUTTON_ID = '[PageNavExtension]_page_num_btn';

const PAGE_NUM_TEXT_ELEMENT_CLASSNAME = 'adsk-page-num-text';
const PAGE_NUM_INPUT_ELEMENT_CLASSNAME = 'adsk-page-num-input';
const BUTTON_DISABLED_CLASSNAME = 'autodesk-btn-disabled';

const pageNumInputKeyDownListener =
  (gotoPageCb: (page: number) => void, currentPageNumber: number, totalPageNumber: number) =>
  (event: KeyboardEvent) => {
    if (isNil(event.target)) return;
    const target = event.target as HTMLInputElement;
    const targetPageNumber = Number(target.value);
    const isPageOutOfRange = targetPageNumber <= 0 || targetPageNumber > totalPageNumber;

    if (event.key === 'Escape') {
      target.value = String(currentPageNumber);
    }

    if (event.key === 'Enter') {
      if (isPageOutOfRange) {
        target.value = String(currentPageNumber);
      } else {
        gotoPageCb(targetPageNumber);
      }
    }
  };

const pageNumInputFocusoutListener =
  (gotoPageCb: (page: number) => void, currentPageNumber: number, totalPageNumber: number) => (event: FocusEvent) => {
    if (isNil(event.target)) return;
    const target = event.target as HTMLInputElement;
    const targetPageNumber = Number(target.value);
    const isPageOutOfRange = targetPageNumber <= 0 || targetPageNumber > totalPageNumber;

    // focusout event gets triggered once user hit enter and input element gets lost the focus
    if (targetPageNumber !== currentPageNumber) {
      if (isPageOutOfRange) {
        target.value = String(currentPageNumber);
      } else {
        gotoPageCb(targetPageNumber);
      }
    }
  };

export const onLoadPageNavExtension =
  ({
    currentPageNumber = 1,
    totalPageNumber = 0,
    gotoPageCb,
  }: {
    currentPageNumber: number;
    totalPageNumber: number;
    gotoPageCb: (page: number) => void;
  }) =>
  (extension: Autodesk.Viewing.Extension) => {
    const controlEl = document.getElementById(PAGE_NUM_BUTTON_ID);
    if (controlEl) {
      const prevInputElCollection = document.getElementsByClassName(PAGE_NUM_INPUT_ELEMENT_CLASSNAME);
      const prevInputEl = prevInputElCollection.length > 0 ? prevInputElCollection[0] : null;
      if (prevInputEl) {
        prevInputEl.remove();
      }
      const inputEl = document.createElement('input');
      inputEl.type = 'number';
      inputEl.classList.add(PAGE_NUM_INPUT_ELEMENT_CLASSNAME);
      inputEl.value = `${currentPageNumber}`;
      inputEl.addEventListener('keydown', pageNumInputKeyDownListener(gotoPageCb, currentPageNumber, totalPageNumber));
      inputEl.addEventListener(
        'focusout',
        pageNumInputFocusoutListener(gotoPageCb, currentPageNumber, totalPageNumber),
      );

      const prevTextEl = document.getElementsByClassName(PAGE_NUM_TEXT_ELEMENT_CLASSNAME);
      head(prevTextEl)?.remove();

      const textEl = document.createElement('p');
      textEl.classList.add(PAGE_NUM_TEXT_ELEMENT_CLASSNAME);
      textEl.innerText = `/ ${totalPageNumber}`;

      controlEl.appendChild(inputEl);
      controlEl.appendChild(textEl);
    }

    const isFirstPage = currentPageNumber === 1;
    if ('subToolbar' in extension && isNotNil(extension.subToolbar)) {
      const subToolbar = extension.subToolbar as Autodesk.Viewing.UI.ControlGroup;
      const prevBtnControl = subToolbar.getControl(PREV_PAGE_BUTTON_ID) as Autodesk.Viewing.UI.Control | undefined;
      if (isFirstPage) {
        prevBtnControl?.addClass(BUTTON_DISABLED_CLASSNAME);
      } else {
        prevBtnControl?.removeClass(BUTTON_DISABLED_CLASSNAME);
      }

      const isLastPage = currentPageNumber === totalPageNumber;
      const nextBtnControl = subToolbar.getControl(NEXT_PAGE_BUTTON_ID) as Autodesk.Viewing.UI.Control | undefined;
      if (isLastPage) {
        nextBtnControl?.addClass(BUTTON_DISABLED_CLASSNAME);
      } else {
        nextBtnControl?.removeClass(BUTTON_DISABLED_CLASSNAME);
      }
    }
  };
