// Copyright ©️ 2025 eVolve MEP, LLC
import { syncIcons } from './360SyncIcons';
import AccountCircle from '../images/icons/account-circle.svg?react';
import Add from '../images/icons/add.svg?react';
import Alert from '../images/icons/alert.svg?react';
import Apps from '../images/icons/apps.svg?react';
import Assembly from '../images/icons/assembly.svg?react';
import Attachment from '../images/icons/attachment.svg?react';
import Blocked from '../images/icons/blocked.svg?react';
import CalendarBoard from '../images/icons/calendar-board.svg?react';
import CheckCircle from '../images/icons/check-circle-icon.svg?react';
import ClearFilters from '../images/icons/clear-filters.svg?react';
import Close from '../images/icons/close-icon.svg?react';
import CollapsePane from '../images/icons/collapse-pane.svg?react';
import Comment from '../images/icons/comment.svg?react';
import Copy from '../images/icons/content-copy.svg?react';
import Delete from '../images/icons/delete.svg?react';
import DeveloperBoard from '../images/icons/developer-board.svg?react';
import Licenses from '../images/icons/double-check.svg?react';
import Download from '../images/icons/download-2.svg?react';
import DrawingDocument from '../images/icons/drawing-document.svg?react';
import DrawingTypeCategory from '../images/icons/drawing-type-category.svg?react';
import ExpandPane from '../images/icons/expand-pane.svg?react';
import Export from '../images/icons/export.svg?react';
import Filter from '../images/icons/filter.svg?react';
import Folder from '../images/icons/folder.svg?react';
import ExternalURL from '../images/icons/globe.svg?react';
import Help from '../images/icons/help.svg?react';
import History from '../images/icons/history.svg?react';
import Home from '../images/icons/home.svg?react';
import Info from '../images/icons/info_outline.svg?react';
import Insights from '../images/icons/insights.svg?react';
import Kanban from '../images/icons/kanban.svg?react';
import ArrowDown from '../images/icons/keyboard-arrow-down.svg?react';
import ArrowLeft from '../images/icons/keyboard-arrow-left.svg?react';
import ArrowRight from '../images/icons/keyboard-arrow-right1.svg?react';
import Link from '../images/icons/link.svg?react';
import Mail from '../images/icons/mail.svg?react';
import MoreOptsVert from '../images/icons/more-vert-alt.svg?react';
import Part from '../images/icons/part.svg?react';
import Pause from '../images/icons/paused.svg?react';
import Pin from '../images/icons/pin.svg?react';
import Play from '../images/icons/play-icon.svg?react';
import Refresh from '../images/icons/refresh.svg?react';
import Repair from '../images/icons/repair.svg?react';
import Search from '../images/icons/search-alt.svg?react';
import Spools from '../images/icons/spools.svg?react';
import TableChart from '../images/icons/table-chart.svg?react';
import TrendingDown from '../images/icons/trending-down.svg?react';
import TrendingUp from '../images/icons/trending-up.svg?react';
import Unpin from '../images/icons/unpin.svg?react';
import Upload from '../images/icons/upload.svg?react';
import WorkCell from '../images/icons/work-cell.svg?react';
import WriteInItem from '../images/icons/write-in-item.svg?react';
import Company from '../images/nav-icons/admin-icons/company-properties.svg?react';
import Settings from '../images/nav-icons/admin-icons/setting.svg?react';
import Projects from '../images/nav-icons/common-icons/projects.svg?react';
import Shops from '../images/nav-icons/common-icons/shops.svg?react';
import ProjectMaterials from '../images/nav-icons/design-icons/project-materials.svg?react';
import Customers from '../images/nav-icons/entitlements-icons/customers.svg?react';
import WorkRequest from '../images/nav-icons/field-icons/work-requests.svg?react';
import EvolveMembers from '../images/nav-icons/management-icons/evolve-members.svg?react';
import BillOfMaterials from '../images/nav-icons/material-icons/bill-of-materials.svg?react';
import Catalog from '../images/nav-icons/material-icons/catalog-setup.svg?react';
import DataImport from '../images/nav-icons/shop-icons/data-import.svg?react';
import Fabrication from '../images/nav-icons/shop-icons/fabrication.svg?react';
import Machine from '../images/nav-icons/shop-icons/machine.svg?react';
import ShopConfig from '../images/nav-icons/shop-icons/shop-configuration.svg?react';
import ShopMembers from '../images/nav-icons/shop-icons/shop-members.svg?react';
import ShopProperties from '../images/nav-icons/shop-icons/shop-properties.svg?react';
import ShopSetup from '../images/nav-icons/shop-icons/shop-setup.svg?react';
import WorkCellTask from '../images/nav-icons/shop-icons/shop-tasks-nav.svg?react';
import WorkCellQueue from '../images/nav-icons/shop-icons/shop-work-cell-queue.svg?react';
import WorkCellsAndMachines from '../images/nav-icons/shop-icons/work-cells-and-machines.svg?react';
import WorkOrder from '../images/nav-icons/shop-icons/work-orders.svg?react';
import WorkPlanning from '../images/nav-icons/shop-icons/work-planning.svg?react';

/**
 * The map defining all possible custom Evolve icons
 */
export const iconMap = {
  AccountCircle,
  Add,
  Alert,
  Apps,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  Assembly,
  Attachment,
  BillOfMaterials,
  Blocked,
  CalendarBoard,
  Catalog,
  CheckCircle,
  ClearFilters,
  Close,
  CollapsePane,
  Comment,
  Company,
  Copy,
  Customers,
  DataImport,
  Delete,
  DeveloperBoard,
  Download,
  DrawingDocument,
  DrawingTypeCategory,
  EvolveMembers,
  ExpandPane,
  Export,
  ExternalURL,
  Fabrication,
  Filter,
  Folder,
  Help,
  History,
  Home,
  Info,
  Insights,
  Kanban,
  Licenses,
  Link,
  Machine,
  Mail,
  MoreOptsVert,
  Part,
  Pause,
  Pin,
  Play,
  ProjectMaterials,
  Projects,
  Refresh,
  Repair,
  Search,
  Settings,
  ShopConfig,
  ShopMembers,
  ShopProperties,
  Shops,
  ShopSetup,
  Spools,
  TableChart,
  TrendingDown,
  TrendingUp,
  Unpin,
  Upload,
  WorkCell,
  WorkCellQueue,
  WorkCellTask,
  WorkCellsAndMachines,
  WorkOrder,
  WorkPlanning,
  WorkRequest,
  WriteInItem,
  ...syncIcons,
} as const;
