// Copyright ©️ 2024 eVolve MEP, LLC
import React, { Children, type ReactNode, useMemo } from 'react';

import { GridLayout } from 'app/Layout/GridLayout';

import { ComponentPane } from './ComponentPane';

type Props = {
  children: ReactNode;
  sizes?: number[];
  /** TODO: Remove after full conversion */
  flyoutMenu?: ReactNode;
  componentPaneProps?: React.ComponentProps<typeof ComponentPane>;
};

export const ComponentPaneLayout = ({ sizes, flyoutMenu, children, componentPaneProps }: Props) => {
  const childrenPanes = useMemo(
    () =>
      Children.map(children, (child) => <ComponentPane {...componentPaneProps}>{child}</ComponentPane>)?.filter(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (child) => !!child.props.children,
      ) ?? [],
    [children, componentPaneProps],
  );

  return (
    <>
      <GridLayout sizes={sizes} panes={childrenPanes} />
      {flyoutMenu}
    </>
  );
};
