// Copyright ©️ 2025 eVolve MEP, LLC
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Checkbox, Flex, Loader, Popover, useMantineTheme, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { AgGridReact } from 'ag-grid-react';

import { useUser } from 'app/UserContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { TextInputDebounced } from 'components/Mantine/TextInputDebounced';
import { WrappedMultiSelect, WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { getMultiRowSelection } from 'helpers/ag-grid/baseColumnDef';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { extractErrorMessage } from 'helpers/extractError';
import { isNil, isNotNil } from 'helpers/isNotNil';
import type { EvolveURL } from 'hooks-api/useEvolveApi';
import { useWrappedGet, useWrappedPaginatedGet, useWrappedPatch, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import { CompanyDocument } from 'modules/Admin/CompanyMembers/CompanyDocument';
import { InviteUsersModal } from 'modules/Shop/ShopMembers/ShopMembersMantine/InviteUsersModal';
import type { Entitlement, ProductPool, ProductPoolId, User, UserId } from 'types/types-api';

import { AddLicensesModal } from './AddLicensesModal';
import { AssignRolesModal } from './AssignRolesModal';
import { getCompanyMembersColDef, getEntitlementsColDef, getRolesColDef } from './columnDefs';
import { type CompanyMembersBulkAction, companyMembersBulkActions } from './CompanyMemberMenu';
import { RoleNamesModal } from './RoleNamesModal';
import type { AssignableRole, UserRole } from './types';

export const CompanyMembersPage = () => {
  const { user, refreshUser, loading: loadingUser } = useUser();
  const theme = useMantineTheme();
  const { apiCall: sendPasswordResetEmail } = useWrappedPost('admin/authentication/forgotPassword', {
    dontAlertOnError: true,
  });
  const [showNoLicense, setShowNoLicense] = useState(false);
  const [selectedProductPools, setSelectedProductPools] = useState<ProductPoolId[]>([]);
  const [singleUserAction, setSingleUserAction] = useState<User | null>(null);
  const [selectedRows, setSelectedRows] = useState<User[]>([]);
  const [selectedAction, setSelectedAction] = useState<CompanyMembersBulkAction | null>(null);
  const [confirmRevitRoleAction, setConfirmRevitRoleAction] = useState(false);
  const [configureRoleNames, setConfigureRoleNames] = useState(false);

  const { data: productPools, setDefaultOpts: setDefaultOptsProductPools } = useWrappedPaginatedGet<ProductPool>(
    'admin/productPool',
    { lazy: true },
  );

  const { apiCall: optInOutRevitConfig, loading: optingInOut } = useWrappedPost(
    `admin/company/optInToRevitConfigurations/${!user.company.optInToRevitConfigurations}`,
  );

  const { apiCall: addOrRemoveAdmin, loading: addingOrRemoving } = useWrappedPatch<User, { isAdmin: boolean }>(
    'admin/user/:userId',
  );

  useEffect(() => {
    setDefaultOptsProductPools({
      lazy: false,
      perPage: 999,
      defaultConfig: {
        params: {
          excludeExpired: true,
          companyId: user.companyId,
        },
      },
    });
  }, [setDefaultOptsProductPools, user]);

  const { apiCall: deleteUsersApiCall, loading: deleting } = useWrappedPatch<unknown, { userIds: UserId[] }>(
    'admin/user/multidelete',
  );
  const deleteUsers = useCallback(
    async (userIds: UserId[]) => {
      await deleteUsersApiCall({ userIds }).then(() => {
        notifications.show({
          title: 'Successfully deleted',
          message: `Deleted ${userIds.length} user${userIds.length === 1 ? '' : 's'}`,
          color: 'green',
        });
      });
    },
    [deleteUsersApiCall],
  );
  const [searchPhrase, setSearchPhrase] = useState('');
  const { fetchPage, setDefaultOpts, searchHandler } = useWrappedPaginatedGet<User>('admin/user', {
    lazy: true,
  });
  useEffect(() => {
    setDefaultOpts({
      lazy: true,
      defaultConfig: {
        params: {
          companyId: user.companyId,
          productPoolIds: selectedProductPools.join(','),
          hasLicense: showNoLicense ? false : undefined,
        },
      },
    });
  }, [setDefaultOpts, selectedProductPools, showNoLicense, user.companyId]);
  const { fetchPage: fetchEntitlementPage } = useWrappedPaginatedGet<Entitlement>('admin/entitlement/multisearch');
  const { fetchPage: fetchUserRoles } = useWrappedPaginatedGet<UserRole>('admin/Role/assigned/:id');
  const { data: assignableRoles, apiCall: fetchAssignableRoles } =
    useWrappedGet<AssignableRole[]>('admin/Role/assignable');

  const actionSingleUser = useCallback((selectedUser: User, action: CompanyMembersBulkAction) => {
    setSelectedAction(action);
    setSingleUserAction(selectedUser);
  }, []);
  const usersToAction = useMemo(
    () => (singleUserAction ? [singleUserAction] : selectedRows),
    [selectedRows, singleUserAction],
  );
  useEffect(() => {
    if (isNil(selectedAction)) {
      setSingleUserAction(null);
    }
  }, [selectedAction]);

  useEffect(() => {
    if (selectedAction === 'Reset password') {
      setSelectedAction(null);
      void Promise.allSettled(
        usersToAction.map(({ userEmail: email }) =>
          sendPasswordResetEmail({}, { params: { email } }).catch((error) => {
            notifications.show({
              title: `Error (${email})`,
              // AWS errors tend to have the useful error on error.message
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              message: error?.message ?? extractErrorMessage(error),
              color: 'red',
            });
            throw error;
          }),
        ),
      ).then((results) => {
        const sent = results.filter((r) => r.status === 'fulfilled').length;
        if (sent > 0) {
          notifications.show({
            title: 'Successfully sent',
            message: `${sent} password reset email${sent === 1 ? '' : 's'} sent`,
            color: 'green',
          });
        }
      });
    }
  }, [selectedAction, sendPasswordResetEmail, usersToAction]);

  const colDef = useMemo(
    () =>
      getCompanyMembersColDef({
        hasDetailTable: true,
        actionSingleUser,
        filterOnStatus: true,
        isOptedInRevitRoles: user.company.optInToRevitConfigurations,
      }),
    [actionSingleUser, user.company.optInToRevitConfigurations],
  );

  const entitlementsColDef = useMemo(() => getEntitlementsColDef(), []);
  const rolesColDef = useMemo(() => getRolesColDef(), []);

  const licensesDetailTable = [
    {
      title: 'Licenses',
      colDef: entitlementsColDef,
      hideHeader: true,
      fetchPage: fetchEntitlementPage,
      icon: null,
      autoExpand: true,
      configMapper: (d: User) => ({ params: { userId: d.userId } }),
    },
  ];

  const rolesDetailTable = [
    {
      title: 'Roles',
      colDef: rolesColDef,
      hideHeader: true,
      fetchPage: fetchUserRoles,
      icon: null,
      configMapper: (d: User) => ({ url: `admin/Role/assigned/${d.userId}` as EvolveURL }),
    },
  ];

  const { agGridProps, expandDetailGrid, refreshDetailGrid, refreshGrid, filterIsSet } = useServerSideGrid({
    tableName: 'company-members',
    fetchPage,
    colDef,
    rowId: 'userId',
    detailTables: user.company.optInToRevitConfigurations
      ? [...licensesDetailTable, ...rolesDetailTable]
      : licensesDetailTable,
  });

  const clearSelection = useCallback(() => {
    agGridProps.ref.current?.api.deselectAll();
  }, [agGridProps.ref]);

  const defaultColDef = useMemo<(typeof agGridProps)['defaultColDef']>(
    () => ({
      ...agGridProps.defaultColDef,
      cellClassRules: {
        'disabled-row': (r) => r.data?.inviteStatus === 'Deleted',
      },
    }),
    [agGridProps.defaultColDef],
  );

  const deleteOrRemoveDisabled = useMemo(
    () => selectedRows.some((r) => r.userId === user.userId),
    [selectedRows, user.userId],
  );

  const rowSelection = useMemo(
    () =>
      getMultiRowSelection<User>({
        checkboxes: (d) => !d.data?.isDeleted,
      }),
    [],
  );

  const getDropdownData = useCallback(
    (action: CompanyMembersBulkAction) => {
      const disabled =
        ((action === 'Delete' || action === 'Remove admin') && deleteOrRemoveDisabled) ||
        (action === 'Remove admin' && !selectedRows.some((u) => u.isAdmin)) ||
        (action === 'Add admin' && selectedRows.some((u) => u.isAdmin));

      if (action === 'Delete' || action === 'Remove admin') {
        if (deleteOrRemoveDisabled) {
          return {
            value: action,
            label: `${action} (cannot ${action === 'Delete' ? 'delete' : 'remove'} self)`,
            disabled,
          };
        } else if (!selectedRows.find((u) => u.isAdmin) && action === 'Remove admin') {
          return { value: action, label: `${action} (non-admin selected)`, disabled };
        }
        return { value: action, label: action, disabled };
      }
      if (action === 'Add admin' && selectedRows.some((u) => u.isAdmin)) {
        return { value: action, label: `${action} (existing admin selected)`, disabled };
      }
      return { value: action, label: action, disabled };
    },
    [deleteOrRemoveDisabled, selectedRows],
  );

  if (isNil(user)) return <Loader m="lg" />;

  return (
    <>
      <BasePageHeader
        title="Company Members"
        filterIsSet={filterIsSet || !!searchPhrase || showNoLicense || selectedProductPools.length > 0}
        onFilterClear={() => {
          setSearchPhrase('');
          searchHandler('');
          setShowNoLicense(false);
          setSelectedProductPools([]);
        }}
        gridRef={agGridProps.ref}
        noHistory
        topRightComponent={
          <>
            <Popover position="bottom-end">
              <Popover.Target>
                <Button variant="outline" color="gray" c="dark" leftSection={<EvolveIcon icon="Download" />}>
                  Downloads
                </Button>
              </Popover.Target>
              <Popover.Dropdown>
                <Flex direction="column" gap="lg">
                  {user.company.companyDownloadPaths.map((d) => (
                    <CompanyDocument key={d.document} user={user} downloadPath={d} />
                  ))}
                  {user.company.companyDownloadPaths.length === 0 && (
                    <Text fz="sm" c="dimmed">
                      No downloads found.
                    </Text>
                  )}
                </Flex>
              </Popover.Dropdown>
            </Popover>
            <InviteUsersModal pageType="company" refresh={refreshGrid} companyId={user.companyId} />
          </>
        }
        bottomLeftComponent={
          <Flex align="center">
            <WrappedSelect<CompanyMembersBulkAction>
              disabled={selectedRows.length === 0}
              value={selectedAction}
              placeholder={selectedRows.length > 0 ? `Action (${selectedRows.length})` : 'Action'}
              data={companyMembersBulkActions
                .filter((a) => (a === 'Assign roles' ? user.company.optInToRevitConfigurations : true))
                .map(getDropdownData)}
              onChange={setSelectedAction}
            />
            {user.isAdmin && (
              <>
                {user.company.optInToRevitConfigurations &&
                  isNotNil(assignableRoles?.find((r) => r.isDisplayNameEditable)) && (
                    <Button ml="sm" variant="outline" onClick={() => setConfigureRoleNames(true)}>
                      Configure Role Names
                    </Button>
                  )}
                <Checkbox
                  label={`Opt${user.company.optInToRevitConfigurations ? 'ed' : ''}-in to Revit Web Centralized Configurations`}
                  checked={user.company.optInToRevitConfigurations}
                  onClick={() => setConfirmRevitRoleAction(true)}
                  ml="md"
                  readOnly
                />
              </>
            )}
          </Flex>
        }
        bottomRightComponent={
          <>
            <TextInputDebounced
              leftSection={<EvolveIcon icon="Search" />}
              placeholder="Search..."
              onChange={searchHandler}
              value={searchPhrase}
              onImmediateChange={setSearchPhrase}
            />
            <WrappedMultiSelect<'No Licenses' | ProductPoolId>
              value={showNoLicense ? ['No Licenses'] : selectedProductPools}
              placeholder="Filter by license..."
              data={[
                {
                  group: '',
                  items: [
                    {
                      label: 'No Licenses',
                      value: 'No Licenses' as const,
                    },
                  ],
                },
                {
                  group: 'Licenses',
                  items: productPools.map((p) => ({
                    label: p.product.productName,
                    value: p.productPoolId,
                  })),
                },
              ]}
              onChange={(selected) => {
                if (showNoLicense || !selected.includes('No Licenses')) {
                  setShowNoLicense(false);
                  setSelectedProductPools(selected.filter((s): s is ProductPoolId => s !== 'No Licenses'));
                } else if (selected.includes('No Licenses')) {
                  setShowNoLicense(true);
                  setSelectedProductPools([]);
                }
              }}
              comboboxProps={{
                styles: {
                  option: { marginTop: 1, marginBottom: 1 },
                },
              }}
              clearable
              style={{ width: 250 }}
            />
          </>
        }
      />
      <div style={{ height: '100%' }}>
        <AgGridReact<User>
          {...agGridProps}
          defaultColDef={defaultColDef}
          rowSelection={rowSelection}
          onSelectionChanged={({ api }) => {
            setSelectedRows(api.getSelectedRows());
          }}
          noRowsOverlayComponent={() => <NoRowsOverlay label="No members found." gridRef={agGridProps.ref} />}
        />
      </div>
      <AddLicensesModal
        userIds={usersToAction.map((r) => r.userId)}
        opened={selectedAction === 'Add licenses'}
        onClose={() => setSelectedAction(null)}
        refresh={() => {
          usersToAction.forEach((r) => {
            refreshDetailGrid(r.userId, 'Licenses');
            expandDetailGrid(r.userId, 'Licenses');
          });
          clearSelection();
        }}
      />
      <AssignRolesModal
        userIds={usersToAction.map((r) => r.userId)}
        opened={selectedAction === 'Assign roles'}
        assignableRoles={assignableRoles ?? []}
        onClose={() => setSelectedAction(null)}
        refresh={() => {
          refreshGrid();
          clearSelection();
        }}
      />
      <ConfirmationModal
        title={`Confirm opt-${user.company.optInToRevitConfigurations ? 'out' : 'in'}`}
        opened={confirmRevitRoleAction}
        onClose={() => setConfirmRevitRoleAction(false)}
        confirmationText="Confirm"
        buttonColor={user.company.optInToRevitConfigurations ? 'red' : theme.primaryColor}
        loading={optingInOut || loadingUser}
        onConfirm={() => {
          void optInOutRevitConfig({}).then(() => {
            void refreshUser().then(() => {
              notifications.show({
                title: 'Success',
                message: `Successfully opted-${!user.company.optInToRevitConfigurations ? 'in' : 'out'}`,
                color: 'green',
              });
              setConfirmRevitRoleAction(false);
            });
          });
        }}
      >
        Are you sure you want to opt-{user.company.optInToRevitConfigurations ? 'out from' : 'in to'} Revit Web
        Centralized Configurations?
      </ConfirmationModal>
      <ConfirmationModal
        title="Confirm delete users"
        opened={selectedAction === 'Delete'}
        onClose={() => setSelectedAction(null)}
        loading={deleting}
        confirmationText="Delete"
        buttonColor="red"
        onConfirm={() => {
          void deleteUsers(usersToAction.map((r) => r.userId)).then(() => {
            clearSelection();
            refreshGrid();
            setSelectedAction(null);
          });
        }}
      >
        Are you sure you want to delete{' '}
        {usersToAction.length === 1 ? usersToAction[0].userEmail : `${usersToAction.length} users`}?
      </ConfirmationModal>
      <ConfirmationModal
        title={`Confirm ${selectedAction === 'Add admin' ? 'add' : 'remove'} admin${usersToAction.length === 1 ? '' : 's'}`}
        opened={selectedAction === 'Add admin' || selectedAction === 'Remove admin'}
        onClose={() => setSelectedAction(null)}
        loading={addingOrRemoving}
        confirmationText={selectedAction === 'Add admin' ? 'Add' : 'Remove'}
        buttonColor={selectedAction === 'Add admin' ? 'green' : 'red'}
        onConfirm={() => {
          usersToAction.forEach((u) => {
            void addOrRemoveAdmin({ isAdmin: selectedAction === 'Add admin' }, { url: `admin/user/${u.userId}` }).then(
              () => {
                notifications.show({
                  title: 'Success',
                  message: `User ${u.userEmail} successfully ${selectedAction === 'Add admin' ? 'added' : 'removed'} as an admin`,
                });
                clearSelection();
                refreshGrid();
                setSelectedAction(null);
              },
            );
          });
        }}
      >
        {selectedAction === 'Add admin'
          ? `Make ${usersToAction.length === 1 ? usersToAction[0].userEmail + ' an admin' : usersToAction.length + ' users admins'}?`
          : `Remove ${usersToAction.length === 1 ? usersToAction[0].userEmail + ' as an admin' : usersToAction.length + ' users as admins'}?`}
      </ConfirmationModal>
      {isNotNil(assignableRoles) && assignableRoles.length > 0 && (
        <RoleNamesModal
          roles={assignableRoles}
          opened={configureRoleNames}
          onClose={() => {
            setConfigureRoleNames(false);
            refreshGrid();
          }}
          fetchRoles={fetchAssignableRoles}
        />
      )}
    </>
  );
};
