// Copyright ©️ 2024 eVolve MEP, LLC
import { forwardRef } from 'react';

import { Card, type CardProps, Stack } from '@mantine/core';

export const ComponentPane = forwardRef<HTMLDivElement, CardProps>(({ children, ...props }, ref) => (
  <Card
    ref={ref}
    display="flex"
    px={16}
    py={12}
    shadow="sm"
    style={{
      height: '100%',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      overflowY: 'hidden',
    }}
    {...props}
  >
    <Stack style={{ height: '100%', flex: 1, overflow: 'hidden', gap: 0 }}>{children}</Stack>
  </Card>
));
