// Copyright ©️ 2024 eVolve MEP, LLC
import { useCallback, useState, useEffect, useMemo } from 'react';

import { AgGridReact } from 'ag-grid-react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import type { SelectedProject } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { TextInputDebounced } from 'components/Mantine/TextInputDebounced';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { getMultiRowSelection } from 'helpers/ag-grid/baseColumnDef';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { useWrappedPaginatedGet, useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import { type ProjectUser, type ProjectUserId, getMembersColDef } from 'modules/Shop/ShopMembers/ShopMembersMantine/columnDefs';
import { InviteUsersModal } from 'modules/Shop/ShopMembers/ShopMembersMantine/InviteUsersModal';

type Props = {
  selectedProject: SelectedProject;
};

export const ProjectMembersPage = ({ selectedProject }: Props) => {
  const [selectedRows, setSelectedRows] = useState<ProjectUserId[]>([]);
  const [usersToDelete, setUsersToDelete] = useState<ProjectUserId[]>([]);

  const { fetchPage, setDefaultOpts, searchHandler } = useWrappedPaginatedGet<ProjectUser>('admin/projectUser', {
    lazy: true,
  });
  useEffect(() => {
    setDefaultOpts({
      lazy: true,
      defaultConfig: {
        params: {
          projectId: selectedProject.id,
        },
      },
    });
  }, [setDefaultOpts, selectedProject.id]);

  const { apiCall: deleteProjectUsers, loading: deleting } = useWrappedPatch<
    unknown,
    {
      projectUserIds: ProjectUserId[];
    }
  >('admin/projectUser/multidelete');

  const onDeleteSingleUser = useCallback((projectUser: ProjectUser) => {
    setUsersToDelete([projectUser.projectUserId]);
  }, []);

  const colDef = useMemo(
    () => getMembersColDef({ showAdmins: true, deleteUser: onDeleteSingleUser }),
    [onDeleteSingleUser],
  );
  const { agGridProps, refreshGrid, filterIsSet } = useServerSideGrid({
    tableName: 'project-members',
    fetchPage,
    colDef,
    rowId: 'projectUserId',
  });

  const onConfirmDeleteProjectUsers = () => {
    void deleteProjectUsers({
      projectUserIds: usersToDelete,
    }).then(() => {
      setUsersToDelete([]);
      agGridProps.ref.current?.api.deselectAll();
      setSelectedRows([]);
      refreshGrid();
    });
  };

  const rowSelection = useMemo(
    () =>
      getMultiRowSelection<ProjectUser>({
        checkboxes: (d) => !d.data?.isDeleted,
      }),
    [],
  );

  return (
    <>
      <BasePageHeader
        title="Project Members"
        gridRef={agGridProps.ref}
        filterIsSet={filterIsSet}
        topRightComponent={
          <InviteUsersModal
            pageType="project"
            refresh={refreshGrid}
            projectId={selectedProject.id}
            companyId={selectedProject.companyId}
          />
        }
        bottomLeftComponent={
          <WrappedSelect
            disabled={selectedRows.length === 0}
            value=""
            placeholder={selectedRows.length > 0 ? `Action (${selectedRows.length})` : 'Action'}
            data={['Delete']}
            onChange={(selected) => {
              if (selected === 'Delete') {
                setUsersToDelete(selectedRows);
              }
            }}
            styles={{
              option: {
                color: 'red',
              },
            }}
          />
        }
        bottomRightComponent={
          <TextInputDebounced
            leftSection={<EvolveIcon icon="Search" />}
            onChange={searchHandler}
            placeholder="Search..."
          />
        }
      />
      <div style={{ height: '100%' }}>
        <AgGridReact<ProjectUser>
          {...agGridProps}
          rowSelection={rowSelection}
          onSelectionChanged={({ api }) => {
            setSelectedRows(api.getSelectedRows().map((r) => r.projectUserId));
          }}
          noRowsOverlayComponent={() => 'No members found.'}
        />
      </div>
      <ConfirmationModal
        onConfirm={onConfirmDeleteProjectUsers}
        loading={deleting}
        onClose={() => {
          setUsersToDelete([]);
        }}
        opened={usersToDelete.length > 0}
        buttonColor="red"
      >
        Are you sure you want to delete {usersToDelete.length === 1 ? 'this user' : `${usersToDelete.length} users`}?
      </ConfirmationModal>
    </>
  );
};
