// Copyright ©️ 2025 eVolve MEP, LLC
import type { ReactNode } from 'react';

import { Flex, Radio } from '@mantine/core';
import type { ColDef } from 'ag-grid-enterprise';

import { CellRenderer } from 'components/Mantine/CellRenderer';
import type { TaskId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import type { WorkCellTask } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

const Spacer = ({ children }: { children: ReactNode }) => (
  <Flex justify="center" align="center" style={{ width: 75, height: '100%' }}>
    {children}
  </Flex>
);

export const getCancelTasksColumnDef = (
  tasksToUpdate: Record<TaskId, boolean>,
  setTaskDecision: (taskId: TaskId, isSaveData: boolean) => void,
  disabled: boolean,
): ColDef<WorkCellTask>[] => [
  {
    field: 'taskName',
    tooltipField: 'taskName',
    flex: 3,
  },
  {
    headerName: 'Item',
    field: 'workRequestItemName',
    tooltipField: 'workRequestItemName',
    flex: 4,
  },
  {
    width: 160,
    headerComponent: () => (
      <Flex justify="space-evenly" style={{ width: '100%' }}>
        <Spacer>Save</Spacer>
        <Spacer>Discard</Spacer>
      </Flex>
    ),
    cellRenderer: CellRenderer<WorkCellTask>(({ data }) => (
      <Radio.Group
        value={data.taskId in tasksToUpdate ? (tasksToUpdate[data.taskId] ? 'yes' : 'no') : undefined}
        onChange={(value) => setTaskDecision(data.taskId, value === 'yes')}
        style={{ width: '100%' }}
      >
        <Flex justify="space-evenly" align="center">
          <Spacer>
            <Radio value="yes" color="gray.8" disabled={disabled} />
          </Spacer>
          <Spacer>
            <Radio value="no" color="gray.8" disabled={disabled} />
          </Spacer>
        </Flex>
      </Radio.Group>
    )),
  },
];
