// Copyright ©️ 2024 eVolve MEP, LLC
import { Tabs } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

import { AddEditItemDetails } from './AddEditItemDetails';
import { AddPartToAssembly } from './AddPartToAssembly';
import { AddTaskToAssembly } from './AddTaskToAssembly';
import { usePartEditor } from './usePartEditor';

export const AddEditItemPane = () => {
  const { selectedTab, setSelectedTab, publishing } = usePartEditor();

  return (
    <Tabs
      value={selectedTab}
      onChange={(t) => setSelectedTab(t as typeof selectedTab)}
      variant="outline"
      display="flex"
      style={{
        flex: selectedTab === 'details' ? '0 1 auto' : '1 1 auto',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Tabs.List mb="md">
        <Tabs.Tab
          value="details"
          disabled={publishing}
          leftSection={<EvolveIcon icon="Assembly" light={selectedTab !== 'details'} />}
        >
          Properties
        </Tabs.Tab>
        <Tabs.Tab
          value="parts"
          disabled={publishing}
          leftSection={<EvolveIcon icon="Part" light={selectedTab !== 'parts'} />}
        >
          Add Parts
        </Tabs.Tab>
        <Tabs.Tab
          value="tasks"
          disabled={publishing}
          leftSection={<EvolveIcon icon="WorkCellTask" light={selectedTab !== 'tasks'} />}
        >
          Add Tasks
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel
        value="details"
        px="xs"
        styles={{ panel: { display: 'flex', flexDirection: 'column', flex: '1 1 auto', overflow: 'auto' } }}
      >
        <AddEditItemDetails />
      </Tabs.Panel>
      <Tabs.Panel value="parts" styles={{ panel: { display: 'flex', flexDirection: 'column', flex: '1 1 auto' } }}>
        <AddPartToAssembly />
      </Tabs.Panel>
      <Tabs.Panel value="tasks" styles={{ panel: { display: 'flex', flexDirection: 'column', flex: '1 1 auto' } }}>
        <AddTaskToAssembly />
      </Tabs.Panel>
    </Tabs>
  );
};
